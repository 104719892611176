<template>
    <div>
        <IndexHeader_home :textMeg="textMeg"/>
        <div class="content">
            <div>
               <div class="head">
                   <p>
                       有奖留言仅限于对平台的建议，如你的留言被采用，平台会以充话费和邮寄精美礼品的形式进行奖励：
                   </p>
                   <p>
                       <span>一等奖1名：价值1000元的平台代金券</span>
                       <span>二等奖2名：价值500元的话费充值卡</span>
                       <span>三等奖3名：价值200元惊喜大礼包</span>
                   </p>
               </div>
                <ul>
                    <li>服务类型：</li>
                    <li>
                        <div>
                            <input id="lx01" type="radio" name="lx" value="1" v-model="serverType" checked>
                            <label for="lx01"></label>
                            <span>我要投诉</span>
                        </div>
                        <div>
                            <input id="lx02" type="radio" name="lx" value="2" v-model="serverType">
                            <label for="lx02"></label>
                            <span>我要建议</span>
                        </div>
                        <div>
                            <input id="lx03" type="radio" name="lx" value="3" v-model="serverType">
                            <label for="lx03"></label>
                            <span>我要咨询</span>
                        </div>
                    </li>
                </ul>
                <formItem required title="留言者姓名" type="text" errorStyle="inline" :error="username.error" :value="username.value"  v-model="username.value" placeholder="请填写留言者的姓名" />
                <formItem required title="联系电话" type="text" errorStyle="inline" :error="tel.error" :value="tel.value"  v-model="tel.value" placeholder="请填写常用的手机号码" />
                <formItem required title="留言主题" type="text" errorStyle="inline" :error="theme.error" :value="theme.value"  v-model="theme.value" placeholder="请填写留言主题" />
                <formItem required title="留言内容" type="textarea" errorStyle="inline" :error="leaveContent .error" :value="leaveContent .value"  v-model="leaveContent .value" placeholder="请填写留言内容" />
                <ul>
                    <li><button @click="submit">提交留言</button></li>
                </ul>
            </div>
        </div>
        <IndexFooter_home />
    </div>
</template>

<script>
import IndexHeader_home from '@/components/IndexHeader_home.vue'
import IndexFooter_home from '@/components/IndexFooter_home.vue'
import formItem from '@/components/UI/formItem.vue'

export default {
    data(){
        return{
            textMeg:'有奖留言',
            serverType:1,
            username:{
                value:"",
                error:"",
                validate:['required'],
                name:"留言者姓名"
            },
            tel:{
                value:"",
                error:"",
                validate:['required','phone'],
                name:"联系电话"
            },
            theme:{
                value:"",
                error:"",
                validate:['required'],
                name:"留言主题"
            },
            leaveContent:{
                value:"",
                error:"",
                validate:['required'],
                name:"留言内容"
            }
        }
    },
    methods:{
        submit(){
            var data = {
                username:this.username.value,
                tel:this.tel.value,
                theme:this.theme.value,
                leaveContent:this.leaveContent.value,
                serverType:this.serverType
            }
            var dataV = {
                username:this.username,
                tel:this.tel,
                theme:this.theme,
                leaveContent:this.leaveContent
            }
            var validate = this.$com.validate(dataV);
            if(validate){
                this.$axios.post(this.$com.testUsIp + '/leaveWord/saveLeaveWord',this.$qs.stringify(data))
                .then(res=>{
                    if(res.data.code==0){
                        this.$dialog.alert('留言成功！','success',()=>{
                            this.username.value="";
                            this.tel.value="";
                            this.theme.value="";
                            this.leaveContent.value="";
                            this.serverType=1
                        })
                    }
                })
            }
        }
    },
    components: {
        IndexHeader_home,IndexFooter_home,formItem
    },
}
</script>

<style scoped>
    .content{
        background: #f5f5f5;
        padding: 20px;min-width: 1200px;
    }
    .content>div{
        width: 1200px;margin: 0 auto;
        background: #fff;
        min-height: 400px;padding: 20px;
    }
    .content .head{
        width: 100%;height: 80px;
        background: #FFF3E9;padding: 10px 20px;
    }
    .head p:first-child{
        font-size: 12px;color: #666;
        line-height: 30px
    }
    .head p:nth-child(2) span{
        font-size: 16px;color: #ff3700;font-weight: bold;
        margin-right: 75px
    }
    ul{
        display: flex;margin-bottom: 20px;
        margin-left: 260px;
    }
    ul:nth-child(2){
        line-height: 40px;
        padding-top:  5px;font-size: 14px;
        margin: 20px 0 10px 290px;
    }
    button{
        margin-left: 107px;border: 0;cursor: pointer;
        width: 180px;height: 40px;font-size: 18px;
        color: #fff;background: #3296FA;line-height: 40px
    }
    ul li:nth-child(2)>div{
        position: relative;
        line-height: 40px;
        float: left;font-size: 14px;color: #666
    }
    ul li:nth-child(2)>div{
        margin-left: 7px;
        margin-right: 50px
    }
    input[type="radio"] {
        width: 10px;
        height: 10px;
        opacity: 0;
    }
    label {
        position: absolute;
        left: 0;
        top: 14px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 1px solid #999;
    }
    input:checked+label{
        background-color: #3296FA;
        border: 1px solid #006eb2;
    }
    input:checked+label::after {
        position: absolute;
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #fff;
        top: 2px;
        left: 2px;
        border: 2px solid #fff;
    }
</style>

