<template>
    <div>
        <router-view></router-view>
        <IndexFooter_home />
    </div>
</template>

<script>
import IndexFooter_home from '@/components/IndexFooter_home.vue'

export default {
    data(){
        return{
            textMeg:''
        }
    },
    components: {
        IndexFooter_home
    },
    mounted:function(){
    }
}
</script>

<style scoped>
    .content{
        background: #f5f5f5;
        padding: 20px 0;
    }
</style>
