<template>
    <div class="Hea">
        <Header />
        <IndexHeader />
        <div class="Nav">
            <img src="../assets/img/kuai.png" alt="">
            <span>{{textMeg}}</span>
            <Nav_ul/>
        </div>
    </div>
</template>


<script>
import Header from '@/components/Header.vue'
import IndexHeader from '@/components/IndexHeader.vue'
import Nav_ul from '@/components/Nav_ul.vue'
export default {
    props:['textMeg'],
    data(){
        return{

        }
    },
    methods:{

    },
    components: {
        Header,IndexHeader,Nav_ul
    },
}
</script>


<style scoped>
    .Hea{
        border-bottom: 3px solid #3296FA;
        min-width: 1200px;
    }
    .Nav{
        width: 1200px;margin: 0 auto;
        display: flex;position: relative;
    }
    .Nav img{
        margin-right:40px
    }
    .Nav span{
        position: absolute;left: 0;
        width: 120px;color: #fff;font-size: 16px;
        text-align: center;line-height: 40px
    }
</style>

