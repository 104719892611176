<template>
    <div class="indx">
        <div class="no1" v-loading="loading">
            <!-- <div v-for="list in lists" class="no3">
                <p><img :src="list.certificateImgPath" alt=""></p>
                <p :title="list.orgName">{{list.orgName}}</p>
                <p>日期：{{list.createTime}}</p>
            </div> -->
            <goodsItem v-show="lists.length > 0" class="x" type="time" v-for="(item,index) in lists" :key="index" :goodsData="item" />
            <pagination v-if="total>8" :total="total" :current="current" :pageSize="8" @pageChange="goQuery"/>
            <img v-show="lists.length == 0" class="empty-img" src="../../assets/img/user/empty_page.jpg" alt="暂无数据">
        </div>
    </div>
</template>

<script>
import goodsItem from '@/components/UI/goodsItem.vue'
import pagination from '@/components/UI/Pagination.vue'
export default {
    props:['id'],
    data(){
        return{
            lists:[],
            total:'',
            loading:true,
            current:1,
        }
    },
    methods:{
        goQuery(page){
            this.$axios.get(this.$com.testCsIp + '/shop/shopManageCaseList/' + this.id + '/'+ page + '/8')
            .then(res=>{
                if(res.data.code == 0){
                    this.loading=false;
                    if (res.data.data.records.length>0) {
                        for (var i = 0; i < res.data.data.records.length; i++) {
                            var item = res.data.data.records[i];
                            res.data.data.records[i].name=item.orgName;
                            res.data.data.records[i].imagePath=item.certificateImgPath;
                            res.data.data.records[i].cycle=item.createTime;
                        };
                    };
                    this.lists=res.data.data.records
                    this.total=res.data.data.total
                }
            })
        }
    },
    components: {
        goodsItem,pagination
    },
    mounted(){
        this.goQuery(1);
    }
}
</script>


<style scoped>
    .indx{
        min-width: 1200px;background: #f5f5f5;
        padding: 20px 0 0.1px 0
    }
    .no1{
        width: 1200px;background: #fff;
        margin: 0 auto;
        overflow:hidden;
        padding: 20px 0 0 20px;min-height: 350px;
        overflow: hidden;margin-bottom: 20px
    }
    .no1>.no3{
        width: 276px;height: 284px;background: #f5f5f5;
        border: none
    }
    .no1>div{
        margin-right: 19px;margin-bottom: 19px;
    }
    .no1 p:nth-child(2){
        height: 44px;color: #333;font-size: 16px;
        line-height: 22px; margin: 16px 12px 0 12px;
        font-weight: bold
    }
    .no1 p:nth-child(3){
        margin: 10px 12px 0;font-size: 12px;color: #666
    }
    .x{
        width: 276px
    }
    .empty-img{
        display: block;
        margin: auto
    }
</style>
