<template>
    <div>
        <el-dialog :close-on-click-modal="false" top="6vh" title="委托单" :visible.sync="snycShow" @close="()=>$emit('update:snycShow', snycShow )">
            <div>
                <div>
                    <div class="meg-wrap">
                        <div class="header"><span>服务方信息</span></div>
                        <ul>
                            <li>
                                <label>服务商名称：</label>
                                <span>{{formData.userServicesName||"无"}}</span>
                            </li>
                            <li>
                                <label>联系人：</label>
                                <span>{{formData.userServicesContacts||"无"}}</span>
                            </li>
                            <li>
                                <label>服务机构地址：</label>
                                <span>{{formData.userServicesAddress||"无"}}</span>
                            </li>
                            <li>
                                <label>联系电话：</label>
                                <span>{{formData.userServicesTel||"无"}}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="meg-wrap">
                        <div class="header"><span>委托人信息</span></div>
                        <ul>
                            <li>
                                <label>委托方名称：</label>
                                <span>{{formData.userDemandName||"无"}}</span>
                            </li>
                            <li v-if="type==='seller'">
                                <label>委托方地址：</label>
                                <span>{{formData.userDemandAddress||"无"}}</span>
                            </li>

                            <formItem :disabled="status=='1'||status=='2'" @change="(val)=>formData.demandAddress=val" itemStyle="inline" inpWidth="60%" v-else width="100" maxlength="25" errorStyle="inline" title="委托方地址" type="text" :value="demandAddress" placeholder="请填写委托方地址" />

                            <li v-if="type==='seller'">
                                <label>联系电话：</label>
                                <span>{{formData.userDemandTel||"无"}}</span>
                            </li>
                            <formItem :disabled="status=='1'||status=='2'" @change="(val)=>formData.demandTel=val" itemStyle="inline" inpWidth="60%" v-else width="100" maxlength="25" errorStyle="inline" title="联系电话" type="text" :value="demandTel" placeholder="请填写联系电话" />
                        </ul>
                    </div>

                    <div class="meg-wrap">
                        <div class="header"><span>服务协议信息</span></div>
                        <div v-if="disabled||status=='1'||status=='2'">
                            <formItem disabled itemStyle="inline" inpWidth="60%" inputType="money" width="100" maxlength="25" errorStyle="inline" title="协议价格" required type="text" v-model="formData.consultPrice.value" placeholder="请填写协议价格" />
                            <formItem disabled itemStyle="inline" inpWidth="60%" width="100" maxlength="200" errorStyle="inline" title="协议内容" required type="textarea" v-model="formData.consultContent.value" placeholder="请填写协议内容" />
                            <formItem disabled itemStyle="inline" inpWidth="60%" width="100" maxlength="25" errorStyle="inline" title="委托人姓名" required type="text" v-model="formData.signature.value" placeholder="请填写委托人姓名" />
                        </div>
                        <div v-else>
                            <formItem itemStyle="inline" inpWidth="60%" inputType="money" width="100" maxlength="25" errorStyle="inline" title="协议价格" required :error="formData.consultPrice.error" type="text" v-model="formData.consultPrice.value" placeholder="请填写协议价格" />
                            <formItem itemStyle="inline" inpWidth="60%" width="100" maxlength="200" errorStyle="inline" title="协议内容" required :error="formData.consultContent.error" type="textarea" v-model="formData.consultContent.value" placeholder="请填写协议内容" />
                            <formItem itemStyle="inline" inpWidth="60%" width="100" maxlength="25" errorStyle="inline" title="委托人姓名" required :error="formData.signature.error" type="text" v-model="formData.signature.value" placeholder="请填写委托人姓名" />
                        </div>
                    </div>

                    <div class="meg-wrap" v-if="showReason">
                        <div class="header"><span>审批意见</span></div>
                        <ul>
                            <formItem :disabled="status=='2'||status=='3'" itemStyle="inline" inpWidth="60%" width="100" maxlength="25" errorStyle="inline" title="驳回原因" type="text" v-model="formData.reason" placeholder="请填写驳回原因" />
                        </ul>
                    </div>

                    <el-checkbox :disabled="status=='1'||status=='2'" v-if="type!='seller'" v-model="checked">委托人郑重声明：上列填写内容正确属实，本人愿意承担有关责任</el-checkbox>
                </div>
                <div slot="footer" class="dialog-footer seller-btn" v-if="type==='seller'">
                    <div v-if="status=='1'">
                        <xbutton @on-click="()=>$emit('on-ok')" value="审核通过" />
                        <xbutton @on-click="()=>$emit('on-no')" btnType="bg-fff" value="审核驳回" />
                    </div>
                </div>
                <div slot="footer" class="dialog-footer" v-else>
                    <xbutton :disabled="status=='1'||status=='2'" @on-click="SubmitClick" value="保存并提交服务商审核" />
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script type="text/javascript">
import xbutton from '@/components/UI/xbutton.vue'
import formItem from '@/components/UI/formItem.vue'
  export default {
    props:{
        snycShow:Boolean,
        title:String,
        formData:Object,
        type:String,
        disabled:Boolean,
        status:String,
    },
    data () {
      return {
        checked:this.status=='1'||this.status=='2',
        thisData:{},
        _formData:this.formData,
      }
    },
    components: {
        xbutton,
        formItem
    },
    watch:{
        status(val){
            if (val == '1'||val == '2') {
                this.checked = true
            }
        },
        snycShow(val){
            this.checked = !val
        },
        formData:{
            handler(val){
                this._formData = val;
            },
            deep: true
        },
    },
    computed:{
        showReason:function(){
            if (this.type==='seller') {
                if(this.status=='2'||this.status=='3'){
                    if (this.formData.reason) {
                        return true;
                    }else{
                        return false;
                    };
                }else{
                    return true;
                }
            }else{
                if (this.status=='3') {
                    return true;
                }else{
                    return false;
                };
            };
        },
        demandAddress:function(){
            if (this.status=='1'||this.status=='2'||this.status=='3') {
                return this.formData.userDemandAddress
            }else{
                return this.formData.demandAddress
            };
        },
        demandTel:function(){
            if (this.status=='1'||this.status=='2'||this.status=='3') {
                return this.formData.userDemandTel
            }else{
                return this.formData.demandTel
            };
        }
    },
    methods: {
        SubmitClick:function(){
            var dataV = {
                consultPrice:this.formData.consultPrice,
                consultContent:this.formData.consultContent,
                signature:this.formData.signature
            };
            var validate = this.$com.validate(dataV);
            if (validate) {
                if (!this.checked) {
                    this.$dialog.alert("请勾选声明",'confirm')
                }else{
                    this.$emit('on-click')
                };
            };
        },
    }
  }
</script>

<style scoped>
.meg-wrap ul{
    clear:both;
    overflow:hidden;
    padding:10px 0;
}
.meg-wrap li{
    width:50%;
    float:left;
}
.header{
    font-weight:bold;
    border-bottom:1px dashed #ccc;
}
.header span{
    border-left:3px solid #ff4a03;
    display:inline-block;
    height:14px;
    line-height:14px;
    padding-left:6px;
}
.meg-wrap label{
    width:100px;
    text-align:right;
    margin-right:7px;
}
.dialog-footer{
    text-align:center;
}
.dialog-footer button{
    width:260px;
}
.seller-btn button{
    width:150px;
    margin:0 10px;
}
</style>