import { render, staticRenderFns } from "./Order_download.vue?vue&type=template&id=5b22fbe6&scoped=true"
import script from "./Order_download.vue?vue&type=script&lang=js"
export * from "./Order_download.vue?vue&type=script&lang=js"
import style0 from "./Order_download.vue?vue&type=style&index=0&id=5b22fbe6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b22fbe6",
  null
  
)

export default component.exports