<template>
    <div class="indx">
        <div class="ind">
            <div class="no" v-loading="loading">
                <div><span>服务列表</span><span @click="goToone" class="more">更多+</span></div>
                <div class="no1">
                    <!-- <div v-for="list in serviceList" class="mores" @click="goToS(list.id)">
                        <p><img :src="list.coveImagePath" class="img1" alt=""></p>
                        <p :title="list.serviceName">{{list.serviceName}}</p>
                        <p v-if="list.priceType === 1">￥{{list.price}}</p>
                        <p v-if="list.priceType === 2">￥{{list.priceMin}}-￥{{list.priceMax}}</p>
                        <p v-if="list.priceType === 3">面议</p>
                        <p>周期：{{list.cycleName}}</p>
                    </div> -->
                    <goodsItem v-show="serviceList.length > 0" type="money" v-for="(item,index) in serviceList" :key="index" :goodsData="item" @on-clickDetail="goToS(item.id)" />
                    <img v-show="serviceList.length == 0" class="empty-img" src="../../assets/img/user/empty_page.jpg" alt="暂无数据">
                </div>
            </div>
            <div class="no" v-loading="loading">
                <div><span>资质证书</span><span @click="goTotwo" class="more">更多+</span></div>
                <div class=" no2">
                    <div class="noi2" v-show="qualificationList.length > 0" v-for="(list,i) in qualificationList" :key="i">
                        <img class="img2" :src="list.imgIdPath||$com.errorImg" :onerror="$com.errorImg" alt="资质证书">
                    </div>
                    
                    <div class="empty-wrap" v-show="qualificationList.length == 0">
                        <img class="empty-img" src="../../assets/img/user/empty_page.jpg" alt="暂无数据">
                    </div>
                </div>
            </div>
            <div class="no" v-loading="loading">
                <div><span>服务案例</span><span @click="goToshree" class="more">更多+</span></div>
                <div class="no4">
                    <!-- <div v-for="list in caseList" class="no3">
                        <p><img :src="list.certificateImgPath" class="img2" alt=""></p>
                        <p :title="list.orgName">{{list.orgName}}</p>
                        <p>日期：{{list.createTime}}</p>
                    </div> -->
                    <goodsItem v-show="caseList.length > 0" class="x" type="time" v-for="(item,index) in  caseList" :key="index" :goodsData="item" />
                    <img v-show="caseList.length == 0" class="empty-img" src="../../assets/img/user/empty_page.jpg" alt="暂无数据">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import goodsItem from '@/components/UI/goodsItem.vue'
export default { 
    props:['id'],
    data(){
        return{
            caseList:[],
            qualificationList:[],
            serviceList:[],
            //id : this.$route.query.ShopId,
            loading:true,
        }
    },
    methods:{
        goToone(){
            this.$router.push({ name:'shop_service_list' , query:{ ShopId:this.id }})
        },
        goTotwo(){
            this.$router.push({ name:'shop_qua_cer' , query:{ ShopId:this.id }})
        },
        goToshree(){
            this.$router.push({ name:'shop_service_case' , query:{ ShopId:this.id }})
        },
        goToS(id){
            var routeData = this.$router.resolve({ name:'detail_page', query:  { id:id ,ShopId:this.id} });
            window.open(routeData.href, '_blank');
            //this.$router.push({ name:'detail_page', query:  { id:id ,ShopId:this.id}})
        },
        goQuery(){
            this.$axios.get(this.$com.testCwIp + '/shop/getShopDetails/' + this.id).then(res=>{
                if(res.data.code == 0){
                    this.loading=false;
                    this.qualificationList=res.data.data.qualificationList;
                    if (res.data.data.serviceList.length>0) {
                        for (var i = 0; i < res.data.data.serviceList.length; i++) {
                            var item = res.data.data.serviceList[i];
                            res.data.data.serviceList[i].name = item.serviceName;
                            res.data.data.serviceList[i].imagePath = item.coveImagePath;
                            res.data.data.serviceList[i].cycle = item.cycleName;
                        };
                    };
                    this.serviceList = res.data.data.serviceList;
                    if (res.data.data.caseList.length>0) {
                        for (var i = 0; i < res.data.data.caseList.length; i++) {
                            var item = res.data.data.caseList[i];
                            this. caseList.push({name:item.orgName,imagePath:item.certificateImgPath,cycle:item.createTime});
                        };
                    };
                }
            })
        }
    },
    components: {
        goodsItem
    },
    mounted(){
        this.goQuery();
    }
}
</script>


<style scoped>
    .noi2{
        width: 276px;
        height: 184px;
        margin-bottom: 18px;
        margin-right: 19px;
        border: 1px solid #ddd;
        float: left;
        position: relative;
    }
    .noi2>img{
        max-height:100%;
        max-width:100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    .indx{
        min-width: 1200px;background: #f5f5f5;
        padding: 20px 0
    }
    .ind{
        width: 1200px;margin: 0 auto;
    }
    .ind>div:not(:last-child){
        margin-bottom: 20px;
    }
    .ind>div{
        background: #fff;
        padding-left: 20px;
    }
    .no>div:first-child{
        line-height: 42px;font-size: 20px;font-weight: bold;
        color: #333;border-bottom: 1px solid #ddd;padding-right: 20px
    }
    .no>div:first-child span:last-child{
        float: right;font-size: 12px;font-weight: normal
    }
    .no1{
        width: 100%;background: #fff;
        overflow:hidden;margin-top: 20px;
        overflow: hidden;min-height: 360px
    }
    .no1>div{
        width: 217px;height: 340px;
        float: left;margin-right: 19px;
        margin-bottom: 19px
    }
    .no1>div p:first-child{
        width:217px;height: 162px;
    }
    .no1>div p:nth-child(2){
        font-size: 16px;font-weight: bold;
        margin: 18px 12px 0 12px;
        overflow: hidden;white-space: nowrap;text-overflow: ellipsis;
    }
    .no1>div p:nth-child(3){
        font-size: 18px;color: #e4393c;
        font-weight: bold;margin-left: 12px
    }
    .no1>div p:nth-child(4){
        font-size: 12px;color: #666;
        margin-left: 12px
    }
    .no2>img{
        width: 276px;height: 184px;
        margin-bottom:18px;margin-right: 19px 
    }
    .no2{
        min-height: 222px;overflow: hidden;padding-top: 20px;
    }
    .no4>.no3{
        width: 276px;height: 284px;background: #f5f5f5;
        border: none
    }
    .no4{
        max-height: 740px;min-height: 330px;overflow: hidden;
        margin-top: 20px
    }
    .no4>div{
        float: left;margin-right: 19px;margin-bottom: 19px;
    }
    .no4 p:nth-child(2){
        height: 44px;color: #333;font-size: 16px;
        line-height: 22px; margin: 16px 12px 0 12px;
        font-weight: bold
    }
    .no4 p:nth-child(3){
        margin: 10px 12px 0;font-size: 12px;color: #666
    }
    .more:hover{
        cursor: pointer;
        color: red
    }
    .mores:hover{
        cursor: pointer;
        box-shadow: 3px 3px 5px 0 #999
    }
    .img1{
        width: 215px;height: 162px;
        border-bottom: 1px solid #ccc
    }
    .x{
        width: 276px
    }
    .empty-wrap{
        min-height: 380px
    }
    .empty-img{
        display: block;
        margin: auto
    }
</style>
