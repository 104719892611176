<template>
    <div>
        <div v-if="titleMeg" class="no1"><span>{{titleMeg}}</span><span v-if="$route.name==='base_auth_per'" class="title-r">互联网安全法要求的实名认证</span></div>
        <router-view :rzData="pdata" @nav-change="navChange"></router-view>
    </div>
</template>

<script>
export default {
    props: {
        pdata:Object
    },
    watch:{

    },
    data(){
        return {
            titleMeg:'基本信息',
            per:false
        }
    },
    components: {

    },
    methods:{
        navChange:function(fn){
            this.$emit('nav-change', fn)
        }
    },
    mounted:function(){
        this.$parent.navData[0].open = true;
    },
}
</script>

<style scoped>
    ul,p{
        list-style: none;
        margin: 0;
        padding: 0;
    }
    div.no1{
        width:992px;height:39px;background:#fff;
        margin-bottom: 2px;
    }
    .no1 span{
        font-size: 16px;
        line-height: 37px;
        text-align: center;
        float:left;
        width: 120px;
        border-bottom: 2px solid #FF4A03;
    }
    div.no2{
        width:992px;
        background:#fff;
        position: relative;
        padding-top: 15px;
    }
    div.no2 ul{
        display: flex;
        margin-left: 112px;
        background: #fff;
        line-height: 60px;
        font-size: 14px;
    }
    div.no2 ul:nth-child(1){
        margin-top: 19px;
        line-height: 30px;
    }
    div.no2 ul>li:nth-child(1){
        width: 88px;
        text-align: right;
        margin-right: 7px;
        color: #333;
    }
    div.no2 ul input{
        width: 415px;height: 40px;
        border: 1px solid #ccc;
        color: #999;
        padding-left: 10px;
        box-sizing: border-box
    }
    div.no2 ul.sex,div.no2 ul.uname{
        line-height: 40px;
    }
    div.no2 ul.sex input{
        width: 13px;height: 13px;
        vertical-align: sub;
        margin-right: 3px
    }
    div.no2 ul.sex input:nth-child(2){
        margin-left: 56px;
    }
    div.no2 ul.addr{
        line-height: 50px;
        padding: 5px 0;
    }
    div.no2 ul.addr>li:last-child input{
        width:550px;
    }
    div.no2 ul.addr select{
        color: #999;
        padding-left: 6px;
        width:180px;height: 40px; margin-right: 5px;
        border: 1px solid #ccc;
    }
    div.no2 ul textarea{
        width:415px;height:114px;margin-top: 10px;
        border: 1px solid #ccc
    }
    div.no2 ul.btn button{
        margin-top: 10px;
        width: 178px;height: 40px;
        background-color: #3296FA;
        border: 0;color: #fff;font-size: 18px;
        margin-right: 20px;
    }
    div.no2 ul.btn button:last-child{
        background-color: #fff;
        border: 1px solid #3296FA;
        color: #3296FA
    }
    div.no2 ul.btn button:hover{
        background: linear-gradient(to right, #00aaff, #0085ff);
        cursor: pointer;
    }
    div.no2 ul.btn button:last-child:hover{
        color: #fff
    }
    div.no2 ul:first-child>li:last-child{
        position: relative;
        width: 82px;height: 82px;border: 1px solid #ccc;
        margin-bottom: 5px
    }
    div.no2 ul:first-child>li:last-child a{
        position: absolute;
        bottom: 0;
        display: block;
        background: rgba(0, 0, 0, .5);
        font-size: 12px;
        width: 80px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        color: #fff;
        text-decoration: none;
    }
    .no1 .title-r{
        font-size:14px;
        color:#ff4a03;
        width:200px;
        float:right;
        border:none;
        margin-right:40px;
    }
</style>
