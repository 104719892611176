<template>
    <div class="no2">
        <ul class="search-wrap">
            <li><span>需求标题：</span><input v-model="keyword" type="text" placeholder="请输入关键字"><a @click="goSearch"><img src="../../../assets/img/buyer/ss24.png"  alt=""></a></li>
            <li class="fr"><input type="button" value="新增需求" @click="goAdd"></li>
        </ul>
        <div class="setting-wrap">
            <div class="fl search-btn">
                <xbutton btnType="bg-fff" class="search-xbtn" @on-click="sxj(1)" value="上架" />
            </div>
            <div class="fl search-btn">
                <xbutton class="search-xbtn" @on-click="sxj(2)" value="下架" />
            </div>
        </div>
        <xtable :loading="loading" checkbox :search="search" :head="head" :tableData="tableData" btn="edit,del" @on-delClick="delClick" @on-editClick="editClick" @on-changePage="(v)=>getData(v)" @on-handleSelectionChange="(v)=>checkboxList=v" />
    </div>
</template>

<script>
import xtable from '@/components/UI/xtable.vue'
import xbutton from '@/components/UI/xbutton.vue'
export default {
    methods:{
        goAdd(){
            this.$router.push({name:'buy_post_demand'})
        },
        getData:function(data){
            this.loading = true;
            this.$com.majax.get(this.$com.testCsIp+'/buyerDemand/selectBuyerdemand',data,{
                success:(res)=>{
                    this.tableData = res.data;
                }
            },this)
        },
        delClick:function(item){
            this.$dialog.confirm('是否确认删除' + item.title + '？','error',()=>{
                this.$com.majax.post(this.$com.testCsIp + '/buyerDemand/removeBuyerDemandById/' + item.id,{},{
                    success:(res)=>{
                        if(res.code==0){
                            this.$message({
                                message: '删除成功！',
                                type: 'success'
                            });
                            this.getData()
                        }
                    }
                })
            })
        },
        editClick:function(item){
            this.$router.push({path:'/buyer/demand/post_demand',query: {id: item.id}})
        },
        goSearch:function(){
            this.search = {title:this.keyword};
            this.getData(this.search);
        },
        sxj:function(v){
            var val = v === 1 ? '上架' : '下架',
                url = v === 1 ? '/buyerDemand/putAwayBuyerDemandById/' : '/buyerDemand/soldOutBuyerDemandById/';
            if (this.checkboxList.length===0) {
                this.$dialog.alert('请至少选择一项' + val,'confirm');
            }else{
                var idList = [];
                if (v===1) {
                    for (var i = this.checkboxList.length - 1; i >= 0; i--) {
                        idList.push(this.checkboxList[i].id);
                        if (this.checkboxList[i].status!=0) {
                            this.$dialog.alert('只能上架审核通过的需求','confirm');
                            return false;
                        };
                        if (this.checkboxList[i].shelfStatus===99) {
                            this.$dialog.alert('强制下架的需求不能上架，请联系管理员','confirm');
                            return false;
                        };
                        if(this.checkboxList[i].shelfStatus===1){
                            this.$dialog.alert('上架需求不能再上架','confirm');
                            return false;
                        }
                    };
                }else{
                    for (var i = this.checkboxList.length - 1; i >= 0; i--) {
                        idList.push(this.checkboxList[i].id);
                        if(this.checkboxList[i].shelfStatus===2||this.checkboxList[i].shelfStatus===99){
                            this.$dialog.alert('下架需求不能再下架','confirm');
                            return false;
                        }
                    };
                };

                var ids = idList.join(',');
                this.$com.majax.post(this.$com.testCsIp + url + ids,this.$qs.stringify(ids),{
                    success:(res)=>{
                        if(res.code==0){
                            this.$message({
                                message: val + '成功！',
                                type: 'success'
                            });
                            this.getData()
                        }
                    }
                })
            };
        }
    },
    data(){
        return {
            list:[{
                title:"标题",
            }],
            search:{},
            tableData:[],
            keyword:"",
            currentPage:1,
            page:{},
            loading:false,
            checkboxList:[],
            head:{
                title:{
                    name:"需求标题",
                    width:"200"
                },
                area:"服务商所在地",
                orgCount:"参与单位",
                deadline:"截止时间",
                demandStatus:"需求状态",
                status:"审核状态",
                shelfStatus:"上下架状态",
                createTime:"发布时间",
            }
        }
    },
    components: {
        xtable,
        xbutton
    },
    mounted:function(){
        this.$parent.titleMeg = '需求列表';
        this.getData()
    },
}
</script>


<style scoped>
    div.no2{
        width:992px;min-height: 580px;
        background:#fff;
        position: relative;
        padding: 15px 20px;
        box-sizing: border-box
    }
    /* div.no2 ul{
        display: flex;
        background: #fff;
        line-height: 60px;
        justify-content: space-between;
        margin-bottom: 10px;
    } */
    div.no2 ul span{
        font-size: 14px;
        color: #333
    }
    div.no2 ul>li:first-child{
        display: flex;
        align-items: center;
        float:left;
    }
    div.no2 ul a{
        display: block;
        background: #3296FA;
        width: 55px;height: 34px;
        text-align: center;line-height: 34px;
        cursor:pointer;
    }
    div.no2 ul input[type=button]{
        width: 90px;
        height: 34px;border: 0;background: #3296FA;
        color: #fff;font-size: 16px;
        cursor: pointer;
    }
    div.no2 ul input[type=button]:hover{
        background: linear-gradient(to right, #00aaff, #0085ff);
    }
    div.no2 ul input[type=text]{
        width: 195px;
        height: 34px;
        border: 1px solid #ccc;
        box-sizing: border-box;
        padding-left: 10px;box-sizing: border-box
    }
    table{
        width: 100%;text-align: center;
        border-collapse: collapse
    }
    table th{
        height: 35px;background: #f5f5f5;
        font-size: 14px;color: #333;font-weight: normal;
        border-right:2px solid #fff
    }
    table th:first-child{
        width: 255px
    }
    table td{
        height: 53px;
        font-size: 12px;border-bottom:1px dotted #ccc ;
    }
    table tr:hover{
        background: #f5f5f5;
    }
    table tr td:nth-child(1){
        text-align: left;color: #066cd4;padding-left: 15px;box-sizing: border-box
    }
    table tr td:nth-child(4),td:nth-child(7){
        color: #999
    }
    table tr td:last-child{
        color: #3296FA
    }
    table tr td a{
        text-decoration: none;
        color: #066cd4
    }
    table tr td span{
        color: #ff4a03
    }
    .fl{
        float:left;
    }
    .fr{
        float:right;
    }
    .search-btn{
        margin-right:10px;
        width:80px;
    }
    .search-xbtn{
        font-size:14px;
        height:34px;
    }
    .search-wrap{
        clear:both;
        overflow:hidden;
        margin-bottom:15px;
    }
    .setting-wrap{
        clear:both;
        overflow:hidden;
        margin-bottom:15px;
    }
</style>
