<template>
    <div class="img-wrap" @click="bigImg" title="点击放大">
        <img :style="{width: width + 'px',height:height + 'px' }" :src="url||$com.errorImg" :onerror="$com.errorImg"/>
    </div>
</template>

<script>
export default {
    props: {
        url:String,
        width:String,
        height:String
    },
    data(){
        return {

        }
    },
    watch: {

    },
    computed: {

    },
    components: {

    },
    methods:{
        bigImg:function(){
            if (this.url) {
                this.$dialog.alert(this.url,'img')
            };
        }
    },
    mounted:function(){

    },
}
</script>

<style scoped>
.img-wrap{
    cursor:pointer;
    margin-right:10px;
}
img{
    width:100%;
    height:100%;
    display:block;
}
</style>
