<template>
    <div v-if="list.length>0" class="div_ad_pr">
         <div class="div_ad">
            <!-- <superslide :options="options" class="slideBox">
                <div class="bd">
                    <ul>
                        <li v-for="(item,index) in list" :key="index">

                        </li>
                    </ul>
                </div>
            </superslide> -->
            <swiper :options="options" class="slideBox">
                <swiper-slide v-for="(item,index) in list" :key="index">
                    <a v-if="item.adUrl" target="_blank" :href="item.adUrl"><img :src="item.adImage"/></a>
                    <a v-else><img :src="item.adImage"/></a>
                </swiper-slide>
            </swiper>
        </div>
    </div>
</template>


<script>
export default {
    props: {
        list:Array,
        effect:String
    },
    data() {
        return {
            options: {
                loop: true,
                autoplay: true,
                allowTouchMove:false,
                effect: this.effect
            },
            ilist:false
        }
    },
    methods : {

    },
    mounted () {

    }
}
</script>

<style scoped>
.slideBox{
    height:90px;
    overflow:hidden;
}
</style>
