<template>
    <ul class="item-wrap" :class="{'item-inline':itemStyle=='inline'}">
        <li v-if="title" :style="{width: width + 'px',lineHeight:lineHeight }" class="item-label"><span class="required" v-if="required">*</span> &nbsp;{{title}}：</li>

        <li class="item-inp item-base" v-if="type == 'readonly'">
            <span>{{ value }}</span>
        </li>

        <li class="item-inp" v-if="type == 'text'||type == 'password'">
            <!-- <div v-if="disabled" class="inp-dis"></div> -->
            <div :style="{width: inpWidth }" class="item-base" :class="{'money-inp':inputType==='money'}">
                <input :style="{width: rwidth + 'px' }" :disabled="disabled" :maxlength="maxlength" class="input" :type="type" :placeholder="placeholder" :class="{'item-dis':disabled}" :value="value" @input="changeInp" @change="blurInp">
            </div>
            <span class="error" :class="{'error-inline':errorStyle=='inline'}">{{ error }}</span>
            <span class="item-ps" v-if="ps">{{ps}}</span>
        </li>

        <li class="item-inp" v-if="type == 'textarea'">
            <!-- <div v-if="disabled" class="inp-dis"></div> -->
            <textarea :style="{height: rheight + 'px',width:inpWidth }" :maxlength="maxlength" :value="value" @input="changeInp" class="textarea" :disabled="disabled" :class="{'item-dis':disabled}" :placeholder="placeholder"></textarea>
            <span class="error" :class="{'error-inline':errorStyle=='inline'}">{{ error }}</span>
        </li>

        <li class="item-inp" v-if="type == 'file'" :class="{'large-file-img-wrap':imgObg}">
            <div v-if="imgObg.img" v-loading="loading" class="item-file" :style="{backgroundImage: 'url(' +imgObg.img+ ')',width:imgObg.width,height:imgObg.height}" :class="{'large-file-img':imgObg}" >
                <input :disabled="loading||disabled" title="点击上传图片" class="file" type="file" @change="changeFile">
                <img :src="thisImgObj.imgUrl" v-if="thisImgObj.imgUrl"/>
            </div>
            <div v-else v-loading="loading" class="item-file" :class="{'large-file-img':imgObg}" >
                <input :disabled="loading||disabled" title="点击上传图片" class="file" type="file" @change="changeFile">
                <span>上传图片</span>
                <img :src="thisImgObj.imgUrl" v-if="thisImgObj.imgUrl"/>
            </div>
            <span class="error" :class="{'error-inline':errorStyle=='inline'}">{{ error }}</span>
        </li>

        <li class="item-inp" v-if="type == 'radio'">
            <div class="item-base">
                <span v-for="item in radioList" :key="item.id"><input  type="radio" :disabled="disabled" :value="item.id" v-model="thisValue" @change="change">{{item.name}}</span>
            </div>
            <span class="error" :class="{'error-inline':errorStyle=='inline'}">{{ error }}</span>
        </li>

        <li class="item-inp" v-if="type == 'select'">
            <Xselect :filterable="filterable" :width="rwidth" :zIndex="zIndex" :disabled="disabled" @on-change="selectChange" :list="thisList" :value="thisValue" :placeholder="placeholder" :selkey="selkey" @on-focus="(fn)=>$emit('on-focus',(list)=>{fn(list)})"/>
            <span class="error" :class="{'error-inline':errorStyle=='inline'}">{{ error }}</span>
        </li>

        <li class="item-inp" v-if="type == 'date'">
            <el-date-picker
                v-model="thisValue"
                :disabled="disabled"
                type="date"
                @change="change"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
            </el-date-picker>
            <span class="error">{{ error }}</span>
        </li>

        <li class="item-inp" v-if="type == 'datetime'">
            <el-date-picker
                v-model="thisValue"
                type="datetime"
                :disabled="disabled"
                @change="change"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期时间">
            </el-date-picker>
            <span class="error">{{ error }}</span>
        </li>

        <li :style="{zIndex: zIndex}" class="item-inp" v-if="type == 'twoSelect'">
            <twoSelect :disabled="disabled" :errorStyle="errorStyle" :value="value" @change="(id)=>$emit('change', id)" />
            <span class="error" :class="{'error-inline':errorStyle=='inline'}">{{ error }}</span>
        </li>

        <li class="item-inp" v-if="type == 'threeSelect'">
            <threeSelect :value="value" @change="(id)=>$emit('change', id)" @on-changeTwo="(item)=>$emit('on-changeTwo', item)" />
            <span class="error">{{ error }}</span>
        </li>

        <li class="item-inp" v-if="type == 'threeSelectCategory'">
            <threeSelectCategory :disabled="disabled" :defaultList="defaultList" :value="value" @change="(id)=>$emit('change', id)" />
            <span class="error" :class="{'error-inline mt8':errorStyle=='inline'}">{{ error }}</span>
        </li>

        <li class="item-inp" v-if="type == 'upload'">
            <el-upload
            class="upload-demo"
            :action="action"
            :headers="headers"
            :data="data"
            :on-success="upSuccess">
            <el-button size="medium">点击上传文件</el-button>
            <div slot="tip" class="el-upload__tip up-error">{{error}}</div>
            <div slot="tip" class="el-upload__tip">上传的每个文件不超过1024kb。</div>

            </el-upload>
        </li>
    </ul>
</template>

<script>
import twoSelect from '@/components/UI/twoSelect.vue'
import threeSelect from '@/components/UI/threeSelect.vue'
import threeSelectCategory from '@/components/UI/threeSelectCategory.vue'
import Xselect from '@/components/UI/select.vue'
export default {
    props: {
        title:String,
        type:{
            type:String,
            default:'readonly'
        },
        defaultList:Array,
        placeholder:String,
        value:String | Number,
        selkey:String,
        required:Boolean,
        error:String,
        radioList:Array,
        ps:String,
        width:{
            type:String,
            default:'100'
        },
        disabled:Boolean,
        // selectList:Object,
        selectList:Array | Object,
        errorStyle:{
            type:String,
            default:'block'
        },
        maxlength:String,
        zIndex:String,
        rwidth:String,
        rheight:String,
        categoryId:String,
        itemStyle:String,
        inputType:String,
        fileType:String,
        inpWidth:String,
        lineHeight:String,
        filterable:Boolean,
        imgObg:{
            type:Object,
            default:function () {
                return {}
            }
        },
        action:String,
        headers:Object,
        data:Object
    },
    data(){
        return {
            thisValue:this.value,
            thisImgObj:{},
            loading:false,
            thisList:[],
            option: {
              img: '',
              outputSize:1, //剪切后的图片质量（0.1-1）
              full: false,//输出原图比例截图 props名full
              outputType: 'png',
              canMove: true,
              original: false,
              canMoveBox: true,
              autoCrop: true,
              autoCropWidth: 150,
              autoCropHeight: 150,
              fixedBox: true
            },
        }
    },
    watch: {
        selectList:{
            handler(val){
                this.thisList = [{id:val.value,name:val.label}];
            },
            deep:true
        },
        value (val) {
            this.thisValue = val;
            this.thisImgObj = val
        },
        /*selectList(val){
            console.log(val)
        }*/
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    components: {
        Xselect,
        twoSelect,
        threeSelect,
        threeSelectCategory,
    },
    mounted:function(){
     
    },
    updated:function(){
       //console.log(this.value,typeof(this.value),'computents/UI/formItem')  
    },
    methods:{
        upSuccess:function(response, file, list){
            this.$emit('on-success', list)
        },
        focusChange:function(fn){
            this.$emit('on-focus',(list) => {
                fn(list)
            })
        },
        selectChange:function(val){
            this.$emit('on-change', val)
            this.$emit('change', val.id)
        },
        change:function(){
            this.$emit('change', this.thisValue)
        },
        changeInp:function(e){
            this.$emit('change', e.target.value)
        },
        blurInp:function(){
            this.$emit('on-change', this.thisValue)
        },
        changeFile:function(e){
            var files = e.target.files[0];
            if (files.type.indexOf('image')==-1) {
                this.$dialog.alert("只能上传图片",'confirm')
            }else if(files.size/1024>1024){
                this.$dialog.alert("图片大小不能超过1M",'confirm')
            }else{
                var image = new FormData();
                image.append('file', files);
                this.loading = true
                this.$com.majax.post(this.$com.testImgin+'/upload/upload-file', image,{
                    success:(res) => {
                        this.thisImgObj = res.data;
                        this.$emit('change', this.thisImgObj)
                    },
                    error:() => {
                        this.$dialog.alert('上传失败，请重试','error')
                    }
                },this)
            };
        }
    },
}
</script>

<style scoped>
li,span{
    font-size:14px;
}
.item-wrap{
    /* height:40px; */
}
.item-label{
    width:100px;
    text-align:right;
    margin-right:7px;
    line-height:40px;
    float:left;
    white-space: normal;
}
.item-inp{
    position:relative;
    width:80%;
    white-space: nowrap
}
.item-inp .inp-dis{
    position:absolute;
    width:415px;
    height:100%;
    top:0;
    left:0;
}
.item-inp .input{
    width:415px;
    height:40px;
    border: 1px solid #ccc;
    font-size: 14px;
    padding-left: 10px;
    vertical-align: middle;
}

.item-inp .textarea{
    width:415px;
    height:100%;
    resize:none;
    padding:10px;
    line-height:20px;
    font-size:14px;
    font-family:'Microsoft YaHei';
    border:1px solid #ccc;
    vertical-align: middle;
}
.item-inp .item-dis{
    background-color: #f5f7fa;
    border-color: #e4e7ed;
    color: #c0c4cc;
    cursor: not-allowed;
}
.required{
    color:red;
}
.error{
    color:red;
    display:block;
}
.error-inline{
    margin-left:5px;
    display:inline-block;
    line-height:40px;
    vertical-align:middle;
}
.item-file{
    position:relative;
    width:70px;
    height:70px;
    border:1px solid #eee;
    background-color:#eee;
    background-size:100%;
    background-image:url('../../../src/assets/img/user/avatar.png');
    display:inline-block;
}
.item-file img{
    width:100%;
    height:100%;
}
.item-file .file{
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    opacity:0;
    cursor:pointer;
    text-indent:80px;
    z-index:9;
}
.item-file span{
    position:absolute;
    display:block;
    width:100%;
    bottom:0;
    left:0;
    text-align:center;
    font-size:12px;
    background:rgba(0,0,0,0.2);
    color:#fff;
    height:22px;
    line-height:22px;
    cursor:pointer;
}
.item-base{
    line-height:40px;
    display: inline-block;
    vertical-align: middle;
}
.item-base span{
    margin-right:10px;
}
.item-ps{
    color: #999;
    font-size: 12px;
    display: block;
    vertical-align: middle;
}
.mt8{
    margin-top:8px;
}
.item-inline .item-inp{
    width:calc(100% - 110px);
    float:left;
    text-align:left;
}
.item-inline .input,
.item-inline .textarea{
    width:100%;
}
.money-inp{
    position:relative;
}
.money-inp::before{
    content:"元";
    position:absolute;
    top:0;
    right:10px;
}
.large-file-img{
    position:relative;
    background-size:100%;
}
.large-file-img-wrap,
.large-file-img-wrap .error-inline{
    line-height:0;
}
.upload-demo{
    width:415px;
}
.up-error{
    display:inline-block;
    color:red;
    margin-left:5px;
}
</style>
