<template>
    <div class="seller">
        <Header />
        <div class="section-seller">
            <div>
                <div>
                    <router-link to="/"><img src="../assets/img/logo1.png" alt="返回首页"></router-link>
                    <ul>
                        <li><router-link to="/seller/home" active-class="active">首页</router-link></li>
                        <li><router-link to="/seller/serve/service_list" active-class="active">服务管理</router-link></li>
                        <li><router-link to="/seller/order/order_list_seller" active-class="active">订单管理</router-link></li>
                        <li><router-link to="/seller/shop/shop_maintenance" active-class="active">店铺管理</router-link></li>
                    </ul>
                </div>
            </div>
            <div>
                <div>
                    <NavVertical :navData="navData" />
                </div>
                <router-view></router-view>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import NavVertical from '@/components/NavVertical.vue'

export default {
    data:function(){
        return{
            navData:[{
                title:'服务管理',
                open:false,
                list:[{
                    title:'服务列表',
                    url:'/seller/serve/service_list',
                },{
                    title:'发布服务',
                    url:'/seller/serve/publishing_service',
                }]
            }/*,{
                title:'咨询师管理',
                open:false,
                list:[{
                    title:'咨询师列表',
                    url:'/seller/counselor/counselor_list'
                }
                 ,{
                     title:'新增咨询师',
                     url:'/seller/counselor/new_counselor'
                 }
                ]
            }*/,{
                title:'订单管理',
                open:false,
                list:[{
                    title:'我的订单',
                    url:'/seller/order/order_list_seller',
                }]
            },{
                title:'确认委托单',
                open:false,
                list:[{
                    title:'委托单列表',
                    url:'/seller/order_ticket/order_ticket_list'
                }]
            },{
                title:'我的竞标',
                open:false,
                list:[{
                    title:'竞标列表',
                    url:'/seller/bid/my_bid'
                }]
            },{
                title:'店铺管理',
                open:false,
                list:[{
                    title:'店铺简介',
                    url:'/seller/shop/about_store'
                },{
                    title:'店铺维护',
                    url:'/seller/shop/shop_maintenance'
                },{
                    title:'资质管理',
                    url:'/seller/shop/qua_man',
                },{
                    title:'案例管理',
                    url:'/seller/shop/case_man',
                }]
            }
            /* ,{
                 title:'我的关注',
                 open:false,
                 list:[{
                     title:'我的收藏',
                     url:'/seller/follow/my_favorite',
                 },{
                     title:'我的足迹',
                     url:'/seller/follow/my_footprint',
                 }]
             }*/
            ],
        }
    },
    mounted:function(){
        //this.$com.isLogin();
    },
    components: {
        Header,Footer,NavVertical
    },
}
</script>


<style scoped>
    body{
        margin: 0;
        padding: 0;
    }
    ul,p{
        margin: 0;
        padding: 0;
        list-style: none;
    }
    .section-seller{
        width: 100%;
        min-width: 1200px;
        background: #f5f5f5;
        padding-bottom: 24px
    }
    .section-seller>div:nth-child(1){
        width: 100%;
        min-width: 1200px;
        height: 60px;
        background: #066CD4;
    }
    .section-seller>div:nth-child(1)>div{
        width: 1200px;
        margin: 0 auto;
        display: flex;
        position: relative;
    }
    .section-seller>div:nth-child(1)>div img{
        position: absolute;
        top: 9px
    }
    .section-seller>div:nth-child(1)>div>ul{
        width: 850px;
        display: flex;
        position: absolute;
        left: 210px;
        line-height: 60px;
    }
    ul li:hover,.active{
        background: rgba(0, 0, 0, .1);
    }
    .section-seller>div:nth-child(1)>div>ul li a{
        display: block;
        color: #f5f5f5;
        width: 100%;
        text-decoration: none;
        font-size: 16px;
        padding: 0 30px;
    }
    .section-seller>div:nth-child(2){
        width: 1200px;
        margin: 0 auto;
        margin-top: 17px;
        display: flex;
        justify-content: space-between;
    }
    .section-seller>div:nth-child(2)>div:nth-child(1){
        width:198px;height:561px;background:#fff;
    }
    .shop-a{
        cursor:pointer;
    }
</style>
