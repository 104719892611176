<template>
    <div class="index">
        <div>
            <img class="avatar" :src="$store.state.user.avatarPath" alt="">
            <div>
                <ul>
                    <li class="nickName">{{$store.state.user.nickName}}</li>
                    <!-- <li><img src="../../assets/img/buyer//yzz.png">已认证</li> -->
                </ul>
                <ul>
                    <li><b>账户安全：</b><span>{{safeGrade}}</span></li>
                    <li><router-link to="/users/account/account_Cpw" target="_blank">修改密码</router-link></li>
                    <li>
                        <router-link v-if="userData.safeEmail&&userData.safeEmail!='未绑定安全邮箱'" to="/users/account/account_bdemail/account_bdemail_Cemail1" target="_blank">{{userData.safeEmail}}</router-link>
                        <router-link v-else to="/users/account/account_bdemail/account_bdemail_add" target="_blank">未绑定安全邮箱</router-link>
                    </li>
                    <li>
                        <router-link v-if="userData.safePhone&&userData.safePhone!='未绑定安全手机'" to="/users/account/account_bdph/account_bdph_Cph1" target="_blank">{{userData.safePhone}}</router-link>
                        <router-link v-else to="/users/account/account_bdph/account_bdph_add" target="_blank">未绑定安全手机</router-link>
                    </li>
                </ul>
                <ul>
                    <li> <b>订单状态：</b><a>委托单待确认<span>（{{homeData.orderStatusRes.entrustOrder}}）</span></a> </li>
                    <li><a>待付款<span>（{{homeData.orderStatusRes.notPayment}}）</span></a></li>
                    <li><a>服务进行中<span>（{{homeData.orderStatusRes.serviceInProgress}}）</span></a></li>
                    <li><a>待确认完成服务<span>（{{homeData.orderStatusRes.serviceUnconfirmed}}）</span></a></li>
                </ul>
            </div>
        </div>
        <div>
            <div>
                <span>最近浏览</span>
                <router-link to="/buyer/follow/my_footprint">更多+</router-link>
            </div>
            <div v-if="zjData.length>0">
                <goodsItem row="4" v-for="(item,index) in zjData" :key="index" :goodsData="item" @on-clickDetail="ClickDetail" />
            </div>
            <div v-else class="empty-wrap">
                <img class="empty-img" src="../../assets/img/user/empty_page.jpg" alt="暂无数据">
            </div>
        </div>
        <div>
            <div>
                <span>推荐服务</span>
                <router-link to="/search_page">更多+</router-link>
            </div>
            <div v-if="tjData.length>0">
                <goodsItem row="4" type="money" v-for="(item,index) in tjData" :key="index" :goodsData="item" @on-clickDetail="ClickDetail" />
            </div>
            <div v-else class="empty-wrap">
                <img class="empty-img" src="../../assets/img/user/empty_page.jpg" alt="暂无数据">
            </div>
        </div>
        <!-- <xeditor v-model="testValue" />
        <span @click="test">提交</span> -->
    </div>
</template>

<script>
import goodsItem from '@/components/UI/goodsItem.vue'
import xeditor from '@/components/UI/xeditor.vue'
export default {
    props: {

    },
    data () {
        return {
            homeData:{
                orderStatusRes:{}
            },
            zjData:[],
            tjData:[],
            testValue:"",
            userData:this.$store.state.user
        }
    },
    components: {
        goodsItem,
        xeditor
    },
    computed:{
        safeGrade:function(){
            var userType = this.$store.state.userType;
            var gradeNumber = 0;
            var gradeList = [this.userData.safePhone,this.userData.safeEmail,this.userData.pwdStrength];
            for (var i = gradeList.length - 1; i >= 0; i--) {
                if (gradeList[i]) {
                    if (gradeList[i]!='未绑定安全邮箱'&&gradeList[i]!='未绑定安全手机') {
                        gradeNumber++
                    };
                };
            };
            if (userType.personlStatus===0||userType.orgStatus===0) {
                gradeNumber++
            };
            switch(gradeNumber){
                case 0:
                    return '危险';
                    break;
                case 1:
                    return '低';
                    break;
                case 2:
                    return '中'
                    break;
                case 3:
                    return '高'
                    break;
                case 4:
                    return '安全'
                    break;
            }
        }
    },
    mounted () {
        /*if (!this.$store.state.tokenId) {
            this.$message({
                message: '请先登录',
                type: 'error',
                duration: 1000
            });
            setTimeout(()=>{
                this.$router.push({ path:'/login' })
            },'1000');
        };*/
        this.GetData();
    },
    methods: {
        ClickDetail:function(item){
            this.$router.push({path:'/shop_management/detail_page',query: {id: item.id}})
        },
        GetData:function(){
            this.$com.majax.get(this.$com.testCsIp+'/buyerHomePage/showBuyerHomePage',{},{
                success:(res)=>{

                    if (res.data.orderStatusRes) {
                        this.homeData = res.data;
                    };
                    this.zjData = res.data.recentBrowseRes.records;
                    if (res.data.recentBrowseRes.records.length>0) {
                        for (var i = 0; i < res.data.recentBrowseRes.records.length; i++) {
                            var item = res.data.recentBrowseRes.records[i];
                            this.zjData[i].name = item.serviceName;
                            this.zjData[i].imagePath = item.serviceImgPath;
                        };
                    };

                    this.tjData = res.data.recommendSerivceRes.records;
                    if (res.data.recommendSerivceRes.records.length>0) {
                        for (var i = 0; i < res.data.recommendSerivceRes.records.length; i++) {
                            var item = res.data.recommendSerivceRes.records[i];
                            this.tjData[i].name = item.serviceName;
                            this.tjData[i].imagePath = item.serviceImgPath;
                        };
                    };
                }
            })
        },
        test:function(){
            console.log(this.testValue)
        }
    }
}
</script>

<style scoped>
    .index>div:nth-child(1){
        width:992px;height:148px;background:#fff;
        position: relative;
    }
    .index>div:nth-child(1)>img{
        position: absolute;
        border: 1px solid #ccc;
        top: 26px;left:29px ;
    }
    .index>div:nth-child(1)>div{
        position: absolute;
        top: 26px;left: 136px;
        width: 855px
    }
    .index>div:nth-child(1)>div ul:nth-child(1){
        font-size: 16px;font-weight: bold;
        width: 100%;padding-bottom: 17px;
        border-bottom:1px solid #ccc ;display: flex
    }
    .index>div:nth-child(1)>div ul:nth-child(1) li:nth-child(2){
        background: #E63217;padding: 2px 10px;font-size: 12px;color: #fff;
        border-radius: 4px
    }
    .index>div:nth-child(1)>div ul:nth-child(2){
        display: flex;margin-top: 8px;font-size: 14px;width: 453px;
        justify-content: space-between
    }
    .index>div:nth-child(1)>div ul:nth-child(1) li:nth-child(2) img{
        vertical-align:text-bottom;margin-right: 6px
    }
    .index>div:nth-child(1)>div ul:nth-child(3){
        display: flex;margin-top: 10px;font-size: 14px;width: 680px;
        justify-content: space-between
    }
    .index>div:nth-child(1)>div ul a{
        text-decoration: none;color: #333;
    }
    .index>div:nth-child(1)>div ul a:hover{
        color: #3296FA
    }
    .index>div:nth-child(1)>div ul span{
        color: #E63217
    }
    .index>div:nth-child(1)>div ul:nth-child(3) li{
        border-right: 1px dotted #ccc;
        padding-right: 20px;box-sizing: border-box
    }
    .index>div:nth-child(1)>div ul:nth-child(3) li:nth-child(4){
        border: none;padding: 0;
    }
    .index>div:not(:first-child){
        margin-top: 10px;padding: 0 20px;box-sizing: border-box;
        width:992px;background:#fff;
    }
    .index>div:not(:first-child)>div:first-child{
        width:100%;height:39px;
        border-bottom: 1px solid #ccc;
    }
    .index>div:not(:first-child)>div:first-child span{
        display: block;
        font-size: 16px;
        line-height: 37px;
        text-align: center;
        width: 100px;
        border-bottom: 2px solid #ff4a03;
        color: #ff4a03;
        float:left;
    }
    .index>div:not(:first-child)>div:first-child a{
        text-decoration: none;color: #333;line-height: 40px;font-size: 12px;
        float:right;
    }
    .index>div:not(:first-child)>div:first-child a:hover{
        color: #ff4a03
    }
    .index>div:not(:first-child)>div:last-child{
        margin-top: 14px
    }
    .index>div:not(:first-child)>div:last-child a{
        display: block;text-decoration: none;
        font-size: 14px;color: #333;
    }
    .index>div:nth-child(2)>div:last-child a:hover{
        color: #3296FA;
    }
    .index>div:nth-child(3)>div:nth-child(2) a{
        border: 1px solid #ccc;padding-bottom: 17px
    }
    .index>div:nth-child(3) ul li:not(:first-child){
        font-size: 14px;line-height: 26px;padding-left: 11px
    }
    .index>div:nth-child(3) ul li span{
        color: #ff4a03
    }
    .index>div:nth-child(3) ul li:nth-child(2){
        font-weight: bold;
    }
    .index>div:nth-child(3)>div:last-child a:hover{
        box-shadow: 6px 6px 12px #eeeeee;
    }
    .avatar{
        width:98px;
        height:98px;
    }
    .nickName{
        margin-right:5px;
    }
    .empty-wrap img{
        display:block;
        margin:auto;
    }
</style>
