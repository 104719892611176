<template>
    <div class="no2">
        <xstep class="xstep" :list="$parent.selfStep" :active="1" />
        <formItem :disabled="disabled" width="160" :error="user.categoryId.error" errorStyle="inline" title="认证项目" required type="threeSelectCategory" v-model="user.categoryId.value" :defaultList="category" />

        <formItem :disabled="disabled" width="160" errorStyle="inline" :error="user.selfHelpType.error" title="服务类型" required type="radio" :radioList="serviceList" v-model="user.selfHelpType.value" />

        <formItem :disabled="disabled" width="160" maxlength="50" errorStyle="inline" title="服务标题" required :error="user.serviceName.error" type="text" v-model="user.serviceName.value" placeholder="请填写服务标题" />

        <formItem :disabled="disabled" width="160" maxlength="25" errorStyle="inline" title="联系人" required :error="user.contact.error" type="text" v-model="user.contact.value" placeholder="请填写联系人" />

        <formItem :disabled="disabled" width="160" maxlength="25" errorStyle="inline" title="联系电话" required :error="user.phone.error" type="text" v-model="user.phone.value" placeholder="请输入11位手机号码或者区号加固定电话" />

        <formItem :disabled="disabled" width="160" maxlength="200" errorStyle="inline" title="认证说明" type="textarea" v-model="user.explain.value" placeholder="请填写认证说明" />

        <div v-if="user.selfHelpType.value=='2'||user.selfHelpType.value=='3'">
            <div class="title"><span>认证所需资料及要求</span></div>
            <formItem width="160" :disabled="disabled" errorStyle="inline" type="file" :error="coveImage.error" :imgObg="imgObg" title="上传组织架构图片" v-model="coveImage.value" />
            <formItem width="160" :disabled="disabled" errorStyle="inline" type="file" :error="coveImage.error" :imgObg="imgObg" title="上传服务实现流程图" v-model="coveImage.value" />
            <formItem lineHeight="20px" width="160" :disabled="disabled" errorStyle="inline" type="file" :error="coveImage.error" :imgObg="imgObg" title="上传计量器具校准证书或检定证书" v-model="coveImage.value" />
            <ul>
                <li class="item-label">是否指定认证机构：</li>
                <li class="item-radio">
                    <div class="item-base">
                        <span><input v-model="jgBool" :value=true type="radio">指定</span>
                        <span><input v-model="jgBool" :value=false type="radio">不指定</span>
                    </div>
                </li>
                <Xselect v-model="user.certificationAuthority.value" filterable :disabled="!jgBool" placeholder="请选择机构" @on-focus="(fn)=>$com.findCode('type_jgyq',(res)=>{fn(GoScreen((res)))})" />
                <span class="error" v-if="jgBoolerror">请选择机构</span>
            </ul>
        </div>

        <ul>
            <li></li>
            <li class="form-btn">
                <xbutton :disabled="disabled" @on-click="goSelf" value="保存开始自助认证"></xbutton>
            </li>
            <li class="form-btn">
                <xbutton btnType="bg-fff" :disabled="disabled" @on-click="goPay" value="委托平台咨询及认证"></xbutton>
            </li>
        </ul>
    </div>
</template>

<script>
import formItem from '@/components/UI/formItem.vue'
import xbutton from '@/components/UI/xbutton.vue'
import xstep from '@/components/UI/xsteps.vue'
import imgBg from '../../../assets/img/seller/file.jpg'
import Xselect from '@/components/UI/select.vue'
export default {
    data(){
        return{
            imgObg:{
                width:'140px',
                height:'140px',
                img:imgBg,
            },
            jgBool:false,
            disabled:false,
            serviceList:[{id:'1',name:'自助咨询服务'},{id:'2',name:'咨询服务（需平台审核）'},{id:'3',name:'认证服务（含咨询费）'}],
            category:[],
            autoServiceId:this.$route.query.autoServiceId||"",
            jgBoolerror:false,
            coveImage:{
                value:"",
                error:""
            },
            user:{
                serviceName:{
                    value:"",
                    error:"",
                    validate:['required'],
                    name:"标题"
                },
                categoryId:{
                    value:"",
                    error:"",
                    validate:['required'],
                    name:"认证项目"
                },
                contact:{
                    value:"",
                    error:"",
                    validate:['required'],
                    name:"联系人"
                },
                phone:{
                    value:"",
                    error:"",
                    validate:['required','telphone&phone'],
                    name:"联系电话"
                },
                selfHelpType:{
                    value:"1",
                    error:"",
                    validate:['required'],
                    name:"服务类型"
                },
                explain:{
                    value:"",
                },
                certificationAuthority:{
                    value:"0",
                }
            },
        }
    },
    methods:{
        goSelf:function(_this){
            var data = {}
            for(var key in this.user){
                data[key] = this.user[key].value
                if (this.user.selfHelpType.value=='1') {
                    delete data.certificationAuthority;
                }
            }
            data.id = this.autoServiceId;
            var validate = this.$com.validate(this.user)
            if (validate) {
                if (this.jgBool&&data.certificationAuthority=='0') {
                    this.jgBoolerror = true;
                    return false;
                };
                this.jgBoolerror = false;
                _this.loading = true;
                this.$com.majax.post(this.$com.testChIp + '/auto-service/beginAutoService',this.$qs.stringify(data),{
                    success:(res)=>{
                        this.$router.push({ path:'/buyer/selfhelp/self_step2',query:{autoServiceId:this.autoServiceId}})
                    }
                },_this)
            };
        },
        goPay:function(){

        },
        GoScreen:function(data){
            var _data = []
            for (var i = 0; i < data.length; i++) {
                var item = data[i];
                if (item.id!='0') {
                    _data.push(item)
                };
            };
            return _data;
        },
        getIntId:function(){
            this.$axios.get(this.$com.testChIp + '/auto-service/initAutoService')
            .then((res)=>{
                this.autoServiceId = res.data.data;
            })
        },
        getData:function(){
            this.$com.majax.get(this.$com.testChIp + '/auto-service/getAutoServiceDetail',{id:this.autoServiceId},{
                success:(res)=>{
                    for(var key in this.user){
                        this.user[key].value = res.data[key];
                    }
                }
            })
        }
    },
    components: {
        formItem,
        xbutton,
        xstep,
        Xselect
    },
    mounted:function(){
        this.$parent.titleMeg = '自助认证-基础信息';
        this.$parent.rightLink = true;
        this.autoServiceId ? this.getData() : this.getIntId();
        this.$router.beforeEach((to, from ,next) => {
            this.$parent.rightLink = false;
            next();
        })
    },
}
</script>

<style scoped>
.no2{
    width:992px;
    background:#fff;
    padding: 25px 20px 15px 20px;
}
.no2 ul{
    display: flex;
    margin-bottom: 20px;
}
.no2 ul li:first-child{
    width: 160px;
    margin-right: 7px;
}
.form-btn{
    width:170px;
    margin-right:10px;
}
.form-btn button{
    font-size:14px;
    height:36px;
}
.step-item{
    width:20px;
    height:20px;
    background:#ccc;
}
.xstep{
    background:#f5f5f5;
}
.title{
    border-bottom:2px dotted #ccc;
    margin-bottom:20px;
}
.title span{
    border-left:3px solid #ff4a03;
    font-size:14px;
    display:inline-block;
    height:14px;
    line-height:14px;
    padding:0 0 6px 10px;
    font-weight:bold;
}
.item-label,.item-radio{
    line-height:40px;
}
.item-label{
    width:160px;
    font-size:14px;
    text-align:right;
}
.item-base span{
    font-size:14px;
    margin-right:15px;
}
.error{
    line-height:40px;
    color:red;
    margin-left:5px;
    font-size:14px;
}
</style>

