<template>
    <div>
        <!-- <div class="index_news_sty" :style="{top:activeTop+'px'}">555555555555555555555555555555555</div> -->
        <div class="index_news_sty">
            <img src="../../../assets/img/wuyi.png" alt="">
        </div>

    </div>
</template>

<script>
export default {
    components:{},
    props:{
        activeTop:{
            type:Number,
            default:-200
        }
    },
    data(){
        return{
            
        }
    },
    computed:{},
    mounted:function(){
    },
    methods:{
    }
}
</script>

<style scoped>
    .index_news_sty{
        height:200px;
        /* transition: all 1s linear 1s; */
        position: absolute;
        top:-200px;
        width: 100%;
        left:0;
        
    }
    .index_news_sty img{
        width: 100%;
        height:100%;
    }
</style>