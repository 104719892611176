<template>
    <div class="no2">
        <ul class="search-wrap">
            <li class="search-li"><span>认证名称：</span><input v-model="keyword" type="text" placeholder="请输入关键字"><a @click="goSearch"><img src="../../../assets/img/buyer/ss24.png"  alt=""></a></li>
            <li class="fr"><xbutton class="search-xbtn" @on-click="goSelf" value="开始自助认证" /></li>
        </ul>
        <xtable :loading="loading" :search="search" :head="head" :tableData="tableData" btn="look" @on-lookClick="lookClick"  @on-changePage="(v)=>getData(v)" @on-handleSelectionChange="(v)=>checkboxList=v" />
    </div>
</template>

<script>
import xtable from '@/components/UI/xtable.vue'
import xbutton from '@/components/UI/xbutton.vue'
export default {
    methods:{
        goSelf:function(){
            this.$router.push({name:'buy_self_step1'})
        },
        getData:function(data){
            this.loading = true;
            this.$com.majax.get(this.$com.testChIp+'/auto-service/getAutoServiceList',data,{
                success:(res)=>{
                    this.tableData = res.data;
                }
            },this)
        },
        goSearch:function(){
            this.search = {categoryName:this.keyword};
            this.getData(this.search);
        },
        lookClick:function(item){
            var name = "buy_self_step" + item.row.fillStatus;
            this.$router.push({name:name,query:{autoServiceId:item.row.id}})
        }
    },
    data(){
        return {
            search:{},
            tableData:{},
            keyword:"",
            loading:false,
            checkboxList:[],
            head:{
                categoryIdName:{
                    name:"认证名称",
                    width:"200"
                },
                selfHelpType:{
                    name:"服务类型",
                    list:[{code:1,value:"自助咨询服务"},{code:2,value:"咨询服务（平台审核）"},{code:3,value:"认证服务（含咨询费）"}]
                },
                certificationBody:"认证机构要求",
                cost:"服务费",
                createTime:"发布日期",
                status:{
                    name:"状态",
                    list:[{code:1,value:'资料填写中',color:'#333'},{code:2,value:'资料填写完成',color:'#e4393c'},{code:10,value:'待平台审核',color:'#333'},{code:11,value:'平台审核中',color:'#333'},{code:12,value:'平台审核通过',color:'#333'},{code:20,value:'认证进行中',color:'#e4393c'},{code:21,value:'认证完成',color:'#333'}]
                },
            }
        }
    },
    components: {
        xtable,
        xbutton
    },
    mounted:function(){
        this.$parent.titleMeg = '自助认证列表';
        this.getData()
    },
}
</script>


<style scoped>
.no2{
    width:992px;min-height: 580px;
    background:#fff;
    position: relative;
    padding: 15px 20px;
    box-sizing: border-box;
}
.search-li span,.search-li a,.search-li input{
    float: left;
}
.search-li a{
    background: #3296FA;
    width: 55px;height: 34px;
    text-align: center;line-height: 34px;
    cursor:pointer;
}
.search-li input{
    width: 195px;
    height: 34px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    padding-left: 10px;box-sizing: border-box
}
.search-li span{
    line-height:34px;
    font-size: 14px;
    color: #333;
}
.search-xbtn{
    font-size:14px;
    height:34px;
}
.fr{
    float:right;
}
.search-wrap{
    height:34px;
    margin-bottom:15px;
}
</style>
