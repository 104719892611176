<template>
    <div class="sear">
        <div>
            <img src="../assets/img/index/search.png" alt="">
            <input type="text" placeholder="请输入关键字搜索" v-model="title" @keyup.13="goSearch">
            <button @click="goSearch">搜&nbsp;索</button>
            <ul>
                <li><router-link :to="{path:'/search_page',query:{title:'ISO9001'}}" target="_blank">ISO9001</router-link></li>
                <li><router-link :to="{path:'/search_page',query:{title:'ISO14001'}}" target="_blank">ISO14001</router-link></li>
                <li><router-link :to="{path:'/search_page',query:{title:'ISO45001'}}" target="_blank">ISO45001</router-link></li>
                <li><router-link :to="{path:'/search_page',query:{title:'IATF16949'}}" target="_blank">IATF16949</router-link></li>
            </ul>
        </div>
        <span class="huo">或</span>
        <button @click="goIssue">发布认证需求</button>
    </div>
</template>

<script>
export default {
    inject:['reload'],
    data(){
        return{
            title:''
        }
    },
    methods:{
        goSearch:function(){
            this.$router.push({name:'search_page',query : {title:this.title}})
            this.reload()
            /*if(this.title !==""){
                this.$router.push({name:'search_page',query : {title:this.title}})
                this.reload()
            }else{
                this.$message({
                    message: '不能为空，请输入关键字！',
                    type: 'error'
                });
            }*/
        },
        goIssue(){
            this.$router.push({ name:'buy_post_demand' })
        },
    },
    /*watch: {
        '$route' (to, from) {
            this.$router.go(0);
        }
    },*/
    mounted(){
        if(location.search.indexOf('id')!=-1){
            this.title=""
        }else{
            if(location.search.indexOf('title')!=-1){
                var title=decodeURI(location.search.split("=")[1])
                if(title.indexOf("&")!=-1){
                    this.title=title.split("&")[0]
                }else{
                    this.title=title
                }
            }
        }

    }
}
</script>


<style scoped>
    .sear{
        display: flex;
        position: relative;
        width: 1200px
    }
    .sear div{
        position: absolute;
        top: 34px;
        left:620px;
        width: 420px;
        height: 35px;
        border: 2px solid #3296FA;
        border-right: none;
        position: relative;
    }
    .sear div img{
        position: absolute;
        top:8px;
        left:12px
    }
    .sear div input{
        height:31px;
        border: none;
        font-size: 12px;
        margin-left:1px;
        width:318px;padding-left: 33px;
        outline:none;
    }
    .sear div button{
        position: absolute;
        right: 0;
        width:100px;height:33px;
        border: none;
        font-size: 16px;
        color: #fff;
        background-color: #3296FA;
        cursor: pointer;
        top:0;

    }
    .sear div button:hover{
        background: linear-gradient(to right, #00aaff, #0085ff);
    }
    .sear div>ul{
        position: absolute;
        top: 30px;
        width: 468px;
        height: 24px;
        overflow: hidden;
    }
    .sear div>ul li{
        float: left;margin-right: 24px;
        font-size: 12px;
    }
    .sear div>ul li a{
        line-height: 30px;
        color: #737373;
        font-size: 12px;
    }
    .sear div>ul li a:hover{
        text-decoration: none;
        color: #2e89e4
    }
    .sear>button{
        position: absolute;
        top: 34px;
        right: 0;
        width: 120px;height: 35px;
        color: #fff;
        background-color: #066bd3;
        border: none ;font-size:16px;
        cursor: pointer;
        white-space: nowrap;
    }
    .sear>button:hover{
        background: linear-gradient(to right, #00aaff, #0085ff);
    }
    .huo{
        position:absolute;
        top:40px;font-size: 12px;
        right:132px;color: #666
    }
</style>
