<template>
    <div class="seller">
        <div class="no1">
            <div><span>我的商铺</span></div>
            <ul class="no11">
                <li @click="goToShop(homepageOne.id)"><img :src="homepageOne.logoPath" alt=""></li>
                <li>
                    <ul>
                        <li @click="goToShop(homepageOne.id)">{{homepageOne.shopName}}（店铺名称）</li>
                        <li>{{homepageOne.orgName}}</li>
                        <li>企业资质<span>（{{homepageTwo.qualificationCount}}）</span></li>
                        <li>服务案例<span>（{{homepageTwo.caseCount}}）</span></li>
                    </ul>
                </li>
                <li>
                    <ul>
                        <li>累计交易金额：<span>{{homepageOne.accumulatedAmount}}</span><span> 元</span></li>
                        <li>待收款：<span>{{homepageTwo.receivable}}</span><span> 元</span></li>
                        <li>已提款：<span>{{homepageOne.withdrawals}}</span><span> 元</span></li>
                        <li>余额：<span>{{homepageOne.balance}}</span><span> 元</span></li>
                    </ul>
                </li>
            </ul>
            <ul class="no12">
                <li>服务商品：</li>
                <li>
                    <ul>
                        <li>店铺总商品<span>（{{homepageOne.goodsTotal}}）</span></li>
                        <li>销售中的商品<span>（{{homepageOne.salesTotal}}）</span></li>
                        <li>待上架的商品<span>（{{homepageOne.upperShelfTotal}}）</span></li>
                        <li>审核驳回的商品<span>（{{homepageOne.rejectTotal}}）</span></li>
                        <li>平台强制下架<span>（{{homepageOne.forceLowerTotal}}）</span> </li>
                    </ul>
                </li>
            </ul>
            <ul class="no12">
                <li>订单状态：</li>
                <li>
                    <ul>
                        <li>委托单待确认<span>（{{homepageOne.entrustConfirmTotal}}）</span></li>
                        <li>待买家付款<span>（{{homepageTwo.buyerPaymentTotal}}）</span></li>
                        <li>待买家确认完成<span>（{{homepageTwo.buyerConfirmTotal}}）</span></li>
                        <li>已完成订单<span>（{{homepageTwo.completedTotal}}）</span></li>
                    </ul>
                </li>
            </ul>
        </div>
        <div class="no2">
            <div><span>推荐需求</span><a href="/demand_hall" target="_blank">更多 +</a></div>
            <ul v-for="(demand,i) in demandList" @click="goToServe(demand.id)" :key="i" class="color">
                <li :title="demand.title">{{demand.title}}</li>
                <li>{{demand.cost}}元</li>
                <li>{{demand.createTime.substr(0, 10)}}</li>
            </ul>
        </div>
        <div class="no3">
            <div><span>交易数据</span><a href="/seller/order/order_list_seller">更多 +</a></div>
            <table>
                <tr>
                    <th>服务项目</th>
                    <th>成交金额</th>
                    <th>完成时间</th>
                </tr>
                <tr class="tr" v-for="(tran,i) in transactionList" :key="i" @click="goToOrder(tran.id)">
                    <td :title="tran.orderName">{{tran.orderName}}</td>
                    <td>{{tran.recAmtSum}}元</td>
                    <td>{{tran.compTime}}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            homepageOne:[],
            homepageTwo:[],
            demandList:[],
            transactionList:[]
        }
    },
    methods:{
        goQuery(){
            this.$com.majax.get(this.$com.testCsIp + '/shop/getShopHomepage',{},{
                success:(res)=>{
                this.homepageOne=res.data.homepageOne;
                this.homepageTwo=res.data.homepageTwo;
                this.demandList=res.data.demandList;
                this.transactionList=res.data.transactionList;
            }})
        },
        goToOrder(id){
            this.$router.push({ name:'order_details_seller',query: { id } })
        },
        goToServe(id){
            this.$router.push({ name:'demand_details',query: { id } })
        },
        goToShop(id){
            this.$router.push({  path:'/shop_management/shop_man_index',query:{ ShopId : id } })
        }
    },
    mounted(){
        /*if (!this.$store.state.tokenId) {
            this.$message({
                message: '请先登录',
                type: 'error',
                duration: 1000
            });
            setTimeout(()=>{
                this.$router.push({ path:'/login' })
            },'1000');
        };*/
        this.goQuery()
    }
}
</script>

<style scoped>
    body{
        margin: 0;padding: 0;
    }
    ul{
        list-style: none;
        padding: 0;margin: 0;
    }
    .seller{
        position: relative;
    }
    .seller>div{
        padding-top: 10px;
        padding-left: 20px;
        box-sizing: border-box
    }
    .no1{
        width: 992px;height: 270px;
        background: #fff;
    }
    .no11{
        margin-top:22px;
        float: left; margin-bottom: 8px
    }
    .no11 li{
        float: left;
    }
    .no11>li:first-child{
        width: 70px;height: 70px;
        border:1px solid #ccc;
        margin-right: 10px;
        line-height: 70px;
    }
    .no11>li:first-child img{
        width: 68px;cursor: pointer;
    }
    .no11>li:nth-child(2) li:not(:first-child):not(:nth-child(2)){
        font-size: 14px;color: #333;
        line-height: 35px;
        margin-left: 55px
    }
    .no11>li:nth-child(2) li:not(:first-child):not(:nth-child(2)) span{
        color: #E62E3D;font-weight: bold
    }
    .no11>li:nth-child(2) li:first-child{
        font-size: 16px;font-weight: bold;
        line-height: 32px;color: #3296FA;cursor: pointer;
    }
    .no11>li:nth-child(2) li:nth-child(2){
        font-size: 12px;
        line-height: 37px;margin-left:5px
    }
    .no11>li:nth-child(3) li{
        font-size: 12px;color: #999;
        line-height: 40px;
    }
    .no11>li:nth-child(3) li:not(:last-child){
        margin-right: 80px
    }
    .no11>li:nth-child(3) li span:first-child{
        font-size: 22px;font-weight: bold;
        color: #E62E3D
    }
    .no11>li:nth-child(3) li span:last-child{
        font-size: 14px;color: #333;
    }
    .no11>li:nth-child(3) li:first-child span:first-child{
        color: #00B247
    }
    .no11>li:nth-child(3) li:nth-child(2) span:first-child{
        color: #3296FA;
    }
    .no11>li:nth-child(3) li:nth-child(3) span:first-child{
        color: #FF4A03
    }
    .no12 li{
        float: left;
    }
    .no12 ul{
        width: 890px;
        border-top: 1px solid #e2e2e2
    }
    .no12{
        float: left;margin-left:12px
    }
    .no12 li{
        font-size: 14px;color: #333;
        line-height: 55px;
    }
    .no12>li:first-child{
        font-weight: bold;
    }
    .no12 ul li:not(:last-child){
        margin-right: 50px
    }
    .no12 ul span{
        color: #E62E3D;font-weight: bold
    }
    .no2{
        position: absolute;
        left: 0;top: 281px;
        width: 491px;height: 280px;
        background: #fff;
    }
    .no2 a,.no2 ul li{
        float: left;line-height: 36px;
        font-size:14px;color: #333 ;
    }
    .no2 div{
        margin-bottom: 10px
    }
    .no2 ul{
        cursor: pointer;
    }
    .color:hover li{
        color: #FF4A03
    }
    .no2 ul li:first-child{
        width: 230px;padding-left: 10px;
        overflow: hidden;white-space: nowrap;text-overflow: ellipsis;
    }
    .no2 ul li:nth-child(2){
        width: 108px;text-align: center;margin-left: 10px;
        margin-right: 27px;white-space: nowrap;
    }
    .seller>div>div{
        width:100%;height:36px;
        border-bottom: 1px solid #e2e2e2;
        display: flex;
    }
    .seller>div>div>span{
        display: block;font-weight: bold;
        font-size: 14px;color: #ff4a03;
        line-height: 35px;
        text-align: center;
        width: 80px;
        border-bottom: 2px solid #ff4a03;
    }
    .seller>div>div>a{
        margin-left: 340px;
        text-decoration: none;
        font-size: 12px;color: #666;
        line-height: 37px
    }
    .no3{
        position: absolute;
        left: 501px;top: 281px;
        width: 491px;height: 280px;
        background: #fff;
        overflow: hidden;
    }
    table{
        width: 450px;margin-top: 20px;
    }
    table th{
        font-size: 14px;
        line-height: 36px;
        background: #f5f5f5;border-right: 1px solid #fff;
        text-align: center;
    }
    table th:first-child{
        width: 205px;
    }
    table th:nth-child(2){
        width: 122px
    }
    table tr:not(:first-child){
        font-size: 12px;
        line-height: 39px;
    }
    table td{
        border-bottom: 1px dotted #ccc;
    }
    table td:first-child{
        font-size: 12px;color: #0561be;
        padding-left: 10px;line-height: 20px;
    }
    table td:not(:first-child){
        text-align: center;

    }
    table td:nth-child(2){
        color: #e62e3d;font-weight: bold;
        font-size: 12px;
    }
    .tr{
        cursor: pointer;
    }
</style>
