import Vue from 'vue'
import ElementUI from 'element-ui'
import axios from 'axios'
import store from '../../store'
import router from '../../router'
import Message from '../../components/UI/dialog'

Vue.use(ElementUI);
/*var testIp = 'http://192.168.0.197:8083'*/
var that = new Vue({router})
const IP = require('./ip.js').IP
/*axios.interceptors.request.use(config=> {
    return config;
}, err=> {
    Message.alert('请求超时！请稍后重试');
    return Promise.resolve(err);
})
axios.interceptors.response.use(data=> {
    if (data.status && data.status == 200 && data.data.status == 'error') {
        Message.alert(data.data.msg);
        return;
    }
    return data;
}, err=> {
    if (err.response.status == 504||err.response.status == 404) {
        Message.alert('服务器繁忙，请稍后重试');
    } else if (err.response.status == 403) {
        Message.alert('权限不足,请联系管理员!');
    }else {
        Message.alert( '服务器繁忙，请稍后重试');
    }
    return Promise.resolve(err);
})*/

export default{
    testUsIp:IP + '/users',//Us
    testUwIp:IP + '/userw',//Uw
    testInin:IP + '/info',//Info
    testImgin:IP + '/zuul/info',//Img
    testCwIp:IP + '/ctcw',//ctcw
    testCsIp:IP + '/ctcs',//ctcs
    testChIp:IP + '/ctcauth',//ctcauth

    errorImg:'this.src="' + require('../img/404.png') + '"',

    user:JSON.parse(localStorage.getItem('user')),
    majax:{
        post:function(url,data,callback,_this){
            var token = localStorage.getItem('tokenId');
            if (!token) {
                /*Message.alert('请先登录','confirm','',(_res)=>{
                    _res.thisShow = false;
                    that.$router.push({ path:'/login' })
                })*/
            }else{
                axios.post(url, data,{
                    headers: {
                        'token': token
                    }
                })
                .then((response) => {
                    if (_this) {
                        _this.loading = false;
                    };
                    var res = response.data;
                    if (res.code === 0) {
                        callback.success(res)
                    }else if(res.code === 1){
                        Message.alert('登录失效，请重新登录','confirm',()=>{
                            localStorage.clear();
                            store.commit('REMOVE_token');
                            that.$router.push({ path:'/login' })
                        },(_res)=>{
                            localStorage.clear();
                            _res.thisShow = false;
                            store.commit('REMOVE_token');
                            that.$router.push({ path: '/login' })
                        })
                    }/*else if(res.code === 2){
                        Message.alert('账号被锁定，请重新登录',{},()=>{
                            localStorage.removeItem('tokenId')
                            _this.$router.push({ path:'/login' })
                        },(res)=>{
                            res.thisShow = false;
                            this.$router.push({ path: '/login' })
                        })
                    }*/else{
                        if (callback.error) {
                            callback.error(res)
                        }else{
                            Message.alert(res.msg,'error')
                        }
                    };
                })
                .catch((error) => {
                    if (_this) {
                        _this.loading = false;
                    };
                    if (callback.error) {
                        callback.error(error)
                    }else{
                        Message.alert('系统繁忙，请稍后再试','error',"",(res)=>{
                            res.thisShow = false;
                            localStorage.clear();
                            store.commit('REMOVE_token');
                            router.push({ path:'/login' })
                        })
                    };
                })
            };
        },
        get:function(url,data,callback,_this){
            var token = localStorage.getItem('tokenId');
            if (!token) {
                /*Message.alert('请先登录','confirm','',(_res)=>{
                    _res.thisShow = false;
                    that.$router.push({ path:'/login' })
                })*/
            }else{
                axios.get(url,{
                    headers: {
                        'token': token
                    },
                    params:data
                })
                .then((response) => {
                    if (_this) {
                        _this.loading = false;
                    };
                    var res = response.data;
                    if (res.code === 0) {
                        callback.success(res)
                    }else if(res.code === 1){
                        Message.alert('登录失效，请重新登录','confirm',()=>{
                            localStorage.clear();
                            store.commit('REMOVE_token');
                            that.$router.push({ path:'/login' })
                        },(_res)=>{
                            localStorage.clear();
                            _res.thisShow = false;
                            store.commit('REMOVE_token');
                            that.$router.push({ path: '/login' })
                        })
                    }/*else if(res.code === 2){
                        Message.alert('账号被锁定，请重新登录',{},()=>{
                            localStorage.removeItem('tokenId')
                            _this.$router.push({ path:'/login' })
                        },(res)=>{
                            res.thisShow = false;
                            this.$router.push({ path: '/login' })
                        })
                    }*/else{
                        if (callback.error) {
                            callback.error(res)
                        }else{
                            Message.alert(res.msg,'error')
                        }
                    };
                })
                .catch((error) => {
                    if (_this) {
                        _this.loading = false;
                    };
                    if (callback.error) {
                        callback.error(error)
                    }else{
                        Message.alert('系统繁忙，请稍后再试','error',"",(res)=>{
                            res.thisShow = false;
                            localStorage.clear();
                            store.commit('REMOVE_token');
                            router.push({ path:'/login' })
                        })
                    };
                    console.log(error)
                })
            };
        }
    },
    validate:function(data){
        var isVali = true;
        for(var key in data){
            var item = data[key];
            item.error = "";
            if (item.min) {
                if (!this.min(item)) {
                    item.error = item.name + '不少于' + item.min + '位';
                }
            };
            if (item.max) {
                if (!this.max(item)) {
                    item.error = item.name + '不大于' + item.max + '位';
                }
            };
            if(item.validate&&item.validate.length>0){
                var v = item.validate
                for (var i = v.length; i >= 0; i--) {
                    if (v[i]=="password") {
                        if (!this.checkPassword(item.value)) {
                            item.error = item.name + '需字母和数字组合';
                        }
                    };
                    /*if (v[i]=="passport") {
                        if (!this.checkPassport(item.value)) {
                            item.error = item.name + '格式不正确';
                        }
                    };*/
                    if (v[i]=="number") {
                        if (!this.checkNumber(item.value)) {
                            item.error = item.name + '格式不正确';
                        }
                    };
                    if (v[i]=="IDcard") {
                        if (!this.checkIDcard(item.value)) {
                            item.error = item.name + '格式不正确';
                        }
                    };
                    if (v[i]=="phone") {
                        if (!this.checkMobile(item.value)) {
                            item.error = item.name + '格式不正确';
                        }
                    };
                    if (v[i]=="telphone") {
                        if (!this.checkPhone(item.value)) {
                            item.error = item.name + '格式不正确';
                        }
                    };
                    if (v[i]=="telphone&phone") {
                        if (!this.checkPhoneMobile(item.value)) {
                            item.error = item.name + '格式不正确';
                        }
                    };
                    if (v[i]=="email") {
                        if (!this.checkEmail(item.value)) {
                            item.error = item.name + '格式不正确';
                        }
                    };
                    if (v[i]=="CN") {
                        if (!this.checkCN(item.value)) {
                            item.error = item.name + '只能中文';
                        }
                    };
                    if (v[i]=="org") {
                        if (!this.checkOrg(item.value)) {
                            item.error = item.name + '只能中文、括号';
                        }
                    };
                    if (v[i]=="credit") {
                        if (!this.checkCredit(item.value)) {
                            item.error = item.name + '格式不正确';
                        }
                    };
                    if (v[i]=="required") {
                        if (!this.required(item.value)) {
                            item.error = item.name + '不能为空';
                        }
                    };
                    if (v[i]=="integer") {
                        if (!this.checkInteger(item.value)) {
                            item.error = item.name + '只能为正整数，且不能有空格';
                        }
                    }
                };
            }
        }

        for (var key in data) {
            if(data[key].validate&&data[key].validate.length>0){
                if (data[key].error != "") {
                    isVali = false;
                }
            }
        };
        return isVali
    },
    //验证空
    required:function(value){
        if (typeof(value)==='object'&&value != null) {
            if (value.fileId) {
                return true;
            }else{
                return false;
            };
        }else{
            if (value === ""||value === null||value === undefined) {
                return false;
            }else{

                var val = value.toString().replace(/(^\s*)|(\s*$)/g, "");
                if (val === "") {
                    return false;
                }else{
                    return true;
                };
            };
        };
    },
    //验证数字
    checkNumber:function(value){
        return isNaN(value)?false:true
    },
    //验证中文
    checkCN:function(value){
        var re = /^[\u4e00-\u9fa5]+$/
        if(re.test(value)){
            return true
        }else{
            return false
        }
    },
    //验证正整数
    checkInteger:function(value){
        var re = /^[1-9]\d*$/;
        if(re.test(value)){
            return true
        }else{
            return false
        }
    },
    //验证中文加括号
    checkOrg:function(value){
        var re = /^[\u4e00-\u9fa5()\（\）]+$/
        if (re.test(value)) {
            return true
        }else{
            return false
        };
    },
    //验证邮箱
    checkEmail:function(value){
        if (!this.required(value)) {
            return true
        };
        var re = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/
        if(re.test(value)){
            return true
        }else{
            return false
        }
    },
    //验证手机
    checkMobile:function(value) {
        if (!this.required(value)) {
            return true
        };
        var re = /^1\d{10}$/
        if (re.test(value)) {
            return true
        } else {
            return false
        }
    },
    //验证固定电话
    checkPhone:function(value){
        if (!this.required(value)) {
            return true
        };
        var re = /(0\d{2,3}\d{7,8})|(0\d{2,3}-\d{7,8})/
        if (re.test(value)) {
            return true
        } else {
            return false
        }
    },
    checkPhoneMobile:function(value){
        if (this.checkMobile(value)||this.checkPhone(value)) {
            return true
        } else {
            return false
        }
    },
    //验证身份证
    checkIDcard:function(value){
        var re = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/
        if (re.test(value)) {
            return true
        } else {
            return false
        }
    },
    //验证护照
    /*checkPassport:function(value){
        var re = /^1[45][0-9]{7}$|(^[P|p|S|s]\d{7}$)|(^[S|s|G|g|E|e]\d{8}$)|(^[Gg|Tt|Ss|Ll|Qq|Dd|Aa|Ff]\d{8}$)|(^[H|h|M|m]\d{8,10}$)/
        if (re.test(value)) {
            return true
        } else {
            return false
        }
    },*/
    //验证社会代码
    checkCredit:function(value){
        var re = /^[1-9A-GY]{1}[1239]{1}[1-5]{1}[0-9]{5}[0-9A-Z]{10}$/
        if (re.test(value)) {
            return true
        } else {
            return false
        }
    },
    //最小位数
    min:function(item){
        if (item.value.length>=item.min) {
            return true
        } else {
            return false
        }
    },
    //最大位数
    max:function(item){
        if (item.value.length<=item.max) {
            return true
        } else {
            return false
        }
    },
    //数字和字母组合
    checkPassword:function(value){
        var re = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]*$/
        if (re.test(value)) {
            return true
        } else {
            return false
        }
    },
    //查字典
    findCode:function(code,fn){
        axios.get(this.testUsIp + '/dic/list/' + code)
        .then((response) => {
            var res = response.data;
            if (res.code===0) {
                var list = []
                for (var i = 0; i < res.data.length; i++) {
                    var item = res.data[i]
                    list[i] = {name:item.typename,id:item.typecode}
                };
                fn(list)
            }else{
                /*Message.alert('系统错误','error','',(res)=>{
                    res.thisShow = false;
                })*/
            };
        })
        .catch((error) => {
            /*Message.alert('系统错误','error','',(res)=>{
                res.thisShow = false;
            })*/
        })
    },
    //排序
    Isort:{
        up:function(arr,sort){
            arr.sort(function(a,b){
                return a[sort] - b[sort]
            })
            return arr;
        },
        down:function(arr,sort){
            arr.sort(function(a,b){
                return b[sort] - a[sort]
            })
            return arr;
        }
    },
    isLogin:function(){
        if (!store.state.tokenId) {
            ElementUI.Message({
                message: '请先登录',
                type: 'error',
                duration: 1000
            });
            setTimeout(()=>{
                location.href = "/login";
            },'1000')
        };
    },
    ImgHW:function(src,fn,_fn){
        var img = new Image();
            img.src = src;
        img.onload = ()=>{
            fn(img.width>img.height)
            if (_fn) {
                _fn();
            };
        }
    },
    UrlBack:function(_this,fn){
        if (_this.$route.query.back) {
            _this.$router.go(-1);
        }else{
            fn();
        };
    },
    GetObjList:function(obj,str){
        var nameList = obj[str].split("/"),
            keyList = obj[str + 'Path'].split(","),
            objList = [];
        for (var i = 0; i < nameList.length; i++) {
            objList.push([{name:nameList[i],id:keyList[i]}])
        };
        return objList;
    },
    findName:function(id,list){
        for (var i = list.length - 1; i >= 0; i--) {
            if(list[i].id === id){
                return list[i].name;
            }
        };
    },
    date:function(val){
        var now = new Date(val);
        var year=now.getFullYear();
        var month=now.getMonth()+1;
        var date=now.getDate();
        var hour=now.getHours();
        var minute=now.getMinutes();
        var second=now.getSeconds();
        return year+"-"+month+"-"+date;
    }
}