<template>
    <div>
        <IndexHeader_home :textMeg="textMeg"/>
        <div class="online">
            <div class="set">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>卖家入门</el-breadcrumb-item>
                </el-breadcrumb>
                <div class="sett">
                    <div>卖家入门</div>
                    <div class="content-body">
                        <h3 id="yhzc">用户注册</h3>
                        <p>第一步、在平台首页上方点击“注册”；</p>
                        <p><img src="../../../assets/img/index1/reg.png" alt=""></p>
                        <p>第二步、选择个人注册或单位注册，填写相关资料，点击注册。完成。</p>
                        <p><img src="../../../assets/img/index1/reg01.png" alt=""></p>
                        <h3>用户认证</h3>
                        <p>完成注册后，可进入个人页面。用户可完善资料，完成认证。</p>
                        <p><img src="../../../assets/img/index1/base.png" alt=""></p>
                        <h3 id="mfkd">免费开店</h3>
                        <p>第一步、进入个人中心，点击“申请免费开店”，</p>
                        <p><img src="../../../assets/img/index1/shop.png" alt=""></p>
                        <p>第二步、根据提示完成信息填写，在完成实名认证和身份认证的前提下，可以创建店铺。</p>
                        <p><img src="../../../assets/img/index1/shop1.png" alt=""></p>
                        <h3 id="spfb">商品发布流程</h3>
                        <p>开店成功后，选择“卖家中心”---“服务管理”------“发布服务”，选择新增商品的类目，然后“填写商品信息”，填写具体的商品信息后，选择“发布上架”即可。</p>
                        <h3 id="zygl">资源管理流程</h3>
                        <p>选择“卖家中心”---“资源管理”---“实验室管理/仪器设备管理/专家人才管理”，填写具体信息，点击“保存/添加”即可。</p>
                        <h3 id="dpzx">店铺装修流程</h3>
                        <p>开店成功后，选择“卖家中心”-----“店铺管理”----“店铺装修”，搜索店铺设计名称，点击“新增”即可。</p>
                    </div>
                </div>
            </div> 
        </div>
        <IndexFooter_home />
    </div>
</template>

<script>
import IndexHeader_home from '@/components/IndexHeader_home.vue'
import IndexFooter_home from '@/components/IndexFooter_home.vue'
export default {
    data(){
        return{
            textMeg:'卖家入门'
        }
    },
    components: {
        IndexHeader_home,IndexFooter_home
    },
}
</script>

<style scoped>
    p{
        margin: 14px 0;
    }
    .online{
        background: #f5f5f5;min-width:1200px;padding-bottom: 20px 
    }
    .set{
        width: 1200px;margin: 0 auto;padding-top: 20px
    }
    .set .sett{
        margin-top: 10px;
        min-height: 500px;background: #fff;
        padding: 30px 40px 60px; 
        
    }
    .set .sett>div:first-child{
        font-size: 28px;border-bottom: 1px solid #eee;
        padding-bottom: 20px;text-align: center;margin-bottom: 25px
    }
    .content-body p{
        text-indent: 28px;
        font-size: 14px;
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    }
    h3{
        display: block;
        font-size: 16px;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        color: #333
    }
    .content-body p span{
        font-size: 20px;font-weight: bold;color: #333
    }
    table{
        text-align: center;font-size: 14px
    }
        img{
        display: block;
        max-width: 100%;
        margin: 0 auto;
    }
</style>
