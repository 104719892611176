<template>
    <div class="mb10">
        <div class="select-wrap">
            <div class="select-list">
                <Xselect :disabled="disabled" :list="list1" placeholder="请选择项目名称" v-model="key1" @on-change="changeSelect1" @on-focus="clickSelect1"/>
            </div>
            <div class="select-list">
                <Xselect :disabled="disabled" :list="list2" placeholder="请选择项目名称" v-model="key2" @on-change="changeSelect2" @on-focus="clickSelect2"/>
            </div>
            <div class="select100">
                <Xselect :disabled="disabled" widthAll :list="list3" placeholder="请选择项目名称" v-model="value" @change="(id)=>$emit('change', id)" @on-focus="clickSelect3"/>
            </div>
        </div>
        <span class="error">{{ error }}</span>
    </div>
</template>

<script>
import Xselect from '@/components/UI/select.vue'
export default {
    props: {
        error:String,
        disabled:Boolean,
        defaultList:Array,
    },
    data(){
        return {
            list1:[],
            list2:[],
            list3:[],
            key1:"",
            key2:"",
            value:"",
        }
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    watch: {
        defaultList(val){
            this.list1 = val[0];
            this.list2 = val[1];
            this.list3 = val[2];
            this.key1 = this.list1[0].id;
            this.key2 = this.list2[0].id;
            this.value = this.list3[0].id;
        },
    },
    computed: {

    },
    components: {
        Xselect,
    },
    methods:{
        clickSelect1:function(fn){
            this.getAddressListData('5a3bf491063548deb25bfac8877df6c4',(res)=>{
                var list = [];
                for (var i = 0; i < res.length; i++) {
                    list.push({id:res[i].id,name:res[i].catName})
                };
                fn(list)
            })
        },
        clickSelect2:function(fn){
            if (this.key1) {
                this.getAddressListData(this.key1,(res)=>{
                    var list = [];
                    for (var i = 0; i < res.length; i++) {
                        list.push({id:res[i].id,name:res[i].catName})
                    };
                    fn(list)
                })
            };
        },
        clickSelect3:function(fn){
            if (this.key2) {
                this.getAddressListData(this.key2,(res)=>{
                    var list = [];
                    for (var i = 0; i < res.length; i++) {
                        list.push({id:res[i].id,name:res[i].catName})
                    };
                    fn(list)
                })
            };
        },

        changeSelect1:function(item){
            if (item.id != this.key1) {
                this.key2 = "";
                this.list2 = [];
                this.value = "";
                this.$emit('change', "")
                this.list3 = [];
            };
        },
        changeSelect2:function(item){
            if (item.id != this.key2) {
                this.value = "";
                this.$emit('change', "")
                this.list3 = [];
            };
        },
        getAddressListData:function(id,fn){
            this.$axios.get(this.$com.testCsIp+'/baseCategory/getCategoryByPid/'+id)
            .then((response) => {
                var res = response.data;
                fn(res.data)
            })
            .catch((error) => {
                this.$dialog.alert("系统错误",'error')
            })
        },
    },
    mounted:function(){

    },
}
</script>

<style scoped>
.select-wrap{
    height: 40px;
}
.select-list{
    position:relative;
    width: 200px;
    height: 40px;
    z-index:12;
    float: left;
    margin-right: 10px;
}
.select-inp{
    position:absolute;
    color: #333;
    padding-left: 6px;
    width:100%;
    height: 40px;
    line-height: 40px;
    margin-right: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
    font-size:14px;
    background:url('../../../src/assets/img/user/kai.png') no-repeat;
    background-position:right 10px center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.select-option{
    width:100%;
    position:absolute;
    border: 1px solid #ccc;
    top:44px;
    background:#fff;
    height: 154px;
    overflow: auto;
    box-shadow:0 2px 12px 0 rgba(0,0,0,.1);
}
.select-option li{
    padding:0 6px;
    color: #333;
    cursor: pointer;
    line-height:30px;
    font-size:14px;
}
.select-option li:hover{
    background:#eee;
}
.mask{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:11;
}
.error{
    color: red;
    display: block;
    font-size: 14px;
}
.mb10{
    margin-bottom: 10px;
}
.inp-dis{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99;
    cursor: not-allowed;
}
.select-list .item-dis{
    background-color: #f5f7fa;
    border-color: #e4e7ed;
    color: #c0c4cc;
}
.select-list .item-default{
    color:#ababab;
}
.fade-enter-active, .fade-leave-active {
  transition: all .2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
.empty-list{
    padding-left:5px;
    color:#999;
}
.select100{
    width:410px;
    float:left;
    margin-top:20px;
}
.select100 .select-list{
    width:100%;
}
.zindex17{
    z-index:17;
}
.zindex16{
    z-index:16;
}
</style>
