<template>
    <div class="no2">

        <formItem :disabled="disabled" required errorStyle="inline" rwidth="220" @on-focus="(fn)=>fn(fileList)" v-model="fileType.value" :error="fileType.error" title="文件类型" type="select"  placeholder="请选择文件类型" :selectList="fileType" />

        <!-- <formItem :disabled="disabled" width="160" maxlength="50" errorStyle="inline" title="服务标题" required :error="title.error" type="text" v-model="title.value" placeholder="请填写服务标题" /> -->

        <formItem type="upload" title="上传文件" />

        <formItem title="文件描述" type="textarea"  placeholder="请输入文件描述" v-model="description"/>
        <ul>
            <li></li>
            <li class="form-btn">
                <xbutton @on-click="goSelf" value="保 存"></xbutton>
            </li>
        </ul>
    </div>
</template>

<script>
import formItem from '@/components/UI/formItem.vue'
import xbutton from '@/components/UI/xbutton.vue'
export default {
    data(){
        return{
            disabled:false,
            fileType:{
                value:"",
                babel:"",
                error:"",
                validate:['required'],
                name:"文件类型"
            },
            description:"",
            fileList:[],
            data:{
                fileType:4,
                businId:"1",
                categoryId:"1",
            },
            autoServiceId:this.$route.query.autoServiceId,
            id:this.$route.query.id,
        }
    },
    methods:{
        childByValue(childValue){
            console.log(childValue)
        },
        goSelf(){
            //this.$refs.upload.submit();
            var data = {
                categoryId:this.autoServiceId,
                id:this.id,
                description:this.description,
                fileType:this.fileType.value,
            }
            var dataV = {
                fileType:this.fileType,
            }
            var validate = this.$com.validate(dataV);
            if(validate){
                this.$com.majax.post(this.$com.testChIp + '/autoLog/saveOrUpdateLog',this.$qs.stringify(data),{
                    success:(res)=>{
                        console.log(res)
                        if(res.code == 0){
                            this.$dialog.alert('保存成功！','success',()=>{
                                this.$router.push({name:'buy_self_step5'})
                            })
                        }
                    }
                })
            }
        },
        getData:function(){
            if(this.id){
                this.disabled = true;
                this.$com.majax.get(this.$com.testChIp + '/autoLog/getLogDetails/' + this.id,{},{
                    success:(res)=>{
                        console.log(res)
                    }
                })
            }else{
                this.$com.majax.get(this.$com.testChIp + '/autoLog/getLogId/' + this.autoServiceId,{},{
                    success:(res)=>{
                        console.log(res)
                        if(res.code == 0){
                            this.id =res.data.logId;
                            var fileType=res.data.fileType;
                            for(var key in fileType){
                                this.fileList.push({id:fileType[key],name:fileType[key]})
                            }              
                        }
                    }
                })
            }
        }
    },
    components: {
        formItem,
        xbutton,
    },
    mounted:function(){
        this.$parent.titleMeg = this.$route.query.id ? '修改日志文件' : '新增日志文件';
        this.$parent.rightLink = false;
        this.getData();
    },
}
</script>

<style scoped>
.no2{
    width:992px;
    background:#fff;
    padding: 25px 20px 15px 20px;
}
.no2 ul{
    margin-left: 150px;
    display: flex;
    margin-bottom: 20px;
}
.no2 ul li:first-child{
    width: 100px;
    margin-right: 7px;
    text-align: right;
    font-size: 14px;
}
.form-btn{
    width:170px;
}
.form-btn button{
    font-size:18px;
    height:36px;
}
.step-item{
    width:20px;
    height:20px;
    background:#ccc;
}
</style>

