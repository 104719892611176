<template>
    <div>
        <ul class="step-wrap">
            <li :class="{'active':index<active}" v-for="(item,index) in list" :key="index">
                <div class="bg"></div>
                <span class="step-num">{{index+1}}</span>
                <span class="title">{{item}}</span>
            </li>
        </ul>
    </div>
</template>


<script>
export default {
    props:{
        list:Array,
        active:Number
    },
    data(){
        return{

        }
    },
    methods:{

    },
    components: {

    },
    mounted:function(){

    },
}
</script>

<style scoped>
.step-wrap{
    display:flex;
    padding:16px 5px 10px 5px;
    margin-bottom:24px;
}
.step-wrap li{
    flex:1;
    text-align:center;
    position:relative;
}
.step-wrap li:not(:first-child):before{
    content:"";
    position:absolute;
    height:3px;
    width:50%;
    background:#ccc;
    left:0;
    top:50%;
    margin-top:-13px;
}
.step-wrap li:not(:last-child):after{
    content:"";
    position:absolute;
    height:3px;
    width:50%;
    background:#ccc;
    right:0;
    top:50%;
    margin-top:-13px;
}
.bg,.step-num{
    position:absolute;
}
.bg{
    width:28px;
    height:28px;
    left:50%;
    margin-left:-15px;
    border-radius:8px;
    transform:rotate(45deg);
    -ms-transform:rotate(45deg);     /* IE 9 */
    -moz-transform:rotate(45deg);    /* Firefox */
    -webkit-transform:rotate(45deg); /* Safari 和 Chrome */
    -o-transform:rotate(45deg);
    background:#ccc;
    z-index:8;
}
.step-wrap li span{
    font-size:12px;
}
.step-num{
    left:50%;
    margin-left:-4px;
    top:5px;
    color:#fff;
    z-index:9;
    font-weight:bold;
}
.title{
    top:10px;
    display:block;
    margin-top:34px;
    color:#333;
}
.active .title{
    color:#ff4a03;
}
.active .bg,.step-wrap li.active:before,.step-wrap li.active:after{
    background:#ff4a03;
}
</style>

