<template>
    <div class="no2">
        <xstep class="xstep" :list="$parent.selfStep" :active="5" />
        <div class="btn-wrap">
            <div class="notice">
                <i class="i-notice"></i>
                日志文件是动态的，不同企业的组织架构和制度文件不同，用户可以通过点击制度文件下载模板中的制度文件，用户可以以此作为参考进行线下编辑。
            </div>
            <xbutton @on-click="goAdd" value="新增文件"></xbutton>
        </div>
        <xtable index :loading="loading" :search="search" :head="head" :tableData="tableData" btn="edit,del" @on-delClick="delClick" @on-editClick="editClick" @on-changePage="(v)=>getData(v)" @on-handleSelectionChange="(v)=>checkboxList=v" />
        <div class="bottom">
            <xbutton btnType="bg-fff" class="btn-back" @on-click="()=>$router.push({ path:'/buyer/selfhelp/self_step4' })" value="返回"></xbutton>
            <xbutton class="btn-next" @on-click="nextStep" value="下一步（认证完成）"></xbutton>
        </div>
    </div>
</template>

<script>
import xtable from '@/components/UI/xtable.vue'
import xbutton from '@/components/UI/xbutton.vue'
import xstep from '@/components/UI/xsteps.vue'
export default {
    data(){
        return{
            search:{},
            tableData:[],
            head:{
                fileTypeName:"文件类型",
                logList:"部门制度",
            },
            loading:true,
            autoServiceId:this.$route.query.autoServiceId,
        }
    },
    methods:{
        goAdd(){
            this.$router.push({name:'step5_details',query:{autoServiceId:this.autoServiceId}})
        },
        editClick(item){
            this.$router.push({name:'step5_details',query:{id:item.id}})
        },
        nextStep(){
            var data = {
                autoServiceId:this.autoServiceId,
                status:2,
            }
            this.$com.majax.post(this.$com.testChIp + '/auto-service/updateAutoServiceStatus',this.$qs.stringify(data),{
                success:(res)=>{
                    if(res.code == 0){
                        this.$router.push({name:'buy_self_step6',query:{autoServiceId:this.autoServiceId}})
                    }
                }
            })
        },
        getData(){
            this.$com.majax.get(this.$com.testChIp + '/autoLog/getAutoLogList/' + this.autoServiceId,{},{
                success:(res)=>{
                    if(res.code == 0){
                        this.loading=false;
                        //this.tableData.records = res.data.fileData;
                    }
                }
            })
        },
        delClick:function(item){
            this.$dialog.confirm('是否确认删除','error',()=>{
                var data = {id:item.id}
                this.$com.majax.post(this.$com.testChIp + '/autoLog/deleteAutoLog/',this.$qs.stringify(data),{
                    success:(res)=>{
                        this.getData();
                    }
                })
            })
        }
    },
    components: {
        xbutton,
        xstep,
        xtable
    },
    mounted:function(){
        this.$parent.titleMeg = '自助认证-日志文件';
        this.$parent.rightLink = true;
        this.$router.beforeEach((to, from ,next) => {
            this.$parent.rightLink = false;
            next();
        });
        this.getData();
    },
}
</script>

<style scoped>
.no2{
    width:992px;
    background:#fff;
    padding: 25px 20px 15px 20px;
}
.xstep{
    background:#f5f5f5;
}
.btn-wrap{
    clear:both;
    overflow:hidden;
    margin-bottom:10px;
}
.btn-wrap button{
    font-size:14px;
    width:80px;
    float:right;
    height:36px;
}
.notice{
    float:left;
    font-size:12px;
    color:#ff0000;
    line-height:36px;
}
.i-notice{
    background:url('../../../assets/img/buyer/notice.png');
    width:14px;
    height:12px;
    display:inline-block;
    margin-right:3px;
}
.bottom{
    clear:both;
    overflow:hidden;
    margin-top:20px;
}
.bottom button{
    font-size:14px;
    height:34px;
}
.btn-back{
    width:80px;
    float:left;
}
.btn-next{
    width:170px;
    float:right;
}
</style>

