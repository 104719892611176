<template>
    <div class="section-top">
        <div class=" d-flex justify-content-between">
            <div style="font-size:30px; line-height: 60px">
                体系认证服务
            </div>
            <ul class="d-flex justify-content-between">
                <li><router-link :to="{path:'/search_page',query:{title:'ISO9001'}}"  target="_blank">ISO9001认证</router-link></li>
                <li><router-link :to="{path:'/search_page',query:{title:'ISO14001'}}"  target="_blank">ISO14001认证</router-link></li>
                <li><router-link :to="{path:'/search_page',query:{title:'IATF16949'}}"  target="_blank">IATF16949认证</router-link></li>
                <li><router-link :to="{path:'/search_page',query:{title:'ISO45001'}}"  target="_blank">ISO45001认证</router-link></li>
                <li><router-link :to="{path:'/search_page',query:{title:'QC080000'}}"  target="_blank">QC080000认证</router-link></li>
                <li><router-link :to="{path:'/search_page',query:{title:'体系认证服务'}}"  target="_blank">更多+</router-link></li>
            </ul>
        </div>
        <div class="d-flex">
            <div class="dflex" id="dflex">
                <ul>
                    <li><router-link :to="{path:'/search_page',query:{title:'ISO9001'}}"  target="_blank">ISO9001</router-link></li>
                    <li><router-link :to="{path:'/search_page',query:{title:'ISO14001'}}"  target="_blank">ISO14001</router-link></li>
                    <li><router-link :to="{path:'/search_page',query:{title:'IATF16949'}}"  target="_blank">IATF16949</router-link></li>
                    <li><router-link :to="{path:'/search_page',query:{title:'QC080000'}}"  target="_blank">QC080000</router-link></li>
                </ul>
            </div>
            <div>
                <indexServeItem  v-for="(item,index) in lists" :key="index" :list="item" />
            </div>
        </div>
    </div>
</template>

<script>
import indexServeItem from '@/components/UI/indexServeItem.vue'
export default {
    data(){
        return{
            lists:[]
        }
    },
    mounted:function(){
        this.GetData();
    },
    components: {
        indexServeItem
    },
    methods: {
        GetData:function(){
            this.$axios.get(this.$com.testCsIp + '/recomCategory/listService',
            {params:{
                id:'4028800a69463bb00169463cab970002',
                num:4,
                recommendType:1
            }})
            .then(res=>{
                this.lists=res.data.data;
            })
        },
        more:function(){

        }
    }
}
</script>

<style scoped>

</style>

