<template>
    <div>
        <div class="header">
            <div class="main01">
                <router-link to="/"><img src="../assets/img/index/logo.png" alt=""></router-link>
                <div></div>
                <span>支付结果</span>
            </div>
        </div>
        <div class="section">
            <div>
                <div class="sp" v-if="true">
                    <div>
                        <img src="../assets/img/user/i-success.png" />
                    </div>
                    <div>
                        <p class="pF">恭喜你，支付成功！</p>
                        <p class="pL">你可以点击查看订单。</p>
                        <xbutton btnType="bg-fff" class="btn-back" @on-click="goOrder" value="查看订单"></xbutton>
                        <xbutton class="btn-back" @on-click="goIndex" value="返回首页"></xbutton>
                    </div>
                </div>
                <div class="sp" v-if="true">
                    <div>
                        <img src="../assets/img/user/i-error.png" />
                    </div>
                    <div>
                        <p class="pF">抱歉，支付失败！</p>
                        <p class="pL">你可以点击查看订单并重新支付。</p>
                        <xbutton btnType="bg-fff" class="btn-back" @on-click="goOrder" value="查看订单"></xbutton>
                        <xbutton class="btn-back" @on-click="goIndex" value="返回首页"></xbutton>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import xbutton from '@/components/UI/xbutton.vue'
export default {
    components: {
        Footer,
        xbutton
    },
    data(){
        return {

        }
    },
    methods:{
        goPay(){},
        goOrder(){},
        goIndex(){},
    },
    mounted:function(){

    }
}
</script>


<style scoped>
.header{
    width: 100%;
    min-width: 1200px;
    height: 102px;
}
.header .main01{
    width: 1200px;
    margin: 0 auto;
    position: relative;
}
.main01 img:first-child{
    position: absolute;
    top: 22px;
    left:0;
}
.main01 div:nth-child(2){
    position: absolute;
    top:35px;
    left: 182px;
    height: 35px;
    border: 0.5px solid #CCDCF9;
}
.main01 span:nth-child(3){
    position: absolute;
    top:35px;
    left:202px;
    font-size: 20px;
    color: #666
}
.section{
    background-color: #F5F5F5;
    width: 100%;
    min-width: 1200px;
    position: relative;
}
.section>div{
    margin: 20px auto;
    width: 1200px;
    min-width: 1200px;
    background: #fff;
    overflow: hidden;
    padding-top: 90px;
}
.sp{
    margin-left:400px;
    overflow: hidden;
}
.sp img{
    width: 40px;height: 40px;
    margin-right: 16px;
    margin-top: 6px;
}
.sp>div{
    float: left;
}
.pF{
    font-size: 20px;
    color: #333;
    font-weight: bold;
}
.pL{
    font-size: 12px;
    color: #666;
    margin-bottom: 33px
}
.xsp{
    border-top:1px solid #ddd ;
    padding: 20px 0 0 36px;
}
.xsp p{
    font-size: 12px;
    color: #666;
}
.xsp p:first-child{
    font-size: 14px;
    color: red;
}
.btn-back{
    width: 120px;
    font-size: 14px;
    margin-right: 10px;
    margin-bottom: 96px;
}
</style>
