<template>
    <div>
        <div class="header">
            <div class="main01">
                <router-link to="/"><img src="../assets/img/index/logo.png" alt=""></router-link>
                <div></div>
                <span>用户登录</span>
            </div>
        </div>
        <div class="section">
            <div>
                <div>
                    <div>
                        <ul class="login-box">
                            <li class="login-box-title">账户登录</li>
                            <div class="form-wrap">
                                <li class="login-box-item item-account">
                                    <div>
                                        <input autocomplete='off' v-on:keyup.13="submit" type="text" name="uname" placeholder="用户名\手机号\邮箱" v-model="account.value">
                                    </div>
                                    <p>{{account.error}}</p>
                                </li>
                                <li class="login-box-item item-password">
                                    <div>
                                        <input autocomplete='off' v-on:keyup.13="submit" type="password" placeholder="请输入密码" name="upwd" v-model="passWord.value">
                                    </div>
                                    <p>{{passWord.error}}</p>
                                </li>
                                <div class="auto-wrap">
                                    <li class="fl-le auto-login"><input type="checkbox"><span>自动登录</span></li>
                                    <li class="fl-ri"><router-link to="/password">忘记密码？</router-link></li>
                                </div>
                            </div>
                            <li class="form-item btn-wrap"><xbutton @on-click="submit" value="登&nbsp; 录" /></li>
                            <li class="form-bottom">还没有账户， <router-link to="/register">免费注册</router-link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import xbutton from '@/components/UI/xbutton.vue'
export default {
    components: {
        Footer,
        xbutton
    },
    data(){
        return {
            account:{
                value:"",
                error:"",
                validate:['required'],
                name:"用户名"
            },
            passWord:{
                value:"",
                error:"",
                validate:['required'],
                name:"密码"
            },
            loginFrom:0,
        }
    },
    methods:{
                //客户端检测
            isAndroid:function() {
                var u = navigator.userAgent;
                if (u.indexOf("Android") > -1 || u.indexOf("Linux") > -1) {
                    if (window.ShowFitness !== undefined){
                        alert('安卓')
                    } 
                }else if(u.indexOf("iPhone") > -1 || u.indexOf("iOS") > -1){
                    alert('ios')
                }else{
                    alert('都不是，PC端还应该重新判断')
                }
            },
        submit:function(_this){
            var data = {
                account:this.account.value,
                passWord:this.passWord.value,
                loginFrom:this.loginFrom
            };
            var dataV = {
                account:this.account,
                passWord:this.passWord
            };
            var validate = this.$com.validate(dataV)
            if (validate) {
                _this.loading = true
                console.log(this.$qs.stringify(data),'登录发送的用户信息=================')
                this.$axios.post(this.$com.testUwIp + '/login', this.$qs.stringify(data))
                .then((response) => {
                    _this.loading = false;
                    var res = response.data;
                    if (res.code === 0) {
                        this.$store.commit('SET_token',res.data.tokenId)
                        this.$store.commit('SET_user',res.data)
                        sessionStorage.setItem('account',res.data.nickName)
                        this.$router.push({ path: '/users' })
                    }else{
                        this.$dialog.alert(res.msg,'error')
                    };
                })
                .catch((error) => {
                    _this.loading = false;
                    this.$dialog.alert('系统繁忙，请稍后再试','error')
                })
            };
        }
    },
    mounted:function(){
       // this.isAndroid()
    }
}
</script>


<style scoped>
body,ul{
    list-style: none;
    margin: 0;
    padding: 0;
}
.header{
    width: 100%;
    min-width: 1200px;
    height: 102px;
}
.header .main01{
    width: 1200px;
    margin: 0 auto;
    position: relative;
}
.main01 img:first-child{
    position: absolute;
    top: 22px;
    left:0;
}
.main01 div:nth-child(2){
    position: absolute;
    top:35px;
    left: 182px;
    height: 35px;
    border: 0.5px solid #CCDCF9;
}
.main01 span:nth-child(3){
    position: absolute;
    top:35px;
    left:202px;
    font-size: 20px;
    color: #666
}
.section{
    background-color: #F5F5F5;
    width: 100%;
    min-width: 1200px;
    height: 570px;
    position: relative;
}
.section>div{
    width: 100%;
    min-width: 1200px;
    height: 570px;
    background-image: url("../assets/img/login/login02.png");
    background-position: center center;
}
.section>div>div{
    width: 1200px;
    margin: 0 auto;
    position: relative;
}
.section>div>div>div{
    position: absolute;
    top: 80px;
    right: 0;
    width:350px;height:412px;
    background-color: #fff;
}
.section>div>div>div ul li a{
    text-decoration: none;
    color: #3296fa;
}
.login-box-title{
    font-size: 16px;
    font-weight: bold;
    top: 30px;
    left: 143px;
    color: #333;
    text-align: center;
    margin-top: 25px;
}
.login-box-item{
    margin-top:17px;
}
.login-box-item div{
    text-align:center;
}
.item-account input{
    width: 100%;
    height: 40px;
    border: 1px solid #ccc;
    background: url("../assets/img/login/yhiocn.png") no-repeat left center;
    background-position: 10px;
    padding-left: 39px;
    box-sizing: border-box;
}
.item-password input{
    width: 312px;
    height: 40px;
    border: 1px solid #ccc;
    background: url("../assets/img/login/mmicon.png") no-repeat left center;
    background-position: 10px;
    padding-left: 39px;
    box-sizing: border-box;
}
.fl-le{
    float:left;
}
.fl-ri{
    float:right;
}
.login-box-item p{
    color:red;
    font-size: 12px;
    height: 18px;
}
.form-item{
    padding:0 20px;
}
.btn-wrap{
    margin-top:10px;
}
.form-wrap{
    padding:0 20px;
}
.form-bottom{
    font-size:14px;
    color: #666;
    text-align: center;
    margin-top:96px;
}
.auto-login *{
    vertical-align:bottom;
}
.auto-wrap{
    clear:both;
    overflow:hidden;
    margin-top:10px;
}
.auto-wrap li{
    font-size:14px;
}

</style>
