<template>
    <div>
        <IndexHeader_home :textMeg="textMeg"/>
        <div class="online">
            <div class="set">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>买家入门</el-breadcrumb-item>
                </el-breadcrumb>
                <div class="sett">
                    <div>买家入门</div>
                    <div class="content-body">
                        <h3>用户注册</h3>
                        <p>第一步、在平台首页上方点击“免费注册”；</p>
                        <p><img src="../../../assets/img/index1/reg.png" alt=""></p>
                        <p>第二步、选择用户类型为个人注册或机构注册，填写相关资料，点击注册。完成。此时用户不仅可以浏览平台信息，搜索资源，还可以完成收藏页面、联系客服等操作。</p>
                        <p><img src="../../../assets/img/index1/reg01.png" alt=""></p>
                        <h3>用户认证</h3>
                        <p>完成注册后，可进入个人页面。用户可完善资料，完成认证。认证后的用户可在平台与专家交流、购买资源或服务等。</p>
                        <p><img src="../../../assets/img/index1/base.png" alt=""></p>
                        <h3>资源搜索</h3>
                        <p>方法一、用户可在科技资源服务列表中检索资源。</p>
                        <p><img src="../../../assets/img/index1/search 1.png" alt=""></p>
                        <p>方法二、用户可直接点击“产品认证”或“体系认证”完成咨询或检测服务。</p>
                        <p><img src="../../../assets/img/index1/search3.png" alt=""></p>
                        <p>方法三、用户可在搜索框里输入关键词搜索相关资源或服务。</p>
                        <p><img src="../../../assets/img/index1/search2.png" alt=""></p>
                    </div>
                </div>
            </div> 
        </div>
        <IndexFooter_home />
    </div>
</template>

<script>
import IndexHeader_home from '@/components/IndexHeader_home.vue'
import IndexFooter_home from '@/components/IndexFooter_home.vue'
export default {
    data(){
        return{
            textMeg:'买家入门'
        }
    },
    components: {
        IndexHeader_home,IndexFooter_home
    },
}
</script>

<style scoped>
    p{
        margin: 14px 0;
    }
    .online{
        background: #f5f5f5;min-width:1200px;padding-bottom: 20px 
    }
    .set{
        width: 1200px;margin: 0 auto;padding-top: 20px
    }
    .set .sett{
        margin-top: 10px;
        min-height: 500px;background: #fff;
        padding: 30px 40px 60px; 
        
    }
    .set .sett>div:first-child{
        font-size: 28px;border-bottom: 1px solid #eee;
        padding-bottom: 20px;text-align: center;margin-bottom: 25px
    }
    .content-body p{
        text-indent: 28px;
        font-size: 14px;
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    }
    h3{
        display: block;
        font-size: 16px;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        color: #333
    }
    .content-body p span{
        font-size: 20px;font-weight: bold;color: #333
    }
    table{
        text-align: center;font-size: 14px
    }
    img{
        display: block;
        max-width: 100%;
        margin: 0 auto;
    }
</style>
