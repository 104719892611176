<template>
    <div class="index">
        <div class="main01">
            <router-link to="/"><img src="../assets/img/index/logo.png" alt=""></router-link>
            <div></div>
            <div class="shop">
                <p>
                    <span>{{shopName}}</span>
                    <!-- <span @click="icon" v-if="show"><i class="el-icon-star-off"></i>收藏店铺</span>
                    <span @click="icon" v-if="!show"><i class="el-icon-star-on"></i>取消收藏</span> -->
                </p>
                <p>[&nbsp;&nbsp;速度&nbsp;&nbsp;<span>4.99</span>&nbsp;&nbsp;|&nbsp;&nbsp;态度&nbsp;&nbsp;<span>4.99</span>&nbsp;&nbsp;|&nbsp;&nbsp;服务<span>{{serviceNum}}</span>次&nbsp;&nbsp;]</p>
            </div>
            <!-- <div>
                <img src="../assets/img/index/search.png" alt="">
                <input type="text" placeholder="请输入关键字搜索" >
                <button @click="goSearch">搜&nbsp;索</button>
                <ul>
                    <li><a href="/search_page" target="_blank">ISO9001</a></li>
                    <li><a href="/search_page" target="_blank">ISO14001</a></li>
                    <li><a href="/search_page" target="_blank">ISO45001</a></li>
                    <li><a href="/search_page" target="_blank">IATF16949</a></li>
                </ul>
            </div>
            <span class="huo">或</span>
            <button @click="goIssue">发布认证需求</button> -->
            <Search />
        </div>
    </div>
</template>


<script>
import Search from '@/components/Search.vue'
export default {
    props:["shopName","serviceNum"],
    data(){
        return{
            show:true
        }
    },
    methods:{
        icon(){
           this.show=!this.show
        }
    },
    components: {
        Search
    },
}
</script>


<style scoped>
    .huo{
        position:absolute;
        top:40px;font-size: 12px;
        right:132px;color: #666
    }
    .shop{
        position: absolute;
        top:30px;left: 200px;
    }
    .shop>p{
        font-size: 12px;color: #666;
        line-height: 22px
    }
    .shop>p:first-child>span:first-child{
        font-size: 16px;font-weight: bold;color: #333;
        margin-right: 20px;
    }
    .shop>p:nth-child(2)>span{
        color: red;
    }
    i{
        color: red;cursor: pointer;
        font-size: 14px;margin-right: 2px;
        vertical-align: inherit
    }
</style>

