<template>
    <div class="inline-block" :class="{'widthAll':widthAll}">
        <el-select :filterable="filterable" :class="{'widthAll':widthAll}" :loading="loading" v-model="value" :disabled='disabled' :placeholder="placeholder" @change="changeSelect" @visible-change="focusSelect">
            <el-option
                v-for="(item,index) in thislist"
                :key="index"
                :label="item.name"
                :value="item.id">
            </el-option>
        </el-select>
    </div>
</template>

<script>
export default {
    props: {
        list:Array,
        disabled:Boolean,
        placeholder:String,
        widthAll:Boolean,
        filterable:Boolean
    },
    model: {
        prop: 'value',
        event: 'change',
    },
    data(){
        return {
            loading:false,
            thislist:[],
            value:"",
        }
    },
    watch: {
        list:{
            handler(val,val2){
                if (val.length===0) {
                    this.value = ""
                }else if(val2.length===0&&this.thislist.length===0){
                    this.thislist = val;
                    this.value = val[0].id;
                };
            },
        },
    },
    computed: {

    },
    components: {

    },
    methods:{
        focusSelect:function(val){
            if (val) {
                this.loading = true;
                this.$emit('on-focus',(lis) => {
                    this.loading = false;
                    this.thislist = lis;
                })
            };
        },
        changeSelect:function(vId){
            var obj = {};
            obj = this.thislist.find((item) => {
                return item.id === vId;
            });
            this.$emit('on-change', obj);
            this.$emit('change', obj.id);
        },
    },
    mounted:function(){

    },
}
</script>

<style scoped>
.inline-block{
    display:inline-block;
    vertical-align: middle;
}
.widthAll{
    width:100%;
}
</style>
