<template>
    <div class="no2" v-loading="loading">
        <div class="xsp">
            <p><span>认证项目：</span><span class="span">{{data.categoryIdName}}</span></p>
            <p><span>服务类型：</span><span>{{data.selfHelpTypeName}}</span></p>
            <p><span>服务标题：</span><span>{{data.serviceName}}</span></p>
            <p><span>联 系 人：</span><span>{{data.contact}}</span></p>
            <p><span>联系电话：</span><span>{{data.phone}}</span></p>
            <p><span>认证说明：</span><span class="span">{{data.serviceExplain}}</span></p>
            <div class="sim">
                <div>
                    <p><img :src="img6" alt="组织结构图"></p>
                    <span>组织结构图</span>
                </div>
                <div>
                    <p><img :src="img7" alt="服务实现流程图"></p>
                    <span>服务实现流程图</span>
                </div>
                <div>
                    <p>
                        <img v-for="(img,index) in img8" :key=index :src="img" alt="计量器具校准证书或检定证书">
                    </p>
                    <span>计量器具校准证书或检定证书</span>
                </div>
                
            </div>
            <div class="simL"></div>
            <p><span>是否指定认证机构：</span><span v-if="data.certificationBodyName">{{data.certificationBodyName}}</span><span v-else>否</span></p>
        </div>
        <xtable index :search="search" :head="head" :tableData="tableData" btn="download" @on-downloadClick="downloadClick" @on-changePage="(v)=>getData(v)" @on-handleSelectionChange="(v)=>checkboxList=v" />
    </div>
</template>

<script>
import xtable from '@/components/UI/xtable.vue'
import xbutton from '@/components/UI/xbutton.vue'
import xstep from '@/components/UI/xsteps.vue'
export default {
    data(){
        return{
            loading:false,
            tableData:[],
            head:{
                resourceTypeName:"文件类型",
                fileList:"部门制度",
            },
            data:{},
            title:"",
            img6:"",
            img7:"",
            img8:[],
        }
    },
    methods:{
        getData(){
            var autoServiceId = this.$route.query.autoServiceId;
            this.$com.majax.get(this.$com.testChIp + '/auto-service/getAutoServiceById/' + autoServiceId,{},{
                success:(res)=>{
                    if(res.code == 0){
                        this.loading=false;
                        var item = res.data
                        this.data = item;
                        //this.tableData.records = res.data.fileData;
                        if(item.status == "1"){
                            this.title = "资料填写中"
                        }else if(item.status == "2"){
                            this.title = "资料填写完成"
                        }else if(item.status == "10"){
                            this.title = "待平台审核"
                        }else if(item.status == "11"){
                            this.title = "平台审核中"
                        }else if(item.status == "12"){
                            this.title = "平台审核通过"
                        }else if(item.status == "20"){
                            this.title = "认证进行中"
                        }else{
                            this.title = "认证完成"
                        };
                        for(var i=0;i<item.otherFile.length;i++){
                            if(item.otherFile[i].resourceType == "6"){
                                this.img6 = item.otherFile[i].url
                            }
                            if(item.otherFile[i].resourceType == "7"){
                                this.img7 = item.otherFile[i].url
                            }
                            if(item.otherFile[i].resourceType == "8"){
                                this.img8.push(item.otherFile[i].url);
                            }
                        }
                    }
                    this.$parent.titleMeg = '自助认证（'+this.title+')';
                }
            })
        },
    },
    components: {
        xbutton,
        xstep,
        xtable
    },
    mounted:function(){
        this.getData()
        this.$parent.rightLink = true;
        this.$router.beforeEach((to, from ,next) => {
            this.$parent.rightLink = false;
            next();
        });
    },
}
</script>

<style scoped>
.no2{
    width:992px;
    background:#fff;
    padding: 25px 20px 15px 20px;
}
.bottom{
    clear:both;
    overflow:hidden;
    margin-top:20px;
}
.bottom button{
    font-size:14px;
    height:34px;
}
.btn-back{
    width:80px;
    float:left;
}
.btn-next{
    width:170px;
    float:right;
}
.xsp>p{
    font-size: 14px;
    color: #333;
    margin-bottom: 10px;
}
.xsp>p>span:first-child{
    display: inline-block;
    width: 137px;
    margin-right: 6.5px;
    text-align: right;
    color: #666;
}
.sim{
    margin: 0 0 10px 144px;
    font-size: 14px;
    overflow: hidden;
}
.sim>div{
    float: left;
    margin-right: 40px;
}
.sim>div img{
    border: 1px solid #ccc;
    width: 120px;
}
.simL{
    clear: both;
}
.span{
    display: inline-flex;
    width: 805px;
}
</style>

