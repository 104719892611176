<template>
    <div class="section-footer">
        <div class=" d-flex justify-content-between">
            <div style="font-size:30px; line-height: 60px">
                服务店铺
            </div>
            <ul>
                <li v-for="(item,index) in serveList" :key="index">
                    <router-link :to="{path:'/shop_list',query:{type:item.id}}" target="_blank">{{item.name}}</router-link>
                </li>
            </ul>
        </div>
        <div class="d-flex">
            <div v-for="(list,i) in lists" :key="i" class="shop">
                <div class="noi2">
                    <img :src="list.logoUrl||$com.errorImg" alt="店铺LOGO" :onerror="$com.errorImg">
                </div>
                <ul>
                    <li :title="list.shopName">{{list.shopName}}</li>
                    <li>服务类型:</li>
                    <li><span v-for="(lis,i) in list.shopCategory" :key="i" :title="lis">{{lis}} &nbsp;</span></li>
                    <li>
                        <span><img class="areaName-i" src="../../../assets/img/index/wz.jpg" alt=""> {{list.areaName}}</span>
                        <span>已服务 <span style="color:red">{{list.serviceNum}}</span> 次</span>
                    </li>
                    <li><router-link :to="{ path:'/shop_management/shop_man_index',query:{ ShopId : list.shopId }}" target="_blank">进入店铺</router-link></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            lists:[],
            serveList:[{name:"咨询师店铺",id:1},{name:"咨询机构店铺",id:2},{name:"检测机构店铺",id:3},{name:"认证机构店铺",id:4},{name:"更多+",id:""}]
        }
    },
    mounted:function(){
        this.GetData();
    },
    components: {

    },
    methods: {
        GetData:function(){
            this.$axios.get(this.$com.testCsIp + '/shop/getShopList/1/4')
            .then(res=>{
                this.lists=res.data.data.records;
            })
        },
        more:function(){

        }
    }
}
</script>

<style scoped>
    .noi2{
        width: 274px;
        height: 162px;
        margin-bottom: 18px;
        margin-right: 19px;
        border-bottom: 1px solid #ddd;
        position: relative;
    }
    .noi2>img{
        max-height:99%;
        max-width:99%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    .shop{
        margin-right: 20px
    }
    .areaName-i{
        vertical-align:unset;
    }
</style>

