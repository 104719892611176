<template>
    <div class="no2">
        <div>
            <div>
                <div></div>
                商品清单
                <span>订单编号：{{detailData.orderNo}}</span>
            </div>
            <ul>
                <li class="img-wrap">
                    <img :src="detailData.coveImagePath" alt="">
                </li>
                <li >
                    <ul class="item">
                        <li>{{detailData.serviceName}}</li>
                        <li>
                            <a @click="GetDialog">查看委托单</a>
                            <router-link v-if="detailData.status=='5'||detailData.status=='6'||detailData.status=='7'" :to="{ name:'order_download',query:{ id : id}}">下载服务凭证</router-link>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
        <div>
            <div><div></div>付款信息</div>
            <div class="item1">
                <ul>
                    <li>交易金额：</li>
                    <li class="money">￥{{detailData.consultPrice}}</li>
                </ul>
                <ul>
                    <li>支付方式：</li>
                    <li>{{detailData.payName}}</li>
                </ul>
                <ul>
                    <li>付款时间：</li>
                    <li>{{detailData.payTime}}</li>
                </ul>
                <!-- <ul>
                    <li>交易金额：</li>
                    <li>11365564646</li>
                </ul> -->
            </div>
        </div>
        <div>
            <div><div></div>收货人信息</div>
            <div class="item1">
                <ul>
                    <li>收货人姓名：</li>
                    <li>{{detailData.receive}}</li>
                </ul>
                <ul>
                    <li>联系电话：</li>
                    <li>{{detailData.phone}}</li>
                </ul>
                <ul>
                    <li>收货人地址：</li>
                    <li>{{detailData.address}}</li>
                </ul>
            </div>
        </div>
        <div>
            <div><div></div>发票信息</div>
            <div class="item1">
                <ul class="width50">
                    <li>是否要发票：</li>
                    <li>{{detailData.isInv===1?'是':'否'}}</li>
                </ul>
                <ul class="width50" v-if="detailData.isInv===1">
                    <li>发票类型：</li>
                    <li>{{detailData.invoiceType=='1'?'增值税发票':'普通发票'}}</li>
                </ul>
                <ul class="width50" v-if="detailData.isInv===1">
                    <li>发票抬头：</li>
                    <li>{{detailData.invoiceTitle=='1'?'单位':'个人'}}</li>
                </ul>
                <ul class="width50" v-if="detailData.isInv===1">
                    <li>发票内容：</li>
                    <li>{{detailData.invoiceContent}}</li>
                </ul>
                <ul class="width50" v-if="detailData.isInv===1">
                    <li>单位名称：</li>
                    <li>{{detailData.orgName}}</li>
                </ul>
                <ul class="width50" v-if="detailData.isInv===1">
                    <li>发票税号：</li>
                    <li>{{detailData.invoiceTfn}}</li>
                </ul>
            </div>
        </div>
        <diyDialog status="2" type="seller" disabled title="委托单" :formData="formData" :snycShow.sync="dialogShow" @on-click="ClickSubmit"/>
    </div>
</template>

<script>
import diyDialog from '@/components/UI/diyDialog.vue'
export default {
    mounted(){
        this.$parent.titleMeg = '订单详情';
        this.GetData();
    },
    data(){
        return {
            id:"",
            detailData:{},
            dialogShow:false,
            formData:{
                consultPrice:{
                    value:""
                },
                consultContent:{
                    value:""
                },
                signature:{
                    value:""
                }
            }
        }
    },
    components: {
        diyDialog
    },
    methods:{
        GetData:function(){
            var id = this.$route.query.id;
            this.id = id;
            if (id) {
                this.$com.majax.get(this.$com.testCsIp+'/order/getOrderDetailsById/' + id,{},{
                    success:(res)=>{
                        this.detailData = res.data;
                    }
                },this)
            };
        },
        GetDialog:function(){
            this.$com.majax.get(this.$com.testCsIp+'/uc-entrust-order/getEntrustOrderDetailsById/' + this.detailData.entrustId,{},{
                success:(res)=>{
                    for(var key in res.data){
                        if (key==="consultPrice"||key==="consultContent"||key==="signature") {
                            this.formData[key].value = res.data[key];
                        }else{
                            this.formData[key] = res.data[key]
                        };
                    }
                    this.dialogShow = true;
                }
            },this)
        }
    },
}
</script>


<style scoped>
        div.no2{
            width:992px;
            background:#fff;
            position: relative;
            padding-bottom:15px;
        }
        div.no2>div{
            padding-left:42px;padding-right: 57px;
            box-sizing: border-box ;font-size: 14px;
        }
        div.no2>div>div:first-child{
            padding-top: 20px;
            display: flex;line-height: 30px;
            border-bottom: 2px dotted #ccc;
            font-weight: bold;margin-bottom: 10px;
            position: relative;
        }
        div.no2>div>div:first-child>div{
            width: 3px;height:14px ;
            background: #ff4a03;margin-top: 8px;
            margin-right: 9px
        }
        div.no2>div>div:first-child span{
            position: absolute;right: 0;
            color: #ff4a03
        }
        div.no2>div>ul{
            display: flex;
            margin-top:20px ;margin-left: 20px
        }
        div.no2>div>ul>li:first-child{
            border: 1px solid #ccc;
            width: 78px;height: 78px;
            padding: 5px;box-sizing: border-box;
            display: flex;justify-content: center;
            align-items: center;margin-right: 10px;

        }
        div.no2 .item li:first-child{
            font-size: 20px;line-height: 30px;
            margin-bottom: 14px
        }
        div.no2 .item a{
            text-align: center;line-height: 34px;
            display: block;width: 120px;height: 34px;
            background: #3296FA;color: #fff;
            float: left;margin-right: 20px;
            text-decoration: none;
            cursor:pointer;
        }
        div.no2 .item a:last-child{
            background: #fff;color: #3296FA;
            border: 1px solid #3296FA;
        }
        div.no2 .item a:hover{
            color: #fff;
            background: linear-gradient(to right, #00aaff, #0085ff);
        }
        div.no2 .item1 ul{
            line-height: 30px;
            overflow:hidden;
        }
        div.no2 .item1 ul li{
            float:left;
        }
        div.no2 .item1 ul li:first-child{
            width: 100px;text-align: right;
            font-size: 14px;color: #666;
        }
        div.no2 .item1 .money{
            color: #ff4a03;font-weight: bold
        }
        div.no2 div:last-child .item1{
            display: flex;flex-wrap: wrap
        }
        div.no2 div:last-child .item1 li:last-child{
            width: 345px
        }
        .item1{
            clear:both;
            overflow:hidden;
        }
        .img-wrap img{
            width:100%;
            height:100%;
        }
        .width50{
            width:50%;
            float:left;
        }
</style>
