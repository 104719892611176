<template>
    <div class="cph1">
        <div class="no2">
            <div class="cph1">
                <span>您已绑定的手机号码是 ：{{phone}}</span>
                <router-link to="/users/account/account_bdph/account_bdph_Cph2">修改手机号码</router-link>
            </div>
            <p> 您可享有以下服务：</p>
            <ul>
                <li>手机号码登录：</li>
                <li>可直接“手机号码”登录到认证平台</li>
            </ul>
            <ul>
                <li>重要事件提醒：</li>
                <li>进行（支付/提现/选稿/中标）时，可及时收到短信提醒</li>
            </ul>
            <ul>
                <li>手机找回密码：</li>
                <li>通过手机短信快速找回登录密码等操作</li>
            </ul>
            <ul>
                <li>账号保护：</li>
                <li>在您进行登录及修改密码等敏感操作是，未经您授权的操作将不被允许</li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        phone:String
    },
    data(){
        return {

        }
    },
    components: {

    },
    watch:{

    },
    methods:{
        reload:function(){
            this.$com.majax.get(this.$com.testUsIp + '/security/getOldPhone',{},{
                success:(res)=>{
                    this.phone = res.data
                },
                error:()=>{}
            })
        }
    },
    mounted:function(){
        this.$parent.$parent.titleMeg = '手机绑定';
        if (this.$route.query.reload) {
            this.reload()
        };
    },
}
</script>
<style>

body{
    margin: 0;
    padding: 0;
}
ul,p{
    list-style: none;
    margin: 0;
    padding: 0;
}
.cph1>div.no1{
    width:992px;height:39px;background:#fff;
    margin-bottom: 2px;
}
.cph1>div.no1>span{
        display: block;
    font-size: 16px;
    line-height: 37px;
    text-align: center;
    width: 120px;
    border-bottom: 2px solid #FF4A03;
}
.cph1>div.no2{
    width:992px;height:580px;
    background:#fff;
    position: relative;
    padding:20px;
    box-sizing: border-box;
}
.cph1>div.no2>.cph1{
    padding: 0 20px ;
    box-sizing: border-box;
    background: #f5f5f5;
    width: 100%;
    height: 60px;
    line-height: 60px;
    font-size: 16px;
    display: flex;
}
div.no2>.cph1 span{
    width: 350px
}
.cph1>div.no2>.cph1>a{
    margin-top: 15px;
    line-height: 30px;
    display: block;
    width: 110px;
    height: 30px;
    background: #3296FA;
    border-radius: 4px;
    color: #fff;
    text-align: center;
    font-size: 14px;
    text-decoration: none;
    margin-left: 15px
}
.cph1>div.no2>.cph1>a:hover{
    background: linear-gradient(to right, #00aaff, #0085ff);
}
.cph1>div.no2>p{
    margin-top: 30px;
    margin-left: 20px;
    color: #333;
    font-size: 15px;
}
.cph1>div.no2>ul{
    display: flex;
    font-size: 12px;
    line-height: 20px;
}
.cph1>div.no2>ul>li:nth-child(1){
    width: 105px;
    text-align: right;
    color: #666;
}
.cph1>div.no2>ul>li:nth-child(2){
    color: #999;
}
</style>
