<template>
    <div class="header">
        <div>
            <ul class="header-left">
                <li>您好，欢迎来到佰证通认证咨询服务平台！</li>
            </ul>
            <ul class="header-nav">
                <li>
                    <router-link v-if="!$store.state.tokenId" to="/login" style="color:#2e89e4;">请登录</router-link>
                    <router-link v-else to="/users" style="color:#FD5541">您好，{{$store.state.user.nickName}}</router-link>
                </li>
                <li :class="{'logout':$store.state.tokenId}">
                    <router-link v-if="!$store.state.tokenId" to="/register">免费注册</router-link>
                    <span v-else @click="logout">退出</span>
                </li>

                <li>  <!-- v-if="userData.userType=='0'" -->
                    <router-link v-if="!$store.state.tokenId" to="/buyer/home">我是雇主</router-link>
                    <router-link v-else to="/buyer/home">买家中心</router-link>
                </li>

                <li>  <!-- v-if="userData.userType!='0'" -->
                    <div v-if="!shopType"><a @click="goshop">开店入驻</a></div>
                    <div v-else><router-link to="/seller/home">卖家中心</router-link></div>
                </li>

                <li><router-link to="/shopping_cart">购物车</router-link></li>
                <li>
                    <el-dropdown>
                        <span class="el-dropdown-link">
                        平台客服<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item><router-link to="/404" target="_blank" class="dropdown-item">雇主帮助中心</router-link></el-dropdown-item>
                            <el-dropdown-item><router-link to="/404" target="_blank" class="dropdown-item">服务商帮助中心</router-link></el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </li>
                <li><router-link to="/contact_us" target="_blank">联系我们</router-link></li>
                <li>
                    <el-dropdown>
                        <span class="el-dropdown-link">
                        平台APP<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                                <div class="app-wrap">
                                    <div class="appimg-wrap">
                                        <!-- <img src="../assets/img/timg.jpg" alt="app" /> -->
                                    </div>
                                    <span>APP下载</span>
                                </div>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    components: {

    },
    data(){
        return {
            shopType:false,
        }
    },
    computed:{
        userType(){
            return this.$store.state.userType;
        },
        userData(){
            return this.$store.state.user||{};
        }
    },
    mounted:function(){
        if (this.userType) {
            if (this.userType.shopStatus===0) {
                this.shopType = true
            };
        };
    },
    watch:{
        userType:{
            deep: true,
            handler:function(val){
                if (val.shopStatus===0) {
                    this.shopType = true
                };
            }
        }
    },
    methods:{
        logout:function(){
            this.$dialog.confirm("是否确认退出登录",'error',()=>{
                this.$com.majax.post(this.$com.testUwIp + '/logout', {},{
                    success:(res)=>{
                        this.$store.commit('REMOVE_token');
                        this.$router.push({ path: '/' })
                    }
                })
            })
        },
        goshop:function(){
            if (!this.$store.state.tokenId) {
                this.$dialog.alert('请先登录','confirm',()=>{
                    this.$router.push({ path:'/login' })
                })
                return false
            };
            if(this.userType.personlStatus===0||this.userType.orgStatus===0){
                if (this.userType.shopStatus) {
                    this.$router.push({ name: 'shop_detailed' })
                }else{
                    this.$router.push({ name: 'shop_base' })
                };
            }else{
                this.$dialog.alert("请先进行实名认证",'confirm',()=>{
                    if (this.userData.userType===1) {
                        this.$router.push({ name:'base_auth_per' });
                    }else{
                        this.$router.push({ name:'base_auth_bus' });
                    };
                })
            }
        }
    },

}
</script>

<style scoped>
    ul{
        list-style: none;
        margin: 0;
        padding: 0;
    }
    .header{
        width: 100%;
        background-color: #E6E6E6;
        min-width: 1200px;
    }
    .header>div{
        width: 1200px;height: 30px;
        margin: 0 auto;
    }
    .header-nav li,.header-nav a{
        line-height: 30px;
        color: #737373;
        font-size: 12px;
        cursor:pointer;
        display:block;
    }
    .header ul li a:hover{
        text-decoration: none;
        color: #2e89e4;
    }
    .header-nav{
        float:right;
    }
    .header-nav li,.header-left{
        float:left;font-size: 12px;color: #737373;
        line-height: 30px
    }
    .header-nav li{
        margin-left:20px;
    }
    .header-nav .logout{
        margin-left:4px;
    }
    .logout:hover{
        color:rgb(253, 85, 65);
    }
    .el-dropdown-link{
        font-size:12px;
        color:#737373;
    }
    .dropdown-item{
        font-size:14px;
        padding:0;
        color:#737373;
    }
    .app-wrap{
        padding:10px 0 0 0;
    }
    .appimg-wrap{
        width:60px;
        height:60px;
        border:1px solid #737373;
    }
    .appimg-wrap img{
        width:100%;
        height:100%;
        display:block;
    }
</style>
