<template>
    <div class="deatails">
        <div class="no">
            <p>{{ user.title }}</p>
            <div>
                <!-- <p><span>发布者 ：</span><span>重庆网络科技有限公司</span></p> -->
                <p><span>联系人 ：</span><span>{{ user.contact }}</span></p>
                <p><span>联系电话 ：</span><span>{{ user.phone }}</span></p>
                <p><span>所在地区 ：</span><span>{{ user.area }}</span></p>
                <p><span>认证项目 ：</span><span>{{ user.category }}</span></p>
                <p><span>服务类型 ：</span><span>{{ user.serviceTypeName }}</span></p>
                <p><span>所属行业 ：</span><span>{{ user.industryName }}</span></p>
                <p><span>企业规模 ：</span><span>{{ user.scaleName }}</span></p>
                <p><span>预算费用 ：</span><span class="from">{{ user.cost }}元</span></p>
                <p><span>发布时间 ：</span><span>{{ user.createTime }}</span></p>
                <p><span>截止时间 ：</span><span>{{ user.deadline }}</span></p>
                <p><span>需求描述 ：</span><span>{{ user.description }}</span></p>
            </div>

            <el-button type="text" @click="GoJoin">我要参与</el-button>
            <el-dialog
            title="我要竞标"
            :visible.sync="dialogVisible"
            width="550px">
            <div class="hang">
                <ul>
                    <formItem maxlength="20" class="width86" itemStyle="inline" title="需求标题" type="readonly" :value="user.title" />
                </ul>
                <ul>
                    <formItem class="width86" itemStyle="inline" zIndex="15" errorStyle="inline" title="服务名称" required :error="submit.serviceId.error" v-model="submit.serviceId.value" type="select" @on-focus="(fn)=>fn(serviceList)" placeholder="请选择服务名称" />
                </ul>
                <ul>
                    <formItem maxlength="200" class="width86" itemStyle="inline" title="卖家留言" type="textarea" v-model="submit.remark.value" placeholder="请输入留言（选填）" />
                </ul>
                <ul>
                    <li></li>
                    <li><xbutton @on-click="goSubmit" value="提交竞标" /></li>
                </ul>
            </div>

            </el-dialog>
        </div>
    </div>
</template>


<script>
import formItem from '@/components/UI/formItem.vue'
import xbutton from '@/components/UI/xbutton.vue'
export default {
    data() {
        return {
            dialogVisible: false,
            user:{},
            serviceList:[],
            demandId:"",
            serviceItem:{},
            submit:{
                demandId:{
                    value:""
                },
                serviceId:{
                    value:"",
                    error:"",
                    validate:['required'],
                    name:"服务名称"
                },
                remark:{
                    value:"",
                }
            }
        };
    },
    methods: {
        GoJoin:function(){
           // var token = sessionStorage.getItem('tokenId'),
            var token =JSON.parse(sessionStorage.getItem('user')).tokenId,
                userType = this.$store.state.userType;
            if (!token) {
                this.$dialog.alert('请先登录','confirm');
            }else if(userType.orgStatus!=0&&userType.personlStatus!=0){
                this.$dialog.alert('请先进行实名认证','confirm');
            }else if(userType.shopStatus!=0){
                this.$dialog.alert('请先申请店铺','confirm');
            }else if(this.serviceList.length===0){
                this.$dialog.alert('请先添加服务','confirm');
            }else{
                this.dialogVisible = true;
            }
        },
        GetData:function(id){
            var id = this.$route.query.id;
            if (id) {
                this.submit.demandId.value = id;
                this.$axios.get(this.$com.testCsIp + '/demand/selectDemand', {params:{demandId:id,demandInlet:1}})
                .then((response) => {
                    var res = response.data;
                    if (res.code === 0) {
                        this.user = res.data
                    }else{
                        this.$dialog.alert(res.msg,'error')
                    };
                })
                .catch((error) => {
                    this.$dialog.alert('系统繁忙，请稍后再试','error')
                })
            };
        },
        goSubmit:function(){
            var dataV = {
                serviceId:this.submit.serviceId,
            }
            var validate = this.$com.validate(dataV)
            if(validate){
                var data = {}
                for(var key in this.submit){
                    data[key] = this.submit[key].value;
                }
                data.serviceName = this.$com.findName(data.serviceId,this.serviceList);
                this.$com.majax.post(this.$com.testCsIp+'/buyerBid/joinBid',this.$qs.stringify(data),{
                    success:(res)=>{
                        this.$dialog.alert('竞标成功！','success',()=>{
                            this.$router.push({name:'demand_hall'})
                        },(_res)=>{
                            _res.thisShow = false;
                            this.$router.push({name:'demand_hall'})
                        })
                    },error:(res)=>{
                        this.$message.error(res.msg);
                    }
                })
            }
        },
        GetServiceList:function(){
            this.$com.majax.post(this.$com.testCsIp+'/buyerBid/joinBidService','',{
                success:(res)=>{
                    var data = res.data;
                    for (var i = 0; i < data.length; i++) {
                        this.serviceList[i] = {id:data[i].id,name:data[i].serviceName};
                    };
                },
                error:()=>{
                    this.serviceList = []
                }
            })
        }
    },
    components: {
        formItem,
        xbutton
    },
    mounted(){
        this.GetData();
        this.GetServiceList();
    }
};
</script>

<style scoped>
    .deatails{
        padding: 20px 0;
    }
    .no{
        width: 1200px;margin: 0 auto;
        background: #fff;
        padding: 20px;text-align: center
    }
    .no>p{
        height: 60px;line-height: 60px;
        background: #EAF4FE;padding-left: 20px;
        font-size: 26px;color: #ff4a03;
        margin-bottom: 20px;text-align: left
    }
    .no>div>p{
        font-size: 14px;padding-left: 20px;
        height: 34px;
        clear:both;
        overflow:hidden;
    }
    .no>div>p span{
        display: block;float: left;
    }
    .no>div>p>span:first-child{
        width: 75px;text-align:right;
        font-weight: bold;margin-right: 10px;
    }
    .no button{
        width: 180px;height: 40px;
        background: #3296FA;color: #fff;font-size: 18px;
        border: 0;margin-top: 20px
    }
    .no button:hover{
        cursor: pointer;
    }
    .no .from{
        color: #ff4a03;font-weight: bold
    }
    .hang>ul{
        display: flex;margin-left: 30px;
        margin-bottom: 20px;line-height: 34px
    }
    ul>li:first-child{
        width: 100px;text-align: right
    }
    input{
        width: 290px;height: 34px;border: 1px solid #ccc;
        padding-left: 10px;font-size: 14px;color: #666
    }
    textarea{
        width: 290px;height: 150px;border: 1px solid #ccc;
        font-size: 14px;padding: 10px;color: #666
    }
    .hang button{
        margin: 0;
    }
    .width86{
        width:86%;
    }
</style>

