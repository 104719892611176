<template>
    <div>
        <IndexHeader_home :textMeg="textMeg"/>
        <div class="content">
            <div>
                <img src="../assets/img/exc.jpg" alt="">
                <router-link to="/">回到首页</router-link>
                <router-view></router-view>
                <div>敬请期待...</div>
            </div>
        </div>
        <IndexFooter_home />
    </div>
</template>

<script>
import IndexHeader_home from '@/components/IndexHeader_home.vue'
import IndexFooter_home from '@/components/IndexFooter_home.vue'

export default {
    data(){
        return{
            textMeg:'开发中',
        }
    },
    components: {
        IndexHeader_home,IndexFooter_home
    },
}
</script>

<style scoped>
    .content{
        background: #f5f5f5;
        padding: 20px 0;
    }
    .content>div{
        width: 1200px;margin: 0 auto;
        background: #fff;text-align: center;
        position: relative;height: 400px;
    }
    .content>div>div{
        width:300px;height: 50px;background: #fff;
        position: absolute;text-align: left;padding-left: 30px;
        top:150px;left: 507px;font-size: 32px;padding-top: 10px
    }
    a{
        position: absolute;font-size: 18px;
        top: 220px;left: 538px;
    }
    img{
        position: absolute;
        top: 40px;left: 378px;
        width: 37.5%;height: 75%;
    }
    h1{
        text-align: center;
        line-height: 300px;
    }
</style>
