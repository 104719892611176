<template>
    <div class="no2">
        <formItem :disabled="disabled" width="160" :error="user.categoryId.error" errorStyle="inline" title="认证项目" required type="threeSelectCategory" v-model="user.categoryId.value" :defaultList="category" />

        <formItem :disabled="disabled" width="160" maxlength="25" errorStyle="inline" title="证书名称" required :error="user.certificateName.error" type="text" v-model="user.certificateName.value" placeholder="请填写证书名称" />

        <formItem :disabled="disabled" width="160" maxlength="25" errorStyle="inline" title="注册号" required :error="user.license.error" type="text" v-model="user.license.value" placeholder="请填写注册号" />

        <formItem :disabled="disabled" width="160" maxlength="25" errorStyle="inline" title="发证单位" required :error="user.authority.error" type="text" v-model="user.authority.value" placeholder="请填写发证单位" />

        <formItem :disabled="disabled" width="160" errorStyle="inline" title="初次颁证日期" type="date" v-model="user.firstTime.value" placeholder="请选择初次颁证日期" />

        <formItem :disabled="disabled" width="160" errorStyle="inline" title="生效日期" type="date" v-model="user.executeTime.value" placeholder="请选择生效日期" />

        <formItem :disabled="disabled" width="160" errorStyle="inline" title="有效日期" type="date" v-model="user.validTime.value" placeholder="请选择有效日期" />

        <formItem :disabled="disabled" width="160" errorStyle="inline" title="换证日期" type="date" v-model="user.changeTime.value" placeholder="请选择换证日期" />

        <formItem :disabled="disabled" width="160" type="file" :imgObg="imgObg" title="上传认证资料" v-model="user.data.value" />

        <formItem :disabled="disabled" width="160" maxlength="200" title="认证范围" type="textarea" v-model="user.scop.value" placeholder="请填写认证范围" />

        <ul>
            <li></li>
            <li class="form-btn"><xbutton :disabled="disabled" @on-click="GoSubmit" value="提交后台审核"></xbutton></li>
        </ul>
    </div>
</template>

<script>
import formItem from '@/components/UI/formItem.vue'
import xbutton from '@/components/UI/xbutton.vue'
import bgImg from '../../../assets/img/buyer/upload.jpg'
export default {
    mounted(){
        this.$parent.titleMeg = '新增认证';
        this.getData();
    },
    data(){
        return {
            imgObg:{
                width:"140px",
                height:"140px",
                img:bgImg
            },
            disabled:false,
            category:[],
            user:{
                categoryId:{
                    value:"",
                    error:"",
                    validate:['required'],
                    name:"认证项目"
                },
                certificateName:{
                    value:"",
                    error:"",
                    validate:['required'],
                    name:"证书名称"
                },
                license:{
                    value:"",
                    error:"",
                    validate:['required'],
                    name:"注册号"
                },
                authority:{
                    value:"",
                    error:"",
                    validate:['required'],
                    name:"发证单位"
                },
                firstTime:{
                    value:"",
                },
                executeTime:{
                    value:"",
                },
                validTime:{
                    value:"",
                },
                changeTime:{
                    value:"",
                },
                scop:{
                    value:"",
                },
                data:{
                    value:"",
                }
            },
        }
    },
    methods:{
        GoSubmit:function(_this){
            var data = {}
            for(var key in this.user){
                data[key] = this.user[key].value
            }
            var validate = this.$com.validate(this.user)
            if (validate) {
                _this.loading = true;
                if (this.id) {
                    data.id = this.id;
                };
                data.data = this.user.data.value.fileId;
                this.$com.majax.post(this.$com.testCsIp + '/existAuthenticate/saveOrUpdateAuthenticate',this.$qs.stringify(data),{
                    success:(res)=>{
                        this.$dialog.alert("提交成功，请等待审核",'success',"",(_res)=>{
                            _res.thisShow = false;
                            this.$router.push({ path:'/buyer/auth/auth_manage' })
                        })
                    }
                },_this)
            };
        },
        getData:function(){
            var id = this.$route.query.id;
            if (id) {
                this.$parent.titleMeg = '修改认证';
                var data = {id:id};
                this.id = id;
                this.$com.majax.get(this.$com.testCsIp + '/existAuthenticate/selectAuthenticateById',data,{
                    success:(res)=>{
                        this.disabled = (res.data.status===1);
                        for(var key in this.user){
                            this.user[key].value = res.data[key]
                        }
                        this.user.data.value = {imgUrl:res.data.dataPath,fileId:res.data.data}
                        this.category = this.$com.GetObjList(res.data,'category');
                    }
                })
            };
        }
    },
    components: {
        formItem,
        xbutton
    },
}
</script>


<style scoped>
    div.no2{
        width:992px;min-height: 824px;
        background:#fff;
        position: relative;
        padding: 15px 20px;
        box-sizing: border-box
    }
    div.no2 ul{
        display: flex;
        margin-bottom:20px;
    }
    div.no2 ul li:first-child{
        width: 160px;margin-left: 7px;text-align: right;
        font-size: 14px;color: #333
    }
    select::-ms-expand{ display: none; }
    select{
        appearance:none;
        -moz-appearance:none;
        -webkit-appearance:none;
        background: url("../../../assets/img/buyer/kai11.png") no-repeat scroll transparent;
        background-position:95% center;
        padding-left: 10px;
        width: 180px;height: 40px;border: 1px solid #ccc;
        color: #999;margin-right: 10px;
        box-sizing: border-box
    }
    div.no2 ul li:last-child input{
        width: 370px;height: 40px;
        padding-left: 10px;box-sizing: border-box;
        border: 1px solid #ccc;color: #666;
    }
    ::-webkit-input-placeholder { color:#999; }
    ::-moz-placeholder { color:#999; } /* firefox 19+ */
    :-ms-input-placeholder { color:#999; } /* Internet Explorer 10+ */
    :-moz-placeholder { color:#999; }
    div.no2 ul:nth-child(9) input:first-child{
        width: 265px
    }
    div.no2 ul:nth-child(9) input:last-child{
        width: 100px;margin-left: 4px;
        vertical-align: middle;background: #fff;
        color: #666;padding: 0;border-radius: 5px
    }
    div.no2 ul:nth-child(9) input:last-child:hover{
        cursor: pointer;color: #fff;
        background: linear-gradient(to right, #00aaff, #0085ff);
    }
    div.no2 ul:nth-child(3) li:nth-child(2)>div{
        position: relative;
        line-height: 40px;
        float: left;font-size: 14px;color: #666
    }
    div.no2 ul li:nth-child(2){
        position: relative;
    }
    div.no2 ul li:nth-child(2) img{
        position: absolute;
        top: 25px;right: 10px;
    }
    div.no2 ul textarea{
        margin-top: 10px;border: 1px solid #ccc;color: #999;
        width: 370px;height: 140px;padding: 10px 0 0 10px;box-sizing: border-box
    }
    div.no2 ul a{
        display: block;width: 180px;height: 40px;background: #3296FA;color: #fff;
        font-size: 18px;line-height: 40px;text-align: center;text-decoration: none
    }
    div.no2 ul a:hover{
        background: linear-gradient(to right, #00aaff, #0085ff);
    }
    .form-btn{
        width:170px;
    }
</style>
