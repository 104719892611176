<template>
    <div>
        <IndexHeader_home :textMeg="textMeg"/>
        <div class="content">
            <div class="no">
                <div class="no1">
                    <p>所有店铺筛选（<span class="active">共{{listData.total}}个</span>）</p>
                    <p>服务类别：
                        <span :class="{'active':serveType===item.id}" v-for="(item,index) in serveList" :key="index" @click="GetListData(item.id,1)">{{item.name}}{{index===0?"":"店铺"}}</span>
                    </p>
                </div>
                <div class="main-wrap">
                    <div class="no2" v-loading="loading">
                        <div class="no21" v-for="item in listData.records">
                            <div class="img-wrap" @click="GoShop(item)"><img :src="item.logoUrl||$com.errorImg" :onerror="$com.errorImg" :alt="item.shopName"></div>
                            <div>
                                <p><span class="title" @click="GoShop(item)">{{item.shopName}}</span>
                                    <span><i class="el-icon-location-outline"></i>{{item.areaName}}</span>
                                </p>
                                <p>服务类型：</p>
                                <p class="type-main">
                                    <span v-for="items in item.shopCategory">{{items}}&nbsp;&nbsp;&nbsp;</span>
                                </p>
                            </div>
                            <div>
                                <router-link :to="{path:'/shop_management/shop_man_index',query:{ShopId:item.shopId}}" target="_blank">进入店铺</router-link>
                                <p>
                                    <span>已服务<span class="no211">{{item.serviceNum}}</span>次</span>
                                </p>
                            </div>
                        </div>
                        <div class="empty-wrap" v-if="listData.records.length===0">
                            暂无数据
                        </div>
                        <ul class="page" v-if="listData.pages>1">
                            <pagination :total="listData.total" :current="listData.current" @pageChange="pageChange"></pagination>
                        </ul>
                    </div>

                    <div class="no3">
                        <div>
                            <span>推荐店铺</span>
                        </div>
                        <div>
                            <div class="no31" v-for="item in listDataTj" @click="GoShop(item)">
                                <div class="img-wrap">
                                    <img :src="item.logoUrl" :alt="item.shopName">
                                </div>
                                <div>
                                    <p :title="item.shopName">{{item.shopName}}</p>
                                    <p>
                                        <span>
                                        <i class="el-icon-location-outline"></i>{{item.areaName}}</span>
                                        <span class="no311">
                                            已服务<span>{{item.serviceNum}}</span>次
                                        </span>
                                    </p>
                                    <p @click="GoShop(item)">进入店铺</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <IndexFooter_home />
    </div>
</template>

<script>
import IndexHeader_home from '@/components/IndexHeader_home.vue'
import IndexFooter_home from '@/components/IndexFooter_home.vue'
import Pagination from '@/components/UI/Pagination.vue'

export default {
    data(){
        return{
            textMeg:'店铺列表',
            show:true,
            listData:{
                records:[],
                current:1,
            },
            listDataTj:[],
            serveType:"",
            loading:false,
            serveList:[{name:"不限",id:""}]
        }
    },
    methods:{
        GetListData:function(val,current){
            this.loading = true;
            this.serveType = val;
            let obj={}            
           if(current ==1){
            obj.num= current
            current=obj
           }
            this.$axios.get(this.$com.testCsIp + '/shop/getShopList/'+ current.num +'/10',
            {params:{
                type:this.serveType,
            }})
            .then(res=>{
                this.loading = false;
                this.listData = res.data.data;
                //console.log(res)
            })
        },
        GetListDataTj:function(){
            this.$axios.get(this.$com.testCsIp + '/recomCategory/listShop',
            {params:{
                id:"4028800a694697cd0169469a4eff000c",
                recommendType:2,
                num:10
            }})
            .then(res=>{
                this.listDataTj = res.data.data;
            })
        },
        GoShop:function(item){
            this.$router.push({ name: 'shop_man_index',query:{ShopId:item.shopId} })
        },
        pageChange:function(current){
            this.GetListData(this.serveType,current)
        },
    },
    mounted:function(){
        this.serveType = this.$route.query.type||"";
        this.GetListData(this.serveType,1);
        this.GetListDataTj();
        this.$com.findCode('type_yh',(res)=>{
            for (var i = 0; i < res.length; i++) {
                if (res[i].id!="0") {
                    this.serveList.push(res[i])
                };
            };
        })
    },
    components: {
        IndexHeader_home,IndexFooter_home,Pagination
    },
}
</script>

<style scoped>
    .content{
        background: #f5f5f5;
        min-width: 1200px;min-height: 900px
    }
    .no{
        width: 1200px;margin: 0 auto;
        clear:both;
        overflow:hidden;
        margin-bottom:10px;
    }
    .no1{
        width: 1200px;height: 77px;background: #fff;
        padding: 10px 20px;
    }
    .no1>p{
        line-height: 28px;font-size: 12px;color: #333;
    }
    .no1>p:last-child{
        font-weight: bold
    }
    .no1>p:last-child span{
        margin-right: 35px;font-weight: normal;color: #666;
        cursor:pointer;
    }
    .no1>p:last-child span.active,.active{
        color: #ff500b
    }
    .no2{
        width: 950px;min-height: 775px;background: #fff;
        float:left;
        margin-right:10px;
    }
    .no2>div{
        width: 950px;height: 140px;border-bottom: 2px solid #eee;
        padding: 20px;
    }
    .no21>div{
        float: left;
    }
    .no21>div:first-child{
        width: 150px;height: 100px;border: 1px solid #ddd;
        position: relative;
    }
    .no21>div:nth-child(2){
        width: 550px;margin-left: 20px
    }
    .no21>div:nth-child(2)>p{
        font-size: 12px;line-height: 16px;color: #999;
        letter-spacing:0.5px;
    }
    .no21>div:nth-child(2)>p:first-child{
        font-size: 16px;color: #333;font-weight: bold;
        line-height: 30px
    }
    .no21>div:nth-child(2)>p:first-child .title{
        font-size: 16px;color: #333;font-weight: bold;
        line-height: 30px;
        margin-left:0;
        cursor:pointer;
    }
    .no21>div:nth-child(2)>p:first-child span{
        font-size: 12px ;color:#666;margin-left: 23px;
        font-weight: normal
    }
    .no21>div:nth-child(2)>p:nth-child(2){
        color: #666
    }
    .no21>div:last-child{
        margin-left: 38px
    }
    .no21 img{
        max-width: 100%;max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    .no21>div:last-child{
        padding:18px 0;font-size: 12px;
    }
    .no21>div:last-child p{
        display: flex;justify-content: space-between;
        color: #666
    }
    .no211,i{
        color: #ff500b
    }
    i{
        font-size: 14px
    }
    .no21 a{
        display: block;width: 140px;height: 40px;font-size: 16px;
        color: #fff;line-height: 40px;text-align: center;
        background: #3296FA;margin-bottom: 12px;text-decoration: none
    }
    .no3{
        width: 240px;background: #fff;
        float:left;
    }
    .no3>div:first-child{
        border-bottom: 1px solid #ccc;
        margin-bottom: 9px
    }
    .no3>div:first-child>span{
        font-size: 14px;line-height: 34px;font-weight: bold;
        display: block;border-bottom:2px solid #ff500b;width: 80px;
        text-align: center
    }
    .no31{
        padding: 20px ;font-size: 12px ;
        border: 1px solid #fff;
        border-bottom: 1px solid #e6e6e6;height: 104px;
    }
    .no31:hover{
        border: 1px solid #ff500b;cursor: pointer;
    }
    .no31>div{
        float: left;
    }
    .no31>div:first-child{
        width: 60px;height: 60px;border: 0.1px solid #e6e6e6;
        margin-right: 10px;padding: 1px;
    }
    .no31>div:nth-child(2)>p:first-child{
        color: #333;font-weight: bold;width: 128px;
        white-space: nowrap;overflow: hidden;text-overflow: ellipsis;
    }
    .no31>div:nth-child(2)>p:nth-child(2){
        line-height: 22px;color: #666
    }
    .no311{
        margin-left: 10px
    }
    .no311>span{
        color: #ff500b
    }
    .no31>div:nth-child(2)>p:last-child{
        width: 60px;height: 20px;background: #3296FA;color: #fff;
        line-height: 20px;text-align: center;cursor: pointer;
    }
    .page{
        width: 630px;
        margin: 0 auto;
    }
    .img-wrap{
        line-height: 58px;
        cursor: pointer;
    }
    .img-wrap img{
        max-width:100%;
    }
    .type-main{
        height:65px;
        overflow:hidden;
    }
    .no2>div.empty-wrap{
        text-align:center;
        font-size:14px;
        height:auto;
    }
    .main-wrap{
        clear:both;
        overflow:hidden;
        margin:10px 0;
    }
</style>