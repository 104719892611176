<template>
    <div class="no">
        <formItem :disabled="disabled" errorStyle="inline" type="file" :error="avatar.error" :imgObg="imgObg" title="头像" v-model="avatar.value" />

        <formItem :disabled="disabled" maxlength="25" required title="真实姓名" type="text" errorStyle="inline"  :error="realname.error" :value="realname.value"  v-model="realname.value"  placeholder="请填写真实姓名" />

        <ul>
            <li>性别：</li>
            <li>
                <div v-for="(list,i) in Rlist" :key=i>
                    <input :disabled="disabled" :id="list.id" type="radio" name="lx" :value="list.value" v-model="sex">
                    <label :for="list.id"></label>
                    <span>{{list.name}}</span>
                </div>
            </li>
        </ul>

        <formItem :disabled="disabled" title="出生日期" type="date" errorStyle="inline" :error="birthday.error" :value="birthday.value" v-model="birthday.value" />

        <formItem :disabled="disabled" errorStyle="inline" @on-focus="(fn)=>$com.findCode('type_zj',(res)=>{fn(res)})" title="证件类型" required @on-change="(val)=>{issued.value = val.id }" :error="issued.error" type="select" placeholder="请选择证件类型" v-model="issued.value" :selectList="issued" />
        
        <formItem :disabled="disabled" maxlength="25" required title="证件号码" type="text" errorStyle="inline"  :error="idCard.error" :value="idCard.value"  v-model="idCard.value"  placeholder="请填写证件号码" />

        <formItem :disabled="disabled" errorStyle="inline" @on-focus="(fn)=>$com.findCode('type_zj',(res)=>{fn(res)})" title="工作年限" required @on-change="(val)=>{workLife.value = val.id }" :error="workLife.error" type="select" placeholder="请选择工作年限" v-model="workLife.value" :selectList="workLife" />

        <formItem :disabled="disabled" errorStyle="inline" @on-focus="(fn)=>$com.findCode('type_zj',(res)=>{fn(res)})" title="最高学历"  @on-change="(val)=>{education.value = val.id }" :error="education.error" type="select" placeholder="请选择最高学历" v-model="education.value" :selectList="education" />

        <formItem :disabled="disabled" maxlength="25" title="职称" type="text" errorStyle="inline"  :error="title.error" :value="title.value"  v-model="title.value"  placeholder="请填写职称" />

        <formItem :disabled="disabled" maxlength="25" required title="联系电话" type="text" errorStyle="inline"  :error="phone.error" :value="phone.value"  v-model="phone.value"  placeholder="请填写联系电话" />

        <formItem :disabled="disabled" maxlength="25" title="电子邮箱" type="text" errorStyle="inline"  :error="email.error" :value="email.value"  v-model="email.value"  placeholder="请填写电子邮箱" />

        <formItem :disabled="disabled" maxlength="25" title="通讯地址" type="text" errorStyle="inline"  :error="detailAddress.error" :value="detailAddress.value"  v-model="detailAddress.value"  placeholder="请填写通讯地址" />   

        <formItem :disabled="disabled" title="工作经历" type="textarea"  placeholder="请填写工作经历
        如：重庆网络科技有限公司-总经理" v-model="jobQua"/> 

        <formItem :disabled="disabled" title="客户案例" type="textarea"  placeholder="请填写客户案例
        如：江苏大元电机有限公司-资质认证" v-model="jobCase"/> 

        <formItem :disabled="disabled" title="优势和特长" type="textarea"  placeholder="请填写你的优势和特长" v-model="speciality"/> 

        <formItem :disabled="disabled" title="个人简介" type="textarea"  placeholder="请填写自我介绍" v-model="introduction"/>

        <ul>
            <li>
                <xbutton :disabled="disabled" @on-click="goAdd" value="保存并提交后台审核"></xbutton>
            </li>
        </ul>
    </div>
</template>

<script>
import formItem from '@/components/UI/formItem.vue'
import Xselect from '@/components/UI/select.vue'
import xbutton from '@/components/UI/xbutton.vue'
import imgBg from '../../../assets/img/seller/photo.png'
export default {
    data() {
        return {
            disabled:false,
            Rlist:[{id:"lx01",value:"0",name:"男"},{id:"lx02",value:"1",name:"女"}],
            sex:0,
            imgObg:{
                width:'100px',
                height:'100px',
                img:imgBg,
            },
            avatar:{
                value:"",
                error:"",
                validate:['required'],
                name:"头像"
            },
            realname:{
                value:"",
                error:"",
                validate:['required'],
                name:"真实姓名"
            },
            birthday:{
                value:"",
                error:"",
                validate:['required'],
                name:"出生日期"
            },
            issued:{
                value:"",
                babel:"",
                error:"",
                validate:['required'],
                name:"证件类型"
            },
            idCard:{
                value:"",
                error:"",
                validate:['required'],
                name:"证件号码"
            },
            workLife:{
                value:"",
                babel:"",
                error:"",
                validate:['required'],
                name:"工作年限"
            },
            education:{
                value:"",
                babel:"",
                error:"",
                validate:['required'],
                name:"最高学历"
            },
            title:{
                value:"",
                error:"",
                validate:['required'],
                name:"职称"
            },
            phone:{
                value:"",
                error:"",
                validate:['required'],
                name:"联系电话"
            },
            email:{
                value:"",
                error:"",
                validate:['required'],
                name:"电子邮箱"
            },
            detailAddress:{
                value:"",
                error:"",
                validate:['required'],
                name:"通讯地址"
            },
            jobQua:"",
            jobCase:"",
            speciality:"",
            introduction:""
        }
    },
    components: {
        formItem,Xselect,xbutton
    },
    methods : {
        goAdd(_this){
            var data = {
                avatar:this.avatar.value.fileId,
                realname:this.realname.value,
                birthday:this.birthday.value,
                issued:this.issued.value,
                idCard:this.idCard.value,
                workLife:this.workLife.value,
                education:this.education.value,
                title:this.title.value,
                phone:this.phone.value,
                email:this.email.value,
                detailAddress:this.detailAddress.value,
                jobQua:this.jobQua,
                jobCase:this.jobCase,
                speciality:this.speciality,
                introduction:this.introduction
            }
            var dataV = {
                issued:this.issued,
                idCard:this.idCard,
                realname:this.realname,
                phone:this.phone,
                workLife:this.workLife
            }
            var validate = this.$com.validate(dataV);
            if (validate) {
                _this.loading = true;
                this.$com.majax.post(this.$com.testCwIp + '/consultant/saveOrUpdateConsultant',this.$qs.stringify(data),{
                    success:(res)=>{
                        _this.loading = false;
                        if(res.code==0){
                            this.$dialog.alert('提交成功，请等待审核！','success',()=>{
                                this.$router.push({ name:'counselor_list' })
                            })
                        }
                    }
                },_this)
            }
        }
    },
    mounted () {
        this.$parent.titleMeg = '新增咨询师';
    }
}
</script>


<style scoped>
    .no{
        width: 992px;
        min-height: 520px;
        background: #fff;padding: 20px 0 20px 150px
    }
    .no>ul{
        margin-bottom: 20px
    }
    .no ul:nth-child(3){
        display: flex;line-height: 40px;
        margin-left: 55px;font-size: 14px
    }
    .no ul:nth-child(3) li:nth-child(2)>div{
        position: relative;
        line-height: 40px;
        float: left;
        font-size: 14px;margin-left: 12px;
        color: #666;margin-right: 20px
    }
    input[type="radio"] {
        width: 10px;
        height: 10px;
        opacity: 0;
    }
    label {
        position: absolute;
        left: 0;
        top: 14px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 1px solid #999;
    }
    input:checked+label{
        background-color: #3296FA;
        border: 1px solid #006eb2;
    }
    input:checked+label::after {
        position: absolute;
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #fff;
        top: 2px;
        left: 2px;
        border: 2px solid #fff;
    }
    button{
        width: 200px;height: 40px;
        background: #3296fa;color: #fff;
        font-size: 18px;margin-left: 107px;
        border: 0
    }
    button:hover{
        cursor: pointer;
        background: linear-gradient(to right, #00aaff, #0085ff);
    }
</style>
