<template>
    <div class="no2">
        <formItem rwidth="375" maxlength="15" errorStyle="inline" title="手机号码" required :error="safePhone.error"  v-model="safePhone.value" type="text" placeholder="请输入手机号码" />
        <ul>
            <li><span style="color:red">*</span> &nbsp;验证码：</li>
            <li>
                <input maxlength="6" type="text" placeholder="请输入短信验证码" v-model="code.value">
            </li>
            <li>
                <codeBtn @on-click="codePh" :disabled="codePhDisable" @on-type="(val)=>{codeType=val}" />
                <span class="error">{{code.error}}</span>
            </li>
        </ul>
        <ul>
            <li></li>
            <li><xbutton @on-click="goSubmit" value="确&nbsp; 定" /></li>
        </ul>
    </div>
</template>

<script>
import codeBtn from '@/components/UI/codeBtn.vue'
import xbutton from '@/components/UI/xbutton.vue'
import formItem from '@/components/UI/formItem.vue'
export default {
    props: {

    },
    data(){
        return {
            formShow:true,
            megBox:{
                show:false,
                bodyMeg:'恭喜您，绑定成功！'
                /*type:'success'*/
            },
            safePhone:{
                value:"",
                error:"",
                validate:['required'],
                name:"手机号码"
            },
            code:{
                value:"",
                error:"",
                validate:['required'],
                name:"验证码"
            },
            codePhDisable:false,
            codeType:false,
        }
    },
    components: {
        codeBtn,xbutton,formItem
    },
    methods:{
        goSubmit:function(_this){
            var data ={
                newPhone:this.safePhone.value,
                vcode:this.code.value
            }
            var dataV = {
                safePhone:this.safePhone,
                code:this.code
            };
            var validate = this.$com.validate(dataV);
            if(validate){
                _this.loading = true;
                this.$com.majax.post(this.$com.testUsIp + '/security/bindPhone',this.$qs.stringify(data),{
                    success:(res)=>{
                        _this.loading = false;
                        if(res.code==0){
                            this.$dialog.alert('恭喜您，绑定成功！','success',()=>{
                                this.$router.push({ name:'account_bdph_Cph1',query:{reload:true} })
                            })
                        }
                    }
                },_this)
            }
            //this.$router.push({ name:'account_bdph_Cph1' });
        },


        codePh:function(){
            if (this.safePhone.value == "") {
                this.$dialog.alert('请输入手机号码','error')
            }else{
                var data = {
                    phone:this.safePhone.value,
                    sendType:1
                }
                this.$axios.post(this.$com.testUsIp+'/sms/send-code', this.$qs.stringify(data))
                .then((response) => {
                    var res = response.data
                    if (res.code === 0) {
                        this.codePhDisable = !this.codeType
                    }else{
                        this.$dialog.alert(res.msg,'error')
                    };
                })
                .catch((error) => {
                    this.$dialog.alert('系统错误','error')
                })
            };
        },
    },
    mounted:function(){
        this.$parent.formShow = false;
    },
}
</script>

<style scoped>
body{
    margin: 0;
    padding: 0;
}
ul,p{
    list-style: none;
    margin: 0;
    padding: 0;
}
div.no2 p{
    line-height: 0px;font-size: 12px;
    color: #FF4A03
}
.bdph>div.no2{
    width:992px;height:580px;
    background:#fff;
    position: relative;
    padding-top: 38px;
}
.bdph>div.no2 ul{
    display: flex;
    margin-left: 84px;
    background: #fff;
    margin-bottom:20px;
}
.bdph>div.no2 ul>li:nth-child(1){
    width: 100px;
    text-align: right;
    font-size: 14px;
    color: #333;
    margin-right:7px;
    line-height:40px;
}
.bdph>div.no2 ul>li:nth-child(2) input{
    width: 375px;
    height: 40px;
    padding-left: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc
}
.bdph>div.no2 ul:nth-child(2)>li:nth-child(2)>input{
    width: 240px;
}
.bdph>div.no2 ul>li:nth-child(2) button{
    width: 240px;
    height: 40px;
    color: #fff;
    background: #3296FA;
    border: 0;
    font-size: 18px;
    margin-top: 20px;
}
.bdph>div.no2 ul>li:nth-child(2) button:hover{
    background: linear-gradient(to right, #00aaff, #0085ff);
    cursor: pointer;
}
.error{
    color:red;
    font-size:14px;
    margin-left:5px;
}
</style>
