var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"indx"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"shopI"},[_c('div',{staticClass:"no"},[_vm._m(0),_c('div',{domProps:{"innerHTML":_vm._s(_vm.list.remark)}})]),_c('div',{staticClass:"no"},[_vm._m(1),_c('div',{staticClass:"no1",attrs:{"id":"no1"}},[_c('ul',[_c('li',[_vm._m(2),_vm._v("："+_vm._s(_vm.list.shopName))]),_c('li',[_vm._m(3),_vm._v("："+_vm._s(_vm.list.shopTel))]),_c('li',[_vm._m(4),_vm._v("："+_vm._s(_vm.list.shopScope))]),_c('li')]),_c('ul',[_c('li',[_vm._m(5),_vm._v("："+_vm._s(_vm.list.shopManager))]),_c('li',[_vm._m(6),_vm._v("："+_vm._s(_vm.list.managerPhone))]),_c('li',[_vm._m(7),_vm._v("："),_vm._l((_vm.list.shopCategory),function(list,i){return _c('span',{key:i,staticClass:"busi"},[_vm._v(_vm._s(list)+"；")])})],2),_c('li')])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div'),_c('span',[_vm._v("店铺介绍")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div'),_c('span',[_vm._v("联系我们")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_vm._v("机构名称"),_c('i')])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_vm._v("座机号码"),_c('i')])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_vm._v("经营描述"),_c('i')])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_vm._v("店铺负责人"),_c('i')])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_vm._v("联系人电话"),_c('i')])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_vm._v("业务范围"),_c('i')])
}]

export { render, staticRenderFns }