<template>
    <div>
        <div class="no2">
            <ul>
                <li>
                    <span>资质名称：</span>
                    <input type="text" placeholder="资质名称" v-model="qualificationName">
                </li>
                <li>
                    <span>审核状态：</span>
                    <Xselect class="x" @on-focus="(fn)=>fn(typeList)" v-model="status" :list="typeList" />
                </li>
                <li><input type="button" value="查 询" @click="goSearch"></li>
                <li><input type="button" value="新增资质" class="btn1" @click="goAdd"> </li>
            </ul>
            <xtable :loading="loading" :search="search" :head="head" :tableData="tableData" btn="edit,del" @on-delClick="delClick" @on-editClick="editClick" @on-changePage="(v)=>getData(v)" />
        </div>
    </div>
</template>

<script>
import Xselect from '@/components/UI/select.vue'
import xtable from '@/components/UI/xtable.vue'
export default {
    data(){
        return {
            head:{
                qualificationName:"资质名称",
                certifiedTime:"获证日期",
                validTime:"有效日期",
                level:"资质等级",
                status:"审核状态",
                createTime:"添加时间"
            },
            lists:[],
            cardType:'请选择',
            typeList:[{id:'',name:'全部'},{id:0,name:'审核通过'},{id:1,name:'待审核'},{id:2,name:'驳回'}],
            qualificationName:'',
            status:'',
            tableData:{},
            loading:false,
            search:{},
        }
    },
    components: {
        Xselect,xtable
    },
    methods:{
        goAdd:function(){
            this.$router.push({ name:'new_qua' })
        },
        editClick(item){
            this.$router.push({ name:'new_qua',query: { QId:item.id } })
        },
        delClick(item){
            this.$dialog.confirm('是否确认删除'+ item.qualificationName,'error',()=>{
                 this.$com.majax.post(this.$com.testCsIp + '/sellerQualification/deleteQualificationById/' + item.id,{},{
                     success:(res)=>{
                         if(res.code==0){
                            this.getData()
                         }
                     }
                 })
            })
        },
        goSearch(){
            this.search = {
                qualificationName:this.qualificationName,
                status:this.status
            };
            this.getData(this.search);
        },
        getData:function(data){
            this.loading = true;
            this.$com.majax.post(this.$com.testCsIp + '/sellerQualification/findListQualification',this.$qs.stringify(data),{
                success:(res)=>{
                     for(var i=0;i<res.data.records.length;i++){
                         if(res.data.records[i].validTime == "0"){
                             res.data.records[i].validTime="长期"
                         }
                     }
                    this.tableData = res.data;
                }
            },this)
        },
        typeChange:function(val){
            this.status = val.id
        },
    },
    mounted:function(){
        this.$parent.titleMeg = '资质管理';
        this.getData()
    },
}
</script>

<style scoped>
    .x{
        width: 175px
    }
    div.no2{
        width:992px;padding-bottom: 20px;
        min-height:520px;
        background:#fff;
        position: relative;
        padding: 20px;
    }
    div.no2 ul{
        display: flex;
        background: #fff;
        line-height: 60px;
    }
    div.no2 ul span{
        font-size: 14px;
        color: #333
    }
    div.no2 ul li{
        display: flex;
        line-height: 40px;
        margin-top: 15px;
        margin-bottom: 10px
    }
    div.no2 ul>li:nth-child(2){
        margin-left: 72px;
    }
    div.no2 ul input[type=button]{
        width: 80px;
        height: 40px;border: 0;background: #3296FA;
        color: #fff;font-size: 16px;
        margin-left: 8px;
        cursor: pointer;border-radius: 4px
    }
    div.no2 ul input[type=button]:hover{
        background: linear-gradient(to right, #00aaff, #0085ff);
    }
    input[type=text]{
        width: 175px;
        border: 1px solid #ccc;
        padding-left: 10px;box-sizing: border-box
    }
    div.no2 ul input.btn1{
        width: 100px;
        text-align: center;
        margin-left: 200px;
        border-radius:0 
    }
    .empty-img{
        display: block;
        margin:auto;
    }
</style>
