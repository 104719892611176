<template>
    <div class="no2">
        <div v-if="goodsList.length>0">
            <div class="item" v-for="(item,index) in goodsList">
                <div class="header">
                    <span class="time">{{item.historyTime.substring(0,10)}}</span>
                    <span class="num">（{{item.buyerHistoryList.length}}个服务）</span>
                </div>
                <goodsItem v-for="(item,index) in item.buyerHistoryList" :key="index" :goodsData="item" delValue="删除足迹" @on-clickDel="ClickDel" @on-clickDetail="ClickDetail" />
            </div>
        </div>
        <div v-else class="center">
            <img class="empty-img" src="../../../assets/img/user/empty_page.jpg" alt="">
        </div>
    </div>
</template>

<script>
import goodsItem from '@/components/UI/goodsItem.vue'
export default {
    mounted(){
        this.$parent.titleMeg = '我的足迹';
        this.GetData();
    },
    components: {
        goodsItem
    },
    data(){
        return {
            goodsList:[],
            show:false,
        }
    },
    methods:{
        GetData:function(){
            this.$com.majax.get(this.$com.testCwIp+'/sellerBuyerHistory/list',"",{
                success:(res)=>{
                    this.goodsList = res.data;
                }
            },this)
        },
        ClickDel:function(item){
            this.$com.majax.post(this.$com.testCsIp+'/sellerBuyerHistory/deleteBuyerHistory/' + item.id,"",{
                success:(res)=>{
                    this.$message({
                        message: '删除成功！',
                        type: 'success'
                    });
                    this.GetData();
                }
            },this)
        },
        ClickDetail:function(item){
            this.$router.push({path:'/shop_management/detail_page',query: {id: item.servicesId}})
        }
    }
}
</script>


<style scoped>
.no2{
    width:992px;min-height: 580px;
    background:#fff;
    position: relative;
    padding: 20px 15px 40px 15px;
    box-sizing: border-box;
}
.time{
    font-size:20px;
    font-weight:bold;
}
.num{
    font-size:14px;
    color:#666;
}
.header{
    margin-bottom:5px;
}
.item{
    border-bottom:1px dashed #ccc;
    margin-bottom:8px;
}
</style>
