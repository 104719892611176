<template>
    <div>
        <IndexHeader_home :textMeg="textMeg"/>
        <div class="content">
            <div class="no">
                <div class="no1">
                    <p>所有机构筛选（<span class="active">共{{listData.total}}个</span>）</p>
                    <p>服务类别：
                        <span :class="{'active':serveType===item.id}" v-for="(item,index) in serveList" :key="index" @click="GetListData(item.id,1)">{{item.name}}</span>
                    </p>
                </div>
                <div class="main-wrap">
                    <div class="no2" v-loading="loading">
                        <div class="no21"  v-for="item in listData.records" v-bind:key="item.size">
                            <div class="img-wrap" :class="{'pointer':item.shopId}" @click="GoShop(item)"><img :src="item.avatarPath||$com.errorImg" :alt="item.name" :onerror="$com.errorImg"></div>
                            <div>
                                <p><span :class="{'pointer':item.shopId}" class="title" @click="GoShop(item)">{{item.name}}</span>
                                    <router-link  v-if="item.shopId" :to="{path:'/shop_management/shop_man_index',query:{ShopId:item.shopId}}" target="_blank">进入店铺</router-link>
                                </p>
                                <span><i class="el-icon-location-outline"></i>{{item.areaName}}</span>
                                <p>经营范围：</p>
                                <p class="type-main" :title="item.businessScope">
                                    <span>{{item.businessScope}}。</span>
                                </p>
                            </div>
                            <div>
                            </div>
                        </div>
                        <div class="empty-wrap" v-if="listData.records.length===0">
                            暂无数据
                        </div>
                        <ul class="page" v-if="listData.pages>1">
                            <pagination :total="listData.total" :pageSize="10" :current="listData.current" @pageChange="pageChange"></pagination>
                        </ul>
                    </div>

                </div>

            </div>
        </div>
        <IndexFooter_home />
    </div>
</template>

<script>
import IndexHeader_home from '@/components/IndexHeader_home.vue'
import IndexFooter_home from '@/components/IndexFooter_home.vue'
import Pagination from '@/components/UI/Pagination.vue'

export default {
    data(){
        return{
            textMeg:'服务机构',
            show:true,
            listData:{
                records:[],
                current:1,
            },
            serveType:"",
            loading:false,
            serveList:[{name:"不限",id:""},{name:"咨询机构",id:"2"},{name:"检测机构",id:"3"},{name:"认证机构",id:"4"}]
        }
    },
    methods:{
        GetListData:function(val,current){
            this.loading = true;
            this.serveType = val;
            this.$axios.get(this.$com.testCsIp + '/ucUser/getOrgList/'+ current +'/10',
            {params:{
                type:this.serveType,
            }})
            .then(res=>{
                this.loading = false;
                this.listData = res.data.data;
            })
        },
        GoShop:function(item){
            if (item.shopId) {
                this.$router.push({ name: 'shop_man_index',query:{ShopId:item.shopId} })
            };
        },
        pageChange:function(current){
            this.GetListData(this.serveType,current.num)
        },
    },
    mounted:function(){
        this.serveType = this.$route.query.type||"";
        this.GetListData(this.serveType,1);
    },
    components: {
        IndexHeader_home,IndexFooter_home,Pagination
    },
}
</script>

<style scoped>
    .content{
        background: #f5f5f5;
        min-width: 1200px;min-height: 880px
    }
    .no{
        width: 1200px;margin: 0 auto;
        clear:both;
        overflow:hidden;
    }
    .no1{
        width: 1200px;height: 77px;background: #fff;
        padding: 10px 20px;
    }
    .no1>p{
        line-height: 28px;font-size: 12px;color: #333;
    }
    .no1>p:last-child{
        font-weight: bold
    }
    .no1>p:last-child span{
        margin-right: 35px;font-weight: normal;color: #666;
        cursor:pointer;
    }
    .no1>p:last-child span.active,.active{
        color: #ff500b
    }
    .no2{
        width: 1200px;min-height: 775px;background: #fff;
        float:left;padding:20px 0 20px 20px;
    }
    .no21{
        width: 570px;height: 140px;border: 2px solid #eee;
        padding: 20px;margin-right:20px;float:left;margin-bottom:20px
    }
    .no21>div{
        float: left;
    }
    .no21>div:first-child{
        width: 100px;height: 100px;border: 0.1px solid #E6E6E6
    }
    .no21>div:nth-child(2){
        width: 405px;margin-left: 20px
    }
    .no21>div:nth-child(2)>p{
        font-size: 12px;line-height: 16px;color: #999;
        letter-spacing:0.5px;
    }
    .no21>div:nth-child(2)>p:first-child{
        font-size: 16px;color: #333;font-weight: bold;
    }
    .no21>div:nth-child(2)>p:first-child .title{
        font-size: 16px;color: #333;font-weight: bold;
        margin-left:0;
        display: inline-block;
        width: 300px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .no21>div:nth-child(2)>p:first-child span{
        font-size: 12px ;color:#666;margin-left: 10px;
        font-weight: normal
    }
    .no21>div:nth-child(2)>span{
        font-size: 12px;
        color: #666;
        font-weight: normal;
    }
    .no21>div:nth-child(2)>p:nth-child(2){
        color: #666
    }
    .no21>div:last-child{
        margin-left: 38px
    }
    .no21 img{
        width: 95px;
    }
    .no21>div:last-child{
        padding:18px 0;font-size: 12px;
    }
    .no21>div:last-child p{
        display: flex;justify-content: space-between;
        color: #666
    }
    .no211,i{
        color: #ff500b
    }
    i{
        font-size: 14px
    }
    .no21 a{
        display: block;width: 104px;height: 38px;font-size: 16px;
        color: #fff;line-height: 38px;text-align: center;float:right;
        background: #3296FA;text-decoration: none;
        font-weight:normal;
    }
    .page{
        width: 630px;
        margin: 0 auto;
        clear: both;
    }
    .img-wrap{
        line-height: 95px;
        text-align:center;
    }
    .pointer{
        cursor: pointer;
    }
    .type-main{
        height:50px;
        overflow:hidden;
    }
    .no2>div.empty-wrap{
        text-align:center;
        font-size:14px;
        height:auto;
    }
    .main-wrap{
        clear:both;
        overflow:hidden;
        margin:10px 0;
    }
</style>