<template>
    <ul class="tree">
        <li v-for="(item,index) in navData" :class="{'open-li':item.open}" :key="index">
            <span :class="{'grzl-i':item.title=='个人资料',
            'zhaq-i':item.title=='账户安全',
            'detail-i':item.title=='需求管理',
            'order-i':item.title=='订单管理',
            'aut-i':item.title=='认证管理',
            'my-i':item.title=='我的关注',
            'shop-i':item.title=='店铺管理',
            'serve-i':item.title=='服务管理',
            'ticket-i':item.title=='确认委托单',
            'bid-i':item.title=='我的竞标',
            'son-i':item.title=='咨询师管理'
            }">
                {{item.title}}
            </span>
            <ul>
                <li v-for="(items,index) in item.list" :key="index"><router-link active-class="active" :to="items.url">{{items.title}}</router-link></li>
            </ul>
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        navData:Array
    },
    data(){
        return {

        }
    },
    components: {

    },
    methods:{
        /*open:function(item){
            item.open = !item.open
        }*/
    },
    mounted:function(){
    
    },
}
</script>

<style scoped>
.tree{
    margin-top: 11px;
}
.tree > li{
    padding-left: 27px;
    position: relative;
    padding-bottom: 10px
}
/* .tree > li:hover{
    background: #eaf4fe;
}
.tree > li:hover:before{
    content: '';
    width: 2px;
    height: 100%;
    background: #3296fa;
    position: absolute;
    top: 0;
    left: 0;
} */
.tree span{
    padding-left: 25px;
    font-size: 16px;
    font-weight: bold;
    line-height: 40px;
    color: #666;
}
.grzl-i{
    background: url("../assets/img/user/i_grzl.png") no-repeat 3px center;
}
.zhaq-i{
    background: url("../assets/img/user/i_zhaq.png") no-repeat 3px center;
}
.detail-i{
    background: url('../assets/img/buyer/detail.png') no-repeat 3px center;
}
.order-i{
    background: url('../assets/img/buyer/order.png') no-repeat 3px center;
}
.aut-i{
    background: url('../assets/img/buyer/aut.png') no-repeat 3px center;
}
.my-i{
    background: url('../assets/img/buyer/my.png') no-repeat 3px center;
}
.shop-i{
    background: url('../assets/img/seller/shop.png') no-repeat 3px center;
}
.serve-i{
    background: url('../assets/img/buyer/detail.png') no-repeat 3px center;
}
.ticket-i{
    background: url('../assets/img/buyer/ticket.png') no-repeat 3px center;
}
.bid-i{
    background: url('../assets/img/seller/bid.png') no-repeat 3px center;
}
.son-i{
    background: url('../assets/img/seller/son.png') no-repeat 3px center;
}
.tree .open{
    /* background: url("../assets/img/user/kai.png") no-repeat center left; */
}
.tree a{
    text-decoration: none;
    color: #666;
    line-height: 25px;
    color: #666;
    font-size: 14px;
}
.tree a:hover,.tree .active{
    color:#3296fa;
}
.tree ul{
    line-height: 25px;
    color: #666;
    font-size: 14px;
    padding-left: 25px;
}
.open-li ul{
    display: block;
}
</style>