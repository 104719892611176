<template>
    <div class="nav-right-top">
        <table></table>
        <ul>
            <li>
                <a href="http://cx.cnca.cn/CertECloud/index/index/page " target="_blank">
                <img src="../../../assets/img/index//renzhengcxicon.png" alt=""><br>
                <span>认证查询</span>
                </a>
            </li>
            <li>
                <router-link :to="{path:'/search_page',query:{id:'f0ddb8a8650c42e59f373f5a46340b79'}}"  target="_blank">
                    <img src="../../../assets/img/index/qzxicon.png" alt="强制性产品"><br>
                    <span>强制性产品</span>
                </router-link>
            </li>
            <li>
                <router-link :to="{path:'/search_page',query:{id:'cfb258ed6b424799aca522c7f880e5f2'}}"  target="_blank">
                    <img src="../../../assets/img/index/gltxicon.png" alt="管理体系"><br>
                    <span>管理体系</span>
                </router-link>
            </li>
            <li>
                <router-link :to="{path:'/search_page',query:{id:'2874cece6c264635a23793ce48a98710'}}"  target="_blank">
                    <img src="../../../assets/img/index/ziyuanicon.png" alt="自愿性产品"><br>
                    <span>自愿性产品</span>
                </router-link>
            </li>
            <li>
                <router-link :to="{path:'/search_page',query:{id:'e5ee6665c0b74514a5adea2346551034'}}"  target="_blank">
                    <img src="../../../assets/img/index/wufuicon.png" alt="服务体系"><br>
                    <span>服务认证</span>
                </router-link>
            </li>
            <li>
                <router-link :to="{path:'/search_page',query:{id:'a082bc3e353347249af51d639bab7373'}}"  target="_blank">
                    <img src="../../../assets/img/index/shipicon.png" alt="食品农产品"><br>
                    <span>食品农产品</span>
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    data(){
        return{
            
        }
    },
    mounted:function(){
        
    },
    components: {

    },
    methods: {

    }
}
</script>

<style scoped>
    .fl{
        overflow: hidden;
        margin-top: 6px;
    }
    .fl>div{
        float: left;
    }
    .fl>div:first-child{
        font-weight: bold;font-size: 14px;color: #333
    }
    .fl>div:first-child img{
        margin: 6px 10px 0 5px;
        vertical-align: top;
    }
    .fl>div:first-child span{
        width: 90px;text-align: right;
        display: inline-block;height: 23px;
        overflow: hidden;white-space: nowrap;text-overflow: ellipsis;
    }
    .fl>div:nth-child(2){
        width: 602px;
    }
    .board{
        padding: 20px 10px 20px 20px
    }
</style>

