<template>
    <div class="no2">
        <div>
            <div>
                <div></div>
                商品清单
                <span>订单编号：{{list.orderNo}}</span>
            </div>
            <ul>
                <li class="img-wrap">
                    <img :src="list.coveImagePath" alt="">
                </li>
                <li >
                    <ul class="item">
                        <li>{{list.serviceName}}</li>
                        <li>
                            <a @click="lookClick">查看委托单</a>
                            <router-link v-if="list.status=='4'||list.status=='5'" :to="{ name:'order_upload',query:{ id : oid}}">上传服务凭证</router-link>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
        <div>
            <div><div></div>付款信息</div>
            <div class="item1">
                <ul>
                    <li>交易金额：</li>
                    <li class="price">￥{{list.consultPrice}}</li>
                </ul>
                <ul>
                    <li>支付方式：</li>
                    <li>{{list.payName}}</li>
                </ul>
                <ul>
                    <li>付款时间：</li>
                    <li>{{list.payTime}}</li>
                </ul>
            </div>
        </div>
        <div>
            <div><div></div>收货人信息</div>
            <div class="item1">
                <ul>
                    <li>收货人姓名：</li>
                    <li>{{list.receive}}</li>
                </ul>
                <ul>
                    <li>联系电话：</li>
                    <li>{{list.phone}}</li>
                </ul>
                <ul>
                    <li>收货人地址：</li>
                    <li>{{list.address}}</li>
                </ul>
            </div>
        </div>
        <div>
            <div><div></div>发票信息</div>
            <div class="item1">
                <ul class="width50">
                    <li>是否要发票：</li>
                    <li>{{list.isInv===1?'是':'否'}}</li>
                </ul>
                <ul class="width50" v-if="list.isInv===1">
                    <li>发票类型：</li>
                    <li>{{list.invoiceType=='1'?'增值税发票':'普通发票'}}</li>
                </ul>
                <ul class="width50" v-if="list.isInv===1">
                    <li>发票抬头：</li>
                    <li>{{list.invoiceTitle=='1'?'单位':'个人'}}</li>
                </ul>
                <ul class="width50" v-if="list.isInv===1">
                    <li>发票内容：</li>
                    <li>{{list.invoiceContent}}</li>
                </ul>
                <ul class="width50" v-if="list.isInv===1">
                    <li>单位名称：</li>
                    <li>{{list.orgName}}</li>
                </ul>
                <ul class="width50" v-if="list.isInv===1">
                    <li>发票税号：</li>
                    <li>{{list.invoiceTfn}}</li>
                </ul>
            </div>
        </div>
        <diyDialog :status="status" title="委托单" type="seller" disabled :formData="formData" :snycShow.sync="dialogShow" @on-ok="pass(2)" @on-no="pass(3)"/>
    </div>
</template>

<script>
import diyDialog from '@/components/UI/diyDialog.vue'
export default {
    data() {
        return {
            list:{},
            formData:{
                userServicesName:'',
                reason:"",
                consultPrice:{
                    value:"",
                    error:"",
                    validate:['required'],
                    name:"协议价格"
                },
                consultContent:{
                    value:"",
                    error:"",
                    validate:['required'],
                    name:"协议内容"
                },
                signature:{
                    value:"",
                    error:"",
                    validate:['required'],
                    name:"委托人姓名"
                },
            },
            dialogShow:false,
            id:'',
            oid:"",
            status:''
        }
    },
    components: {
        diyDialog
    },
    methods: {
        lookClick(){
            this.dialogShow = true;
            this.$com.majax.get(this.$com.testCsIp + '/uc-entrust-order/getEntrustOrderDetailsById/' + this.id,{},{
                success:(res)=>{
                    this.status = res.data.enStatus.toString()
                    this.formData.userServicesName=res.data.userServicesName;
                    this.formData.userServicesContacts=res.data.userServicesContacts
                    this.formData.userServicesTel=res.data.userServicesTel;
                    this.formData.userServicesAddress=res.data.userServicesAddress;
                    this.formData.userDemandName=res.data.userDemandName;
                    this.formData.userDemandAddress=res.data.userDemandAddress;
                    this.formData.userDemandTel=res.data.userDemandTel;
                    this.formData.consultPrice.value=res.data.consultPrice;
                    this.formData.consultContent.value=res.data.consultContent;
                    this.formData.signature.value=res.data.signature;
                    this.formData.reason=res.data.reason
                }
            },this)
        },
        pass(status){
            if(status == 2){
                var val="审核通过！"
            }else{
                var val="已驳回！"
            }
            var data = {
                reason:this.formData.reason,
                status:status,
                id:this.id
            }
            this.$com.majax.post(this.$com.testCwIp + '/entrustOrder/auditEntrustOrder',this.$qs.stringify(data),{
                success:(res)=>{
                    if(res.code == 0){
                        this.$dialog.alert(val,'success',()=>{
                            this.dialogShow = false;
                        })
                    }
                }
            })
        },
        goQuery(){
            var id = this.$route.query.id;
            this.oid = id;
            this.$com.majax.get(this.$com.testCsIp + '/order/getOrderDetailsById/' + id,{},{
                success:(res)=>{
                    this.list=res.data;
                    this.id=res.data.entrustId
                }
            })
        },
    },
    mounted(){
        this.$parent.titleMeg = '订单详情';
        this.goQuery()
    }
}
</script>


<style scoped>
        div.no2{
            width:992px;
            background:#fff;
            position: relative;
            padding-bottom:15px;
        }
        div.no2>div{
            padding-left:42px;padding-right: 57px;
            box-sizing: border-box ;font-size: 14px;
        }
        div.no2>div>div:first-child{
            padding-top: 20px;
            display: flex;line-height: 30px;
            border-bottom: 2px dotted #ccc;
            font-weight: bold;margin-bottom: 10px;
            position: relative;
        }
        div.no2>div>div:first-child>div{
            width: 3px;height:14px ;
            background: #ff4a03;margin-top: 8px;
            margin-right: 9px
        }
        div.no2>div>div:first-child span{
            position: absolute;right: 0;
            color: #ff4a03
        }
        div.no2>div>ul{
            display: flex;
            margin-top:20px ;margin-left: 20px
        }
        div.no2>div>ul>li:first-child{
            border: 1px solid #ccc;
            width: 78px;height: 78px;
            padding: 5px;box-sizing: border-box;
            display: flex;justify-content: center;
            align-items: center;margin-right: 10px;

        }
        div.no2 .item li:first-child{
            font-size: 20px;line-height: 30px;
            margin-bottom: 14px
        }
        div.no2 .item a{
            text-align: center;line-height: 34px;
            display: block;width: 120px;height: 34px;
            background: #3296FA;color: #fff;
            float: left;margin-right: 20px;
            text-decoration: none;
            cursor:pointer;
        }
        div.no2 .item a:last-child{
            background: #fff;color: #3296FA;
            border: 1px solid #3296FA;
        }
        div.no2 .item a:hover{
            color: #fff;
            background: linear-gradient(to right, #00aaff, #0085ff);
        }
        div.no2 .item1 ul{
            line-height: 30px;
            overflow:hidden;
        }
        div.no2 .item1 ul li{
            float:left;
        }
        div.no2 .item1 ul li:first-child{
            width: 100px;text-align: right;
            font-size: 14px;color: #666;
        }
        div.no2 .item1 .money{
            color: #ff4a03;font-weight: bold
        }
        div.no2 div:last-child .item1{
            display: flex;flex-wrap: wrap
        }
        div.no2 div:last-child .item1 li:last-child{
            width: 345px
        }
        .item1{
            clear:both;
            overflow:hidden;
        }
        .img-wrap img{
            width:100%;
            height:100%;
        }
        .width50{
            width:50%;
            float:left;
        }
</style>
