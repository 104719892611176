<template>
    <div class="cpw">
        <div class="no2">
            <div class="c1">
                <img src="../../../assets/img/user/laba_04c6e6e.png" style="vertical-align: sup;margin-right:8px">
                <span>安全提醒：请妥善保管密码！认证平台工作人员不会一任何理由向您索取密码。 </span>
            </div>
            <formItem errorStyle="inline" validate title="当前密码" required :error="oldPassword.error" v-model="oldPassword.value" type="password" placeholder="请输入当前密码，（6-16位，由数字和字母组成）" />
            <formItem errorStyle="inline" validate title="新密码" required :error="newPassword.error" v-model="newPassword.value" type="password" placeholder="请输入新密码，（6-16位，由数字和字母组成）" />
            <formItem errorStyle="inline" validate title="确认新密码" required :error="newPasswordA.error" v-model="newPasswordA.value" type="password" placeholder="请输入新密码，（6-16位，由数字和字母组成）" />
            <ul>
                <li></li>
                <li><xbutton @on-click="goSubmit" value="确认修改" /></li>
            </ul>
        </div>
    </div>
</template>

<script>
import formItem from '@/components/UI/formItem.vue'
import xbutton from '@/components/UI/xbutton.vue'
export default {
    props: {
        navData:Array
    },
    data(){
        return {
            oldPassword:{
                value:"",
                error:"",
                validate:['required','password'],
                min:6,
                max:16,
                name:"当前密码"
            },
            newPassword:{
                value:"",
                error:"",
                validate:['required','password'],
                min:6,
                max:16,
                name:"新密码"
            },
            newPasswordA:{
                value:"",
                error:"",
                validate:['required','password'],
                min:6,
                max:16,
                name:"新密码"
            }
        }
    },
    components: {
        formItem,
        xbutton
    },
    methods:{
        goSubmit:function(_this){
            var data = {
                newPw:this.newPassword.value,
                password:this.oldPassword.value
            }
            var dataV = {
                oldPassword:this.oldPassword,
                newPassword:this.newPassword,
                newPasswordA:this.newPasswordA
            }
            var validate = this.$com.validate(dataV)
            if (this.newPassword.value!=this.newPasswordA.value) {
                this.newPasswordA.error="密码不一致";
                return false;
            };
            if (validate) {
                _this.loading = true;
                this.$com.majax.post(this.$com.testUsIp+'/security/updatePassword', this.$qs.stringify(data),{
                    success:() => {
                        _this.loading = false;
                        this.$dialog.alert("修改成功,请重新登录",'success',()=>{
                            sessionStorage.clear();
                            this.$store.commit('REMOVE_token');
                            this.$router.push({ path: '/' })
                        },(res)=>{
                            res.thisShow = false;
                            sessionStorage.clear();
                            this.$store.commit('REMOVE_token');
                            this.$router.push({ path: '/' })
                        })
                    }
                },_this)
            };
        }
    },
    mounted:function(){
        this.$parent.titleMeg = '修改登录密码';
    },
}
</script>

<style scoped>
    ul,p{
        list-style: none;
        margin: 0;
        padding: 0;
    }
    .cpw>div.no1{
        width:992px;height:39px;background:#fff;
        margin-bottom: 2px;
    }
    .cpw>div.no1>span{
        display: block;
        font-size: 16px;
        line-height: 37px;
        text-align: center;
        width: 120px;
        border-bottom: 2px solid #FF4A03;
    }
    .cpw>div.no2{
        width:992px;height:580px;
        background:#fff;
        position: relative;
        padding: 20px;
        box-sizing: border-box;
    }
    .cpw>div.no2>.c1{
        width: 100%;
        height:40px;
        background-color: #f5f5f5;
        padding-left: 20px;
        padding-left: 20px;
        box-sizing: border-box;
        line-height: 40px;
        color: #FF4A03;
        font-size: 14px;
    }
    .cpw>div.no2 ul{
        display: flex;
        margin-left: 84px;
        background: #fff;
        margin-bottom: 20px;
    }
    .cpw>div.no2 ul:nth-child(2){
        margin-top: 20px;
    }
    .cpw>div.no2 ul>li:nth-child(1){
        width: 107px;
        text-align: right;
        font-size: 14px;
        color: #333;
    }
    .cpw>div.no2 ul>li:nth-child(2) input{
        width: 370px;
        height: 40px;
        padding-left: 10px;
        box-sizing: border-box;
        border: 1px solid #ccc;
    }
    .cpw>div.no2 ul>li:nth-child(2) button{
        width: 180px;
        height: 40px;
        color: #fff;
        background: #3296FA;
        border: 0;
        font-size: 16px;
        margin-top: 20px;
    }
    .cpw>div.no2 ul>li:nth-child(2) button:hover{
        background: linear-gradient(to right, #00aaff, #0085ff);
        cursor: pointer;
    }
</style>
