<template>
    <div class="no2">
        <formItem required :title="form.fileName.name" type="text" v-model="form.fileName.value" />
        <formItem required :title="form.fileNum.name" type="upload"  />
        <formItem required :title="form.fileNum.name" type="text" />
        <formItem :title="form.description.name" type="textarea" v-model="form.description.value" />
        <ul>
            <li></li>
            <li class="form-btn">
                <xbutton @on-click="Submit" value="保 存"></xbutton>
            </li>
        </ul>
    </div>
</template>

<script>
import formItem from '@/components/UI/formItem.vue'
import xbutton from '@/components/UI/xbutton.vue'
export default {
    data(){
        return{
            autoServiceId:this.$route.query.autoServiceId||"",
            id:this.$route.query.id||"",
            IntData:{},
            form:{
                fileName:{
                    value:"",
                    error:"",
                    validate:['required'],
                    name:"文件名称"
                },
                fileNum:{
                    value:"",
                    error:"",
                    validate:['required'],
                    name:"文件编号"
                },
                description:{
                    value:"",
                    name:"文件描述"
                }
            }
        }
    },
    methods:{
        getIntId:function(){
            this.$com.majax.get(this.$com.testChIp + '/auto-prog/initProgSave/' + this.autoServiceId,{autoServiceId:this.autoServiceId},{
                success:(res)=>{
                    this.IntData = res.data;
                }
            })
        },
        getData:function(){
            this.$com.majax.get(this.$com.testChIp + '/auto-prog/getProgDetails/' + this.id,{id:this.id},{
                success:(res)=>{
                    for(var key in this.form){
                        this.form[key].value = res.data[key];
                    }
                }
            })
        },
        Submit:function(){
            var data = {};
            for(var key in this.form){
                data[key] = this.form[key].value
            }
        }
    },
    components: {
        formItem,
        xbutton,
    },
    mounted:function(){
        this.$parent.titleMeg = this.$route.query.id ? '修改程序文件' : '新增程序文件';
        this.$parent.rightLink = false;
        this.id ? this.getData() : this.getIntId();
    },
}
</script>

<style scoped>
.no2{
    width:992px;
    background:#fff;
    padding: 25px 20px 15px 20px;
}
.no2 ul{
    margin-left: 150px;
    display: flex;
    margin-bottom: 20px;
}
.no2 ul li:first-child{
    width: 100px;
    margin-right: 7px;
    text-align: right;
    font-size: 14px;
}
.form-btn{
    width:170px;
}
.form-btn button{
    font-size:18px;
    height:36px;
}
.step-item{
    width:20px;
    height:20px;
    background:#ccc;
}
</style>

