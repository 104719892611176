<template>
    <div>
        <div class="no2">
            <ul>
                <li><span>收货人：</span><input type="text" v-model="receive" placeholder="请输入收货人"> </li>
                <li><span>手机号码：</span><input type="text" v-model="phone" placeholder="请输入手机号码"> </li>
                <li><input type="button" value="搜 索" @click="goSearch"></li>
                <li><input type="button" value="新增地址" class="btn1" @click="goAdd"> </li>
            </ul>
            <table v-if="show" v-loading="tableLoading">
                <tr>
                    <th>收货人姓名</th>
                    <th>联系电话</th>
                    <th>收货地址</th>
                    <th>邮编</th>
                    <th>操作</th>
                </tr>
                <tr v-for="(list,i) in lists" :key="i">
                    <td>{{list.receive}}</td>
                    <td>{{list.phone}}</td>
                    <td>{{list.areaAddress}}{{list.detailAddress}}</td>
                    <td>{{list.zipCode}}</td>
                    <td><span @click="edit(list.id)">编辑</span> &nbsp; <span href="##" @click="del(list.id,i,list.receive)">删除</span>
                        <span class="notdefault" @click="def(list.id)" v-if="list.isdefault==1">设为默认</span>
                        <span class="default" v-if="list.isdefault==0">默认地址</span>
                    </td>
                </tr>
            </table>
            <div v-else>
                <table>
                    <tr>
                        <th>收货人姓名</th>
                        <th>联系电话</th>
                        <th>收货地址</th>
                        <th>邮编</th>
                        <th>操作</th>
                    </tr>
                </table>
                <img class="empty-img" src="../../../assets/img/user/empty_page.jpg" alt="">
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data(){
        return {
            megBox:{
                show:false,
                bodyMeg:'删除成功！'
                /*type:'success'*/
            },
            titleMeg:'地址管理',
            lists:[],
            phone:'',
            receive:'',
            show:false,
            areaAddress:'',
            tableLoading:false
        }
    },
    components: {

    },
    methods:{
        goAdd:function(){
            this.$router.push({ name:'address_add' })
        },
        edit(id){
            this.$router.push({ name:'address_add',query: { id:id } })
        },
        del(id,i,name){
            this.$dialog.confirm('是否确认删除'+name,'error',()=>{
                 this.$com.majax.post(this.$com.testUsIp + '/receive/removeReceive/' + id,{},{
                     success:(res)=>{
                         if(res.code==0){
                            this.$com.majax.get(this.$com.testUsIp + '/receive/selectReceive',{},{
                                success:(resp)=>{
                                    this.lists=resp.data
                                },
                            })
                         }
                     }
                 })
            })
        },
        def(id){
            this.tableLoading=true
            this.$com.majax.post(this.$com.testUsIp + '/receive/defaultReceive/' + id,{},{
                success:(res)=>{
                        if(res.code==0){
                            this.tableLoading=false
                            this.goQuery()
                        }

                 },
            })
        },
        goSearch(){
            var data={
                receive:this.receive,
                phone:this.phone,
            }
            this.$com.majax.get(this.$com.testUsIp + '/receive/selectReceive',data,{
                success:(res)=>{
                    this.lists=res.data
                 },
            })
        },
        goQuery(){
            this.$com.majax.get(this.$com.testUsIp + '/receive/selectReceive',{},{
                success:(res)=>{
                    if(res.data.length !== 0){
                        this.lists=res.data;
                        this.show=true;
                    }
                },
            })
        }

    },
    mounted:function(){
        this.$parent.titleMeg = '地址管理';
        this.goQuery()
    },
}
</script>

<style scoped>
    body{
        margin: 0;
        padding: 0;
    }
    ul,p{
        list-style: none;
        margin: 0;
        padding: 0;
    }
    div.no1{
        width:992px;height:39px;background:#fff;
        margin-bottom: 2px;
    }
    div.no1>span{
        display: block;
        font-size: 16px;
        line-height: 37px;
        text-align: center;
        width: 120px;
        border-bottom: 2px solid #FF4A03;
    }
    div.no2{
        width:992px;padding-bottom: 20px;
        min-height:581px;
        background:#fff;
        position: relative;
        padding-top: 15px;
    }
    div.no2 ul{
        display: flex;
        margin-left: 20px;
        background: #fff;
        line-height: 60px;
    }
    div.no2 ul span{
        font-size: 14px;
        color: #333
    }
    div.no2 ul>li:nth-child(2){
        margin-left: 72px;
    }
    div.no2 ul input[type=button]{
        width: 80px;
        height: 36px;border: 0;background: #3296FA;
        color: #fff;font-size: 16px;
        margin-top: 14px;
        margin-left: 8px;
        cursor: pointer;
        border-radius: 4px;
    }
    div.no2 ul input[type=button]:hover{
        background: -webkit-gradient(linear, left top, right top, from(#00aaff), to(#0085ff));
        background: linear-gradient(to right, #00aaff, #0085ff);
    }
    div.no2 ul input.btn1{
        width: 100px;
        margin-left:140px;
    }
    div.no2 ul input[type=text]{
        width: 210px;
        height: 36px;
        border: 1px solid #ccc;
        box-sizing: border-box;
    }
    table{
        width: 950px;
        margin: 4px auto;
        text-align: center;
        border-left: none;
        border-top: none
    }
    td{
        border-bottom: none;
        border-right: none;
        height: 52px;
        font-size: 12px;
        color: #333;
        white-space:wrap;
        border-bottom: 1px dotted #ccc
    }
    table tr:not(:first-child):hover{
        background: #f5f5f5
    }
    table tr>th{
        height: 40px;font-size: 14px;color: #333;
        background: #f5f5f5;
        color: #333;border-right: 1px solid #fff;
        font-weight: normal
    }
    table tr>td:nth-child(3){
        width: 373px;
    }
    table td:last-child{
        padding-left: 20px;
        width: 160px
    }
    table td span{
        color: #333;
        float: left;margin-right: 5px
    }
    table td span:hover{
        cursor: pointer;
        color: #3296FA
    }
    table td:last-child .default{
        display: block;width: 60px;height: 20px;
        line-height: 20px;color: #3296fa;
        background: #c1dffd;cursor: default;
        border-radius:4px
    }
    table td:last-child .notdefault{
        display: block;width: 60px;height: 20px;
        line-height: 20px;color: #3296fa
    }
    .empty-img{
        display: block;
        margin:auto;
    }
</style>
