<template>
    <div class="users">
        <Header />
        <div class="section-users">
            <div>
                <div>
                    <router-link to="/"><img src="../assets/img/logo1.png" alt=""></router-link>
                    <ul>
                        <li><router-link to="/users/base" active-class="active">账户设置</router-link></li>
                        <li><router-link to="/users/inv" active-class="active">发票管理</router-link></li>
                        <li><router-link to="/users/address" active-class="active">地址管理</router-link></li>
                        <div v-if="shopShow">
                            <li v-if="shop"><router-link to="/users/shop/shop_base" active-class="active">申请免费开店</router-link></li>
                            <li v-else><a class="shop-a" @click="goShop">申请免费开店</a></li>
                        </div>
                    </ul>
                </div>
            </div>
            <div>
                <div>
                    <NavVertical :navData="navData" />
                </div>
                <router-view :pdata="rzData" @nav-change="navChange"></router-view>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import NavVertical from '@/components/NavVertical.vue'

export default {
    data:function(){
        return{
            rzData:{},
            shop:false,
            shopShow:false,
            navData:[{
                title:'个人资料',
                list:[{
                    title:'基本信息',
                    url:'/users/base/base_main',
                },{
                    title:'实名认证',
                    url:'/users/base/base_auth',
                }]
            },{
                title:'账户安全',
                list:[{
                    title:'安全级别',
                    url:'/users/account/account_safeGrade',
                },{
                    title:'修改登录密码',
                    url:'/users/account/account_Cpw',
                },{
                    title:'手机绑定',
                    url:'/users/account/account_bdph',
                },{
                    title:'邮箱绑定',
                    url:'/users/account/account_bdemail',
                }]
            }],
        }
    },
    computed:{
        userType(){
            return this.$store.state.userType
        }
    },
    watch:{
        userType(val){
            this.store(val);
        }
    },
    mounted:function(){
        this.store(this.userType);
    },
    methods:{
        store:function(val){
            //是否绑定安全邮箱
            this.shopShow = val.shopStatus!=0;
            if (val.safeEmail) {
                this.navData[1].list[3].url = "/users/account/account_bdemail/account_bdemail_Cemail1"
            };
            //是否绑定安全手机
            if (val.safePhone) {
                this.navData[1].list[2].url = "/users/account/account_bdph/account_bdph_Cph1"
            };
            /*//是否个人实名认证
            if (val.personlStatus||val.personlStatus===0) {
                this.navData[0].list[1].url = "/users/base/base_auth/base_auth_per"
            };
            //是否机构实名认证
            if (val.orgStatus||val.orgStatus===0) {
                this.navData[0].list[1].url = "/users/base/base_auth/base_auth_bus"
            };*/
            if (this.$store.state.user===1) {
                this.navData[0].list[1].url = "/users/base/base_auth/base_auth_per"
            }else{
                this.navData[0].list[1].url = "/users/base/base_auth/base_auth_bus"
            };
            if (val.shopStatus===0||val.orgStatus===0||val.personlStatus===0) {
                this.shop = true
            };
        },
        goShop:function(){
            this.$dialog.alert("请先进行实名认证",'confirm',()=>{
                var user = this.$store.state.user;
                if (user.userType===1) {
                    this.$router.push({ name:'base_auth_per' });
                }else{
                    this.$router.push({ name:'base_auth_bus' });
                };
            })
        },
        navChange:function(fn){
            fn(this)
        }
    },
    components: {
        Header,Footer,NavVertical
    }
}
</script>


<style scoped>
    body{
        margin: 0;
        padding: 0;
    }
    .users>ul,p{
        margin: 0;
        padding: 0;
        list-style: none;
    }
    .users>.section-users{
        width: 100%;
        min-width: 1200px;
        background: #f5f5f5;
        padding-bottom: 24px
    }
    .users>.section-users>div:nth-child(1){
        width: 100%;
        min-width: 1200px;
        height: 60px;
        background: #066CD4;
    }
    .users>.section-users>div:nth-child(1)>div{
        width: 1200px;
        margin: 0 auto;
        display: flex;
        position: relative;
    }
    .users>.section-users>div:nth-child(1)>div img{
        position: absolute;
        top: 9px
    }
    .users>.section-users>div:nth-child(1)>div>ul{
        width: 850px;
        display: flex;
        position: absolute;
        left: 210px;
        line-height: 60px;
    }
    .users>.section-users>div:nth-child(1)>div>ul li:hover,.section-users .active{
        background: rgba(0, 0, 0, .1);
    }
    .users>.section-users>div:nth-child(1)>div>ul li a{
        display: block;
        color: #f5f5f5;
        width: 100%;
        text-decoration: none;
        font-size: 16px;
        padding: 0 30px;
    }
    .users>.section-users>div:nth-child(2){
        width: 1200px;
        margin: 0 auto;
        margin-top: 17px;
        display: flex;
        justify-content: space-between;
    }
    .users>.section-users>div:nth-child(2)>div:nth-child(1){
        width:198px;height:622px;background:#fff;
    }
    .shop-a{
        cursor:pointer;
    }
</style>
