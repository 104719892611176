<template>
    <div class="no2">
        <formItem :disabled="disabled" zIndex="18" width="160" errorStyle="inline" title="服务商所在地区" :error="user.areaId.error" required type="twoSelect" v-model="user.areaId.value" />

        <formItem :disabled="disabled" width="160" :error="user.categoryId.error" errorStyle="inline" title="认证项目" required type="threeSelectCategory" v-model="user.categoryId.value" :defaultList="category" />

        <formItem :disabled="disabled" width="160" errorStyle="inline" :error="user.serviceType.error" title="服务类型" required type="radio" :radioList="serviceList" v-model="user.serviceType.value" />

        <formItem :disabled="disabled" width="160" maxlength="50" errorStyle="inline" title="需求标题" required :error="user.title.error" type="text" v-model="user.title.value" placeholder="请填写需求标题" />

        <formItem :disabled="disabled" width="160" zIndex="15" errorStyle="inline" title="所属行业" required :error="user.industry.error" type="select" v-model="user.industry.value" placeholder="请选择所属行业" :selectList="user.industry" @on-focus="(fn)=>$com.findCode('industry',(res)=>{fn(res)})" />

        <formItem :disabled="disabled" width="160" errorStyle="inline" title="企业规模" required :error="user.scale.error" type="select" v-model="user.scale.value" placeholder="请选择企业规模" :selectList="user.scale" @on-focus="(fn)=>$com.findCode('scale',(res)=>{fn(res)})" />

        <formItem :disabled="disabled" width="160" maxlength="9" errorStyle="inline" title="预算费用" required :error="user.cost.error" type="text" v-model="user.cost.value" placeholder="请填写预算费用" inputType="money" />

        <formItem :disabled="disabled" width="160" maxlength="25" errorStyle="inline" title="联系人" required :error="user.contact.error" type="text" v-model="user.contact.value" placeholder="请填写联系人" />

        <formItem :disabled="disabled" width="160" maxlength="25" errorStyle="inline" title="联系电话" required :error="user.phone.error" type="text" v-model="user.phone.value" placeholder="请填写联系电话" />

        <formItem :disabled="disabled" width="160" maxlength="25" errorStyle="inline" title="截止日期" type="datetime" v-model="user.deadline.value" placeholder="请选择截止日期" />

        <formItem :disabled="disabled" width="160" maxlength="200" errorStyle="inline" title="需求描述" type="textarea" required :error="user.description.error" v-model="user.description.value" placeholder="请填写需求描述" />

        <ul>
            <li></li>
            <li class="form-btn"><xbutton :disabled="disabled" @on-click="goSubmit" value="提交后台审核"></xbutton></li>
        </ul>
    </div>
</template>


<script>
import formItem from '@/components/UI/formItem.vue'
import xbutton from '@/components/UI/xbutton.vue'
export default {
    data(){
        return{
            disabled:false,
            serviceList:[],
            industryList:[],
            scaleList:[],
            area:[],
            category:[],
            id:"",
            user:{
                title:{
                    value:"",
                    error:"",
                    validate:['required'],
                    name:"标题"
                },
                areaId:{
                    value:"",
                    error:"",
                    validate:['required'],
                    name:"地区"
                },
                categoryId:{
                    value:"",
                    error:"",
                    validate:['required'],
                    name:"认证项目"
                },
                industry:{
                    value:"",
                    label:"",
                    error:"",
                    validate:['required'],
                    name:"所属行业"
                },
                scale:{
                    value:"",
                    label:"",
                    error:"",
                    validate:['required'],
                    name:"企业规模"
                },
                cost:{
                    value:"",
                    error:"",
                    validate:['required','integer'],
                    name:"预算费用"
                },
                contact:{
                    value:"",
                    error:"",
                    validate:['required'],
                    name:"联系人"
                },
                phone:{
                    value:"",
                    error:"",
                    validate:['required'],
                    name:"联系电话"
                },
                serviceType:{
                    value:"",
                    error:"",
                    validate:['required'],
                    name:"服务类型"
                },
                deadline:{
                    value:"",
                },
                description:{
                    value:"",
                    error:"",
                    validate:['required'],
                    name:"描述"
                }
            },
        }
    },
    methods:{
        goSubmit:function(_this){
            var data = {}
            for(var key in this.user){
                data[key] = this.user[key].value
            }
            var validate = this.$com.validate(this.user)
            if (validate) {
                _this.loading = true;
                if (this.id) {
                    data.id = this.id;
                };
                this.$com.majax.post(this.$com.testCsIp + '/buyerDemand/saveOrUpdateBuyerDemand',this.$qs.stringify(data),{
                    success:(res)=>{
                        this.$dialog.alert("提交成功，请等待审核",'success',"",(_res)=>{
                            _res.thisShow = false;
                            this.$router.push({ path:'/buyer/demand/demand_list' })
                        })
                    }
                },_this)
            };
        },
        getXqfw:function(){
            this.$com.findCode('type_xqfw',(res)=>{
                this.serviceList = res
            })
        },
        getData:function(){
            var id = this.$route.query.id;
            if (id) {
                this.$parent.titleMeg = '修改需求';
                this.id = id;
                this.$com.majax.get(this.$com.testCsIp + '/demand/selectDemand',{demandId:id,demandInlet:0},{
                    success:(res)=>{
                        var area = res.data.area;
                        this.area = area.split("/");
                        this.category = this.$com.GetObjList(res.data,'category');
                        for(var key in this.user){
                            this.user[key].value = res.data[key]
                        }
                        this.user.scale.label = res.data.scaleName;
                        this.user.industry.label = res.data.industryName;
                        this.disabled = res.data.status=='1'||res.data.demandStatus=='2';
                    }
                })
            };
        }
    },
    components: {
        formItem,
        xbutton
    },
    mounted:function(){
        this.$parent.titleMeg = '发布需求';
        this.getXqfw();
        this.getData();
    },
}
</script>

<style scoped>
        div.no2{
            width:992px;min-height: 893px;
            background:#fff;
            position: relative;
            padding: 25px 20px 15px 20px;
            box-sizing: border-box
        }
        div.no2 ul{
            display: flex;
            margin-bottom: 20px;
        }
        div.no2 ul li:first-child span{
            color: #ff4a03
        }
        div.no2 ul li:first-child{
            line-height: 40px;
            width: 160px;text-align: right;
            margin-right: 7px;font-size: 14px;color: #333
        }
        select::-ms-expand{ display: none; }
        select{
            appearance:none;
            -moz-appearance:none;
            -webkit-appearance:none;
            background: url("../../../assets/img/buyer/kai11.png") no-repeat scroll transparent;
            background-position:95% center;
            padding-left: 10px;
            width: 180px;height: 40px;border: 1px solid #ccc;
            color: #999;margin-right: 10px;
            box-sizing: border-box
        }
        div.no2 ul li select:nth-child(4),div.no2 ul:nth-child(6) select,div.no2 ul:nth-child(5) select{
            width: 370px;
            background-position:98% center;
        }
        div.no2 ul:nth-child(3){
            line-height: 40px;
            padding-top:  5px
        }
        div.no2 ul:not(:nth-child(3)) li:last-child input{
            width: 370px;height: 40px;
            padding-left: 10px;box-sizing: border-box;
            border: 1px solid #ccc;color: #666;
        }
        ::-webkit-input-placeholder { color:#999; }
        ::-moz-placeholder { color:#999; } /* firefox 19+ */
        :-ms-input-placeholder { color:#999; } /* Internet Explorer 10+ */
        :-moz-placeholder { color:#999; }
        div.no2 ul:nth-child(3) li:nth-child(2)>div{
            position: relative;
            line-height: 40px;
            float: left;font-size: 14px;color: #666
        }
        div.no2 ul:nth-child(3) li:nth-child(2)>div{
            margin-right: 50px
        }
        div.no2 ul:nth-child(10) li:nth-child(2){
            position: relative;
        }
        div.no2 ul:nth-child(10) li:nth-child(2) img{
            position: absolute;
            top: 25px;right: 10px;
        }
        input[type="radio"] {
            width: 10px;
            height: 10px;
            opacity: 0;
        }
        label {
            position: absolute;
            left: 0;
            top: 14px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            border: 1px solid #999;
        }
        input:checked+label{
            background-color: #3296FA;
            border: 1px solid #006eb2;
        }
        input:checked+label::after {
            position: absolute;
            content: "";
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: #fff;
            top: 2px;
            left: 2px;
            border: 2px solid #fff;
        }
        div.no2 ul textarea{
            margin-top: 10px;border: 1px solid #ccc;color: #999;
            width: 370px;height: 140px;padding: 10px 0 0 10px;box-sizing: border-box
        }
        div.no2 ul a{
            display: block;width: 220px;height: 40px;background: #3296FA;color: #fff;
            font-size: 18px;line-height: 40px;text-align: center;text-decoration: none
        }
        div.no2 ul a:hover{
            background: linear-gradient(to right, #00aaff, #0085ff);
        }
        .form-btn{
            width:170px;
        }
</style>

