<template>
    <div class="no2">
        <xstep class="xstep" :list="$parent.selfStep" :active="3" />
        <div class="btn-wrap">
            <div class="notice">
                <i class="i-notice"></i>
                程序文件是固定的，不需要用户修改
            </div>
            <xbutton @on-click="goAdd" value="新增文件"></xbutton>
        </div>
        <xtable index :loading="loading" :search="search" :head="head" :tableData="tableData" btn="edit,del" @on-delClick="delClick" @on-editClick="editClick" @on-changePage="(v)=>getData(v)" @on-handleSelectionChange="(v)=>checkboxList=v" />
        <div class="bottom">
            <xbutton btnType="bg-fff" class="btn-back" @on-click="()=>$router.push({ name:'buy_self_step2',query:{autoServiceId:autoServiceId} })" value="返回"></xbutton>
            <xbutton class="btn-next" @on-click="goNext" value="下一步（制度文件）"></xbutton>
        </div>
    </div>
</template>

<script>
import xtable from '@/components/UI/xtable.vue'
import xbutton from '@/components/UI/xbutton.vue'
import xstep from '@/components/UI/xsteps.vue'
export default {
    data(){
        return{
            search:{},
            autoServiceId:this.$route.query.autoServiceId||"",
            tableData:{
                records:[{
                    fileName:"文件名称",
                    fileNum:"文件编号",
                    id:"123"
                }]
            },
            head:{
                fileName:"文件名称",
                fileNum:"文件编号",
            }
        }
    },
    methods:{
        goNext:function(_this){
            var data = {
                autoServiceId:this.autoServiceId,
                fillStatus:4
            }
            _this.loading = true;
            this.$com.majax.post(this.$com.testChIp + '/auto-service/updateAutoServiceFillStatus',this.$qs.stringify(data),{
                success:(res)=>{
                    this.$router.push({ path:'/buyer/selfhelp/self_step4',query:{autoServiceId:this.autoServiceId}})
                }
            },_this)
        },
        goAdd:function(){
            this.$router.push({name:'step3_details',query:{autoServiceId:this.autoServiceId}})
        },
        delClick:function(item){
            this.$dialog.confirm('是否确认删除'+item.fileName,'error',()=>{
                var data = {id:item.id}
                this.$com.majax.post(this.$com.testChIp + '/auto-prog/deleteAutoProg/',this.$qs.stringify(data),{
                    success:(res)=>{
                        this.getData();
                    }
                })
            })
        },
        editClick:function(item){
            this.$router.push({name:'step3_details',query:{id:item.id}})
        },
        getData:function(){

        }
    },
    components: {
        xbutton,
        xstep,
        xtable
    },
    mounted:function(){
        this.$parent.titleMeg = '自助认证-程序文件';
        this.$parent.rightLink = true;
        this.$router.beforeEach((to, from ,next) => {
            this.$parent.rightLink = false;
            next();
        });
        //this.getData();
    },
}
</script>

<style scoped>
.no2{
    width:992px;
    background:#fff;
    padding: 25px 20px 15px 20px;
}
.xstep{
    background:#f5f5f5;
}
.btn-wrap{
    clear:both;
    overflow:hidden;
    margin-bottom:10px;
}
.btn-wrap button{
    font-size:14px;
    width:80px;
    float:right;
    height:36px;
}
.notice{
    float:left;
    font-size:12px;
    color:#ff0000;
    line-height:36px;
}
.i-notice{
    background:url('../../../assets/img/buyer/notice.png');
    width:14px;
    height:12px;
    display:inline-block;
    margin-right:3px;
}
.bottom{
    clear:both;
    overflow:hidden;
    margin-top:20px;
}
.bottom button{
    font-size:14px;
    height:34px;
}
.btn-back{
    width:80px;
    float:left;
}
.btn-next{
    width:170px;
    float:right;
}
</style>

