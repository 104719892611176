import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import qs from 'qs'
import ElementUI from 'element-ui';
import BaiduMap from 'vue-baidu-map'
import Dialog from './components/UI/dialog.js'
import 'element-ui/lib/theme-chalk/index.css';
import common from './assets/js/common.js'
import MetaInfo from 'vue-meta-info'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueLazyload from 'vue-lazyload'
import 'swiper/dist/css/swiper.css'

import 'lib-flexible/flexible'

Vue.use(VueAwesomeSwiper)

Vue.use(VueLazyload,{
  loading:require("./assets/img/loading.jpg"),
  error:require("./assets/img/404.png"),
})

Vue.use(MetaInfo)

Vue.use(ElementUI);
Vue.config.productionTip = false

Vue.use(BaiduMap, {
  ak:'LYXwhlUgLtQjIKV0lleRPSXAdbuqRePW'
});

//请求头带cookie
axios.defaults.withCredentials=true

Vue.prototype.$com = common
Vue.prototype.$qs = qs
Vue.prototype.$axios = axios
Vue.prototype.$dialog = Dialog

const LOGIN_URL = ["/buyer","/shopping_cart","/users","/seller","/shopping_order"];


router.beforeEach((to, from ,next) => {
  var userType = store.state.userType,
  user = store.state.user;
  for (var i = 0; i < LOGIN_URL.length; i++) {
    if (to.matched[0].path === LOGIN_URL[i]) {
      //登录权限限制
      if (!store.state.tokenId) {
        Dialog.alert('请先登录','confirm',()=>{
            router.push({ path:'/login' })
        })
        return false
      };
      //卖家买家权限
      /*if(to.matched[0].path==='/buyer'){
        if(user.userType!='0'){
          Dialog.alert('你无权发布需求','confirm');
          return false;
        }
      }*/

      //实名权限限制
      if (LOGIN_URL[i] != "/users") {
        if (!userType) {
          Dialog.alert('系统繁忙，请稍后再试','confirm',"",(res)=>{
            res.thisShow = false;
            router.push({ path:'/login' })
          })
          return false;
        };
        if (userType.orgStatus!=0&&userType.personlStatus!=0) {
          Dialog.alert('请先进行实名认证','confirm',()=>{
              if (user.userType===1) {
                  router.push({ name:'base_auth_per' });
              }else{
                  router.push({ name:'base_auth_bus' });
              };
          })
          return false
        };
      };
    };
  };
  if (from.path==="/login"&&to.path==="/users/base/base_main") {
      store.commit('SET_userType',"");
  };
  next()
})


new Vue({
  router,
  store,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
