<template>
    <div class="no2">
        <formItem :disabled="disabled" maxlength="25" required title="证书名称" type="text" errorStyle="inline"  :error="qualificationName.error" :value="qualificationName.value"  v-model="qualificationName.value"  placeholder="请填写证书名称" />
        <formItem :disabled="disabled" maxlength="25" required title="证书编号" type="text" errorStyle="inline"  :error="certificateNo.error" :value="certificateNo.value"  v-model="certificateNo.value"  placeholder="请填写证书编号" />
        <formItem :disabled="disabled" maxlength="25" required title="发证单位" type="text" errorStyle="inline"  :error="authority.error" :value="authority.value" v-model="authority.value" placeholder="请填写发证单位" />
        <formItem :disabled="disabled" required title="获证日期" type="date" errorStyle="inline"  :error="certifiedTime.error" :value="certifiedTime.value" v-model="certifiedTime.value" />
        <ul class="date">
            <li>有效日期：</li>
            <li class="item-inp">
                <input :disabled="disabled" v-model="dateType" value="1" type="radio" name="date">
                <el-date-picker :disabled="disabled"
                    @change="dateChange"
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期" v-model="validTime">
                </el-date-picker>
            </li>
            <li class="item-inp-cq"><input :disabled="disabled" v-model="dateType" value='0' name="date" type="radio" ><span>长期</span> </li>
        </ul>
        <!-- <ul class="qua">
            <li>资质等级：</li>
            <li class="item-inp1">
                <input  value="1" type="radio" name="qua">
                <Xselect width="220" @on-change="typeChange" :list="typeList" :value="typevalue" />
            </li>
            <li class="item-inp-cq"><input value='0' name="qua" type="radio" ><span>无等级要求</span> </li>
        </ul> -->
        <!-- <ul class="qua">
            <li>资质等级：</li>
            <li>
                <Xselect width="220" @on-focus="(fn)=>fn(typeList)" v-model="level.value" :selectList="level" :list="typeList" :value="level.value" placeholder="请选择"/>
            </li>
        </ul>  -->

        <formItem :disabled="disabled" rwidth="220" @on-focus="(fn)=>fn(typeList)" v-model="level.value" :value="level.value" title="资质等级" type="select"  placeholder="请选择资质等级" :selectList="level" />
         <!-- <ul class="p">
            <li>上传证书图片：</li>
            <li class="item-inp">
                <div class="item-file-img">
                    <input title="点击上传图片" class="file" type="file">
                </div>
            </li>
        </ul>  -->
        <formItem :disabled="disabled" required errorStyle="inline" type="file" :error="imgId.error" :imgObg="imgObg" title="资质图片" v-model="imgId.value" />

        <formItem :disabled="disabled" title="备注说明" type="textarea"  placeholder="请输入备注说明" v-model="remark"/>
        <ul>
            <li>
                <xbutton :disabled="disabled" @on-click="goAdd" value="保存并提交后台审核"></xbutton>
            </li>
        </ul>
    </div>
</template>

<script>
import formItem from '@/components/UI/formItem.vue'
import Xselect from '@/components/UI/select.vue'
import xbutton from '@/components/UI/xbutton.vue'
import imgBg from '../../../assets/img/seller/file.jpg'
export default {
    data(){
        return{
            typeList:[{id:'无等级要求',name:'无等级要求'},{id:'壹级资质',name:'壹级资质'},{id:'贰级资质',name:'贰级资质'}],
            dateType:'',
            qualificationName:{
                value:"",
                error:"",
                validate:['required'],
                name:"证书名称"
            },
            certificateNo:{
                value:"",
                error:"",
                validate:['required'],
                name:"证书编号"
            },
            authority:{
                value:"",
                error:"",
                validate:['required'],
                name:"发证单位"
            },
            certifiedTime:{
                value:"",
                error:"",
                validate:['required'],
                name:"获证日期"
            },
            imgId:{
                value:"",
                error:"",
                validate:['required'],
                name:"资质图片"
            },
            remark:'',
            QId:this.$route.query.QId,
            level:{
                value:"",
                babel:"",
                name:"资质等级"
            },
            validTime:'',
            imgObg:{
                width:'140px',
                 height:'140px',
                img:imgBg,
             },
             disabled:false
        }
    },
    methods:{
        typeChange(val){
            this.level=val.id
        },
        goAdd(_this){
            var data = {
                authority:this.authority.value,
                certificateNo:this.certificateNo.value,
                certifiedTime:this.certifiedTime.value,
                id:this.QId,
                level:this.level.value,
                qualificationName:this.qualificationName.value,
                remark:this.remark,
                validTime:this.validTime,
                imgId:this.imgId.value.fileId
            }
            var dataV = {
                authority:this.authority,
                certificateNo:this.certificateNo,
                certifiedTime:this.certifiedTime,
                qualificationName:this.qualificationName,
                imgId:this.imgId
            }
            if (this.dateType=='0') {
                data.validTime = 0
            };
            var validate = this.$com.validate(dataV);
            if(validate){
                _this.loading = true;
                this.$com.majax.post(this.$com.testCwIp + '/sellerQualification/saveOrUpdateQualification',this.$qs.stringify(data),{
                    success:(res)=>{
                        _this.loading = false;
                        if(res.code==0){
                            this.$dialog.alert('提交成功，请等待审核！','success',()=>{
                                this.$router.push({ name:'qua_man' })
                            })
                        }
                    }
                },_this)
            }
        },
        dateChange:function(){
            this.dateType = "1"
        }
    },
    created(){
        var id = this.QId ;
        if(this.QId !== undefined){
            this.$parent.titleMeg = '修改资质';
            this.$com.majax.get(this.$com.testCsIp + '/sellerQualification/findQualificationById/' + id,{},{
                success:(res)=>{
                    this.disabled = (res.data.status===1);
                    this.authority.value=res.data.authority;
                    this.certificateNo.value=res.data.certificateNo;
                    this.certifiedTime.value=res.data.certifiedTime;
                    this.remark=res.data.remark;
                    this.qualificationName.value=res.data.qualificationName;
                    this.level.value=res.data.level;
                    this.level.babel=res.data.level;
                    this.validTime=res.data.validTime;
                    if (res.data.validTime == 0) {
                        this.validTime = "";
                        this.dateType = "0"
                    }else{
                        this.dateType = "1"
                    }
                    if(res.data.validTime == ""){
                        this.validTime = "";
                        this.dateType = ""
                    }
                    this.imgId.value={imgUrl:res.data.imagePath,fileId:res.data.imgId}
                },
            })
        }else{
            this.CId='';
            this.$parent.titleMeg = '新增资质';
        }
    },
    components: {
        formItem,Xselect,xbutton
    },
    mounted:function(){
        
    },
}
</script>

<style scoped>
    div.no2{
        width:992px;padding-bottom: 20px;
        min-height:520px;
        background:#fff;
        position: relative;
        padding-top: 30px;
        padding-left: 80px
    }
    .no2>ul{
        display: flex;
        font-size: 14px;margin-bottom: 20px
    }
    .no2>ul.date,ul.qua{
        margin-left: 30px;
        line-height: 40px
    }
    ul.p{
        line-height: 40px
    }
    .p li:first-child,.qua li:first-child{
        margin-right: 10px
    }
    .no2>ul.date .item-inp{
        margin-right: 20px;
    }
    .no2>ul.qua .item-inp1{
        display: flex;
        margin-right: 10px;
    }
    .item-inp1 input{
        margin-top: 15px
    }
    input[type=radio]{
        vertical-align: sub
    }
    .item-file-img{
        width:244px;
        height:154px;
        position:relative;
        background:url('../../../assets/img/user/license.jpg');
    }
    .no2 .item-file-img .file{
        position: absolute;
        width:100%;
        height:100%;
        cursor:pointer;
        opacity:0;
        text-indent:-100px;
    }
    .item-file-img img{
        height:100%;
        width:100%;
    }
    .no2>ul:nth-child(8){
        margin-top: 20px
    }
    button{
        width: 200px;height: 40px;
        background: #3296fa;color: #fff;
        font-size: 18px;margin-left: 105px;
        border: 0
    }
    button:hover{
        cursor: pointer;
        background: linear-gradient(to right, #00aaff, #0085ff);
    }
</style>
