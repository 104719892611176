<template>
    <div class="content">
        <xtable fixed :loading="loading" index :head="head" :tableData="tableData" btn="download" @on-downloadClick="downloadClick" />
        <div class="submit-btn-wrap">
            <xbutton :disabled="disabled" class="submit-btn" @on-click="SubmitOrder" value="凭证下载完成"></xbutton>
        </div>
    </div>
</template>

<script>
import xbutton from '@/components/UI/xbutton.vue'
import xtable from '@/components/UI/xtable.vue'
export default {
    data(){
        return{
            id:this.$route.query.id,
            loading:false,
            tableData:{
                records:[{type:"咨询资料",name:""},{type:"证书图片",IMGurl:""},{type:"发票图片",IMGurl:""}]
            },
            head:{
                type:"凭证类型",
                name:"文件名称",
            },
            disabled:true,
        }
    },
    methods:{
        GetData:function(){
            this.$axios.get(this.$com.testCsIp + '/voucherOrder/getVoucherDetailsByOrderId/' + this.id)
            .then(res=>{
                var data = res.data.data
                if (data) {
                    this.tableData.records[0].name = this.substr(data.consultFilePath);
                    this.tableData.records[1].IMGurl = data.certificateFilePath;
                    this.tableData.records[2].IMGurl = data.invoceFilePath;
                    if (data.status=='6'||data.status=='7') {
                        this.disabled = true;
                    }else{
                        this.disabled = false;
                    };
                }else{
                    this.$message.warning('服务商未上传凭证');
                    this.disabled = true;
                    setTimeout(()=>{
                        this.$router.go(-1);
                    },'1000')
                };
            })
        },
        downloadIamge:function(imgsrc, name) {//下载图片地址和图片名
            let image = new Image();
            // 解决跨域 Canvas 污染问题
            image.setAttribute("crossOrigin", "anonymous");
            image.onload = function() {
            let canvas = document.createElement("canvas");
            canvas.width = image.width;
            canvas.height = image.height;
            let context = canvas.getContext("2d");
            context.drawImage(image, 0, 0, image.width, image.height);
            let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
            let a = document.createElement("a"); // 生成一个a元素
            let event = new MouseEvent("click"); // 创建一个单击事件
            a.download = name || "photo"; // 设置图片名称
            a.href = url; // 将生成的URL设置为a.href属性
            a.dispatchEvent(event); // 触发a的单击事件
          };
          image.src = imgsrc;
        },
        substr:function(src){
            return src.substring(src.lastIndexOf('/')+1,src.length);
        },
        downloadClick:function(item){
            var name = "";
            if (item.type === "咨询资料") {
                window.location.href = this.$com.testInin+'/upload/download?fileName=' + item.name
            }else{
                name = item.IMGurl.substring(item.IMGurl.lastIndexOf('/')+1,item.IMGurl.length)
            };
            this.downloadIamge(this.$com.testInin+'/upload/download?fileName=' + name,name)
        },
        SubmitOrder:function(){
            this.$dialog.confirm('确认后交易完成，请确认下载资料无误','error',()=>{
                var data = {orderId:this.id}
                this.$com.majax.post(this.$com.testCsIp+'/voucherOrder/confirmCompletionService',this.$qs.stringify(data),{
                    success:(res)=>{
                        this.$dialog.alert("提交成功",'success',"",(res)=>{
                            res.thisShow = false;
                            this.$router.push({ name:'buy_order_list' })
                        })
                    }
                })
            })
        }
    },
    components: {
        xbutton,
        xtable
    },
    mounted(){
        this.$parent.titleMeg = '下载凭证';
        this.GetData();
    }
}
</script>


<style scoped>
.content{
    background:#fff;
    padding:30px 20px;
}
.submit-btn-wrap{
    text-align:center;
    margin-top:20px;
}
.submit-btn{
    width:180px;
}
</style>
