<template>
    <div class="no">
        <xeditor v-model="editorContent" class="editor" />
        <div class='xb'>
            <xbutton @on-click="goAdd" value="保 存"></xbutton>
        </div>
    </div>
</template>

<script>
import xeditor from '@/components/UI/xeditor.vue'
import xbutton from '@/components/UI/xbutton.vue'
import E from 'wangeditor'
export default {
    data () {
        return {
            editorContent:"",
        }
    },
    components: {
        xeditor,xbutton
    },
    methods:{
        goAdd(_this){
            var data = {
                remark:this.editorContent
            }
            _this.loading = true;
            this.$com.majax.post(this.$com.testUsIp + '/category/saveOrUpdateRemark',this.$qs.stringify(data),{
                success:(res)=>{
                    _this.loading = false;
                    if(res.code === 0){
                        this.$message.success("保存成功！")
                        this.goData()
                    }
                },
                error:(res)=>{
                    if (res.code === 401) {
                        this.$message.success("保存成功！")
                        this.goData()
                    }else{
                        this.$message.error(res.msg);
                    };
                }
            },_this)
        },
        goData(){
            this.$com.majax.get(this.$com.testUsIp + '/category/getShopRemark',"",{
                success:(res)=>{
                    if(res.data != null){
                        this.editorContent = res.data;
                    }
                }
            })
        }
    },
    mounted() {
        this.$parent.titleMeg = '店铺简介';
        this.goData()
    },
}
</script>


<style scoped>
    .no{
        width: 992px;
        min-height: 520px;
        background: #fff;
    }
    .xb{
        width:140px;margin: 0 auto
    }
    .editor{
        padding: 20px
    }
    .requ{
        font-size: 14px;color: red;
        margin-left: 20px
    }
</style>
