<template>
    <div class="no2">
        <div>
             <div><div></div>服务方信息</div>
             <div>
                <ul>
                    <li>名称：</li>
                    <li>重庆启翔咨询管理有限公司</li>
                </ul>
                <ul>
                    <li>联系人：</li>
                    <li>甘士光</li>
                </ul>
                <ul>
                    <li>服务机构地址：</li>
                    <li>重庆市渝北区汽博中心123号</li>
                </ul>
                <ul>
                    <li>联系电话：</li>
                    <li>123456789789</li>
                </ul>
             </div>
        </div>
        <div>
            <div><div></div>委托人信息</div>
            <div>
                <ul>
                    <li>委托方名称：</li>
                    <li>重庆金渝建筑工程有限公司</li>
                </ul>
                <ul>
                    <li>联系人：</li>
                    <li>州小小</li>
                </ul>
                <ul>
                    <li>委托方地址：</li>
                    <li>重庆市渝北区汽博中心线外城市花园89号6幢10楼</li>
                </ul>
                <ul>
                    <li>联系电话：</li>
                    <li>11365564646</li>
                </ul>
            </div>
        </div>
        <div>
            <div><div></div>服务协议信息</div>
            <ul>
                <li>协议价格：</li>
                <li style="color:#ff4a03;font-weight:bold">￥50000.00</li>
            </ul>
            <ul>
                <li>协议内容：</li>
                <li style="width:800px">重庆市渝北区汽博中心线外城市花园89号6幢10楼重庆市渝北区汽博中心线外城市花园89号6幢10楼
                    重庆市渝北区汽博中心线外城市花园89号6幢10楼重庆市渝北区汽博中心线外城市花园89号6幢10楼
                </li>
            </ul>
            <ul>
                <li></li>
                <li><span>审核通过</span><span>审核驳回</span> </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    mounted(){
        this.$parent.titleMeg = '确认委托单';
    }
}
</script>


<style scoped>
    div.no2{
        width:992px;min-height: 522px;
        background:#fff;
        position: relative;
    }
    div.no2>div{
        padding-left:42px;padding-right: 57px;
        box-sizing: border-box ;font-size: 14px;
    }
    div.no2>div>div:first-child{
        padding-top: 20px;
        display: flex;line-height: 30px;
        border-bottom: 2px dotted #ccc;
        font-weight: bold;margin-bottom: 10px
    }
    div.no2>div>div:first-child>div{
        width: 3px;height:14px ;
        background: #ff4a03;margin-top: 8px;
        margin-right: 9px
    }
    div.no2>div div:not(:first-child){
        display: flex;flex-wrap: wrap
    }
    div.no2>div ul{
        display: flex;
        line-height: 30px;
    }
    div.no2>div ul li:first-child{
        width: 100px;text-align: right;
        color: #666
    }
    div.no2>div ul li:last-child{
        width: 340px;color: #333
    }
    div.no2>div:nth-child(3) ul:last-child li:nth-child(2){
        display: flex
    }
    div.no2>div:nth-child(3) ul span{
        display: block;width: 180px;height: 40px;
        background: #3296FA;color: #fff;font-size: 18px;
        text-align: center;line-height: 40px;
        margin-right: 10px;margin-top: 30px
    }
    div.no2>div:nth-child(3) ul span:last-child{
        background: #fff;border: 1px solid #3296fa;
        color: #3296FA
    }
    div.no2>div:nth-child(3) ul span:hover{
        cursor: pointer;
        background: linear-gradient(to right, #00aaff, #0085ff);
    }
    div.no2>div:nth-child(3) ul span:last-child:hover{
        background: #3296fa;color: #fff
    }
    div.no2>div:nth-child(3) ul:nth-child(5),ul:nth-child(6){
        line-height: 40px
    }
</style>
