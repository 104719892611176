<template>
    <div>
        <Header />
        <ShopHeader :serviceNum="serviceNum" :shopName="shopName" />
        <div class="shop">
            <ul>
                <li><router-link :to="{path:'/shop_management/shop_man_index',query:{ ShopId:ShopId }}" active-class="active" target="_blank">首页</router-link></li>
                <li><router-link :to="{path:'/shop_management/shop_service_list',query:{ ShopId:ShopId }}" active-class="active" target="_blank">服务列表</router-link></li>
                <li><router-link :to="{path:'/shop_management/shop_qua_cer',query:{ ShopId:ShopId }}" active-class="active" target="_blank">资质证书</router-link></li>
                <li><router-link :to="{path:'/shop_management/shop_service_case',query:{ ShopId:ShopId }}" active-class="active" target="_blank">服务案例</router-link></li>
                <li><router-link :to="{path:'/shop_management/shop_introduction',query:{ ShopId:ShopId }}" active-class="active" target="_blank">店铺简介</router-link></li>
            </ul>
        </div>
        <router-view :id="ShopId"></router-view>
        <IndexFooter_home />
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import ShopHeader from '@/components/ShopHeader.vue'
import IndexFooter_home from '@/components/IndexFooter_home.vue'
export default {
    components: {
        ShopHeader,IndexFooter_home,Header
    },
    data(){
        return{
            id:this.$route.query.id,
            ShopId:this.$route.query.ShopId,
            shopName:'',
            serviceNum:'',
        }
    },
    methods:{
        goData(){
            this.$axios.get(this.$com.testCsIp + '/sellerServices/getGoodsDetailsById/' + this.id)
            .then(res=>{
                this.ShopId=res.data.data.shopId;
                this.serviceNum=res.data.data.serviceNum;
                this.shopName=res.data.data.shopName;
            })
        },
        getData(){
            this.$axios.get(this.$com.testCwIp + '/shop/getShopDetails/' + this.ShopId)
            .then(res=>{
                this.serviceNum=res.data.data.serviceNum;
                this.shopName=res.data.data.shopName;
            })
        }
    },
    mounted(){
        if(this.$route.query.ShopId != null){
            this.getData()
        }
        if(this.id !=null || undefined){
            this.goData()
        }    
    }
}
</script>

<style scoped>
    .shop{
        width: 100%;
        min-width: 1200px;
        height: 40px;
        background: #066CD4;
    }
    ul{
        width: 1200px;
        margin: 0 auto;
        line-height: 40px;
    }
    ul li{
        float: left;
    }
    ul li:hover,.active{
        background: rgba(0, 0, 0, .1);
    }
    ul li a{
        display: block;
        color: #f5f5f5;
        width: 100%;
        text-decoration: none;
        font-size: 16px;
        padding: 0 30px;
    }
    /*.sett{
        width: 100%;min-width: 1200px;padding: 20px 0;
        min-height: 500px;background: #f5f5f5;
    }*/
</style>

