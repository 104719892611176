<template>
        <div class="section-password">
        <div>
            <div>
                <div>
                </div>
                <div>
                    <span>1</span>
                </div>
                <span>验证身份</span>
            </div>
            <div>
                <div>
                </div>
                <div>
                    <span>2</span>
                </div>
                <span>设置密码</span>
            </div>
            <div>
                <div>
                </div>
                <div>
                    <span>3</span>
                </div>
                <span>设置成功</span>
            </div>
            <div class="content">
                <formItem title="新密码" required :error="newPw.error" v-model="newPw.value" type="password" errorStyle="inline" placeholder="请输入新密码，（6-16位，由数字和字母组成）" />
                <formItem title="确认新密码" required :error="newPwA.error" v-model="newPwA.value" type="password" errorStyle="inline" placeholder="请输入新密码，（6-16位，由数字和字母组成）" />
                <ul>
                    <li>
                        <xbutton Class="content-btn" @on-click="goNext" value="提交" />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import formItem from '@/components/UI/formItem.vue'
import xbutton from '@/components/UI/xbutton.vue'
export default {
    data(){
        return {
            newPw:{
                value:"",
                error:"",
                validate:['required','password'],
                min:6,
                max:16,
                name:"密码"
            },
            newPwA:{
                value:"",
                error:"",
                validate:['required','password'],
                min:6,
                max:16,
                name:"密码"
            },
            params:{}
        }
    },
    components: {
        formItem,
        xbutton
    },
    computed: {

    },
    mounted:function(){
        this.params = this.$route.params
    },
    methods:{
        goNext:function(_this){
            var dataV = {
                newPw:this.newPw,
                newPwA:this.newPwA
            }
            var data = {
                newPw:this.newPw.value,
                phoneOrEmail:this.params.phoneOrEmail,
                vcode:this.params.vcode,
                step:'2'
            }

            var validate = this.$com.validate(dataV)
            if (this.newPw.value!=this.newPwA.value) {
                this.newPwA.error="密码不一致";
                return false;
            };
            if (validate) {
                _this.loading = true;
                if (this.newPw.value === this.newPwA.value) {
                    this.$axios.post(this.$com.testUsIp+'/security/forgetPassword', this.$qs.stringify(data))
                    .then((response) => {
                        _this.loading = false;
                        var data = response.data;
                        if (data.code === 0) {
                            this.$router.push({ path: '/pwthree' })
                        }else{
                            this.$dialog.alert(data.msg,'error')
                        };
                    })
                    .catch((error) => {
                        _this.loading = false;
                        this.$dialog.alert('系统错误','error')
                    })
                }else{
                    this.newPwA.error = "密码不一致"
                };
            };
        }
    }
}
</script>

<style scoped>

body,ul{
    list-style: none;
    margin: 0;
    padding: 0;
}
.section-password{
    width: 100%;
    min-width: 1200px;
    height: 547px;
    background-color: #f5f5f5;
    padding-top: 20px
}
.section-password>div{
    width: 1200px;
    margin: 0 auto;
    background-color: #fff;
    height: 507px;
    position: relative;
}
.section-password>div>div>div:nth-child(1){
    width: 267px;
    border: 1px solid #ff4a03;
    position: absolute;
    top: 81px;
    left: 200px
}
.section-password>div>div>div:nth-child(2){
    width: 30px;
    height: 30px;
    background-color: #ff4a03;
    transform:rotate(45deg);
    position: absolute;
    top: 67px;
    left: 318px;
    text-align: center;
    line-height: 30px;
}
.section-password>div>div>div:nth-child(2) span{
    display: block;
    color: #fff;
    transform: rotate(-45deg)
}
.section-password>div>div:nth-child(1)>span,.section-password>div>div:nth-child(2)>span{
    color: #ff4a03;
    font-size: 16px;
    position: absolute;
    top: 125px;
    left: 302px
}
.section-password>div>div:nth-child(2)>span,.section-password>div>div:nth-child(3)>span{
    position: absolute;
    top: 125px;
    left: 567px;
}
.section-password>div>div:nth-child(3)>span{
    color: #333;
    font-size: 16px;
    position: absolute;
    left: 835px;
}
.section-password>div>div:nth-child(2)>div:nth-child(1){
    position: absolute;
    left: 466px;
}
.section-password>div>div:nth-child(2)>div:nth-child(2){
    position: absolute;
    left: 584px;
}
.section-password>div>div:nth-child(3)>div:nth-child(1){
    position: absolute;
    left:733px ;
    border: 1px solid #ccc;
}
.section-password>div>div:nth-child(3)>div:nth-child(2){
    background-color: #ccc;
    position: absolute;
    left: 852px;
}
.section-password ul{
    display: flex;
    font-size: 14px;
    color: #333;
    margin-bottom: 20px;
}
.section-password ul span{
    color: red
}
.section-password ul:nth-child(4){
    position: absolute;
    top: 210px;
    left: 326px;
    line-height: 40px;
}
.section-password ul:nth-child(4) li:nth-child(2) input{
    border: 1px solid #ccc;
    width: 416px;
    height: 40px;
    padding-left: 10px;
    box-sizing: border-box
}
.section-password ul:nth-child(5){
    position: absolute;
    top: 270px;
    left: 298px;
    line-height: 40px;
}
.section-password ul:nth-child(5) li:nth-child(2) input{
    border: 1px solid #ccc;
    width: 416px;
    height: 40px;
    padding-left: 10px;
    box-sizing: border-box
}
.section-password ul:nth-child(6) li button{
    width: 240px;
    height: 40px;
    position: absolute;
    top: 330px;
    left: 398px;
    border: 0;
    background-color: #3296fa;
    color: #fff;
    font-size: 18px;
}
.section-password ul:nth-child(6) li button:hover{
    background: linear-gradient(to right, #00aaff, #0085ff);
    cursor: pointer;
}
.inp-label{
        width:100px;
        margin-right:7px;
        text-align:right;
        line-height:40px;
    }
    .code-inp{
        padding-left:10px;
        border: 1px solid #ccc;
        font-size: 14px;
        padding-left: 10px;
        height: 40px;
        line-height: 40px;
    }
    .content-btn{
        margin-left:107px;
        width: 240px;
        height: 40px;
        border: 0;
        background-color: #3296fa;
        color: #fff;
        font-size: 18px;
        cursor: pointer;
    }
    .content-btn:hover{
        background: linear-gradient(to right, #00aaff, #0085ff);

    }
    .content{
        padding-top:200px;
        width:540px;
        margin:auto;
    }
    .error{
        color:red;
    }
</style>
