<template>
    <div class="index">
        <div class="main01" >
            <router-link to="/"><img src="../assets/img/index/logo.png" alt=""></router-link>
            <div></div>
            <img  src="../assets/img/index/sloganicon.png" alt="">
            <Search />
        </div>
    </div>
</template>


<script>
import Search from '@/components/Search.vue'
export default {
    methods:{

    },
    components: {
        Search
    },
}
</script>


<style scoped>
    @import '../assets/css/bootstrap.css';
    @import '../assets/css/header.css';
</style>

