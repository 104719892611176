<template>
    <router-view></router-view>
</template>

<script>
export default {
    props: {
        navData:Array
    },
    data(){
        return {
            formShow:true
        }
    },
    components: {

    },
    methods:{
        goAuth:function(type){
            this.$router.push({ name:type })
        }
    },
    mounted:function(){
        this.formShow = true
    },
}
</script>

<style scoped>

body{
    list-style: none;
    margin: 0;
    padding: 0;
}
div.no1{
    width:992px;height:39px;background:#fff;
    margin-bottom: 2px;
}
div.no1>span{
    display: block;
    font-size: 16px;
    line-height: 37px;
    text-align: center;
    width: 120px;
    border-bottom: 2px solid #FF4A03;
}
div.no2{
    width:992px;
    background:#fff;
    position: relative;
}
div.no2>span{
    position: absolute;
    top: 30px;
    left: 435px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    line-height: 44px;
    color: #435e92;
    width: 120px;
    border-bottom: 2px solid #435e92;
}
div.no2>span:nth-child(4){
    position: absolute;
    top: 316px;
}
div.no2>div{
    width: 400px;height: 120px;
    background-color: #f5f5f5;
    border-radius: 5px;
    position: absolute;
    top: 96px;
    left: 75px;
    position: relative;
}
div.no2>div>div:nth-child(1){
    position: absolute;
    top: 31px;
    left: 40px;
    width: 58px;
    height: 58px;
}
div.no2>div:nth-child(2)>div:nth-child(1){
    background-image: url("../../../assets/img/user/31.png")
}
div.no2>div:nth-child(2):hover  div:nth-child(1){
    background-image: url("../../../assets/img/user/34.png")
}
div.no2>div:nth-child(3)>div:nth-child(1){
    background-image: url("../../../assets/img/user/hk_m_t_3997d31.png")
}
div.no2>div:nth-child(3):hover  div:nth-child(1){
    background: url("../../../assets/img/user/32.png") no-repeat
}
div.no2>div:nth-child(5)>div:nth-child(1){
    background-image: url("../../../assets/img/user/mainland_card_8ce58a4.png")
}
div.no2>div:nth-child(5):hover  div:nth-child(1){
    background: url("../../../assets/img/user/33.png") no-repeat;
}
div.no2>div:nth-child(6)>div:nth-child(1){
    background-image: url("../../../assets/img/user/mainland_face_e0f8829.png")
}
div.no2>div:nth-child(6):hover  div:nth-child(1){
    background: url("../../../assets/img/user/35.png") no-repeat
}
div.no2>div>div:nth-child(2)>span:nth-child(1){
    position: absolute;
    top: 33px;
    left: 112px;
    font-size: 18px;
    font-weight: bold;
}
div.no2>div>div:nth-child(2)>span:nth-child(2){
    position: absolute;
    top: 62px;
    left: 112px;
    font-size: 12px;
    width: 250px;
    color: #666
}
div.no2>div:nth-child(3){
    position: absolute;
    left: 515px;
}
div.no2>div:nth-child(5){
    position: absolute;
    top: 382px;
}
div.no2>div:nth-child(6){
    position: absolute;
    top: 382px;
    left: 515px;
}
div.no2>div:hover{
    background: #fa6e37;
    cursor: pointer;
    color: #fff
}
div.no2>div:hover div:nth-child(2)>span:nth-child(2){
    color: #fff;
}
</style>
