<template>
    <div class="no2">
        <xstep class="xstep" :list="$parent.selfStep" :active="2" />

        <div class="bottom">
            <xbutton btnType="bg-fff" class="btn-back" @on-click="()=>$router.push({ name:'buy_self_step1',query:{autoServiceId:autoServiceId} })" value="返回"></xbutton>
            <xbutton class="btn-next" @on-click="goNext" value="下一步（制度文件）"></xbutton>
        </div>
    </div>
</template>

<script>
import xbutton from '@/components/UI/xbutton.vue'
import xstep from '@/components/UI/xsteps.vue'
export default {
    data(){
        return{
            search:{},
            tableData:[],
            autoServiceId:this.$route.query.autoServiceId||"",
            head:{
                demandStatus:"文件名称",
                status:"文件编号",
            }
        }
    },
    methods:{
        goNext:function(_this){
            var data = {
                autoServiceId:this.autoServiceId,
                fillStatus:3
            }
            _this.loading = true;
            this.$com.majax.post(this.$com.testChIp + '/auto-service/updateAutoServiceFillStatus',this.$qs.stringify(data),{
                success:(res)=>{
                    this.$router.push({ path:'/buyer/selfhelp/self_step3',query:{autoServiceId:this.autoServiceId}})
                }
            },_this)
        }
    },
    components: {
        xbutton,
        xstep,
    },
    mounted:function(){
        this.$parent.titleMeg = '自助认证-质量管理手册';
        this.$parent.rightLink = true;
        this.$router.beforeEach((to, from ,next) => {
            this.$parent.rightLink = false;
            next();
        })
    },
}
</script>

<style scoped>
.no2{
    width:992px;
    background:#fff;
    padding: 25px 20px 15px 20px;
}
.xstep{
    background:#f5f5f5;
}
.btn-wrap{
    clear:both;
    overflow:hidden;
    margin-bottom:10px;
}
.btn-wrap button{
    font-size:14px;
    width:80px;
    float:right;
    height:36px;
}
.notice{
    float:left;
    font-size:12px;
    color:#ff0000;
    line-height:36px;
}
.i-notice{
    background:url('../../../assets/img/buyer/notice.png');
    width:14px;
    height:12px;
    display:inline-block;
    margin-right:3px;
}
.bottom{
    clear:both;
    overflow:hidden;
    margin-top:20px;
}
.bottom button{
    font-size:14px;
    height:34px;
}
.btn-back{
    width:80px;
    float:left;
}
.btn-next{
    width:170px;
    float:right;
}
</style>

