<template>
    <div class="no2">
        <div v-if="goodsList.length>0">
            <goodsItem v-for="(item,index) in goodsList" :key="index" :goodsData="item" delValue="取消收藏" @on-clickDel="ClickDel" @on-clickDetail="ClickDetail" />
        </div>
        <div v-else class="center">
            <img class="empty-img" src="../../../assets/img/user/empty_page.jpg" alt="">
        </div>
    </div>
</template>

<script>
import goodsItem from '@/components/UI/goodsItem.vue'
export default {
    mounted(){
        this.$parent.titleMeg = '我的收藏';
        this.GetData()
    },
    components: {
        goodsItem
    },
    data(){
        return {
            goodsList:[]
        }
    },
    methods:{
        GetData:function(){
            this.$com.majax.get(this.$com.testCsIp+'/sellerBuyerFavorite/list',"",{
                success:(res)=>{
                    this.goodsList = res.data;
                }
            },this)
        },
        ClickDel:function(item){
            this.$com.majax.post(this.$com.testCsIp+'/sellerBuyerFavorite/deleteBuyerFavorite/' + item.id,"",{
                success:(res)=>{
                    this.$message({
                        message: '取消收藏成功！',
                        type: 'success'
                    });
                    this.GetData();
                }
            },this)
        },
        ClickDetail:function(item){
            this.$router.push({path:'/shop_management/detail_page',query: {id: item.servicesId}})
        }
    }
}
</script>

<style scoped>
.no2{
    width:992px;min-height: 580px;
    background:#fff;
    position: relative;
    padding: 20px 15px 40px 15px;
    box-sizing: border-box;
}

</style>
