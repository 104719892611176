<template>
    <input @click="clickBtn" :disabled="thisDisable" class="code-btn" :class="{'code-btn-disable':thisDisable}" type="button" :value="content" />
</template>

<script type="text/javascript">
  export default {
    name: 'codeBtn',
    props: {
        disabled:{
            type:Boolean,
            default:false
        },
    },
    data () {
        return {
            thisSec:60,
            content:'发送验证码',
            thisDisable:this.disabled
        }
    },
    watch: {
        disabled (val) {
            this.setIntervalTime()
        },
    },
    mounted () {

    },
    methods: {
        clickBtn:function(){
            this.$emit('on-click')
        },
        setIntervalTime:function(){
            this.thisDisable = true;
            this.content = this.thisSec + 's后重新发送';
            var clock = setInterval(() => {
                this.thisSec--
                this.content = this.thisSec + 's后重新发送';
                if (this.thisSec === 0) {
                    clearInterval(clock);
                    this.content = '重新发送验证码';
                    this.thisDisable = false;
                    this.thisSec = 60;
                    this.$emit('on-type',this.disabled)
                };
            },1000)
        }
    }
  }
</script>

<style scoped>
.clickBtn-wrap{
    display:inline-block;
}
.code-btn{
    width: 130px;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-left: 5px;
    background-color: #fff;
    color: #333;
    padding-left: 0;
    cursor: pointer;
    padding:0;
}
.code-btn:hover{
    background: linear-gradient(to right, #00aaff, #0085ff);
    color: #fff;
}
.code-btn-disable,.code-btn-disable:hover{
    background:#ddd;
    cursor: not-allowed;
    color: #333;
}
</style>