<template>
    <div>
        <router-view :email="email"></router-view>
    </div>
</template>

<script>
export default {
    props: {
        navData:Array
    },
    data(){
        return {
            email:""
        }
    },
    components: {

    },
    methods:{

    },
    mounted:function(){
        this.$parent.titleMeg = '邮箱绑定';
        this.$com.majax.get(this.$com.testUsIp + '/security/getOldEmail',{},{
            success:(res)=>{
                if(res.data!='未绑定安全邮箱'){
                    this.email = res.data
                    this.$router.push({ name: 'account_bdemail_Cemail1' })
                }else{
                    this.$router.push({ name: 'account_bdemail_add' })
                }
            },
            error:()=>{}
        })
    },
}
</script>

<style scoped>

body{
    margin: 0;
    padding: 0;
}
div.no2{
    width:992px;height:580px;
    background:#fff;
    position: relative;
    padding-top: 15px;
}
div.no2 ul{
    display: flex;
    margin-left: 84px;
    background: #fff;
    line-height: 60px;
}
div.no2 ul>li:nth-child(1){
    width: 120px;
    text-align: right;
    font-size: 14px;
    color: #333;
}
div.no2 ul>li:nth-child(2) input{
    width: 375px;
    height: 40px;
    padding-left: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc
}
div.no2 ul:nth-child(2)>li:nth-child(2)>input{
    width: 240px;
}
div.no2 ul:nth-child(2)>li:nth-child(3)>input{
    width: 130px;
    height: 40px;
    margin-left: 5px;
    margin-top: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
}
div.no2 ul>li:nth-child(2) button{
    width: 240px;
    height: 40px;
    color: #fff;
    background: #3296FA;
    border: 0;
    font-size: 18px;
    margin-top: 20px;
}
div.no2 ul>li:nth-child(2) button:hover{
    background: linear-gradient(to right, #00aaff, #0085ff);
    cursor: pointer;
}
</style>
