<template>
  <div class="index" :style="{'margin-top':stymargin+'px'}">
    <IndexNewsNotice :activeTop="activeTops" />
    <Header />
    <div class="header_active" >
      <IndexTopBanner :list="AdtopList" />
      <IndexHeader />
      <div id="move">
        <IndexHeader id="move01" />
      </div>
      <div class="nav d-flex">
        <div>认证服务分类</div>
        <Nav_ul />
      </div>
      <div class="nav-item">
        <IndexBanner :list="BannerList" />
        <div>
          <IndexNavLeft />
          <div class="nav-right">
            <IndexNavRightTop />
            <IndexNews />
          </div>
        </div>
      </div>

      <IndexAdCenter :list="AdCenterList" effect="fade" />

      <div class="section">
        <IndexServer />
        <IndexServerBottom />
        <div id="div_ad">
          <IndexAdCenter :list="AdBottomList" effect="fade" />
        </div>
        <div class="d-flex justify-content-between">
          <IndexDemand />
          <IndexDemandRight />
        </div>
        <IndexShop />
      </div>
      <el-tooltip class="item" effect="dark" content="返回顶部" placement="left">
        <div id="toTop" @click="toTop"></div>
      </el-tooltip>
      <IndexFooter_home />
    </div>
  </div>
</template>

<script>
import Nav_ul from "@/components/Nav_ul.vue";
import Header from "@/components/Header.vue";
import IndexHeader from "@/components/IndexHeader.vue";
import IndexFooter_home from "@/components/IndexFooter_home.vue";
import IndexBanner from "@/components/index/components/index_banner";
import IndexNews from "@/components/index/components/index_news";
import IndexServer from "@/components/index/components/index_server";
import IndexDemand from "@/components/index/components/index_demand";
import IndexShop from "@/components/index/components/index_shop";
import IndexServerBottom from "@/components/index/components/index_server_bottom";
import IndexDemandRight from "@/components/index/components/index_demand_right";
import IndexNavLeft from "@/components/index/components/index_nav_left";
import IndexNavRightTop from "@/components/index/components/index_nav_right_top";
import IndexAdCenter from "@/components/index/components/index_ad_center.vue";
import IndexTopBanner from "@/components/index/components/index_top_banner.vue";
import IndexNewsNotice from "@/components/index/news/index_news_notice.vue";
export default {
  name: "home",
  data() {
    return {
      toTopShow: false,
      show: false,
      editor: "",
      BannerList: [],
      AdCenterList: [],
      AdBottomList: [],
      AdtopList: [],
      stymargin: 0,
      activeTops: -200
    };
  },
  components: {
    Header,
    IndexHeader,
    Nav_ul,
    IndexFooter_home,
    IndexBanner,
    IndexNews,
    IndexServer,
    IndexDemand,
    IndexShop,
    IndexServerBottom,
    IndexDemandRight,
    IndexNavLeft,
    IndexNavRightTop,
    IndexAdCenter,
    IndexTopBanner,
    IndexNewsNotice
  },
  methods: {
    /*page:function(){
            POBrowser.openWindowModeless('http://192.168.0.117:8092/backstageShowXls?autoFileId=4028800769b7b3920169b7b5c6c60001&userId=3692928369467558016946aeda6a0002&status=11','width=1200px;height=800px;')
        },*/
    toTop() {
      document.documentElement.scrollTop -= 150;
      if (document.documentElement.scrollTop > 10) {
        var timer = setTimeout(() => this.toTop(), 10);
      } else {
        clearTimeout(timer);
      }
    },
    MyOnscroll() {
      var scrollTop = document.documentElement.scrollTop,
        toTop = document.getElementById("toTop"),
        move = document.getElementById("move");

      if (scrollTop >= 600) {
        toTop.style.display = "block";
      } else {
        toTop.style.display = "none";
      }

      if (this.stymargin == 0) {
        if (scrollTop >= 100) {
          move.style.display = "block";
        } else {
          move.style.display = "none";
        }
      } else if (this.stymargin == 200) {
        if (scrollTop >= 300) {
          move.style.display = "block";
        } else {
          move.style.display = "none";
        }
      }
    },
    GetBannerData: function() {
      this.$axios.get(this.$com.testCsIp + "/ad/adinfos").then(res => {
        var data = res.data.data;
        if (data.length > 0) {
          var BannerList = [],
            AdCenterList = [],
            AdBottomList = [],
            AdtopList = [];
          for (var i = 0; i < data.length; i++) {
            if (data[i].adLocation == "1") {
              BannerList.push(data[i]);
            }
            if (data[i].adLocation == "2") {
              AdCenterList.push(data[i]);
            }
            if (data[i].adLocation == "3") {
              AdBottomList.push(data[i]);
            }
            if (data[i].adLocation == "4") {
              AdtopList.push(data[i]);
            }
          }
          this.BannerList = this.$com.Isort.down(BannerList, "sort");
          this.AdCenterList = this.$com.Isort.down(AdCenterList, "sort");
          this.AdBottomList = this.$com.Isort.down(AdBottomList, "sort");
          this.AdtopList = this.$com.Isort.down(AdtopList, "sort");
        }
      });
    }
  },
  mounted: function() {
    this.GetBannerData();
    window.onscroll = () => {
      if (this.$route.fullPath === "/") {
        this.MyOnscroll();
      }
    };
    setTimeout(() => {
      this.stymargin = 200;
      this.activeTops = 0;
      setTimeout(() => {
        this.stymargin = 0;
        this.activeTops = -200;
      }, 5000);
    }, 2000);
  }
};
</script>

<style scoped>
@import "../assets/css/bootstrap.css";
@import "../assets/css/section.css";
#dflex ul li a {
  width: 108px;
  margin-left: 8px;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.index {
  transition: margin-top 1s linear 1s;
  position: relative;
}
.header_active {
}
.index > .main01 div:nth-child(4) {
  height: 38px;
  left: 630px;
}
.index > .main01 div:nth-child(4) button {
  height: 36px;
  width: 78px;
}
.index > .main01 > button:nth-child(5) {
  height: 38px;
}
.index > .main01 > button:nth-child(5) {
  width: 130px;
}
.index > .main01 div:nth-child(4) img {
  top: 10px;
}
#toTop {
  width: 50px;
  height: 50px;
  position: fixed;
  display: none;
  right: 0;
  bottom: 200px;
  background: url("../assets/img/index/top.png") no-repeat 100%;
}
#toTop:hover {
  cursor: pointer;
  background: url("../assets/img/index/top1.png") no-repeat 100%;
}
#move {
  background: #fff;
  z-index: 100;
  width: 100%;
  position: fixed;
  top: 0;
  display: none;
  min-width: 1200px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
}
#move01 ul {
  display: none;
}
</style>
