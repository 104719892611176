<template>
    <div id="app">
        <router-view v-if="isRouterAlive"/>
    </div>
</template>
<script>

export default {
    // 配置title和meta数据
    metaInfo() {
        return{
            title: (this.$route.meta.title?this.$route.meta.title + '-':"") + '佰证通认证平台',
            meta: [
                {
                    name: 'keywords',
                    content: '佰证通,认证,平台'
                },
                {
                    name: 'description',
                    content: '佰证通认证平台'
                }
            ]
        }
    },
    components: {

    },
    provide(){
        return {
            reload: this.reload
        }
    },
    data(){
        return {
            isRouterAlive: true
        }
    },
    methods:{
        reload () {
            this.isRouterAlive = false
            this.$nextTick(function () {
                this.isRouterAlive = true
            })
        },

    },
    mounted:function(){
        // ===============================================================
        //=====================================================================
    }
}
</script>
<style>
    *{
        font-family:'微软雅黑';
    }
    body{
        min-width: 1200px;
    }
    a{
        font-size:14px;
    }
    input,textarea,button{
        outline:none !important;
    }
    input:focus,textarea:focus{
        box-shadow:0px 0px 1px #3296fa;
    }
    input::-webkit-input-placeholder,textarea::-webkit-input-placeholder {
        color: #c0c4cc;
    }
    input:-moz-placeholderr,textarea:-moz-placeholderr {
        color: #c0c4cc;
    }
    input::-moz-placeholder,textarea::-moz-placeholder {
        color: #c0c4cc;
    }
    input:-ms-input-placeholder,textarea:-ms-input-placeholder {
        color: #c0c4cc;
    }
    .el-dialog__header{
        background:#f5f5f5;
        border-radius:8px;
        padding:10px 20px 10px !important;
    }
    .el-dialog{
        border-radius:8px !important;
    }
    .el-dialog__headerbtn{
        top:16px !important;
        right:16px !important;
    }
    .el-dialog__body{
        padding:30px 20px 10px 20px !important;
    }
    .dialog-alert .el-dialog__body{
        height:60vh;
        overflow:auto;
    }
    .el-input__inner{
        border:1px solid #ccc !important;
        border-radius:0 !important;
    }
    .el-table th>.cell{
        font-weight:normal;
        color:#333;
    }
    .el-table th.is-leaf{
        background:#f5f5f5;
        position:relative;
        padding:6px 0;
    }
    .el-table th.is-leaf::before{
        content: '';
        position:absolute;
        width:2px;
        height:100%;
        background:#fff;
        top:0;
        right:0;
    }
    .el-table th.head-buycar::before{
        width:0;
    }
    .el-table th.head-buycar>.cell{
        font-weight:bold;
    }
    .el-table th.is-leaf:last-child::before{
        content: '';
        background:#f5f5f5;
    }
    .el-table td.is-center{
        padding:6px 0;
    }
    .el-table td{
        border-bottom:1px dotted #ccc !important;
    }
    .el-table__fixed-right::before, .el-table__fixed::before{
        height:0;
    }
    .el-table--enable-row-transition .el-table__body td{
        font-size:12px;
    }
    .el-date-editor.el-input, .el-date-editor.el-input__inner{
        width:195px !important;
    }
    .el-dialog__wrapper{
        position:fixed !important;
    }
    .center{
        text-align:center;
    }
    .body-item .el-radio__label{
        display:none;
    }

</style>
