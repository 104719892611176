<template>
    <div class="server">
        <p class="img"><img v-lazy="list.coveImagePath" alt="服务图片"></p>
        <ul>
            <li><router-link :to="{path:'/shop_management/detail_page',query: {id: list.serviceId }}" target="_blank" :title="list.serviceName">{{list.serviceName}}</router-link></li>
            <li><router-link :to="{ path:'/shop_management/shop_man_index',query:{ ShopId : list.shopId }}" target="_blank">{{list.shopName}}</router-link></li>
            <li v-if="list.priceType == 1">￥{{list.price}}</li>
            <li v-if="list.priceType == 2">￥{{list.priceMin}}-￥{{list.priceMax}}</li>
            <li v-if="list.priceType == 3">面议</li>
            <li class="d-flex justify-content-between">
                <span><img src="../../assets/img/index/wz.jpg" class="areaName-i" alt="">&nbsp;{{list.areaName}}</span>
                <span>已服务<span style="color:red">{{list.serviceNum}}</span>次</span>
            </li>
            <li>
                <router-link :to="{path:'/shop_management/detail_page',query: {id: list.serviceId }}" target="_blank">申请服务</router-link>
            </li>
        </ul>
    </div>
</template>

<script>

export default {
    props: {
        list:Object,
    },
    data(){
        return {

        }
    },
    mounted:function(){

    },
    methods:{

    },
}
</script>

<style scoped>
.img{
    width: 142px;
    height: 173px;
    float: left;
    border: 1px solid #f5f5f5;
    overflow:hidden;
    position: relative;
}
.img img{
    max-height:100%;
    max-width:100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.areaName-i{
    vertical-align:unset;
}
</style>
