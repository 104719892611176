<template>
    <div>
        <div class="no2">
            <ul>
                <li>
                    <img src="../../assets/img/user/tuoyuan1_03.jpg" alt="">
                    <span>{{ meg }}</span>
                </li>
                <li>
                    <router-link to="/">返回首页</router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        navData:Array
    },
    data(){
        return {
            meg:"恭喜您，绑定成功！"
        }
    },
    components: {

    },
    methods:{
        open:function(item){
            item.open = !item.open
        }
    },
    mounted:function(){
        this.meg = this.$route.params.meg;
        this.$parent.titleMeg = false;
    },
}
</script>

<style scoped>
    div.no1{
        width:992px;height:39px;background:#fff;
        margin-bottom: 2px;
    }
    div.no1>span{
        display: block;
        font-size: 16px;
        line-height: 37px;
        text-align: center;
        width: 120px;
        border-bottom: 2px solid #FF4A03;
    }
    div.no2{
        width:992px;height:580px;
        background:#fff;
        position: relative;
        box-sizing: border-box;
        position: relative;
    }
    div.no2>ul{
        position: absolute;
        top: 243px;
        left: 351px;
        font-size: 18px;
    }
    div.no2>ul>li:first-child img{
        vertical-align: text-bottom;margin-right:10px
    }
    div.no2>ul>li>a{
        font-size: 14px;
        margin-left: 35px;
        text-decoration: none;
        color: #3296fa;
    }
</style>
