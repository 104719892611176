<template>
    <div>
        <div class="no2">
            <ul>
                <li>
                    <span>咨询师姓名：</span>
                    <input type="text" placeholder="咨询师姓名" v-model="realName">
                </li>
                <li>
                    <span>审核状态：</span>
                    <Xselect class="x" @on-focus="(fn)=>fn(typeList)" v-model="status" :list="typeList" />
                </li>
                <li><input type="button" value="查 询" @click="goSearch"></li>
                <li><input type="button" value="新增咨询师" class="btn1" @click="goAdd"> </li>
            </ul>
            <xtable :loading="loading" :search="search" :head="head" :tableData="tableData" btn="edit,del" @on-delClick="delClick" @on-editClick="editClick" @on-changePage="(v)=>getData(v)" />
        </div>
    </div>
</template>

<script>
import Xselect from '@/components/UI/select.vue'
import xtable from '@/components/UI/xtable.vue'
export default {
    data(){
        return {
            head:{
                realName:"姓名",
                phone:"联系电话",
                idCard:"证件号码",
                workLife:"工作年限",
                status:"审核状态",
            },
            lists:[],
            cardType:'请选择',
            typeList:[{id:'',name:'全部'},{id:0,name:'审核通过'},{id:1,name:'待审核'},{id:2,name:'驳回'}],
            realName:'',
            status:'',
            tableData:{},
            loading:false,
            search:{},
        }
    },
    components: {
        Xselect,xtable
    },
    methods:{
        goAdd:function(){
            this.$router.push({ name:'new_counselor' })
        },
        editClick(item){
            this.$router.push({ name:'new_counselor',query: { QId:item.id } })
        },
        delClick(item){
            this.$dialog.confirm('是否确认删除'+ item.qualificationName,'error',()=>{
                 this.$com.majax.post(this.$com.testCsIp + '/sellerQualification/deleteQualificationById/' + item.id,{},{
                     success:(res)=>{
                         if(res.code==0){
                            this.getData()
                         }
                     }
                 })
            })
        },
        goSearch(){
            this.search = {
                realName:this.realName,
                status:this.status
            };
            this.getData(this.search);
        },
        getData:function(data){
            this.loading = true;
            this.$com.majax.get(this.$com.testCsIp + '/ucConsultant/getConsultantList',data,{
                success:(res)=>{
                    this.tableData = res.data;
                }
            },this)
        },
        typeChange:function(val){
            this.status = val.id
        },
    },
    mounted:function(){
        this.$parent.titleMeg = '咨询师列表';
        //this.getData()
    },
}
</script>

<style scoped>
    .x{
        width: 175px
    }
    div.no2{
        width:992px;padding-bottom: 20px;
        min-height:520px;
        background:#fff;
        position: relative;
        padding: 20px;
    }
    div.no2 ul{
        display: flex;
        background: #fff;
        line-height: 60px;
    }
    div.no2 ul span{
        font-size: 14px;
        color: #333
    }
    div.no2 ul li{
        display: flex;
        line-height: 40px;
        margin-top: 15px;
        margin-bottom: 10px
    }
    div.no2 ul>li:nth-child(2){
        margin-left: 72px;
    }
    div.no2 ul input[type=button]{
        width: 80px;
        height: 40px;border: 0;background: #3296FA;
        color: #fff;font-size: 16px;
        margin-left: 8px;
        cursor: pointer;border-radius: 4px
    }
    div.no2 ul input[type=button]:hover{
        background: linear-gradient(to right, #00aaff, #0085ff);
    }
    input[type=text]{
        width: 175px;
        border: 1px solid #ccc;
        padding-left: 10px;box-sizing: border-box
    }
    div.no2 ul input.btn1{
        width: 100px;
        text-align: center;
        margin-left: 185px;
        border-radius:0 
    }
    table{
        width: 950px;
        margin: 4px auto;
        text-align: center;
        border-left: none;
        border-top: none
    }
    td{
        border-bottom: none;
        border-right: none;
        height: 52px;
        font-size: 12px;
        color: #333;
        white-space:wrap;
        border-bottom: 1px dotted #ccc
    }
    table tr:not(:first-child):hover{
        background: #f5f5f5
    }
    table tr>th{
        height: 40px;font-size: 14px;color: #333;
        background: #f5f5f5;
        color: #333;border-right: 1px solid #fff;
        font-weight: normal
    }
    table td:first-child{
        width:275px;padding-left: 20px;
        text-align: left;overflow: hidden;
        white-space: nowrap;text-overflow: ellipsis;
    }
    table td:nth-child(2),td:nth-child(6){
        color: #999
    }
    table td:last-child{
        width: 140px
    }
    table td span{
        color: #333;
    }
    table td span:hover{
        cursor: pointer;
        color: #3296FA
    }
    .empty-img{
        display: block;
        margin:auto;
    }
</style>
