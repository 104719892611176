<template>
    <div>
        <div v-if="titleMeg" class="no1"><span>{{titleMeg}}</span></div>
        <router-view @nav-change="navChange"></router-view>
    </div>
</template>

<script>
export default {
    data(){
        return {
            titleMeg:'当前安全级别'
        }
    },
    components: {

    },
    methods:{
        navChange:function(fn){
            this.$emit('nav-change', fn)
        }
    },
    mounted:function(){
        this.$parent.navData[1].open = true;
    },
}
</script>

<style scoped>
    body{
        margin: 0;
        padding: 0;
    }
    .no1{
        width:992px;height:39px;background:#fff;
        margin-bottom: 2px;
    }
    .no1>span{
        display: block;
        font-size: 16px;
        line-height: 37px;
        text-align: center;
        width: 120px;
        border-bottom: 2px solid #FF4A03;
    }
</style>
