<template>
  <div class="buycar-wrap">
    <ul class="header" :class="{'order-header':type==='order'}">
      <span v-if="checked" class="checked"><el-checkbox v-model="allCheck" @change="handleCheckAllChange"></el-checkbox></span>
      <li :class="{tgl:item==='商品信息'}" v-for="item in head" :key="item">{{item}}</li>
      <li>操作</li>
    </ul>
    <div class="body">
      <div v-if="thisTableData.length>0">
        <div class="body-item" v-for="(item,index) in thisTableData" :key="index" :class="{'order-item':type==='order'}">
          <div class="vend-name" v-if="type==='order'">
            <span>订单号：{{item.orderNo}}</span>
            <span class="color999">{{item.cfmTime}}</span>
            <span class="color999">{{item.vendName}}</span>
          </div>
          <div class="vend-name" v-else>{{item.vendName}}</div>
          <ul class="body-item-ul">
            <span v-if="checked" class="checked">
              <el-checkbox v-model="item.checked" @change="handleItemChange"></el-checkbox>
            </span>
            <span v-if="radio" class="checked">
              <el-radio v-model="radioItem" :label="item" @change="radioChange"><span></span></el-radio>
            </span>
            <li class="tgl"><img :src="item.serviceImgPath"/><span class="goods-name">{{item.serviceName}}</span></li>
            <li v-if="type==='order'">{{item.price}}元</li>
            <li v-else>
              <div v-if="item.cost">
                <span>{{item.cost}}</span>
              </div>
              <div v-else>
                <span v-if="item.priceType==='3'">面议</span>
                <span v-if="item.priceType==='2'">{{item.priceMin}}-{{item.priceMax}}</span>
                <span v-if="item.priceType==='1'">{{item.price}}</span>
              </div>
            </li>
            <li>{{item.quantity}}</li>
            <li v-if="type==='order'">{{item.subTotal}}元</li>
            <li v-else>
              <div v-if="item.cost">
                <span>{{item.cost}}</span>
              </div>
              <div v-else>
                <span v-if="item.priceType==='3'">面议</span>
                <span v-if="item.priceType==='2'">{{item.priceMin}}-{{item.priceMax}}</span>
                <span v-if="item.priceType==='1'">{{item.price}}</span>
              </div>
            </li>
            <li v-if="type==='order'">
              <template v-if="$route.name==='order_list_seller'">
                <router-link v-if="item.status==='4'||item.status==='5'" :to="{ name:'order_upload',query:{ id : item.id}}" >上传服务凭证</router-link>
                <span v-else>--</span>
              </template>
              <template v-else>
                <router-link v-if="item.status==='5'||item.status==='6'||item.status==='7'" :to="{ name:'order_download',query:{ id : item.id}}">下载服务凭证</router-link>
                <span v-else>--</span>
              </template>
            </li>
            <li v-if="type==='order'">
              <span style='color:#999;' v-if="item.status==='1'">订单取消</span>
              <span style='color:#999;' v-if="item.status==='2'">订单关闭（24小时）</span>
              <span style='color:#ff4a03;' v-if="item.status==='3'">未付款</span>
              <span style='color:#00aa28;' v-if="item.status==='4'">已付款</span>
              <span style='color:#999;' v-if="item.status==='5'">待确认完成服务</span>
              <span style='color:#00aa28;' v-if="item.status==='6'">交易完成</span>
              <span style='color:#999;' v-if="item.status==='7'">平台自动交易完成（7天）</span>
            </li>
            <li class="setting order-setting" v-if="type==='order'">
                <div v-if="$route.name==='order_list_seller'">
                  <span class="default-btn" @click="GoDetailSeller(item)">订单详情</span>
                </div>
                <div v-else>
                  <!-- <span >立即支付</span> -->
                  <router-link v-if="item.status==='3'" class="pay-btn" :to="{name:'pay',query:{id:item.id}}">立即支付</router-link>
                  <span v-if="item.status==='3'" class="default-btn" @click="DelOrder(item)">取消订单</span>
                  <span class="default-btn" @click="GoDetail(item)">订单详情</span>
                </div>
            </li>
            <li class="setting" v-else>
                <div>
                  <span @click="delClick(item)" class="pointer">删除</span>
                </div>
                <div>
                  <span @click="GetDialog(item)" v-if="!item.entrustStatus" class="pointer">填写委托单</span>
                  <span v-if="item.entrustStatus==='1'" class="colore4393c">委托单待确认</span>
                  <span v-if="item.entrustStatus==='2'" class="color00aa28">委托单已确认</span>
                  <span v-if="item.entrustStatus==='3'" class="colore4393c">委托单被驳回</span>
                </div>
                <div>
                  <span class="pointer" @click="lookDialog(item)" v-if="item.entrustStatus==='1'||item.entrustStatus==='2'||item.entrustStatus==='3'">查看委托单</span>
                </div>
            </li>
          </ul>
        </div>
      </div>
      <div v-else class="empty-wrap">
        暂无数据
      </div>
    </div>
    <div class="body-bottom" v-if="type!='order'">
        <div class="fl all-checkbox">
          <el-checkbox v-if="checked" v-model="allCheck" @change="handleCheckAllChange">全选</el-checkbox><!--  &nbsp; | &nbsp; <a href="">删除</a> -->
        </div>
        <div class="submit-wrap fr">
          <xbutton :disabled="buycarBtn.disabled" @on-click="SubmitOrder" :value="buycarBtn.value"></xbutton>
        </div>
        <div v-if="checked" class="fr total-money"><span>￥<span class="num"><!-- {{total.money}} --></span></span></div>
        <div v-if="radio&&totalMoney" class="fr total-money">价格：<span>￥<span class="num">{{totalMoney}}</span></span></div>
        <div v-if="checked" class="fr total-num">总计：<span><!-- {{total.num}} --></span> 件商品</div>
    </div>
    <diyDialog v-if="dialogShow" :status="status" title="委托单" :formData="formData" :item="diyItem" :snycShow.sync="dialogShow" @on-click="ClickSubmit"/>
  </div>
</template>

<script type="text/javascript">
import diyDialog from '@/components/UI/diyDialog.vue'
import xbutton from '@/components/UI/xbutton.vue'
  export default {
    name: 'xtable',
    props: {
        head:Object,
        tableData:Object | Array,
        btn:String,
        type:String,
        checkbox:Boolean,
        disabled:Boolean,
        checked:Boolean,
        radio:Boolean
    },
    data () {
        return {
          total:{
            num:0,
          },
          buycarBtn:{
            disabled:true,
            value:"请选择服务",
          },
          diyItem:"",
          id:"",
          totalMoney:"",
          radioItem:{},
          thisTableData:{},
          allCheck:false,
          isIndeterminate:false,
          orderItem:{},
          checkList:[],
            headerList:[],
            dialogShow:false,
            cartId:"",
            status:"",
            formData:{
              demandAddress:"",
              demandTel:"",
              consultPrice:{
                value:"",
                error:"",
                validate:['required','integer'],
                name:"协议价格"
              },
              consultContent:{
                value:"",
                error:"",
                validate:['required'],
                name:"协议内容"
              },
              signature:{
                value:"",
                error:"",
                validate:['required'],
                name:"委托人姓名"
              },
            },
          }
    },
    watch: {
      tableData:{
        handler(val){
          this.thisTableData = this.type==='order' ? val.records : val
        },
        deep: true
      },
    },
    computed:{

    },
    components: {
      diyDialog,
      xbutton
    },
    mounted () {

    },
    methods: {
      delClick:function(item){
        this.$emit('on-delClick', item)
      },
      radioChange:function(item){
        this.orderItem = item;
        this.totalMoney = item.entrustPrice;
        if (item.entrustStatus==='2') {
          this.buycarBtn.value = "提交订单";
          this.buycarBtn.disabled = false;
        }else{
          this.buycarBtn.value = "请选择已确认的服务";
          this.buycarBtn.disabled = true;
        };
      },
      DelOrder:function(item){
        var data = {id:item.id};
        this.$dialog.confirm('是否确认取消订单？','error',()=>{
          this.$com.majax.post(this.$com.testCsIp+'/order/cancelOrderById/' + item.id,{},{
            success:(res)=>{
              this.$message({
                message: '取消成功！',
                type: 'success'
              });
              this.$parent.GetData();
            }
          },this)
        })
      },
      GoDetail:function(item){
        this.$router.push({ name:'buy_order_details', query:  { id:item.id }})
      },
      GoDetailSeller:function(item){
        this.$router.push({ name:'order_details_seller', query:  { id:item.id }})
      },
      handleCheckAllChange:function(val){
        this.thisTableData.map((item,i)=>{
          item.checked = val;
        })
      },
      handleItemChange:function(val){
        for(let i = 0,l = this.thisTableData.length;i < l;i ++){
          if(this.thisTableData[i].checked !== val){
            this.allCheck = false;
            return;
          }
        }
        this.allCheck = val;
      },
      GetDialog:function(item){
        this.formData.signature.value = "";
        this.formData.signature.error = "";
        this.formData.consultPrice.value = "";
        this.formData.consultPrice.error = "";
        this.formData.consultContent.value = "";
        this.formData.consultContent.error = "";
        var data = {serviceId:item.servicesId}
        this.$com.majax.get(this.$com.testCsIp+'/uc-entrust-order/selectEntrustOrderInfo',data,{
          success:(res)=>{
              this.dialogShow = true;
              this.cartId = item.id;
              this.status = item.entrustStatus;
              for(var key in res.data){
                this.formData[key] = res.data[key]
              }
              this.formData.demandAddress = res.data.userDemandAddress;
              this.formData.demandTel = res.data.userDemandTel;
          }
        },this)
      },
      lookDialog:function(item){
        this.id = "";
        this.$com.majax.get(this.$com.testCsIp+'/uc-entrust-order/getEntrustOrderDetailsById/' + item.entrustId,{},{
          success:(res)=>{
            this.dialogShow = true;
            this.id = res.data.id;
              for(var key in res.data){
                if (key==="consultPrice"||key==="consultContent"||key==="signature") {
                    this.formData[key].value = res.data[key];
                }else{
                  this.formData[key] = res.data[key]
                };
              }
              this.status = res.data.enStatus;
              this.cartId = item.id;
          }
        },this)
      },
      SubmitOrder:function(){
        this.$router.push({ path:'/shopping_order',query:{demandId:this.orderItem.demandId,entrustId:this.orderItem.entrustId,serviceId:this.orderItem.servicesId} });
      },
      ClickSubmit:function(){
        var formData = this.formData;
        var data = {cartId:this.cartId};
        for(var key in formData){
          if(formData[key]&&typeof(formData[key])==="object"){
            if (key==='ucEntrustOrderVo') {
              data[key] = formData[key]
            }else{
              data[key] = formData[key].value
            };
          }else{
            data[key] = formData[key]
          }
        }

        var _data = {};
        _data.ucEntrustOrderVo = {};
        if (this.id) {
          _data.ucEntrustOrderVo.id = this.id;
        };
        for (var key in data) {
          if (key === 'cartId'||key === 'consultContent'||key === 'consultPrice'||key === 'demandAddress'||key === 'demandTel'||key === 'signature') {
            _data[key] = data[key]
          }else{
            _data.ucEntrustOrderVo[key] = data[key]
          };
        };
        this.$com.majax.post(this.$com.testCsIp+'/uc-entrust-order/saveEntrustOrder',_data,{
            success:(res)=>{
              this.dialogShow = false;
              this.$dialog.alert("提交成功，请等待审核","success","",(res)=>{
                res.thisShow = false;
                this.$parent.GetData();
              })
            }
        },this)
      }
    }
  }
</script>

<style scoped>
.page-wrap{
  text-align:center;
  margin-top:20px;
}
.cursor-pinter{
  cursor: pointer;
}
.item-large{
  line-height:38px;
}
.item-nowrap{
  white-space:nowrap;
}

.buycar-wrap{
  font-size:14px;
}
.header,
.body-item ul{
  display:flex;
  padding-left:20px;
  align-items:center;
}
.body-item ul{
  padding-top:10px;
  padding-bottom:8px;
}
.body{
  background:#fff;
}
.header li{
  flex:1;
  text-align:center;
  font-weight:bold;
  line-height:45px;
}
.order-header{
  background:#f5f5f5;
  padding:0;
}
.order-header li{
  font-weight:normal;
}
.header .checked{
  line-height: 45px;
  margin-bottom: -.5rem;
}
.body-item-ul li{
  flex:1;
  text-align:center;
  width:1px;
}
.body-item-ul .tgl,
.header .tgl{
  text-align:left;
  margin-left:20px;
  flex:2;
}
.header .tgl{
  padding-left:14px;
}
.order-header .tgl{
  padding-left:0;
}
.body-item-ul img{
  width:70px;
  height:70px;
  border:1px solid #ccc;
}
.goods-name{
  margin-left:10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: calc(100% - 88px);
  display: inline-block;
  vertical-align: middle;
}
.vend-name{
  padding-left:55px;
  border-bottom:1px solid #E6E6E6;
  border-top:1px solid #E6E6E6;
  line-height:40px;
  font-weight:bold;
}
.setting .pointer{
  cursor:pointer;
}
.body-bottom{
  background:#fff;
  margin-top:20px;
  clear:both;
  overflow:hidden;
  border:1px solid #e6e6e6;
  height:46px;
  line-height:46px;
}
.fl{
  float:left;
}
.fr{
  float:right;
}
.submit-wrap{
  width:200px;
  height:100%;
}
.submit-wrap button{
  height:100%;
}
.all-checkbox{
  margin-left:20px;
}
.total-money{
  margin-right:20px;
}
.total-money .num{
  font-size:24px;
}
.total-money span,
.total-num span{
  color:#e4393c;
}
.total-num{
  margin-right:28px;
}
.order-item{
  margin-top:10px;
  border:1px solid #e6e6e6;
}
.order-item .vend-name{
  padding-left:20px;
  font-weight:normal;
  background:#f5f5f5;
  border-top:none;
}
.order-item .vend-name span{
  margin-right:15px;
}
.order-item ul{
  padding: 20px 0;
}
.order-setting span{
  display:block;
  width:72px;
  margin:auto;
  padding: 2px 0;
  cursor:pointer;
}
.pay-btn{
  background:#e61728;
  color:#fff;
  border-radius:3px;
  text-decoration:none;
  padding:3px;
}
.default-btn:hover{
  color:#3296fa;
}
.color999{
  color:#999;
}
.colore4393c{
  color:#e4393c;
}
.color00aa28{
  color:#00aa28;
}
.empty-wrap{
  text-align:center;
  color:#909399;
  border-bottom:1px solid #e6e6e6;
  line-height:60px;
}
</style>