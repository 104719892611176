<template>
    <div class="no2">
        <div class="sp">
            <div>
                <img src="../../../assets/img/user/i-success.png" />
            </div>
            <div>
                <p class="pF">恭喜你，支付成功！</p>
                <p class="pL">你可以点击查看。</p>
                <xbutton class="btn-back" @on-click="goAdd" value="查看"></xbutton>
            </div>
        </div>
        <div class="xsp">
            <p>特别提醒：</p>
            <p>1、用户申请平台咨询师审核资料，咨询师可以修改资料，用户需向平台支付：999元的咨询费；</p>
            <p>2、如认证资料没有通过认证机构的审核，平台咨询师负责根据认证机构的要求修改认证资料，再次修改不产生费用；</p>
            <p>3、认证成功后，平台可以向企业提供正规发票，无需额外支付发票费。</p>
        </div>
    </div>
</template>

<script>
import xtable from '@/components/UI/xtable.vue'
import xbutton from '@/components/UI/xbutton.vue'
import xstep from '@/components/UI/xsteps.vue'
export default {
    data(){
        return{

        }
    },
    methods:{

    },
    components: {
        xbutton,
        xstep,
    },
    mounted:function(){
        this.$parent.titleMeg = '支付成功';
    },
}
</script>

<style scoped>
.no2{
    width:992px;
    background:#fff;
    padding: 25px 0 15px 0;
    min-height: 580px;
}
.sp{
    margin-top: 112px;
    margin-left: 303px;
    overflow: hidden;
}
.sp img{
    width: 40px;height: 40px;
    margin-right: 16px;
    margin-top: 6px;
}
.sp>div{
    float: left;
}
.pF{
    font-size: 20px;color: #333;
    font-weight: bold;
}
.pL{
    font-size: 12px;color: #666;
    margin-bottom: 33px
}
.xsp{
    border-top:1px solid #ddd ;
    padding: 20px 0 0 36px;
}
.xsp p{
    font-size: 12px;color: #666;
}
.xsp p:first-child{
    font-size: 14px;
    color: red;
}
.btn-back{
    width: 120px;
    font-size: 14px;
    margin-right: 10px;
    margin-bottom: 96px;
}
.btn-next{
    width: 160px;
    font-size: 14px;
}
</style>

