<template>
    <div>
        <div class="header">
            <div class="main01">
                <router-link to="/"><img src="../assets/img/index/logo.png" alt=""></router-link>
                <div></div>
                <span>找回密码</span>
            </div>
        </div>
        <router-view></router-view>
        <Footer />
    </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
export default {
    components: {
        Footer
    }
}
</script>

<style scoped>
    body,ul{
        list-style: none;
        margin: 0;
        padding: 0;
    }
    .header{
        width: 100%;
        min-width: 1200px;
        height: 102px;
        border-bottom: 2px solid #e6e6e6
    }
    .header .main01{
        width: 1200px;
        margin: 0 auto;
        position: relative;
    }
    .main01 img:first-child{
        position: absolute;
        top: 22px;
        left:0;
    }
    .main01 div:nth-child(2){
        position: absolute;
        top:35px;
        left: 182px;
        height: 35px;
        border: 0.5px solid #CCDCF9;
    }
    .main01 span:nth-child(3){
        position: absolute;
        top:35px;
        left:202px;
        font-size: 20px;
        color: #666
    }
</style>
