<template>
    <div class="no2">
        <formItem width="150" :title="form.parentdepartId.name" type="select" @on-focus="(fn)=>getParentList(fn)" v-model="form.parentdepartId.value" />
        <formItem width="150" required :title="form.departName.name" errorStyle="inline" :error="form.departName.error" type="text" v-model="form.departName.value" />
        <formItem width="150" required :title="form.auditor.name" errorStyle="inline" :error="form.auditor.error" type="text" v-model="form.auditor.value" />
        <formItem width="150" required title="上传部门制度" :error="upList.error" type="upload"
            :action="$com.testInin+'/autoauth/uploadfile'"
            :headers="{token:$store.state.tokenId}"
            :data="{businId:IntData.departId,categoryId:IntData.categoryId,fileType:3}"
            @on-success="(list)=>upList.list=list"/>
        <formItem width="150" :title="form.description.name" type="textarea" v-model="form.description.value" />
        <ul>
            <li></li>
            <li class="form-btn">
                <xbutton @on-click="goSelf" value="保 存"></xbutton>
            </li>
        </ul>
    </div>
</template>

<script>
import formItem from '@/components/UI/formItem.vue'
import xbutton from '@/components/UI/xbutton.vue'
export default {
    data(){
        return{
            autoServiceId:this.$route.query.autoServiceId||"",
            id:this.$route.query.id||"",
            IntData:{},
            parentdepartList:[],
            upList:{
                error:"",
                list:[],
            },
            form:{
                auditor:{
                    value:"",
                    error:"",
                    validate:['required'],
                    name:"内审员"
                },
                departName:{
                    value:"",
                    error:"",
                    validate:['required'],
                    name:"部门名称"
                },
                description:{
                    value:"",
                    name:"部门及部门描述"
                },
                parentdepartId:{
                    value:"",
                    name:"上级部门"
                }
            }
        }
    },
    methods:{
        /*upChange:function(item){
            if(item.response.code!=0){
                on-remove
            }
        },*/
        goSelf:function(_this){
            var data = {};
            data.autoServiceId = this.autoServiceId;
            if(this.id){
                data.id = this.id;
            }
            if (this.upList.list.length===0) {
                this.upList.error = "请上传部门制度"
            }else{
                this.upList.error = ""
            };
            var validate = this.$com.validate(this.form);
            if(validate&&!this.upList.error){
                for(var key in this.form){
                    data[key] = this.form[key].value;
                }
                _this.loading = true;
                this.$com.majax.post(this.$com.testChIp + '/auto-depart/saveOrUpdateDepart/',this.$qs.stringify(data),{
                    success:(res)=>{
                        this.$dialog.alert('保存成功！','success',"",(_res)=>{
                            _res.thisShow = false;
                            this.$router.go(-1);
                        })
                    }
                },_this)
            }
        },
        getParentList:function(fn){
            this.$com.majax.get(this.$com.testChIp + '/auto-depart/getDepartName/',{},{
                success:(res)=>{
                    var list = res.data.map((n)=>{
                        return {id:n.parentDepartId,name:n.parentDepartName}
                    });
                    fn(list)
                }
            })
        },
        getIntId:function(){
            this.$com.majax.get(this.$com.testChIp + '/auto-depart/initDepartSave/' + this.autoServiceId,{autoServiceId:this.autoServiceId},{
                success:(res)=>{
                    this.IntData=res.data;
                }
            })
        },
        getData:function(){
            this.$com.majax.get(this.$com.testChIp + '/auto-depart/getProgDetails/' + this.id,{id:this.id},{
                success:(res)=>{
                    for(var key in this.form){
                        this.form[key].value = res.data[key];
                    }
                }
            })
        },
    },
    components: {
        formItem,
        xbutton,
    },
    mounted:function(){
        this.$parent.titleMeg = this.$route.query.id ? '修改组织架构及制度' : '新增组织架构及制度';
        this.$parent.rightLink = false;
        this.id ? this.getData() : this.getIntId();
    },
}
</script>

<style scoped>
.no2{
    width:992px;
    background:#fff;
    padding: 25px 20px 15px 20px;
}
.no2 ul{
    margin-left: 110px;
    display: flex;
    margin-bottom: 20px;
}
.no2 ul li:first-child{
    width: 150px;
    margin-right: 7px;
    text-align: right;
    font-size: 14px;
}
.form-btn{
    width:170px;
}
.form-btn button{
    font-size:18px;
    height:36px;
}
.step-item{
    width:20px;
    height:20px;
    background:#ccc;
}
</style>

