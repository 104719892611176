<template>
    <div class="no2">
        <div>
             <div><div></div>服务方信息</div>
             <div>
                <ul>
                    <li>名称：</li>
                    <li>重庆启翔咨询管理有限公司</li>
                </ul>
                <ul>
                    <li>联系人：</li>
                    <li>甘士光</li>
                </ul>
                <ul>
                    <li>服务机构地址：</li>
                    <li>重庆市渝北区汽博中心123号</li>
                </ul>
                <ul>
                    <li>联系电话：</li>
                    <li>123456789789</li>
                </ul>
             </div>
        </div>
        <div>
            <div><div></div>委托人信息</div>
            <div>
                <ul>
                    <li>委托方名称：</li>
                    <li>重庆金渝建筑工程有限公司</li>
                </ul>
                <ul>
                    <li>联系人：</li>
                    <li>州小小</li>
                </ul>
                <ul>
                    <li>委托方地址：</li>
                    <li>重庆市渝北区汽博中心线外城市花园89号6幢10楼</li>
                </ul>
                <ul>
                    <li>联系电话：</li>
                    <li>11365564646</li>
                </ul>
            </div>
        </div>
        <div>
            <div><div></div>服务协议信息</div>
            <ul>
                <li>协议价格：</li>
                <li><input type="text" placeholder="请填写协议价格"></li>
            </ul>
            <ul>
                <li>协议内容：</li>
                <li><textarea placeholder="请填写协议内容"></textarea></li>
            </ul>
            <ul>
                <li>委托人姓名：</li>
                <li><input type="text" placeholder="请填写委托人姓名"></li>
            </ul>
            <ul>
                <li></li>
                <li><input type="checkbox">上列填写内容正确属实，本人愿意承担有关责任</li>
            </ul>
            <ul>
                <li></li>
                <li><a href="/404" target="_blank">保存并提交服务商审核</a></li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    mounted(){
        this.$parent.titleMeg = '填写委托单';
    }
}
</script>


<style scoped>
        div.no2{
            width:992px;min-height: 722px;
            background:#fff;
            position: relative;
        }
        div.no2>div{
            padding-left:42px;padding-right: 57px;
            box-sizing: border-box ;font-size: 14px;
        }
        div.no2>div>div:first-child{
            padding-top: 20px;
            display: flex;line-height: 30px;
            border-bottom: 2px dotted #ccc;
            font-weight: bold;margin-bottom: 10px
        }
        div.no2>div>div:first-child>div{
            width: 3px;height:14px ;
            background: #ff4a03;margin-top: 8px;
            margin-right: 9px
        }
        div.no2>div div:not(:first-child){
            display: flex;flex-wrap: wrap
        }
        div.no2>div ul{
            display: flex;
            line-height: 30px;
        }
        div.no2>div ul li:first-child{
            width: 100px;text-align: right;
            color: #666
        }
        div.no2>div ul li:last-child{
            width: 340px;color: #333
        }
        div.no2>div:nth-child(3) ul{
            line-height: 60px
        }
        div.no2>div ul li input[type=text]{
            width: 370px;height: 40px;
            border: 1px solid #ccc;
            padding-left: 10px;box-sizing: border-box
        }
        div.no2>div:nth-child(3) ul:nth-child(3){
            line-height: 30px;padding-top: 10px
        }
        div.no2>div ul li textarea{
            padding: 10px 0 0 10px;
            width: 370px;height: 140px;
            border: 1px solid #ccc;box-sizing: border-box
        }
        div.no2>div:nth-child(3) ul:nth-child(5) li{
            font-size: 12px;color: #e62e3d;
        }
        div.no2>div:nth-child(3) input[type=checkbox]{
            vertical-align: middle
        }
        div.no2>div:nth-child(3) ul a{
            display: block;width: 270px;height: 40px;
            background: #3296FA;color: #fff;font-size: 18px;
            text-decoration: none;text-align: center;line-height: 40px
        }
        div.no2>div:nth-child(3) ul a:hover{
            background: linear-gradient(to right, #00aaff, #0085ff);
        }
        div.no2>div:nth-child(3) ul:nth-child(5),ul:nth-child(6){
            line-height: 40px
        }
</style>
