<template>
    <ul>
        <li><a href="/">首页</a></li>
        <li>
            <router-link :to="{path:'/search_page',query:{title:'产品认证'}}" target="_blank">产品认证</router-link>
        </li>
        <li>
            <router-link :to="{path:'/search_page',query:{title:'体系认证'}}" target="_blank">体系认证</router-link>
        </li>
        <li>
            <router-link to="/facilitating_agency" active-class="router-active" target="_blank">服务机构</router-link>
        </li>
        <li>
            <router-link to="/shop_list" active-class="router-active" target="_blank">店铺服务</router-link>
        </li>
        <li>
            <router-link to="/demand_hall" active-class="router-active" target="_blank">需求大厅</router-link>
        </li>
        <li><router-link to="/news_home/news_list" active-class="router-active" target="_blank">平台资讯</router-link></li>
        <li>
            <router-link to="/prize_message" active-class="router-active" target="_blank">有奖留言</router-link>
        </li>
    </ul>
    <!-- <ul>
        <li v-for="(lis,i) in list" @click="go(lis.textMeg,lis.url)" :key="i">
            <span :href="lis.url" target="_blank"></span>
            <router-link :to="{path:'/search_page',query:{title:lis.textMeg}}"  target="_blank">{{lis.textMeg}}</router-link>
        </li>
    </ul> -->
</template>


<script>
export default {
    data(){
        return{

        }
    },
    methods:{

    }
}
</script>


<style scoped>
    ul li{
        float: left;
        margin-right: 50px
    }
    ul li a{
        font-size: 16px;color: #333;
        text-decoration: none
    }
    ul li a:hover{
        color: #2e89e4
    }
    ul{
        width: 1200px;margin: 0 auto;
        height: 40px;
        line-height: 40px;

    }
    .router-active{
        color:#3296FA;
    }
</style>