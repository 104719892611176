<template>
    <div>
        <div v-if="titleMeg" class="no1"><span>{{titleMeg}}</span></div>
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    data(){
        return {
            titleMeg:'地址管理'
        }
    },
    components: {

    },
    methods:{

    },
    mounted:function(){

    },
}
</script>

<style scoped>
        body{
            margin: 0;
            padding: 0;
        }
        ul,p{
            list-style: none;
            margin: 0;
            padding: 0;
        }
        div.no1{
            width:992px;height:39px;background:#fff;
            margin-bottom: 2px;
        }
        div.no1>span{
            display: block;
            font-size: 16px;
            line-height: 37px;
            text-align: center;
            width: 120px;
            border-bottom: 2px solid #FF4A03;
        }
        div.no2{
            width:992px;padding-bottom: 20px;
            min-height: 660px;
            background:#fff;
            position: relative;
            padding-top: 15px;
        }
        div.no2 ul{
            display: flex;
            margin-left: 70px;
            background: #fff;
            line-height: 60px;
        }
        div.no2 ul span{
            font-size: 14px;
            color: #333
        }
        div.no2 ul>li:nth-child(2){
            margin-left: 23px;
        }
        div.no2 ul input[type=button]{
            width: 98px;
            height: 36px;border: 0;background: #3296FA;
            color: #fff;font-size: 16px;
            margin-top: 14px;
            margin-left: 8px;
            cursor: pointer;
        }
        div.no2 ul input[type=button]:hover{
            background: linear-gradient(to right, #00aaff, #0085ff);
        }
        div.no2 ul input.btn1{
            width: 150px;
            text-align: center;
            margin-left: 20px;
        }
        div.no2 ul input[type=text]{
            width: 210px;
            height: 36px;
            border: 1px solid #ccc;
            box-sizing: border-box;
        }
        table{
            width: 840px;
            margin: 10px auto;
            text-align: center;
            border: 1px solid #ccc;
            border-left: none;
            border-top: none
        }
        tr,th,td{
            border: 0.5px solid #ccc;
            border-bottom: none;
            border-right: none;
            height: 40px;
            font-size: 14px;
            color: #333;
            white-space:wrap
        }
        table tr>th{
            background: #EAF4FE;
            color: #333;
            font-weight: normal
        }
        table tr>td:nth-child(3){
            width: 373px;
        }
        table td a{
            color: #333
        }
        table td a:hover{
            color: #3296FA
        }
</style>
