<template>
    <div class="section-top">
        <div class=" d-flex justify-content-between">
            <div style="font-size:30px; line-height: 60px">
                产品认证服务
            </div>
            <ul class="d-flex justify-content-between" id="ulli">
                <li><router-link :to="{path:'/search_page',query:{title:'有机产品认证'}}"  target="_blank">有机产品认证</router-link></li>
                <li><router-link :to="{path:'/search_page',query:{title:'绿色食品认证'}}"  target="_blank">绿色食品认证</router-link></li>
                <li><router-link :to="{path:'/search_page',query:{title:'无公害农产品认证'}}"  target="_blank">无公害农产品认证</router-link></li>
                <li><router-link :to="{path:'/search_page',query:{title:'CQC标志认证'}}"  target="_blank">CQC标志认证</router-link></li>
                <li><router-link :to="{path:'/search_page',query:{title:'节能节水环保产品认证'}}"  target="_blank">节能节水环保产品认证</router-link></li>
                <li><router-link :to="{path:'/search_page',query:{title:'产品认证服务'}}"  target="_blank">更多+</router-link></li>
            </ul>
        </div>
        <div class="d-flex">
            <div id="dflex">
                <img src="../../../assets/img/index/cprzfw.png" alt="">
                <ul>
                    <li><router-link :to="{path:'/search_page',query:{title:'有机产品认证'}}"  target="_blank">有机产品认证</router-link></li>
                    <li><router-link :to="{path:'/search_page',query:{title:'绿色食品认证'}}"  target="_blank">绿色食品认证</router-link></li>
                    <li><router-link :to="{path:'/search_page',query:{title:'无公害农产品'}}"  target="_blank">无公害农产品</router-link></li>
                    <li><router-link :to="{path:'/search_page',query:{title:'CQC标志认证'}}"  target="_blank">CQC标志认证</router-link></li>
                </ul>
            </div>
            <div>
                <indexServeItem v-for="(item,index) in lists" :key="index" :list="item" />
            </div>
        </div>
    </div>
</template>

<script>
import indexServeItem from '@/components/UI/indexServeItem.vue'
export default {
    data(){
        return{
            lists:[]
        }
    },
    mounted:function(){
        this.GetData();
    },
    components: {
        indexServeItem
    },
    methods: {
        GetData:function(){
            this.$axios.get(this.$com.testCsIp + '/recomCategory/listService',
            {params:{
                id:'4028800a69463bb001694649f2400008',
                num:4,
                recommendType:1
            }})
            .then(res=>{
                this.lists=res.data.data
            })
        },
        more:function(){

        }
    }
}
</script>

<style scoped>
    .server>img{
        width: 140px;height: 173px;
    }
    .img{
        width: 142px;height: 173px;
        float:left;border: 1px solid #ddd;
        overflow: hidden;
    }
    .img>img{
        max-width: 100%;
        max-height: 100%;
        display: block;
        margin: auto;
    }
</style>

