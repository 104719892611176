<template>
    <div class="indx">
        <div class="detail">
            <div class="no">
                <div class="no1" v-loading="loading">
                    <div>
                        <img class="img" :src="list.coveImagePath||$com.errorImg" alt="商品图片" :onerror="$com.errorImg">
                    </div>
                    <div>
                        <p>{{list.serviceName}}</p>
                        <ul>
                            <li>价格：</li>
                            <li v-if="list.priceType === 1">￥{{list.price}}</li>
                            <li v-if="list.priceType === 2">￥{{list.priceMin}}-￥{{list.priceMax}}</li>
                            <li v-if="list.priceType === 3">面议</li>
                            <li class="no11">
                                <span @click="icon()" v-if="list.favoriteStatus==1"><i class="el-icon-star-off"></i>收藏</span>
                                <span @click="icons()" v-if="list.favoriteStatus==0"><i class="el-icon-star-on"></i>已收藏</span>
                            </li>
                        </ul>
                        <ul>
                            <li>评价：</li>
                            <li><span>{{list.evaluateNum}}</span>次</li>
                        </ul>
                        <ul>
                            <li>已服务：</li>
                            <li><span>{{list.serviceNum}}</span>次</li>
                        </ul>
                        <ul>
                            <li>服务周期：</li>
                            <li>{{list.cycleName}}</li>
                        </ul>
                        <ul>
                            <li>服务单位：</li>
                            <li v-if="list.orgName !=null ">{{list.orgName}}</li>
                            <li v-if="list.personalName != null">{{list.personalName}}</li>
                        </ul>
                        <ul>
                            <li>服务店铺：</li>
                            <li>{{list.shopName}}</li>
                            <li class="A">
                                <router-link :to="{ path:'/shop_management/shop_man_index',query:{ ShopId : ShopId}}" target="_blank">进入店铺</router-link>
                            </li>
                        </ul>
                        <p class="btn1">
                            <!-- <button>申请服务</button>--><button @click="goCart">加入购物车</button>
                        </p>
                    </div>
                    <div class="no12">
                        <div class="title">服务流程</div>
                        <img src="../../assets/img/shop/2_03.png" alt="">
                        <div class="title">服务详情</div>
                        <div class="fuwu">
                            {{list.detail}}
                        </div>
                    </div>
                </div>
                <div class="no2">
                    <div class="no21">
                        <span>推荐资源</span>
                        <span @click="more">更多+</span>
                    </div>
                    <!-- <div class="no22" v-for="(reco,i) in recommend" :key="i" @click="ClickDetail(reco.id)">
                        <img :src="reco.coveImagePath" alt="">
                        <p :title="reco.serviceName">{{reco.serviceName}}</p>
                        <p v-if="reco.priceType == 1">￥{{reco.price}}</p>
                        <p v-if="reco.priceType == 2">￥{{reco.priceMin}}-￥{{reco.priceMax}}</p>
                        <p v-if="reco.priceType == 3">面议</p>
                        <p>服务周期：{{reco.cycleName}}</p>
                    </div> -->
                    <goodsItem type="money" v-for="(item,index) in recommend" :key="index" :goodsData="item" @on-clickDetail="ClickDetail(item.id)" />
                    <!-- <div class="no22">
                        <img src="../../assets/img/seller/show.png" alt="">
                        <p title="软件开发、系统调试">软件开发、系统调试软件开发、系统调试</p>
                        <p>面议</p>
                        <p>周期：1-3个月</p>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import goodsItem from '@/components/UI/goodsItem.vue'
export default {
    metaInfo() {
        return{
            title: this.meta.title,
            meta: [
                {
                    name: 'keywords',
                    content: '佰证通,认证,平台'
                },
                {
                    name: 'description',
                    content: this.meta.title
                }
            ]
        }
    },
    data(){
        return{
            id:this.$route.query.id,
            ShopId:'',
            list:{},
            recommend:[],
            loading:true,
            himg:false,
            meta:{
                title:""
            }
        }
    },
    components: {
        goodsItem,
    },
    methods:{
        goData(){
            if(this.$store.state.tokenId !=null){
                this.$com.majax.get(this.$com.testCsIp + '/sellerServices/getGoodsDetailsById/' + this.id,{},{
                    success:(res)=>{
                        this.GetData(res.data)
                    }
                })
            }else{
                this.$axios.get(this.$com.testCsIp + '/sellerServices/getGoodsDetailsById/' + this.id)
                .then(res=>{
                    this.GetData(res.data.data)
                })
            }
        },
        GetData:function(_data){
            this.loading=false;
            this.list = _data;
            this.meta.title = (this.list.serviceName?this.list.serviceName + '-':"") + '佰证通认证平台';
            this.ShopId=_data.shopId;
            this.recommend=_data.recommendService;
            if (_data.recommendService.length>0) {
                for (var i = 0; i < _data.recommendService.length; i++) {
                    var item = _data.recommendService[i];
                    item.name = item.serviceName;
                    item.imagePath = item.coveImagePath;
                    item.cycleName = item.cycleName;
                }
            };
        },
        footprint(){
            this.$com.majax.post(this.$com.testCsIp+'/sellerBuyerHistory/saveOrUpdateBuyerHistory/'+this.id+'/1',"",{
                success:(res)=>{}
            })
        },
        icon(){
            if(this.$store.state.tokenId !=null ){
                this.list.favoriteStatus="0"
                this.$com.majax.post(this.$com.testCsIp+'/sellerBuyerFavorite/saveBuyerFavorite/'+this.id+'/1',"",{
                    success:(res)=>{
                        if(res.code == 0){
                            this.$message({
                                message: '收藏成功！',
                                type: 'success'
                            });
                        }
                    }
                })
            }else{
                this.$dialog.alert('请先登录！','confirm',()=>{
                    this.$router.push({ path:'/login',query:{ back: this.$route.path } })
                })
            }
        },
        icons(){
            this.list.favoriteStatus="1"
            this.$com.majax.post(this.$com.testCsIp+'/sellerBuyerFavorite/deleteByServiceId',this.$qs.stringify({serviceId:this.id}),{
                success:(res)=>{
                    if(res.code == 0){
                        this.$message({
                            message: '取消收藏成功！',
                            type: 'success'
                        });
                    }
                }
            },this)
        },
        ClickDetail(id){
            var routeData = this.$router.resolve({ name:'detail_page', query:  { id:id } });
            window.open(routeData.href, '_blank');
        },
        more(){
            this.$router.push({name:'shop_service_list',query:{ShopId:this.ShopId}})
        },
        goCart(){
            var data = {
                addCartLocation:1,
                servicesId:this.id
            }
            if(this.$store.state.tokenId !=null ){
                var val=this.$store.state.userType
                if(val.orgStatus===0||val.personlStatus===0){
                    this.$com.majax.post(this.$com.testCsIp + '/ucCart/AddCart',this.$qs.stringify(data),{
                        success:(res)=>{
                            if(res.code == 0){
                                this.$message({
                                    message: '加入购物车成功！',
                                    type: 'success'
                                });
                            }
                        }
                    })
                }else{
                    this.$dialog.alert('请先进行实名认证！','confirm',()=>{
                        var url;
                        if(this.$store.state.user.userType==1){
                            url='/users/base/base_auth/base_auth_per'
                        }
                        else{
                            url='/users/base/base_auth/base_auth_bus'
                        }
                        this.$router.push({ path:url,query:{ back: this.$route.path } })
                    })
                }
            }else{
                this.$dialog.alert('请先登录！','confirm',()=>{
                    this.$router.push({ path:'/login',query:{ back: this.$route.path } })
                })
            }
        }
    },
    mounted(){
        this.goData()
        this.footprint()
        //console.log(this.$store.state)
    }
}
</script>

<style scoped>
    .indx{
        min-width: 1200px;background: #f5f5f5;
        padding-bottom: 20px ;
    }
    .detail{
        min-width: 1200px;padding-top: 40px;background: #fff;
        overflow:hidden
    }
    .no{
        width: 1200px;margin: 0 auto;
    }
    .no>div{
        float: left;
    }
    .no1{
        width: 922px;min-height: 890px;
    }
    .no1>div{
        float: left;
    }
    .no1 p{
        width: 470px;font-size: 18px;font-weight: bold
    }
    .no p:first-child{
        max-height: 53px;overflow: hidden;
    }
    .no1>div:first-child{
        width: 375px;height: 375px;border: 1px solid #e6e6e6;
        margin-right: 20px;line-height: 375px;overflow: hidden;
        position: relative;
    }
    .no1 ul{
        display: flex;font-size: 14px;line-height: 30px;
    }
    .img{
        max-height:100%;
        max-width:100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    .no1 li:first-child{
        width: 70px;text-align: right;color: #666;
    }
    .no1 ul:nth-child(2){
        margin: 18px 0;
        height: 60px;background:#EFF7FF ;width: 527px;
        line-height: 60px;position: relative;
    }
    .no1 ul:nth-child(2) li:nth-child(1){
        padding-top: 5px
    }
    .no1 ul:nth-child(2) li:nth-child(2){
        color: #e4393c;font-size: 30px;font-weight: bold;
    }
    ul li:nth-child(2) span{
        color: #e4393c
    }
    .no11{
        position: absolute;right: 20px
    }
    i{
        color: #e4393c;font-size: 16px
    }
    .A{
        margin-left: 30px
    }
    .btn1 button{
        width: 220px;height: 50px;font-size: 20px;
        line-height: 50px;margin-top: 25px;border: 1px solid #3296FA
    }

    .btn1 button:first-child{
        margin-right: 20px;background: #3296FA;color: #fff
    }
    .btn1 button:first-child:hover{
        cursor: pointer;
        background: linear-gradient(to right, #00aaff, #0085ff);
    }

    .btn1 button:nth-child(2){
        background: #fff;color: #3296FA;
    }
    .btn1 button:nth-child(2):hover{
        cursor: pointer;color: #fff;
        background: linear-gradient(to right, #00aaff, #0085ff);
    }
    .no1>div:nth-child(3){
        width: 922px;min-height: 423px;border: 1px solid #e6e6e6;
        margin-top: 20px
    }
    .no2{
        width: 257px;height: 770px;background: #f5f5f5;
        margin-left: 20px;padding: 20px;
    }
    .title{
        width: 60px;text-align: center;color: #FF4A03;font-weight: bold;
        margin: 15px auto;font-size: 14px;border-bottom: 2px solid #FF4A03;
    }
    .no12{
        text-align: center
    }
    .fuwu{
        text-align: left;padding: 0 19px 10px;font-size: 12px;color: #666
    }
    .no21{
        border-bottom: 1px solid #ccc;line-height: 20px;
        margin-bottom: 15px;
    }
    .no21>span:first-child{
        font-size: 14px;color: #FF4A03;font-weight: bold;border-bottom: 2px solid #FF4A03;
    }
    .no21>span:nth-child(2){
        font-size: 12px;color: #666;float: right;
    }
    .no21>span:nth-child(2):hover{
        cursor: pointer;color: #3296FA
    }
    .no22{
        width: 218px;height: 272px;border: 1px solid #ccc
        ;margin-top:22px;cursor: pointer;
    }
    .no22>img{
        width: 216px;height: 165px;
    }
    .no22>p:nth-child(2){
        font-size: 16px;font-weight: bold;padding: 0 12px;
        line-height: 30px;margin-top: 15px;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
    }
    .no22>p:nth-child(3){
        font-size: 18px;font-weight: bold;color: #e4393c;
        line-height: 30px;padding: 0 12px;
    }
    .no22>p:nth-child(4){
        font-size: 12px;color: #666;padding: 0 12px;
    }
</style>
