<template>
    <div>
        <IndexHeader_home :textMeg="textMeg"/>
        <div class="content">
            <div>
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/news_home/news_list'}">资讯列表</el-breadcrumb-item>
                    <!-- <el-breadcrumb-item :to="{ path: '/news_home/news_list',query:{sid:sid}}">{{name}}</el-breadcrumb-item> -->
                    <el-breadcrumb-item v-if="name">{{name}}</el-breadcrumb-item>
                    <el-breadcrumb-item>新闻详情</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div v-loading="loading">
                <div class="no1">
                    <span>{{data.newsName}}</span> 
                    <p>
                        <span>发表于：{{data.createTime}}</span>
                        <span>信息来源：{{data.source}}</span>
                    </p>
                </div>
                <div class="no2" v-html="data.content">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IndexHeader_home from '@/components/IndexHeader_home.vue'
export default {
    data(){
        return{
            textMeg:'新闻详情',
            id:this.$route.query.id,
            name:this.$route.query.name,
            sid:this.$route.query.sid,
            data:{},
            loading:true,
        }
    },
    methods:{
        getData(){
            this.$axios.get(this.$com.testUsIp + '/news/getNewsById',
            {params:{
                id:this.id,
            }}).then(res=>{
                if(res.data.code == 0){
                    this.loading=false;
                    this.data=res.data.data;
                    this.data.createTime=this.data.createTime.slice(0,10)
                }
            })
        }
    },
    components: {
        IndexHeader_home,
    },
    mounted(){
        this.getData()
    }
}
</script>

<style scoped>
    .content{
        background: #f5f5f5;
        padding: 20px 0;min-width: 1200px
    }
    .content>div{
        width: 1200px;margin: 0 auto;
        padding-bottom: 10px
    }
    .content>div:nth-child(2){
        background: #fff;
        min-height: 400px;padding: 0px 32px;
    }
    .no1{
        text-align: center;border-bottom: 1px dotted #ccc;
        padding:30px 0 20px 0;
    }
    .no1>span{
        font-size: 20px;color: #404040;
        font-weight: bold;
    }
    .no1>p>span{
        font-size: 12px;color: #999;
    }
    .no1>p>span:first-child{
        margin-right: 30px
    }
    .no2{
        padding: 20px 0 31px 0;
        font-size: 14px;color: #666;
        text-align: justify;text-indent:28px;
    }
</style>

