import { render, staticRenderFns } from "./account_bdph_add.vue?vue&type=template&id=6048cfbe&scoped=true"
import script from "./account_bdph_add.vue?vue&type=script&lang=js"
export * from "./account_bdph_add.vue?vue&type=script&lang=js"
import style0 from "./account_bdph_add.vue?vue&type=style&index=0&id=6048cfbe&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6048cfbe",
  null
  
)

export default component.exports