<template>
    <div>
        <div class="header">
            <div class="main01">
                <router-link to="/"><img src="../assets/img/index/logo.png" alt="logo"></router-link>
                <div></div>
                <span>确认订单</span>
            </div>
        </div>

        <div class="body">
            <div class="cen mb20">
                <div class="title"><span>确认地址</span></div>
                <div class="body-wrap padding18">
                    <ul class="body-list" v-if="addressData.length>0">
                        <li :class="{'active':item === thisAdd}" v-for="(item,index) in addressData" :key="index">
                            <el-radio v-model="thisAdd" :label="item">
                                <span class="fontb">{{item.receive}} &nbsp; {{item.detailAddress}} &nbsp; {{item.phone}} {{item.isdefault===0?"（默认地址）":""}}</span>
                            </el-radio>
                            <span v-if="item === thisAdd" class="edit" @click="GoAddress(item.id)">修改地址</span>
                        </li>
                    </ul>
                    <div class="empty-wrap" v-else>暂无地址</div>
                    <xbutton class="address-btn" @on-click="GoAddress" value="新增地址" />
                </div>
            </div>
            <div class="cen mb20">
                <div class="title"><span>订单信息</span></div>
                <div class="body-wrap">
                    <div class="head-wrap padding18">
                        <ul class="flex-wrap">
                            <li class="flex2 tgl">商品信息</li>
                            <li>单价（元）</li>
                            <li>数量</li>
                            <li class="tgr">总金额（元）</li>
                        </ul>
                        <div class="org-name">{{serviceData.shopName}}</div>
                    </div>
                    <ul class="flex-wrap padding18 goods-wrap">
                        <li class="flex2 tgl">
                            <img class="img" :src="serviceData.coveImagePath" :alt="serviceData.serviceName">
                            <span>{{serviceData.serviceName}}</span>
                        </li>
                        <li>￥{{serviceData.consultPrice}}</li>
                        <li>1</li>
                        <li class="tgr">￥{{serviceData.consultPrice}}</li>
                    </ul>
                </div>
            </div>
            <div class="cen">
                <div class="title"><span>发票信息</span><span class="stitle">开企业抬头发票须填写纳税人识别号，以免影响报销</span></div>
                <div class="body-wrap padding18">
                    <div class="inline-list" v-if="invData.content">
                        <div class="inline-list">
                            <span>发票类型：</span>{{invData.invoiceType===0?"普通发票":"增值税发票"}}
                        </div>
                        <div class="inline-list">
                            <span>发票抬头：</span>{{invData.orgName}}
                        </div>
                        <div class="inline-list">
                            <span>发票税号：</span>{{invData.tfn}}
                        </div>
                        <div class="inline-list">
                            <span>发票内容：</span>{{invData.content}}
                        </div>
                    </div>
                    <div class="inline-list" v-else>
                        <div class="inline-list">
                            未添加发票
                        </div>
                    </div>
                    <span class="edit" @click="GoInv">{{invData.content?'修改':'添加'}}</span>
                </div>
            </div>
            <div class="cen bottom-wrap">
                <div class="submit-wrap">
                    <xbutton class="submit-btn" @on-click="SubmitOrder" value="提交订单"></xbutton>
                    <div class="money-wrap">
                        应付总金额：<span>￥{{serviceData.consultPrice}}</span>
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import xbutton from '@/components/UI/xbutton.vue'
export default {
    components: {
        Footer,
        xbutton
    },
    data () {
        return {
            query:{},
            radioItem:false,
            addressData:[],
            thisAdd:{},
            invData:{},
            serviceData:{},
        }
    },
    computed:{

    },
    mounted () {
        //this.$com.isLogin();
        this.query = this.$route.query;
        this.GetAddressData();
        this.GetInv();
        this.GetServeData();
    },
    methods: {
        GoInv:function(){
            this.$router.push({ name:'inv', query:  { back:true }})
        },
        GoAddress:function(id){
            var _id = typeof(id)==='object'?undefined:id;
            this.$router.push({ name:'address_add', query:  { back:true,id:_id }})
        },
        GetAddressData:function(){
            this.$com.majax.get(this.$com.testUsIp + '/receive/selectReceive',{},{
                success:(res)=>{
                    this.addressData = res.data;
                    if (this.addressData.length>0) {
                        this.thisAdd = this.addressData[0]
                    };
                }
            })
        },
        GetInv:function(){
            this.$com.majax.get(this.$com.testUsIp + '/invoice/selectCommonInvoice',{},{
                success:(res)=>{
                    if (res.data) {
                        this.invData = res.data;
                    }else{

                    };
                }
            })
        },
        GetServeData:function(){
            this.$com.majax.get(this.$com.testCsIp+'/uc-entrust-order/getEntrustOrderDetailsById/' + this.query.entrustId,{},{
                success:(res)=>{
                    this.serviceData = res.data;
                }
            },this)
        },
        SubmitOrder:function(){
            var data = [{
                demandId:this.query.demandId,
                entrustId:this.query.entrustId,
                serviceId:this.query.serviceId,
                receiveId:this.thisAdd.id
            }];
            if (!this.thisAdd.id) {
                this.$dialog.alert('地址不能为空','error');
                return false;
            };
            this.$com.majax.post(this.$com.testCwIp+'/order/createOrder',data,{
                success:(res)=>{
                    this.$dialog.alert('提交成功','success',"",(_res)=>{
                        _res.thisShow = false;
                        this.$router.push({ name:'pay',query:{id:res.data.orderId} });
                    })
                }
            },this)
        },
    }
}
</script>



<style scoped>
.header{
    width: 100%;
    min-width: 1200px;
    height: 102px;
}
.header .main01{
    width: 1200px;
    margin: 0 auto;
    position: relative;
}
.main01 img:first-child{
    position: absolute;
    top: 22px;
    left:0;
}
.main01 div:nth-child(2){
    position: absolute;
    top:35px;
    left: 182px;
    height: 35px;
    border: 0.5px solid #CCDCF9;
}
.main01 span:nth-child(3){
    position: absolute;
    top:40px;
    left:199px;
    font-size: 20px;
    color: #666;
}
.body{
    background:#f5f5f5;
    padding:20px 0;
}
.cen{
    width:1200px;
    margin:auto;
}
.title{
    margin-bottom:8px;
}
.title span{
    border-left:3px solid #ff4a03;
    padding-left:12px;
    font-size:18px;
    font-weight:bold;
    display:inline-block;
    line-height:18px;
}
.title .stitle{
    font-size:12px;
    color:#ff4a03;
    border:none;
    font-weight:normal;
}
.body-wrap{
    background:#fff;
    border:1px solid #e6e6e6;
    border-top:1px dashed #e6e6e6;
}
.mb20{
    margin-bottom:20px;
}
.padding18{
    padding:18px;
}
.body-list li{
    line-height:34px;
    padding:0 18px;
    margin-bottom:15px;
}
.body-list li.active{
    background:#ffede5;
    border:1px solid #ff4a03;
}
.body-list li.active span{
    font-weight:bold;
}
.body-list span{
    font-size:14px;
}
.body-list li.active .edit,.edit{
    color:#3296fa;
    float:right;
    cursor:pointer;
    font-weight:normal;
    font-size:14px;
}
.address-btn{
    width:88px;
    font-size:14px;
    height:30px;
    line-height:30px;
    margin-left:42px;
}
.inline-list{
    font-size:14px;
    display:inline-block;
    margin-right:40px;
}
.inline-list span{
    color:#999;
}
.flex-wrap{
    display:flex;
    font-size:14px;
}
.flex-wrap li{
    flex:1;
    text-align:center;
}
.flex-wrap .flex2{
    flex:2;
}
.flex-wrap .tgl{
    text-align:left;
}
.flex-wrap .tgr{
    text-align:right;
}
.org-name{
    font-size:14px;
    font-weight:bold;
    margin-top:12px;
}
.bottom-wrap{
    margin-top:18px;
    clear:both;
    overflow:hidden;
    background: #fff;
    border:1px solid #e6e6e6;
}
.goods-wrap{
    border-top:1px solid #e6e6e6;
}
.goods-wrap li{
    line-height:78px;
}
.submit-btn{
    width:140px;
    float:right;
    height:45px;
}
.money-wrap{
    float:right;
    font-size:14px;
    line-height:40px;
    margin-right:20px;
}
.money-wrap span{
    font-size:24px;
    color:#e4393c;
}
.empty-wrap{
    font-size:14px;
    text-align:center;
}
.tgl img{
    width:78px;
    height:78px;
    border:1px solid #e6e6e6;
    margin-right:10px;
}
</style>
