<template>
    <div class="section-password">
        <div>
            <div>
                <div>
                </div>
                <div>
                    <span>1</span>
                </div>
                <span>验证身份</span>
            </div>
            <div>
                <div>
                </div>
                <div>
                    <span>2</span>
                </div>
                <span>设置密码</span>
            </div>
            <div>
                <div>
                </div>
                <div>
                    <span>3</span>
                </div>
                <span>设置成功</span>
            </div>

            <div class="content">
                <formItem title="用户名" required :error="account.error" v-model="account.value" type="text" errorStyle="inline" placeholder="请输入手机号\邮箱" />
                <ul>
                    <li class="inp-label"><span>*</span> &nbsp;验证码：</li>
                    <li class="lis">
                        <input class="code-inp" type="text" placeholder="请输入验证码" v-model="code.value">
                        <codeBtn @on-click="codeEm" :disabled="codeDisable" @on-type="(val)=>{codeType=val}" />
                        <span class="error">{{code.error}}</span>
                    </li>
                </ul>
                <ul>
                    <li>
                        <xbutton Class="content-btn" @on-click="goNext" value="下一步" />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import formItem from '@/components/UI/formItem.vue'
import codeBtn from '@/components/UI/codeBtn.vue'
import xbutton from '@/components/UI/xbutton.vue'
export default {
    data(){
        return {
            account:{
                value:"",
                error:"",
                validate:['required'],
                name:"手机/邮箱"
            },
            code:{
                value:"",
                error:"",
                validate:['required'],
                name:"验证码"
            },
            codeDisable:false,
            codeType:false
        }
    },
    components: {
        formItem,
        codeBtn,
        xbutton
    },
    computed: {

    },
    methods:{
        codeEm:function(v){
            var val = this.account.value,
                url = "",
                data = {},
                isEmail = this.$com.checkEmail(val),
                isPhone = this.$com.checkMobile(val),
                dataV = {
                    account:this.account
                };
            var validate = this.$com.validate(dataV)
            if (validate) {
                if (isEmail) {
                    url = this.$com.testUsIp+'/email/send-forgerpwd-code';
                    data = {
                        sendType:3,
                        email:this.account.value
                    }
                }else if(isPhone){
                    url = this.$com.testUsIp+'/sms/send-code';
                    data = {
                        sendType:2,
                        phone:this.account.value
                    }
                }else{
                    this.account.error="格式不正确";
                    return false
                };
            }else{
                return false
            };
            this.$axios.post(url, this.$qs.stringify(data))
            .then((response) => {
                var res = response.data
                if (res.code === 0) {
                    this.codeDisable = !this.codeType
                }else{
                    this.$dialog.alert(res.msg,'error')
                };
            })
            .catch((error) => {
                this.$dialog.alert('系统错误','error')
            })

        },
        goNext:function(_this){
            var dataV = {
                code:this.code,
                account:this.account
            }
            var data = {
                phoneOrEmail:this.account.value,
                vcode:this.code.value,
                step:'1'
            }
            var validate = this.$com.validate(dataV)
            if (validate) {
                _this.loading = true;
                this.$axios.post(this.$com.testUsIp+'/security/forgetPassword', this.$qs.stringify(data))
                .then((response) => {
                    _this.loading = false;
                    var res = response.data;
                    if (res.code === 0) {
                        this.$router.push({ name: 'Pwtwo',params:data })
                    }else{
                        this.$dialog.alert(res.msg,'error')
                    };
                })
                .catch((error) => {
                    _this.loading = false;
                    this.$dialog.alert('系统错误','error')
                })
            };
        }
    }
}
</script>

<style scoped>
     .section-password{
        width: 100%;
        min-width: 1200px;
        height: 547px;
        background-color: #f5f5f5;
        padding-top: 20px
    }
    .section-password>div{
        width: 1200px;
        margin: 0 auto;
        background-color: #fff;
        height: 507px;
        position: relative;
    }
    .section-password>div>div>div:nth-child(1){
        width: 267px;
        border: 1px solid #ff4a03;
        position: absolute;
        top: 81px;
        left: 200px
    }
    .section-password>div>div>div:nth-child(2){
        width: 30px;
        height: 30px;
        background-color: #ff4a03;
        transform:rotate(45deg);
        position: absolute;
        top: 67px;
        left: 318px;
        text-align: center;
        line-height: 30px;
    }
    .section-password>div>div>div:nth-child(2) span{
        display: block;
        color: #fff;
        transform: rotate(-45deg)
    }
    .section-password>div>div:nth-child(1)>span{
        color: #ff4a03;
        font-size: 16px;
        position: absolute;
        top: 125px;
        left: 302px
    }
    .section-password>div>div:nth-child(2)>span,.section-password>div>div:nth-child(3)>span{
        position: absolute;
        top: 125px;
        left: 567px;
        color: #333;
        font-size: 16px;
    }
    .section-password>div>div:nth-child(3)>span{
        position: absolute;
        left: 835px;
    }
    .section-password>div>div:nth-child(2)>div:nth-child(1){
        position: absolute;
        left: 466px;
        border: 1px solid #ccc;
    }
    .section-password>div>div:nth-child(2)>div:nth-child(2){
        background-color: #ccc;
        position: absolute;
        left: 584px;
    }
    .section-password>div>div:nth-child(3)>div:nth-child(1){
        position: absolute;
        left:733px ;
        border: 1px solid #ccc;
    }
    .section-password>div>div:nth-child(3)>div:nth-child(2){
        background-color: #ccc;
        position: absolute;
        left: 852px;
    }
    .section-password ul{
        display: flex;
        font-size: 14px;
        color: #333;
        margin-bottom:20px;
    }
    .section-password ul span{
        color: red;
    }

    .section-password ul:nth-child(4) li:nth-child(2) input{
        width: 416px;
        height: 40px;
        padding-left: 10px;
        box-sizing: border-box;
        border: 1px solid #ccc;
    }

    .section-password ul:nth-child(5) li:nth-child(2) input{
        height: 40px;
        border: 1px solid #ccc;
        box-sizing: border-box;
    }
    .inp-label{
        width:100px;
        margin-right:7px;
        text-align:right;
        line-height:40px;
    }
    .code-inp{
        padding-left:10px;
        border: 1px solid #ccc;
        font-size: 14px;
        padding-left: 10px;
        height: 40px;
    }
    .content-btn{
        margin-left:107px;
        width: 240px;
        height: 40px;
        border: 0;
        background-color: #3296fa;
        color: #fff;
        font-size: 18px;
        cursor: pointer;
    }
    .content-btn:hover{
        background: linear-gradient(to right, #00aaff, #0085ff);

    }
    .content{
        padding-top:200px;
        width:540px;
        margin:auto;
    }
    .error{
        color:red;
        margin-left: 5px
    }
    .lis{
        line-height: 40px;
    }
</style>
