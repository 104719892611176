<template>
    <button :class="{'bg-fff':btnType==='bg-fff','not-allowed':disabl}" :style="{width: width + 'px' }" :btnType="btnType" v-loading="loading" :disabled="disabl" type="button" @click="onClick" v-html="value">{{value}}</button>
</template>

<script type="text/javascript">
  export default {
    name: 'xbutton',
    props: {
        disabled:{
            type:Boolean,
            default:false
        },
        value:String,
        width:String,
        btnType:String
    },
    data () {
        return {
            loading:false,
            disabl:this.disabled
        }
    },
    watch: {
        disabled(val){
            //console.log(val)
            this.disabl = val
        },
        loading(val){
            this.disabl = val
        }
    },
    mounted () {
        //console.log(this.disabled)
    },
    methods: {
        onClick:function(){
            this.$emit('on-click',this)
        }
    }
  }
</script>

<style scoped>
button{
    width: 100%;
    height: 40px;
    font-size: 18px;
    color: #fff;
    background-color: #3296fa;
    border: 0;
    cursor: pointer;
    outline:0
}
button:hover{
    background: -webkit-gradient(linear, left top, right top, from(#00aaff), to(#0085ff));
    background: linear-gradient(to right, #00aaff, #0085ff);
}
.bg-fff{
    background: #fff;
    border: 1px solid #3296FA;
    color: #3296FA;
}
.bg-fff:hover{
    background: #fff;
}
.bg-fff[disabled='disabled']{
    border:none;
    color:#fff;
}
.not-allowed,
.not-allowed:hover{
    background:#ccc;
    cursor:not-allowed;
}
</style>