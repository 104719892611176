<template>
    <div>
        <div class="no2">
            <div class="cph1">
                <span>您已绑定的邮箱是 ：{{email}}</span>
                <router-link to="/users/account/account_bdemail/account_bdemail_Cemail2">修改邮箱</router-link>
            </div>
            <p>邮箱绑定成功后，您可享有一下服务：</p>
            <ul>
                <li>邮箱地址登录：</li>
                <li>可直接“邮箱地址”登录本网站</li>
            </ul>
            <ul>
                <li>重要事件提醒：</li>
                <li>进行（支付/提现/选稿/中标）时，可及时收到邮件提醒</li>
            </ul>
            <ul>
                <li>找回账号密码：</li>
                <li>忘记密码时，可使用邮件找回密码</li>
            </ul>
            <ul>
                <li>账号保护：</li>
                <li>在您进行登录及修改密码等敏感操作是，未经您授权的操作将不被允许</li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        email:String
    },
    data(){
        return {

        }
    },
    components: {

    },
    methods:{
        reload:function(){
            this.$com.majax.get(this.$com.testUsIp + '/security/getOldEmail',{},{
                 success:(res)=>{
                     this.email=res.data
                 },
                 error:()=>{}
            })
        }
    },
    mounted:function(){
        this.$parent.$parent.titleMeg = '邮箱绑定';
        if (this.$route.query.reload) {
            this.reload()
        };
    },
}
</script>
<style scoped>
body{
    margin: 0;
    padding: 0;
}
ul,p{
    list-style: none;
    margin: 0;
    padding: 0;
}
div.no2{
    width:992px;height:580px;
    background:#fff;
    position: relative;
    padding:20px;
    box-sizing: border-box;
}
div.no2>.cph1{
    padding: 0 20px ;
    box-sizing: border-box;
    background: #f5f5f5;
    width: 100%;
    height: 60px;
    line-height: 60px;
    font-size: 16px;
    display: flex;
}
div.no2>.cph1 span{
    width: 350px
}
div.no2>.cph1>a{
    margin-top: 15px;
    line-height: 30px;
    display: block;
    width: 110px;
    height: 30px;
    background: #3296FA;
    border-radius: 4px;
    color: #fff;
    text-align: center;
    font-size: 14px;
    text-decoration: none;
    margin-left: 15px
}
div.no2>.cph1>a:hover{
    background: linear-gradient(to right, #00aaff, #0085ff);
}
div.no2>p{
    margin-top: 30px;
    margin-left: 20px;
    color: #333;
    font-size: 15px;
}
div.no2>ul{
    display: flex;
    font-size: 12px;
    line-height: 20px;
}
div.no2>ul>li:nth-child(1){
    width: 105px;
    text-align: right;
    color: #666;
}
div.no2>ul>li:nth-child(2){
    color: #999;
}
</style>
