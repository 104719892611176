<template>
    <div class="no2">

        <formItem :disabled="disabled" errorStyle="inline" title="认证项目" required type="threeSelectCategory" v-model="categoryId.value" :defaultList="category" :error="categoryId.error"/>

        <formItem :disabled="disabled" maxlength="50" required title="服务名称" type="text" errorStyle="inline"  placeholder="请填写服务名称" :error="serviceName.error" :value="serviceName.value" v-model="serviceName.value"/>

        <ul>
            <li><span class="required">*</span>&nbsp; 价格类型：</li>
            <li>
                <div v-for="(list,i) in Rlist" :key=i @click="bdChange(i)">
                    <input :disabled="disabled" :id="list.id" type="radio" name="lx" :value="list.value" v-model="priceType">
                    <label :for="list.id"></label>
                    <span>{{list.name}}</span>
                </div>
            </li>
        </ul>

        <formItem :disabled="disabled" maxlength="11" required v-if="priceType == 1" errorStyle="inline" title="服务价格" type="text"  placeholder="请填写服务价格" :error="price.error" :value="price.value" inputType="money" v-model="price.value"/>
        <ul v-if="priceType == 2">
            <li><span class="required">*</span>&nbsp; 服务价格：</li>
            <li>
                <input maxlength="11" :disabled="disabled" type="text" placeholder="请填写服务价格" inputType="money"  v-model="priceMin.value"> —— <input type="text" maxlength="11" :disabled="disabled" placeholder="请填写服务价格" inputType="money" v-model="priceMax.value">
                <span class="inline">{{priceMax.error}}</span>
            </li>
        </ul>
        <formItem maxlength="11" v-if="priceType == 3" errorStyle="inline" title="服务价格" type="text"  placeholder="请填写服务价格" disabled/>

        <!-- <formItem maxlength="20" required title="服务周期" type="text" errorStyle="inline" placeholder="请填写服务周期" :error="cycle.error" :value="cycle.value" v-model="cycle.value"/> -->

        <formItem :disabled="disabled" errorStyle="inline" @on-focus="(fn)=>$com.findCode('period_service',(res)=>{fn(res)})" rwidth="220" title="服务周期" required :error="cycle.error" type="select" placeholder="请选择服务周期" v-model="cycle.value" :selectList="cycle" />

        <formItem :disabled="disabled" maxlength="10" required title="联系人" type="text" errorStyle="inline" placeholder="请填写联系人姓名" :error="contact.error" :value="contact.value" v-model="contact.value"/>
        <formItem :disabled="disabled" maxlength="20" required title="联系电话" type="text" errorStyle="inline" placeholder="请填写联系人电话" :error="phone.error" :value="phone.value" v-model="phone.value"/>

        <!-- <ul class="p">
            <li><span class="required">*</span>&nbsp;封面图片：</li>
            <li class="item-inp">
                <div class="item-file-img">
                    <input title="点击上传图片" class="file" type="file">
                </div>
            </li>
        </ul> -->
        <formItem :disabled="disabled" required errorStyle="inline" type="file" :error="coveImage.error" :imgObg="imgObg" title="封面图片" v-model="coveImage.value" />

        <formItem :disabled="disabled" maxlength="255" required title="服务详情" type="textarea" errorStyle="inline" placeholder="请填写服务详情" :error="detail.error" :value="detail.value" v-model="detail.value"/>

        <ul>
            <li></li>
            <li><xbutton :disabled="disabled" @on-click="goAdd" value="保存并提交后台审核"></xbutton></li>
        </ul>
    </div>
</template>


<script>
import formItem from '@/components/UI/formItem.vue'
import Xselect from '@/components/UI/select.vue'
import xbutton from '@/components/UI/xbutton.vue'
import imgBg from '../../../assets/img/seller/file.jpg'
export default {
    data(){
        return{
            Rlist:[{id:"lx01",value:"1",name:"固定价格"},{id:"lx02",value:"2",name:"区间价格"},{id:"lx03",value:"3",name:"面议"}],
            SId:this.$route.query.SId,
            priceType:1,
            disabled:false,
            imgObg:{
                width:'140px',
                height:'140px',
                img:imgBg,
            },
            serviceName:{
                value:"",
                error:"",
                validate:['required'],
                name:"服务名称"
            },
            contact:{
                value:"",
                error:"",
                validate:['required'],
                name:"联系人"
            },
            phone:{
                value:"",
                error:"",
                validate:['required','phone'],
                name:"联系电话"
            },
            cycle:{
                value:"",
                label:"",
                error:"",
                validate:['required'],
                name:"服务周期"
            },
            price:{
                value:"",
                error:"",
                validate:['required','integer'],
                name:"服务价格"
            },
            priceMin:{
                value:"",
                error:"",
                validate:['required','integer'],
                name:"服务价格"
            },
            priceMax:{
                value:"",
                error:"",
                validate:['required','integer'],
                name:"服务价格"
            },
            detail:{
                value:"",
                error:"",
                validate:['required'],
                name:"服务详情"
            },
            categoryId:{
                value:"",
                error:"",
                validate:['required'],
                name:"服务项目"
            },
            category:[],
            coveImage :{
                value:"",
                error:"",
                validate:['required'],
                name:"封面图片"
            },
            cycleList:[]
        }
    },
    components: {
        formItem,Xselect,xbutton
    },
    methods:{
        bdChange(i){
            this.checkedValue=i+1
        },
        goAdd(_this){
            var data={
                categoryId:this.categoryId.value,
                contact:this.contact.value,
                coveImage:this.coveImage.value.fileId,
                cycle:this.cycle.value,
                detail:this.detail.value,
                id:this.SId,
                phone:this.phone.value,
                priceType:this.priceType,
                serviceName:this.serviceName.value,
                status:3,
            }

            var dataV = {
                contact:this.contact,
                detail:this.detail,
                phone:this.phone,
                serviceName:this.serviceName,
                cycle:this.cycle,
                coveImage:this.coveImage,
                categoryId:this.categoryId
            }
            if(this.priceType == 1){
                dataV.price=this.price;
                data.price=this.price.value
            }
            if(this.priceType == 2){
                dataV.priceMin=this.priceMin,
                dataV.priceMax=this.priceMax;
                data.priceMin=this.priceMin.value,
                data.priceMax=this.priceMax.value
            }
            var validate = this.$com.validate(dataV);
            if(validate){
                 _this.loading = true;
                 this.$com.majax.post(this.$com.testCwIp + '/sellerServices/saveOrUpdateService',this.$qs.stringify(data),{
                     success:(res)=>{
                         _this.loading = false;
                         if(res.code==0){
                             this.$dialog.alert('提交成功，请等待审核！','success',"",(_res)=>{
                                _res.thisShow = false;
                                this.$router.push({ name:'service_list' })
                             })
                         }
                     }
                 },_this)
             }
        },
    },
    created(){
        var id = this.SId
        if(this.SId !== undefined){
            this.$parent.titleMeg = '修改服务';
            this.$com.majax.get(this.$com.testCsIp + '/sellerServices/findServiceById/'+id,{},{
                success:(res)=>{
                    this.disabled = (res.data.status===1);
                    this.contact.value=res.data.contact;
                    this.detail.value=res.data.detail;
                    this.phone.value=res.data.phone;
                    this.priceType=res.data.priceType;
                    this.price.value=res.data.price;
                    this.priceMin.value=res.data.priceMin;
                    this.priceMax.value=res.data.priceMax;
                    this.serviceName.value=res.data.serviceName;
                    this.categoryId.value=res.data.categoryId;
                    this.cycle.value=res.data.cycle;
                    this.cycle.label=res.data.cycleName;
                    this.coveImage.value={imgUrl:res.data.imagePath,fileId:res.data.coveImage};
                    this.category = this.$com.GetObjList(res.data,'category');
                },
            })
        }else{
            this.SId='';
            this.$parent.titleMeg = '发布服务';
        }
    },
    mounted:function(){

    },
}
</script>

<style scoped>
    div.no2{
        width:992px;min-height: 893px;
        background:#fff;
        position: relative;
        padding: 15px 20px;
        box-sizing: border-box;padding-left: 120px
    }
    div.no2 ul{
        display: flex;
        line-height: 40px;
        margin-bottom: 20px
    }
    input[type="text"]{
        width: 187px;height: 40px;
        padding-left: 10px;box-sizing: border-box;
        border: 1px solid #ccc;color: #666;
    }
    div.no2 ul li:first-child span{
        color: #ff4a03
    }
    div.no2 ul li:first-child{
        width: 100px;text-align: right;
        margin-right: 7px;font-size: 14px;color: #333
    }
    div.no2 ul:nth-child(3){
        line-height: 40px;
        padding-top:  5px
    }
    ::-webkit-input-placeholder { color:#999; }
    ::-moz-placeholder { color:#999; } /* firefox 19+ */
    :-ms-input-placeholder { color:#999; } /* Internet Explorer 10+ */
    :-moz-placeholder { color:#999; }
    div.no2 ul:nth-child(3) li:nth-child(2)>div{
        position: relative;
        line-height: 40px;
        float: left;font-size: 14px;color: #666
    }
    div.no2 ul:nth-child(3) li:nth-child(2)>div{
        margin-right: 50px
    }
    div.no2 ul:nth-child(10) li:nth-child(2){
        position: relative;
    }
    input[type="radio"] {
        width: 10px;
        height: 10px;
        opacity: 0;
    }
    label {
        position: absolute;
        left: 0;
        top: 14px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 1px solid #999;
    }
    input:checked+label{
        background-color: #3296FA;
        border: 1px solid #006eb2;
    }
    input:checked+label::after {
        position: absolute;
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #fff;
        top: 2px;
        left: 2px;
        border: 2px solid #fff;
    }
    button{
        width: 220px;height: 40px;background: #3296FA;color: #fff;
        font-size: 18px;line-height: 40px;text-align: center;border: 0;cursor: pointer;
    }
    button:hover{
        background: linear-gradient(to right, #00aaff, #0085ff);
    }
    .item-file-img{
        width:244px;
        height:154px;
        position:relative;
        background:url('../../../assets/img/user/license.jpg');
    }
    .no2 .item-file-img .file{
        position: absolute;
        width:100%;
        height:100%;
        cursor:pointer;
        opacity:0;
        text-indent:-100px;
    }
    .item-file-img img{
        height:100%;
        width:100%;
    }
    .inline{
        font-size: 14px;color: red;margin-left: 5px
    }
    input:disabled{
        border: 1px solid #E4E7ED;
        background-color:#F5F7FA;
        color:#C3C8CF;
    }
</style>

