<template>
    <div>
        <Header />
        <div class="section-buyer">
            <div>
                <div>
                    <router-link to="/"><img src="../assets/img/index/logo1.png" alt=""></router-link>
                    <ul class="buy-nav">
                        <li><router-link to="/buyer/home" active-class="active">首页</router-link></li>
                        <li><router-link to="/buyer/demand" active-class="active">需求管理</router-link></li>
                        <li><router-link to="/buyer/order/order_list" active-class="active">订单管理</router-link></li>
                        <li><router-link to="/buyer/auth/auth_manage" active-class="active">认证管理</router-link></li>
                        <li><router-link to="/buyer/follow/my_favorite" active-class="active">我的关注</router-link></li>
                    </ul>
                    <!-- <input class="search-inp" type="text" placeholder="请输入关键字">
                    <img src="../assets/img/buyer/ss24.png" alt=""> -->
                </div>
            </div>
            <div>
                <div>
                    <NavVertical :navData="navData" />
                </div>
                <router-view></router-view>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import NavVertical from '@/components/NavVertical.vue'

export default {
    data:function(){
        return{
            navData:[{
                title:'需求管理',
                list:[{
                    title:'需求列表',
                    url:'/buyer/demand/demand_list',
                },{
                    title:'发布需求',
                    url:'/buyer/demand/post_demand',
                }]
            },{
                title:'订单管理',
                list:[{
                    title:'订单列表',
                    url:'/buyer/order/order_list',
                }]
            },{
                title:'认证管理',
                list:[{
                    title:'认证管理',
                    url:'/buyer/auth/auth_manage'
                },{
                    title:'新增认证',
                    url:'/buyer/auth/new_auth',
                }]
            },{
                title:'我的关注',
                list:[{
                    title:'我的收藏',
                    url:'/buyer/follow/my_favorite',
                },{
                    title:'我的足迹',
                    url:'/buyer/follow/my_footprint',
                }]
            }/*,{
                title:'自助认证',
                list:[{
                    title:'自助认证列表',
                    url:'/buyer/selfhelp/self_list',
                }]
            }*/],
        }
    },
    mounted:function(){
        //this.$com.isLogin();
    },
    components: {
        Header,Footer,NavVertical
    }
}
</script>


<style scoped>
    .section-buyer{
        width: 100%;
        min-width: 1200px;
        background: #f5f5f5;
        padding-bottom: 20px;
    }
    .section-buyer>div:nth-child(1){
        width: 100%;
        min-width: 1200px;
        height: 60px;
        background: #066CD4;
    }
    .section-buyer>div:nth-child(1)>div{
        width: 1200px;
        margin: 0 auto;
        display: flex;
        position: relative;
    }
    .section-buyer>div:nth-child(1)>div img:nth-child(1){
        position: absolute;
        top: 9px
    }
    .section-buyer>div:nth-child(1)>div>ul{
        display: flex;
        position: absolute;
        left: 210px;
        line-height: 60px;
    }
    .section-buyer>div:nth-child(1)>div>input{
        position: absolute;
        right: 0;top: 12px;background: #83B6EA;border: 0;
        width: 270px;height: 36px;border-radius: 5px;
        padding-left: 10px;box-sizing: border-box
    }
    .section-buyer>div:nth-child(1)>div>img:nth-child(4){
        position: absolute;
        top: 19px;right: 11px;
    }
    .section-buyer>div:nth-child(1)>div>ul li:hover{
        background: rgba(0, 0, 0, .1);
    }
    .buy-nav a{
        display: block;
        color: #f5f5f5;
        width: 100%;
        text-decoration: none;
        font-size: 16px;
        padding: 0 30px;
    }
    .buy-nav .active{
        background:rgba(0, 0, 0, .1);
    }
    .section-buyer>div:nth-child(2){
        width: 1200px;
        margin: 0 auto;
        margin-top: 17px;
        display: flex;
        justify-content: space-between
    }
    .section-buyer>div:nth-child(2)>div:nth-child(1){
        width:198px;height:620px;background:#fff
    }
    .section-buyer>div:nth-child(2)>div:nth-child(1)>ul{
        margin-top: 23px;
    }
    .section-buyer>div:nth-child(2)>div:nth-child(1)>ul>li{
        padding-left: 27px;
    }
    .section-buyer>div:nth-child(2)>div:nth-child(1)>ul>li:hover{
        background-color: #EAF4FE;
    }
    .section-buyer>div:nth-child(2)>div:nth-child(1)>ul>li a{
        text-decoration: none;
        color: #666
    }
    .section-buyer>div:nth-child(2)>div:nth-child(1)>ul>li a:hover{
        color: #3296FA
    }
    .section-buyer>div:nth-child(2)>div:nth-child(1) ul li span{
        padding-left: 20px;
        cursor: pointer;
        background: url("../assets/img/user/he.png") no-repeat center left;
        font-size: 16px;
        font-weight: bold;
        line-height: 46px;
        color: #666
    }
    .section-buyer>div:nth-child(2)>div:nth-child(1) ul li ul{
        display: none;
        line-height: 30px;
        color: #666;
        font-size: 14px;
        padding-left: 20px;
    }
    .section-buyer>div:nth-child(2)>div:nth-child(1) ul li ul li:hover{
        color: #3296FA;
    }
    .section-buyer>div:nth-child(2)>div:nth-child(1) ul .open{
        background: url("../assets/img/user/kai.png") no-repeat center left;
    }
    .section-buyer>div:nth-child(2)>div:nth-child(1) ul .open+ul{
        display: block;
    }
    .search-inp::-webkit-input-placeholder{
        color: #066bd3;
    }
    .search-inp:-moz-placeholder{
        color: #066bd3;
    }
    .search-inp::-moz-placeholder{
        color: #066bd3;
    }
    .search-inp:-ms-input-placeholder{
        color: #066bd3;
    }
</style>
