<template>
    <div class="no2">
        <!-- <ul class="search-wrap">
            <formItem v-model="search.title" title="需求标题" type="select" />
            <formItem v-model="search.createDate" title="发布时间" type="date" />
            <li><xbutton class="search-btn" @on-click="goSearch" value="搜索" /></li>
        </ul> -->
        <xtable :search="search" :head="head" :tableData="tableData" btn="buycar" @on-buycarClick="buycarClick" @on-changePage="(v)=>getData(v)" />
    </div>
</template>

<script>
import xtable from '@/components/UI/xtable.vue'
import formItem from '@/components/UI/formItem.vue'
import xbutton from '@/components/UI/xbutton.vue'
export default {
    data(){
        return{
            search:{},
            cardType:'请选择',
            typeList:[],
            head:{
                orgName:"参与单位",
                recommend:"推荐服务",
                remark:"商家留言",
                joinTime:"参与时间",
                deadline:"截止时间",
                createTime:"发布时间",
                demandStatus:"需求状态",
                bidStatus:"中标状态",
            },
            tableData:{}
        }
    },
    components: {
        xtable,
        formItem,
        xbutton
    },
    methods:{
        goSearch:function(){
            this.getData(this.search)
        },
        getData:function(data){
            var data = data||{};
            var id = this.$route.query.id
            if (id) {
                data.demandId = id;
                this.$com.majax.get(this.$com.testCsIp+'/buyerBid/selectBuyerBid',data,{
                    success:(res)=>{
                        this.tableData = res.data;
                    }
                })
            };
        },
        buycarClick:function(item){
            var data = {
                demandId:item.demandId,
                servicesId:item.servicesId,
                addCartLocation:2
            }
            this.$com.majax.post(this.$com.testCsIp+'/ucCart/AddCart',this.$qs.stringify(data),{
                success:(res)=>{
                    this.$message({
                        message: '添加成功！',
                        type: 'success',
                        duration:1000
                    });
                }
            })
        }
    },
    mounted(){
        this.$parent.titleMeg = '需求竞标单位';
        this.getData();
    }
}
</script>

<style scoped>
        div.no2{
            width:992px;min-height: 580px;
            background:#fff;
            position: relative;
            padding: 15px 20px;
            box-sizing: border-box
        }
        div.no2 ul{
            display: flex;
            background: #fff;
        }
        div.no2 ul span{
            font-size: 14px;
            color: #333
        }
        div.no2 ul>li{
            display: flex;
            align-items: center;
        }
        div.no2 ul>li:nth-child(2){
            margin-left: 50px;
            margin-right: 20px;
            position: relative;
        }
        div.no2 ul>li:nth-child(2) img{
            position: absolute;
            top: 23px;right: 10px;
        }
        div.no2 ul a{
            text-decoration: none;
            display: block;
            background: #3296FA;
            width: 80px;height: 38px;
            text-align: center;line-height: 38px;
            border-radius:4px ;color: #fff;font-size: 14px
        }
        div.no2 ul input[type=text]{
            width: 195px;
            height: 40px;
            border: 1px solid #ccc;
            box-sizing: border-box;
            padding-left: 10px;box-sizing: border-box
        }
        table{
            width: 100%;text-align: center;
            border-collapse: collapse
        }
        table th{
            height: 35px;background: #f5f5f5;
            font-size: 14px;color: #333;font-weight: normal;
            border-right:2px solid #fff ;border-bottom: 1px solid #fff ;
        }
        table tr:not(:first-child){
            border-bottom:2px dotted #ccc ;
        }
        table td{
            height: 53px;
            font-size: 12px;
        }
        table tr:hover{
            background: #f5f5f5;
        }
        table tr td:nth-child(5),td:nth-child(7){
            color: #999
        }
        table tr td:last-child{
            color: #3296FA
        }
        table tr td a{
            text-decoration: none;
            color: #066cd4
        }
        table tr td span{
            color: #ff4a03
        }
        table tr td:nth-child(3){
            width: 160px;height: 53px;
            display: -webkit-inline-box;
            line-height: 18px;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
        }
        .search-wrap{
            margin-bottom:15px;
        }
        .search-btn{
            font-size:14px;
            width:80px;
            height:34px;
            border-radius:5px;
        }
</style>

