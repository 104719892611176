<template>
    <div class="no2" v-if="formShow">
        <span>个人认证</span>
        <div @click="goAuth('base_auth_per')">
            <div></div>
            <div>
                <span>大陆个人快速实名认证</span>
                <span>此认证只支持中国大陆用户</span>
            </div>
        </div>
        <div>
            <div></div>
            <div>
                <span>港澳台个人快速实名认证</span>
                <span>需要进行人工审核，审核周期1-3个工作日</span>
            </div>
        </div>

        <span>企业认证</span>
        <div @click="goAuth('base_auth_bus')">
            <div></div>
            <div>
                <span>大陆企业实名认证</span>
                <span>请确保您是具有法人资格的商户，您需要提供相关的执照、证件等</span>
            </div>
        </div>
        <div>
            <div></div>
            <div>
                <span>境外企业实名认证</span>
                <span>请确保您是具有法人资格的商户，您需要提供相关的执照、证件等</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        navData:Array
    },
    data(){
        return {
            formShow:true
        }
    },
    components: {

    },
    methods:{
        goAuth:function(type){
            this.$router.push({ name:type })
        },
    },
    mounted:function(){
        //this.$com.isLogin(this);
        this.formShow = true;
        this.$parent.$parent.titleMeg = '实名认证';
    },
}
</script>

<style scoped>
    div.no1{
        width:992px;height:39px;background:#fff;
        margin-bottom: 2px;
    }
    div.no1>span{
        display: block;
        font-size: 16px;
        line-height: 37px;
        text-align: center;
        width: 120px;
        border-bottom: 2px solid #FF4A03;
    }
    div.no2{
        width:992px;
        height:580px;
        background:#fff;
        position: relative;
    }
    div.no2>span{
        position: absolute;
        top: 30px;
        left: 435px;
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        line-height: 44px;
        color: #435e92;
        width: 120px;
        border-bottom: 2px solid #435e92;
    }
    div.no2>span:nth-child(4){
        position: absolute;
        top: 266px;
    }
    div.no2>div{
        width: 400px;height: 120px;
        background-color: #f5f5f5;
        border-radius: 5px;
        position: absolute;
        top: 96px;
        left: 75px;
    }
    div.no2>div>div:nth-child(1){
        position: absolute;
        top: 31px;
        left: 40px;
        width: 58px;
        height: 58px;
    }
    div.no2>div:nth-child(2)>div:nth-child(1){
        background-image: url("../../../assets/img/user/31.png")
    }
    div.no2>div:nth-child(2):hover  div:nth-child(1){
        background-image: url("../../../assets/img/user/34.png")
    }
    div.no2>div:nth-child(3)>div:nth-child(1){
        background-image: url("../../../assets/img/user/hk_m_t_3997d31.png")
    }
    div.no2>div:nth-child(3):hover  div:nth-child(1){
        background: url("../../../assets/img/user/32.png") no-repeat
    }
    div.no2>div:nth-child(5)>div:nth-child(1){
        background-image: url("../../../assets/img/user/mainland_card_8ce58a4.png")
    }
    div.no2>div:nth-child(5):hover  div:nth-child(1){
        background: url("../../../assets/img/user/33.png") no-repeat;
    }
    div.no2>div:nth-child(6)>div:nth-child(1){
        background-image: url("../../../assets/img/user/mainland_face_e0f8829.png")
    }
    div.no2>div:nth-child(6):hover  div:nth-child(1){
        background: url("../../../assets/img/user/35.png") no-repeat
    }
    div.no2>div>div:nth-child(2)>span:nth-child(1){
        position: absolute;
        top: 33px;
        left: 112px;
        font-size: 18px;
        font-weight: bold;
    }
    div.no2>div>div:nth-child(2)>span:nth-child(2){
        position: absolute;
        top: 62px;
        left: 112px;
        font-size: 12px;
        width: 250px;
        color: #666
    }
    div.no2>div:nth-child(3){
        position: absolute;
        left: 515px;
    }
    div.no2>div:nth-child(5){
        position: absolute;
        top: 332px;
    }
    div.no2>div:nth-child(6){
        position: absolute;
        top: 332px;
        left: 515px;
    }
    div.no2>div:hover{
        background: #fa6e37;
        cursor: pointer;
        color: #fff
    }
    div.no2>div:hover div:nth-child(2)>span:nth-child(2){
        color: #fff;
    }
</style>
