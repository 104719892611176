<template>
    <div>
        <div class="no2">
            <formItem errorStyle="inline" maxlength="10" type="text" required title="收货人姓名" :error="receive.error" :value="receive.value" v-model="receive.value" placeholder="请输入收货人姓名"/>
            <formItem errorStyle="inline" maxlength="15" type="text" required title="手机号码" :error="phone.error" :value="phone.value" v-model="phone.value" placeholder="请输入手机号码"/>

            <!-- <formItem maxlength="15" type="text" title="固定电话" :error="tel.error" :value="tel" v-model="tel"/> -->

            <ul class="tel">
                <li>座机号码：</li>
                <li><input class="inp-zone" maxlength="5" v-model="zone.value" placeholder="区号" type="text"><input class="inp-number" maxlength="15" v-model="number.value" placeholder="请输入座机号码" type="text"></li>
            </ul>

            <ul class="select" >
                <li><span>*</span> 收货地址：</li>
                <li>
                    <threeSelect class="fl-l" @on-change="getArea" v-model="areaId.value" />
                    <p>{{areaId.error}}</p>
                </li>
            </ul>
            <formItem errorStyle="inline" maxlength="25" type="text" required title="详细地址" :error="detailAddress.error" :value="detailAddress.value" v-model="detailAddress.value" placeholder="请输入详细地址"/>
            <formItem maxlength="8" type="text" title="邮编" :value="zipCode" v-model="zipCode" placeholder="请输入邮编"/>
            <ul class="isdefault" >
                <li></li>
                <li><input type="checkbox" v-model="isdefault">默认地址</li>
            </ul>
            <ul>
                <li></li>
                <li><xbutton @on-click="goSubmit" value="保&nbsp;存" /></li>
            </ul>
        </div>
    </div>
</template>

<script>
import threeSelect from '@/components/UI/threeSelect.vue'
import formItem from '@/components/UI/formItem.vue'
import xbutton from '@/components/UI/xbutton.vue'
export default {
    props: {
        navData:Array
    },
    data(){
        return {
            formShow:true,
            areaId:{
                value:"",
                error:"",
                validate:['required'],
                name:"地区"
            },
            detailAddress:{
                value:"",
                error:"",
                validate:['required'],
                name:"详细地址"
            },
            phone:{
                value:"",
                error:"",
                validate:['required'],
                name:"手机号码"
            },
            receive:{
                value:"",
                error:"",
                validate:['required'],
                name:"收货人"
            },
            zone:{
                value:"",
            },
            number:{
                value:"",
            },
            tel:'',
            zipCode:'',
            isdefault:'',
            addId:this.$route.query.id,
        }
    },
    components: {
        threeSelect,formItem,xbutton
    },
    created(){
        var data= { id: this.addId }
        if(this.addId !== undefined){
            this.$com.majax.get(this.$com.testUsIp + '/receive/selectReceiveById',data,{
                success:(res)=>{

                    this.receive.value=res.data.receive;
                    this.phone.value=res.data.phone;
                    this.zone.value = res.data.zone;
                    this.number.value = res.data.number
                    this.$axios.get(this.$com.testUsIp + '/area/getAreasById/' +res.data.areaId).then(_res=>{
                        this.areaId.value=_res.data.data[0].id
                    });
                    this.zipCode=res.data.zipCode;
                    this.detailAddress.value=res.data.detailAddress;
                    if(res.data.isdefault === 0){
                        this.isdefault=true
                    }else{
                        this.isdefault=false
                    }
                },
            })
        }
    },
    methods:{
        goSubmit(_this){
            var isdefault
            if(this.isdefault){
                isdefault=0
            }else{
                isdefault=1
            }
            var data = {
                areaId:this.areaId.value,
                detailAddress:this.detailAddress.value,
                phone:this.phone.value,
                receive:this.receive.value,
                zone:this.zone.value,
                number:this.number.value,
                zipCode:this.zipCode,
                isdefault:isdefault,
                id:this.addId
            }
            var dataV = {
                areaId:this.areaId,
                detailAddress:this.detailAddress,
                phone:this.phone,
                receive:this.receive,
            };
            var validate = this.$com.validate(dataV)
            if(validate){
                _this.loading = true;
                this.$com.majax.post(this.$com.testUsIp + '/receive/saveOrUpdateReceive',this.$qs.stringify(data),{
                    success:(res)=>{
                        _this.loading = false;
                        if(res.code==0){
                            this.$dialog.alert('恭喜您，保存成功！','success',"",(res)=>{
                                res.thisShow = false;
                                this.$com.UrlBack(this,()=>{
                                    this.$router.push({ name:'address_list' });
                                });
                            })
                        }
                    },
                },_this)
            }
        },
        getArea:function(item){
            this.areaId.value=item.id
        }
    },
    mounted:function(){
        this.$parent.titleMeg = '新增地址';
    }
}
</script>

<style scoped>
    div.no2{
        width:992px;height:581px;
        background:#fff;
        position: relative;
        padding-top: 15px;
    }
    div.no2 ul{
        display: flex;
        margin-left: 84px;
        background: #fff;
        margin-bottom: 20px
    }
    div.no2 ul>li:nth-child(1){
        width: 108px;
        text-align: right;
        font-size: 14px;
        color: #333;
        padding-right: 8px
    }
    div.no2 span{
        color: red
    }
    div.no2 ul>li:nth-child(2) input{
        width: 415px;
        height: 40px;
        padding-left: 10px;
        box-sizing: border-box;
        border: 1px solid #ccc;
    }
    div.no2 ul.select{
        line-height: 40px;
        margin-bottom: 10px
    }
    div.no2 ul.select li:last-child{
        width: 740px;
    }
    div.no2 ul.select p{
        font-size: 14px;color: red;line-height: 20px;
        display: inline-block;
    }
    div.no2 ul>li:nth-child(2) button{
        width: 180px;
        height: 40px;
        color: #fff;
        background: #3296FA;
        border: 0;
        font-size: 18px;
        margin-top: 10px;
    }
    div.no2 ul>li:nth-child(2) button:hover{
        background: linear-gradient(to right, #00aaff, #0085ff);
        cursor: pointer;
    }
    div.no2 .isdefault>li:nth-child(2) input{
        width: 14px;height: 14px;vertical-align: bottom
    }
    div.no2 .isdefault>li:nth-child(2){
        font-size: 14px
    }
    .fl-l{
        float: left;
    }
    div.no2 ul>li:nth-child(1){
        line-height:40px;
    }
    .no2 ul>li:nth-child(2) input.inp-zone{
        width:80px;
        margin-right:10px;
    }
    .no2 ul>li:nth-child(2) input.inp-number{
        width:240px;
    }
</style>
