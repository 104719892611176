<template>
    <div class="content">
        <xtable fixed :loading="loading" index :head="head" :tableData="tableData" btn="upload" @on-uploadClick="UploadClick" @on-uploadDel="UploadDel"></xtable>
        <div class="submit-btn-wrap">
            <xbutton :disabled="disabled" class="submit-btn" @on-click="SubmitOrder" value="凭证上传完成"></xbutton>
        </div>
    </div>
</template>

<script>
import xbutton from '@/components/UI/xbutton.vue'
import xtable from '@/components/UI/xtable.vue'
export default {
    data(){
        return{
            id:this.$route.query.id,
            loading:false,
            disabled:true,
            tableData:{
                records:[{type:"咨询资料",name:""},{type:"证书图片",IMGurl:""},{type:"发票图片",IMGurl:""}]
            },
            head:{
                type:"凭证类型",
                name:"文件名称",
            }
        }
    },
    methods:{
        UploadDel:function(item){
            if (item.row.name) {
                this.tableData.records[item.$index].name = "";
                this.tableData.records[item.$index].fileId = "";
            }else{
                this.tableData.records[item.$index].IMGurl = "";
                this.tableData.records[item.$index].fileId = "";
            };

        },
        GetData:function(){
            this.$axios.get(this.$com.testCsIp + '/voucherOrder/getVoucherDetailsByOrderId/' + this.id)
            .then(res=>{
                var data = res.data.data;
                this.disabled = !(data.status=='4'||data.status=='5');
                if (data) {
                    this.tableData.records[0].name = this.substr(data.consultFilePath);
                    this.tableData.records[0].fileId = data.consultFileId;
                    this.tableData.records[1].IMGurl = data.certificateFilePath;
                    this.tableData.records[1].fileId = data.certificateFileId;
                    this.tableData.records[2].IMGurl = data.invoceFilePath;
                    this.tableData.records[2].fileId = data.invoceFileId;
                };
            })
        },
        substr:function(src){
            return src.substring(src.lastIndexOf('/')+1,src.length);
        },
        UploadClick:function(file,item){
            if (file.response) {
                for (var i = this.tableData.records.length - 1; i >= 0; i--) {
                    var It = this.tableData.records[i];
                    if (It.type === item) {
                        if (item === "咨询资料") {
                            It.fileId = file.response.data.fileId;
                            It.name = file.name;
                        }else{
                            It.IMGurl = file.response.data.imgUrl;
                            It.fileId = file.response.data.fileId;
                        };
                    };
                };
            };
        },
        SubmitOrder:function(){
            var data = {};
            data.orderId = this.id;
            for (var i = this.tableData.records.length - 1; i >= 0; i--) {
                var item = this.tableData.records[i];
                if (item.type === "咨询资料") {
                    data.consultFileId = item.fileId;
                }else if(item.type === "证书图片"){
                    data.certificateFileId = item.fileId;
                }else if(item.type === "发票图片"){
                    data.invoceFileId = item.fileId;
                };
            };
            if(!data.consultFileId){
                this.$dialog.alert("请上传咨询资料",'confirm')
                return false;
            }else if(!data.certificateFileId){
                this.$dialog.alert("请上传证书图片",'confirm')
                return false;
            }
            /*if (!data.consultFileId) {
                this.$dialog.alert("请上传咨询资料",'confirm')
                return false;
            }else if(!data.certificateFileId){
                this.$dialog.alert("请上传证书图片",'confirm')
                return false;
            }else if(!data.invoceFileId){
                this.$dialog.alert("请上传发票图片",'confirm')
                return false;
            };*/
            this.$com.majax.post(this.$com.testCsIp+'/voucherOrder/saveOrUpdateVoucherOrder',this.$qs.stringify(data),{
                success:(res)=>{
                    this.$dialog.alert("凭证上传成功",'success',"",(res)=>{
                        res.thisShow = false;
                        this.$router.push({ path:'/seller/order/order_list_seller' })
                    })
                }
            })
        }
    },
    components: {
        xbutton,
        xtable
    },
    mounted(){
        this.$parent.titleMeg = '上传凭证';
        this.GetData();

    }
}
</script>


<style scoped>
.content{
    background:#fff;
    padding:30px 20px;
}
.submit-btn-wrap{
    text-align:center;
    margin-top:20px;
}
.submit-btn{
    width:180px;
}
</style>
