<template>
    <div>
        <div class="no2">
            <ul>
                <li><span>需求单位名称：</span><input type="text" placeholder="请输入关键字" v-model="orgName"> </li>
                <li><span>资质名称：</span><input type="text" placeholder="请输入关键字" v-model="certificateName"> </li>
                <li><input type="button" value="查 询" @click="goSearch"></li>
                <li><input type="button" value="新增案例" class="btn1" @click="goAdd"> </li>
            </ul>
            <!-- <table>
                <tr>
                    <th>需求单位名称</th>
                    <th>资质名称</th>
                    <th>证书编号</th>
                    <th>办理日期</th>
                    <th>审核状态</th>
                    <th>添加时间</th>
                    <th>操作</th>
                </tr>
                <tr v-for="(list,i) in lists" :key="i">
                    <td>{{list.orgName}}</td>
                    <td>{{list.certificateName}}</td>
                    <td>{{list.certificateCode}}</td>
                    <td>{{list.executeTime.substr(0, 10)}}</td>
                    <td v-show="list.status===1" style="color:lightgreen">审核中</td>
                    <td v-show="list.status===0" style="color:green">审核通过</td>
                    <td v-show="list.status===2" style="color:#999">审核驳回</td>
                    <td v-show="list.status===9">已移除</td>
                    <td>{{list.createTime.substr(0, 10)}}</td>
                    <td><span @click="edit(list.id)">编辑</span> &nbsp;|&nbsp; <span href="##" @click="del(list.id,i,list.orgName)">删除</span>
                    </td>
                </tr>
            </table> -->
            <xtable :loading="loading" :search="search" :head="head" :tableData="tableData" btn="edit,del" @on-delClick="delClick" @on-editClick="editClick" @on-changePage="(v)=>getData(v)" />
        </div>
    </div>
</template>

<script>
import xtable from '@/components/UI/xtable.vue'
export default {
    data(){
        return {
            head:{
                orgName:"需求单位名称",
                certificateName:"资质名称",
                certificateCode:"证书编号",
                executeTime:"办理日期",
                status:"审核状态",
                createTime:"上传时间",
            },
            tableData:{},
            lists:[],
            certificateName:'',
            orgName:'',
            loading : false,
            search:{},
        }
    },
    components: {
        xtable
    },
    methods:{
        goAdd:function(){
            this.$router.push({ name:'new_case' })
        },
        editClick(item){
            this.$router.push({ name:'new_case',query:  { CId:item.id } })
        },
        delClick(item){
            this.$dialog.confirm('是否确认删除'+ item.certificateName,'error',()=>{
                 this.$com.majax.post(this.$com.testCsIp + '/sellerCases/deleteById/' + item.id,{},{
                     success:(res)=>{
                         if(res.code==0){
                            this.getData()
                         }
                     }
                 })
            })
        },
        goSearch(){
            this.search = {
                orgName:this.orgName,
                certificateName:this.certificateName
            }
            this.getData(this.search);
        },
        getData:function(data){
            this.loading = true;
            this.$com.majax.post(this.$com.testCsIp + '/sellerCases/findListCase',this.$qs.stringify(data),{
                success:(res)=>{
                    this.tableData=res.data   
                },
            },this)
        }

    },
    mounted:function(){
        this.$parent.titleMeg = '案例管理';
        this.getData()
    },
}
</script>

<style scoped>
    div.no2{
        width:992px;padding-bottom: 20px;
        min-height:520px;
        background:#fff;
        position: relative;
        padding: 20px;
    }
    div.no2 ul{
        display: flex;
        margin-left: 20px;
        background: #fff;
        line-height: 60px;
    }
    div.no2 ul span{
        font-size: 14px;
        color: #333
    }
    div.no2 ul>li:nth-child(2){
        margin-left: 55px;
    }
    div.no2 ul input[type=button]{
        width: 80px;
        height: 36px;border: 0;background: #3296FA;
        color: #fff;font-size: 16px;
        margin-top: 14px;
        margin-left: 8px;
        cursor: pointer;border-radius: 4px
    }
    div.no2 ul input[type=button]:hover{
        background: linear-gradient(to right, #00aaff, #0085ff);
    }
    div.no2 ul input.btn1{
        width: 100px;
        text-align: center;
        margin-left: 118px;
        border-radius:0 
    }
    div.no2 ul input[type=text]{
        width: 200px;
        height: 36px;
        border: 1px solid #ccc;
        box-sizing: border-box;
        padding-left: 10px
    }
    table{
        width: 950px;
        margin: 4px auto;
        text-align: center;
        border-left: none;
        border-top: none
    }
    td{
        border-bottom: none;
        border-right: none;
        height: 52px;
        font-size: 12px;
        color: #333;
        white-space:wrap;
        border-bottom: 1px dotted #ccc
    }
    table tr:not(:first-child):hover{
        background: #f5f5f5
    }
    table tr>th{
        height: 40px;font-size: 14px;color: #333;
        background: #f5f5f5;
        color: #333;border-right: 1px solid #fff;
        font-weight: normal
    }
    table td:first-child{
        width: 278px;padding-left: 20px;
        text-align: left
    }
    table tr>td:nth-child(2){
        width: 145px
    }
    table tr>td:nth-child(3){
        width: 113px
    }
    table td:nth-child(4),td:nth-child(6){
        color: #999
    }
    table td span{
        color: #333;
    }
    table td span:hover{
        cursor: pointer;
        color: #3296FA
    }
    .empty-img{
        display: block;
        margin:auto;
    }
</style>
