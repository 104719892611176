<template>
    <div>
    <div class="no2">
        <div>
            <div>
                <div>
                </div>
                <div>
                    <span>1</span>
                </div>
                <span>验证原邮箱</span>
            </div>
            <div>
                <div>
                </div>
                <div>
                    <span>2</span>
                </div>
                <span>绑定新邮箱</span>
            </div>
            <div>
                <div>
                </div>
                <div>
                    <span>3</span>
                </div>
                <span>绑定成功</span>
            </div>
        </div>
        <ul>
            <li>
                <img src="../../../assets/img/user/tuoyuan1_03.jpg" alt="">
                <span>恭喜您，修改邮箱绑定成功</span>
            </li>
            <li>
                <router-link to="/">返回首页</router-link>
            </li>
        </ul>
    </div>
    </div>
</template>
<script>
export default {
    props: {

    },
    data(){
        return {

        }
    },
    components: {

    },
    methods:{

    },
    mounted:function(){
        this.$parent.$parent.titleMeg = '修改绑定手机';
    },
}
</script>
<style scoped>

body{
    margin: 0;
    padding: 0;
}
ul,p{
    list-style: none;
    margin: 0;
    padding: 0;
}
div.no1{
    width:992px;height:39px;background:#fff;
    margin-bottom: 2px;
}
div.no1>span{
    display: block;
    font-size: 16px;
    line-height: 37px;
    text-align: center;
    width: 120px;
    border-bottom: 2px solid #FF4A03;
}
div.no2{
    width:992px;height:580px;
    background:#fff;
    position: relative;
    box-sizing: border-box;
    position: relative;
}
div.no2>div>div>div:nth-child(1){
    width: 267px;
    border: 1px solid #ff4a03;
    position: absolute;
    top: 96px;
    left: 95px
}
div.no2>div>div>div:nth-child(2){
    width: 30px;
    height: 30px;
    background-color: #ff4a03;
    transform:rotate(45deg);
    position: absolute;
    top: 82px;
    left: 208px;
    text-align: center;
    line-height: 30px;
}
div.no2>div>div>div:nth-child(2) span{
    display: block;
    color: #fff;
    transform: rotate(-45deg)
}
div.no2>div>div:nth-child(1)>span,div.no2>div>div:nth-child(2)>span{
    color: #ff4a03;
    font-size: 16px;
    position: absolute;
    top: 141px;
    left: 180px
}
div.no2>div>div:nth-child(2)>span,div.no2>div>div:nth-child(3)>span{
    position: absolute;
    top: 141px;
    left: 454px;
}
div.no2>div>div:nth-child(3)>span{
    position: absolute;
    left: 725px;
    top: 141px;
    color: #FF4A03
}
div.no2>div>div:nth-child(2)>div:nth-child(1){
    position: absolute;
    left: 361px;
}
div.no2>div>div:nth-child(2)>div:nth-child(2){
    position: absolute;
    left: 474px;
}
div.no2>div>div:nth-child(3)>div:nth-child(1){
    position: absolute;
    left:628px ;
}
div.no2>div>div:nth-child(3)>div:nth-child(2){
    position: absolute;
    left: 742px;
}
div.no2>ul{
    position: absolute;
    top: 243px;
    left: 351px;
    font-size: 18px;
}
div.no2>ul>li:first-child>img{
    vertical-align: text-bottom;margin-right:10px
}
div.no2>ul>li>a{
    font-size: 14px;
    margin-left: 35px;
    text-decoration: none;
    color: #3296fa;
}
</style>
