<template>
    <div>
        <div class="no1"><span>发票管理</span></div>
        <div class="no2">

            <formItem zIndex="15" errorStyle="inline" title="发票类型" required :error="invoiceType.error" type="select" v-model="invoiceType.value" placeholder="请选择发票类型" :selectList="invoiceType" @on-focus="(fn)=>fn(typeList)" />

            <formItem errorStyle="inline" title="发票抬头" required :error="title.error" type="select" v-model="title.value" placeholder="请选择发票抬头" :selectList="title" @on-focus="(fn)=>fn(lists)" />

            <formItem title="发票内容" v-model="content" />

            <formItem errorStyle="inline" maxlength="20" title="单位名称" required :error="orgName.error" type="text" v-model="orgName.value" placeholder="请输入单位名称" />

            <formItem errorStyle="inline" maxlength="25" title="发票税号" required :error="tfn.error" type="text" v-model="tfn.value" placeholder="请输入发票税号" />

            <ul>
                <li></li>
                <li><xbutton @on-click="goSubmit" value="保存发票信息" /></li>
            </ul>
        </div>
    </div>
</template>

<script>
import formItem from '@/components/UI/formItem.vue'
import Xselect from '@/components/UI/select.vue'
import xbutton from '@/components/UI/xbutton.vue'
export default {
    props: {

    },
    data(){
        return {
            lists:[{id:1,name:'单位名称'}],
            content:'根据服务内容',
            orgName:{
                value:"",
                error:"",
                validate:['required','org'],
                name:"单位名称"
            },
            tfn:{
                value:"",
                error:"",
                validate:['required'],
                name:"发票税号"
            },
            title:{
                value:"",
                label:"",
                error:"",
                validate:['required'],
                name:"发票抬头"
            },
            invoiceType:{
                value:"",
                label:"",
                error:"",
                validate:['required'],
                name:"发票类型"
            },
            listId:'',
            id:null,
            typeList:[{id:0,name:'普通发票'}],
            cardType:'请选择发票类型',
            ttType:'请选择发票抬头'
        }
    },
    components: {
        formItem,
        Xselect,
        xbutton
    },
    methods:{
        goSubmit:function(_this){
            if(this.id!==null){
                var data={
                    orgName:this.orgName.value,
                    title:this.title.value,
                    tfn:this.tfn.value,
                    content:this.content,
                    invoiceType:this.invoiceType.value,
                    isDefault:0,
                    id:this.id
                }
            }else{
                var data={
                    orgName:this.orgName.value,
                    title:this.title.value,
                    tfn:this.tfn.value,
                    content:this.content,
                    invoiceType:this.invoiceType.value,
                    isDefault:0,
                }
            }
            var dataV = {
                orgName:this.orgName,
                tfn:this.tfn,
                title:this.title,
                invoiceType:this.invoiceType
            }
            var validate = this.$com.validate(dataV);
            if(validate){
                _this.loading = true;
                this.$com.majax.post(this.$com.testUsIp+'/invoice/saveCommonInvoice',this.$qs.stringify(data),{
                    success:(res)=>{
                        _this.loading = false;
                        if(res.code==0){
                            this.$dialog.alert('保存成功！','success',"",(res)=>{
                                res.thisShow = false;
                                this.$com.UrlBack(this)
                            })
                        }
                    }
                },_this)
            }
        },
        typeChange:function(val){
            this.invoiceType.value = val.id
        },
        ttChange:function(val){
            this.title.value = val.id
        },
    },
    mounted:function(){
        this.$parent.titleMeg = '修改登录密码';
        this.$com.majax.get(this.$com.testUsIp + '/invoice/selectCommonInvoice',{},{
            success:(res)=>{
            if(res.data){
                this.orgName.value=res.data.orgName;
                this.tfn.value=res.data.tfn;
                this.content=res.data.content;
                this.title.value=res.data.title;
                this.invoiceType.value = res.data.invoiceType;
                this.id = res.data.id;

                this.invoiceType.label = this.$com.findName(res.data.invoiceType,this.typeList)
                this.title.label = this.$com.findName(res.data.title,this.lists)

                this.cardType = '普通发票';
                for (var i = this.lists.length - 1; i >= 0; i--) {
                    if(this.lists[i].id == res.data.title){
                        this.ttType = this.lists[i].name
                    }
                };
            }
        }})
    },
}
</script>

<style scoped>
    body{
        margin: 0;
        padding: 0;
        background-color: #f5f5f5
    }
    div.no1{
        width:992px;height:39px;background:#fff;
        margin-bottom: 2px;
    }
    div.no1>span{
            display: block;
            font-size: 16px;
            line-height: 37px;
            text-align: center;
            width: 120px;
            border-bottom: 2px solid #FF4A03;
    }
    div.no2{
        width:992px;height:580px;
        background:#fff;
        position: relative;
        padding-top: 30px;
    }
    div.no2 ul{
        display: flex;
        margin-left: 84px;
        background: #fff;
        margin-bottom: 20px;
    }
    div.no2 ul>li:nth-child(1){
        width: 100px;
        text-align: right;
        font-size: 14px;
        color: #333;
        line-height: 40px;
        margin-right: 7px;
    }
    div.no2 ul>li:nth-child(2) input{
        width: 415px;
        height: 40px;
        padding-left: 10px;
        box-sizing: border-box;
        border: 1px solid #ccc
    }
    div.no2 ul>li:nth-child(2) select{
        width: 415px;
        height: 40px;
        color: #666;
        padding-left: 10px;
        box-sizing: border-box;
        border: 1px solid #ccc
    }
    div.no2 ul.date>li:nth-child(2) input{
        width: 180px;
        height: 40px;
    }
    div.no2 ul>li:nth-child(2) button{
        width: 180px;
        height: 40px;
        color: #fff;
        background: #3296FA;
        border: 0;
        font-size: 18px;
        margin-top: 20px;
    }
    div.no2 ul>li:nth-child(2) button:hover{
        background: linear-gradient(to right, #00aaff, #0085ff);
        cursor: pointer;
    }
    div.no2 ul.a{
        line-height: 40px;
        font-size: 14px;
        color: #333;
    }
    div.no2 p{
        font-size: 12px;line-height: 0;
        color: red;
    }
    .required{
        color:red;
    }
    .error{
        color:red;
        font-size:14px;
        display:inline-block;
        margin-left:5px;
        line-height:40px;
    }
</style>
