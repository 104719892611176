<template>
    <div class="no2" v-if="domShow">
        <authenTitle :status="shop.status" :audit="shop.audit" />
        <div>
             <div><div></div>店铺信息</div>
             <div>
                <ul>
                    <li>店铺logo：</li>
                    <li><ximg width="100" height="100" :url="bigData.logoPath" /></li>
                </ul>
                <ul>
                    <li>店铺名称：</li>
                    <li>{{shop.shopName}}</li>
                </ul>
                <ul>
                    <li>店铺地址：</li>
                    <li>{{shop.fullAddress}}</li>
                </ul>
                <ul>
                    <li>店铺负责人：</li>
                    <li>{{shop.shopManager}}</li>
                </ul>
                <ul>
                    <li>负责人电话：</li>
                    <li>{{shop.managerPhone}}</li>
                </ul>
                <ul>
                    <li>座机电话：</li>
                    <li>{{shop.shopTel}}</li>
                </ul>
                <ul>
                    <li>经营描述：</li>
                    <li style="width:760px">{{shop.shopScope}}</li>
                </ul>
             </div>
        </div>
        <div v-if="org.name">
            <div><div></div>企业信息</div>
            <div>
                <ul>
                    <li>机构名称：</li>
                    <li>{{org.name}}</li>
                </ul>
                <ul>
                    <li>社会统一信用代码：</li>
                    <li>{{org.creditCode}}</li>
                </ul>
                <ul>
                    <li>注册地址：</li>
                    <li>{{org.detailAddress}}</li>
                </ul>
                <ul>
                    <li>成立时间：</li>
                    <li>{{org.establishTime.slice(0,10)}}</li>
                </ul>
                <ul>
                    <li>经营年限：</li>
                    <li>{{org.businessTerm==='0'?"长期":org.businessTerm}}</li>
                </ul>
                <ul>
                    <li>所属行业：</li>
                    <li>{{bigData.industryName}}</li>
                </ul>
                <ul>
                    <li>企业规模：</li>
                    <li>{{bigData.scaleName}}</li>
                </ul>
                <ul>
                    <li>营业执照照片：</li>
                    <ximg width="275" height="170" :url="bigData.licensePath" />
                </ul>
            </div>
        </div>
        <div v-if="personal.name">
            <div><div></div>个人信息</div>
            <div>
                <ul>
                    <li>姓名：</li>
                    <li>{{personal.name}}</li>
                </ul>
                <ul>
                    <li>地址：</li>
                    <li>{{personal.authority}}</li>
                </ul>
                <ul>
                    <li>证件类型：</li>
                    <li>{{issued}}</li>
                </ul>
                <ul>
                    <li>证件号码：</li>
                    <li>{{personal.idCard}}</li>
                </ul>
                <ul>
                    <li>证件有效期：</li>
                    <li>{{personal.expiration==='0'?"长期":personal.expiration}}</li>
                </ul>
                <ul>
                    <li>证件照片：</li>
                    <ximg width="275" height="170" :url="bigData.idPhotoBackPath" />
                    <ximg width="275" height="170" :url="bigData.idPhotoFrontPath" />
                </ul>
            </div>
        </div>
        <div>
            <div><div></div>银行信息</div>
            <div>
                <ul>
                    <li>开户银行：</li>
                    <li>{{bigData.bankName}}</li>
                </ul>
                <ul>
                    <li>支行名称/分理处：</li>
                    <li>{{shop.branchName}}</li>
                </ul>
                <ul>
                    <li>银行账号：</li>
                    <li>{{shop.bankAccount}}</li>
                </ul>
                <ul>
                    <li>单位名称：</li>
                    <li>{{shop.orgName}}</li>
                </ul>
            </div>
        </div>
        <div class="ywfw-wrap">
            <div><div></div>业务范围</div>
            <ul v-for="(item,index) in selectList" :key="index">
                <li>{{item.key}}：</li>
                <li><span v-for="(items,indexs) in item.list" :key="indexs">{{items}}</span></li>
            </ul>
        </div>
    </div>
</template>

<script>
import authenTitle from '@/components/UI/authenTitle.vue'
import ximg from '@/components/UI/ximg.vue'
export default {
    data(){
        return {
            id:"",
            selectList:[],
            shop:{},
            org:{},
            personal:{},
            domShow:false,
        }
    },
    components: {
        ximg,
        authenTitle
    },
    computed: {
        issued:function(){
            if(this.personal.issued===0){
                return "身份证"
            }else{
                return "护照"
            }
        }
    },
    methods:{
        getData:function(){
            this.$com.majax.get(this.$com.testCwIp+'/shop/findShopByUserId',{},{
                success:(res)=>{
                    this.bigData = res.data
                    this.$emit('on-load', false)
                    this.shop = res.data.shop
                    this.domShow = true
                    if (this.shop.status ===2 ) {
                        this.$router.push({ name: 'shop_base'})
                    };
                    if (res.data.org) {
                        this.org = res.data.org
                    };
                    if (res.data.personal) {
                        this.personal = res.data.personal
                    };
                    var category = res.data.category
                    var list = []
                    for(var key in category){
                        list.push({key:key,list:category[key]})
                    }
                    this.selectList = list

                    if (this.shop.status===0) {
                        var _data = this.$store.state.userType;
                        _data.shopStatus = 0;
                        this.$store.commit('SET_userType',_data);
                    };

                }
            })
        }
    },
    mounted(){
        this.$parent.titleMeg = '店铺信息';
        this.getData()
    }
}
</script>


<style scoped>
        div.no2{
            width:992px;
            background:#fff;
            position: relative;padding-bottom: 37px
        }
        div.no2>div{
            padding-left:42px;padding-right: 57px;
            box-sizing: border-box ;font-size: 14px;
        }
        div.no2>div>div:first-child{
            padding-top: 20px;
            display: flex;line-height: 30px;
            border-bottom: 2px dotted #ccc;
            font-weight: bold;margin-bottom: 10px
        }
        div.no2>div>div:first-child>div{
            width: 3px;height:14px ;
            background: #ff4a03;margin-top: 8px;
            margin-right: 9px
        }
        div.no2>div div:not(:first-child){
            display: flex;flex-wrap: wrap
        }
        div.no2>div ul{
            display: flex;
            line-height: 30px;
        }
        div.no2>div ul li:first-child{
            width: 150px;text-align: right;
            color: #666
        }
        div.no2>div ul li:last-child{
            width: 270px;color: #333
        }
       .no2>div:nth-child(4) li:last-child{
           width: 735px;
       }
       .no2>div:nth-child(4) li:last-child span{
           margin-right: 20px
       }
       .ywfw-wrap span{
            margin-right: 12px;
       }
       div.no2>div ul li.img-wrap{
            width:275px;
            height:170px;
       }
       .img-wrap img{
            width:100%;
            height:100%;
       }
       .status-wrap{
            font-size:14px;
            padding: 20px 0 0 40px;
       }
       .status-wrap li{
            display:inline-block;
       }
</style>