<template>
    <div class="indx">
        <div class="no1" v-loading="loading">
            <!-- <div v-for="(list,i) in lists" :key="i" @click="goToS(list.id)" class="mores" >
                <p><img :src="list.coveImagePath" class="img1" alt=""></p>
                <p :title="list.serviceName">{{list.serviceName}}</p>
                <p v-if="list.priceType === 1">￥{{list.price}}</p>
                <p v-if="list.priceType === 2">￥{{list.priceMin}}-￥{{list.priceMax}}</p>
                <p v-if="list.priceType === 3">面议</p>
                <p>周期：{{list.cycleName}}</p>
            </div> -->
            <goodsItem v-show="lists.length > 0" type="money" v-for="(item,index) in lists" :key="index" :goodsData="item" @on-clickDetail="goToS(item.id)" />
            <pagination v-if="total>10" :total="total" :current="current" :pageSize="10" @pageChange="goQuery"/>
            <img v-show="lists.length == 0" class="empty-img" src="../../assets/img/user/empty_page.jpg" alt="暂无数据">
        </div>
    </div>
</template>

<script>
import goodsItem from '@/components/UI/goodsItem.vue'
import pagination from '@/components/UI/Pagination.vue'
export default {
    props:['id'],
    data(){
        return{
            lists:[],
            total:'',
            loading:true,
            current:1,
        }
    },
    methods:{
        goQuery(page){
            this.$axios.get(this.$com.testCsIp + '/shop/shopManageServiceList/' + this.id +'/'+ page +'/10')
            .then(res=>{
                if(res.data.code == 0){
                    this.loading=false;
                    if (res.data.data.records.length>0) {
                        for (var i = 0; i < res.data.data.records.length; i++) {
                            var item = res.data.data.records[i];
                            res.data.data.records[i].name = item.serviceName;
                            res.data.data.records[i].imagePath = item.coveImagePath;
                            res.data.data.records[i].cycle = item.cycleName;
                        };
                    };
                    this.lists = res.data.data.records;
                    this.total=res.data.data.total;
                }
            })
        },
        goToS(id){
            var routeData = this.$router.resolve({ name:'detail_page', query:  { id:id ,ShopId:this.id} });
            window.open(routeData.href, '_blank');
            //this.$router.push({ name:'detail_page', query:  { id:id }})
        },
    },
    components: {
        goodsItem,pagination
    },
    mounted(){
        this.goQuery(1);
    }
}
</script>


<style scoped>
    .indx{
        min-width: 1200px;background: #f5f5f5;
        padding: 20px 0 0.1px 0
    }
    .no1{
        width: 1200px;background: #fff;
        margin: 0 auto;
        padding: 20px 0 0 20px;min-height: 360px;
        overflow: hidden;margin-bottom: 20px
    }
    .no1>div{
        margin-right: 16px;
        margin-bottom: 19px;
    }
    .no1>div p:first-child{
        width:217px;height: 162px;
    }
    .no1>div p:nth-child(2){
        font-size: 16px;font-weight: bold;
        margin: 18px 12px 0 12px;
        overflow: hidden;white-space: nowrap;text-overflow: ellipsis;
    }
    .no1>div p:nth-child(3){
        font-size: 18px;color: #e4393c;
        font-weight: bold;margin-left: 12px
    }
    .no1>div p:nth-child(4){
        font-size: 12px;color: #666;
        margin-left: 12px
    }
    .img1{
        width: 215px;height: 162px;
        border-bottom: 1px solid #ccc
    }
    .mores:hover{
        cursor: pointer;
        box-shadow: 3px 3px 5px 0 #999
    }
    .empty-img{
        display: block;
        margin: auto
    }
</style>
