<template>
        <div class="no2">
            <authenTitle class="authen-title" :status="status" :audit="audit" />
            <formItem maxlength="20" errorStyle="inline" :disabled="disabled" width="150" title="机构名称" required :error="name.error" ps="请填写与营业执照上一致的名称" v-model="name.value" type="text" placeholder="请填写企业名称" />

            <ul class="addr">
                <li class="addr-label"><span class="required">*</span> &nbsp;联系地址：</li>
                <li class="addr-sel">
                    <threeSelect :disabled="disabled" @on-change="getArea" v-model="areaId.value" />
                    <div class="single-inp">
                        <div v-if="disabled" class="inp-dis"></div>
                        <input maxlength="25" :class="{'item-dis':disabled}" type="text"  placeholder="请输入详细地址" v-model="detailAddress.value">
                    </div>
                    <span class="error">{{detailAddress.error||areaId.error}}</span>
                </li>
            </ul>

            <formItem maxlength="25" errorStyle="inline" :disabled="disabled" width="150" title="社会统一信用代码" required :error="creditCode.error" v-model="creditCode.value" type="text" placeholder="请填写信用代码" />
            <ul class="p">
                <li><span>*</span> 营业执照5555：</li>
                <li class="item-inp">
                    <div class="item-file-img" v-loading="frontLoading">
                        <input :disabled="disabled" title="点击上传图片" class="file" type="file" @change="changeFile">
                        <img :src="license.value.imgUrl" v-if="license.value.imgUrl"/>
                    </div>
                </li>
                <!-- <li><img src="../../../assets/img/user/zj.png" alt=""></li> -->
                <li>
                    <p>• &nbsp; 必须为<span>清晰、完整</span>的彩色原件扫描或数码照</p>
                    <p>• &nbsp; 仅支持JPG、PNG、JPEG的图片格式，图片<span>小于1M</span> </p>
                    <p>• &nbsp; 必须在有效期内且年检章齐全（当年成立公司可无年检章）</p>
                    <p>• &nbsp; 必须为中国大陆工商局颁发</p>
                    <span class="error">{{ license.error }}</span>
                </li>
            </ul>

            <ul class="date">
                <li><span>*</span> 营业年限：</li>
                <li class="item-inp">
                    <input :disabled="disabled" v-model="dateType" value="1" class="date-radio" type="radio" >
                    <el-date-picker
                        @change="dateChange"
                        v-model="businessTerm.value"
                        type="date"
                        :disabled="disabled"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                    </el-date-picker>
                </li>
                <li class="item-inp-cq">&nbsp; <input value='0' :disabled="disabled" v-model="dateType" type="radio" ><span>长期</span></li><span class="error lh40">{{ businessTerm.error }}</span>
            </ul>

            <formItem :disabled="disabled" required width="150" maxlength="25" errorStyle="inline" title="成立时间" type="date" v-model="establishTime.value" placeholder="请选择成立时间" />

            <formItem zIndex="15" errorStyle="inline" :disabled="disabled" width="150" title="所属行业" required :error="industry.error" type="select" v-model="industry.value" :selectList="industry" placeholder="请选择所属行业" @on-focus="(fn)=>$com.findCode('industry',(res)=>{fn(res)})" />

            <formItem errorStyle="inline" :disabled="disabled" width="150" title="企业规模" required @on-focus="(fn)=>$com.findCode('scale',(res)=>{fn(res)})" :error="scale.error" type="select" placeholder="请选择企业规模" v-model="scale.value" :selectList="scale" />

            <formItem maxlength="200" rheight="120" errorStyle="inline" width="150" :disabled="disabled" title="经营范围" required :error="businessScope.error" type="textarea" v-model="businessScope.value" placeholder="请输入经营范围" />
            <ul>
                <li></li>
                <li><xbutton :class="{'btn-dis':disabled}" :disabled="disabled" @on-click="goSubmit" value="提交审核" /></li>
            </ul>
        </div>
</template>

<script>
import formItem from '@/components/UI/formItem.vue'
import Xselect from '@/components/UI/select.vue'
import threeSelect from '@/components/UI/threeSelect.vue'
import xbutton from '@/components/UI/xbutton.vue'
import authenTitle from '@/components/UI/authenTitle.vue'
export default {
    props: {
        navData:Array
    },
    data(){
        return {
            dateType:"1",
            formShow:true,
            name:{
                value:"",
                error:"",
                validate:['required','org'],
                name:"机构名称",
            },
            industryList:[],
            scaleList:[],
            areaId:{
                value:"",
                error:"",
                validate:['required'],
                name:"地址",
            },
            detailAddress:{
                value:"",
                error:"",
                validate:['required'],
                name:"地址",
            },
            creditCode:{
                value:"",
                error:"",
                validate:['required','credit'],
                name:"社会统一信用代码",
            },
            businessTerm:{
                value:"",
                error:"",
                validate:['required'],
                name:"营业年限",
            },
            establishTime:{
                value:"",
                error:"",
                validate:['required'],
                name:"成立时间",
            },
            scale:{
                value:"",
                valueName:"请选择规模",
                error:"",
                label:"",
                validate:['required'],
                name:"企业规模",
            },
            license:{
                value:"",
                error:"",
                validate:['required'],
                name:"营业执照",
            },
            businessScope:{
                value:"",
                error:"",
                validate:['required'],
                name:"经营范围",
            },
            industry:{
                value:"",
                label:"",
                valueName:"请选择行业",
                error:"",
                validate:['required'],
                name:"所属行业",
            },
            status:"",
            id:"",
            disabled:false,
            audit:"",
            frontLoading:false
        }
    },
    components: {
        formItem,
        Xselect,
        threeSelect,
        xbutton,
        authenTitle
    },
    computed:{
        titleVal:function(){
            var val = "";
            switch(this.status){
                case 1:
                    val = '提交成功，等待审核';
                    break;
                case 2:
                    val = '非常抱歉，您的请求被驳回';
                    break;
                case 0:
                    val = '恭喜您，审核通过！'
                    break;
            }
            return val
        },
        userType:function(){
            return this.$store.state.userType
        }
    },
    methods:{
        goSubmit:function(_this){
            var dataV = {
                areaId:this.areaId,
                businessScope:this.businessScope,
                businessTerm:this.businessTerm,
                creditCode:this.creditCode,
                detailAddress:this.detailAddress,
                industry:this.industry,
                license:this.license,
                name:this.name,
                scale:this.scale,
                establishTime:this.establishTime,
            }

            var data = {};

            for(var key in dataV){
                data[key] = dataV[key].value
            }
            if (this.id) {
                data.id = this.id
            };
            if (this.dateType==='0') {
                delete dataV.businessTerm
                data.businessTerm = '0'
                this.businessTerm.error = ""
            };
            var validate = this.$com.validate(dataV);
            if (validate) {
                data.license = this.license.value.fileId;
                _this.loading = true;
                this.$com.majax.post(this.$com.testUsIp + '/org/orgCertificate',this.$qs.stringify(data),{
                    success:(res)=>{
                        this.$dialog.alert("恭喜您，提交成功！请等待后台审核！",'success',() => {
                            this.getOrgData()
                        },(res)=>{
                            res.thisShow = false;
                            this.getOrgData()
                        })
                    }
                },_this)
            };
        },
        //根据id获取地址信息
        addressData:function(id,fn){
            this.$axios.get(this.$com.testUsIp+'/area/getAreasById/'+id)
            .then((response) => {
                var res = response.data;
                fn(res.data)
            })
            .catch((error) => {

                this.$dialog.alert("系统错误",'error')
            })
        },
        industryChange:function(val){
            this.industry.value = val;
        },
        changeFile:function(e){
            var files = e.target.files[0];
            if (files.type.indexOf('image')==-1) {
                this.$dialog.alert("只能上传图片",'confirm')
            }else if(files.size/1024>1024){
                this.$dialog.alert("图片大小不能超过1M",'confirm')
            }else{
                var image = new FormData();
                image.append('file', files);
                this.frontLoading = true
                this.$com.majax.post(this.$com.testImgin+'/upload/upload-file', image,{
                    success:(res) => {
                        this.frontLoading = false;
                        this.license.value = res.data;
                    },
                    error:() => {
                        this.frontLoading = false
                        this.$dialog.alert('上传失败，请重试','error')
                    }
                })
            };
        },
        getCode:function(code,fn){
            this.$com.findCode(code,(res)=>{
                fn(res)
            })
        },
        //获取企业认证信息
        getOrgData:function(){
            this.$com.majax.get(this.$com.testUsIp+'/org/selectOrgByUser', {},{
                success:(res) => {
                    var data = res.data;
                    if (data) {
                        this.areaId.value = data.areaId;
                        this.businessScope.value = data.businessScope;
                        this.businessTerm.value = data.businessTerm;
                        this.establishTime.value = data.establishTime;
                        this.creditCode.value = data.creditCode;
                        this.detailAddress.value = data.detailAddress;
                        this.industry.value = data.industry;
                        this.industry.label = data.industryName;
                        this.license.value = {fileId:data.license,imgUrl:data.licensePath};
                        this.name.value = data.name;
                        this.scale.value = data.scale;
                        this.scale.label = data.scaleName;
                        this.audit = data.audit;
                        this.status = data.status;
                        this.id = data.id;

                        var userType = this.userType;
                        userType.orgStatus = data.status;
                        this.$store.commit('SET_userType',userType);

                        //日期为长期判断
                        if (data.businessTerm=='0') {
                            this.businessTerm.value = "";
                            this.dateType = '0'
                        }

                        if (data.status===1||data.status===0) {
                            this.disabled = true
                        };

                    }else{
                        this.status = 3
                    };
                },error:function(res){
                        console.log(res,'////////////')
                }
            })
        },
        getArea:function(val){
            this.areaId.value = val.id;
        },
        dateChange:function(){
            this.dateType = "1";
        }
    },
    mounted:function(){
        //this.$com.isLogin(this);
        this.$parent.formShow = false;
        this.$parent.$parent.titleMeg = '企业认证';
        this.getOrgData();
    },
}
</script>

<style scoped>
        body{
            margin: 0;
            padding: 0;
        }
        ul,p{
            list-style: none;
            margin: 0;
            padding: 0;
        }
        div.no1{
            width:992px;height:39px;background:#fff;
            margin-bottom: 2px;
        }
        div.no1>span{
            display: block;
            font-size: 16px;
            line-height: 37px;
            text-align: center;
            width: 120px;
            border-bottom: 2px solid #FF4A03;
        }
        div.no2{
            width:992px;
            background:#fff;
            position: relative;
            padding-top: 15px;
            padding-bottom: 20px;
        }
        div.no2 ul{
            display: flex;
            margin-left: 84px;
            background: #fff;
            margin-bottom: 20px;
        }
        div.no2 ul>li:nth-child(1){
            width: 150px;
            text-align: right;
            font-size: 14px;
            color: #333;
            line-height:40px;
            margin-right: 7px;
        }
        div.no2 ul span{
            color: red
        }
        div.no2 ul:nth-child(1) li:nth-child(3){
            color:#999;font-size:12px;width:340px;
            line-height:18px;margin-top: 22px;margin-left: 10px
        }
        /* div.no2 ul>li:nth-child(2) input{
            width: 370px;
            height: 40px;
            padding-left: 10px;
            box-sizing: border-box;
            border: 1px solid #ccc
        } */
        div.no2 ul.addr{
            padding: 5px 0
        }
        div.no2 ul>li:nth-child(2) select{
            width: 180px;
            height: 40px;
            color: #666;
            padding-left: 5px;
            box-sizing: border-box;
            border: 1px solid #ccc;
            margin-right: 5px;
        }
            div.no2 ul.date>li:nth-child(2){
        position: relative;
    }
    div.no2 ul.date>li:nth-child(2)>img{
        position: absolute;
        right: 10px;
        top: 25px
    }
        div.no2 ul.p>li:nth-child(3){
            margin-top: 52px;
            margin-left: 20px;
        }
        div.no2 ul>li:nth-child(2) textarea{
            width:370px;height:114px; margin-top: 10px;
            border: 1px solid #ccc;
            padding:10px;
        }
        div.no2 ul.p>li:nth-child(3)>p{
            padding: 0;
            line-height: 20px;
            font-size: 12px;
            color: #666;
            background: #fff
        }
        div.no2 ul>li:nth-child(2) button{
            width: 180px;
            height: 40px;
            color: #fff;
            background: #3296FA;
            border: 0;
            font-size: 16px;
            margin-top: 20px;
        }
        div.no2 ul>li:nth-child(2) button:hover{
            background: linear-gradient(to right, #00aaff, #0085ff);
            cursor: pointer;
        }
        .addr-sel{
            width:75%;
        }
        .item-inp-cq{
            line-height:40px;
        }
        .no2 .item-inp-cq span{
            color:#666;
        }
        .item-file-img{
            width:244px;
            height:154px;
            position:relative;
            background:url('../../../assets/img/user/license.jpg');
        }
        .no2 .item-file-img .file{
            position: absolute;
            width:100%;
            height:100%;
            cursor:pointer;
            opacity:0;
            text-indent:-100px;
        }
        .item-file-img img{
            height:100%;
            width:100%;
        }
        textarea{
            resize:none;
        }
        div.no2 ul .error{
            display:inline-block;
            font-size:14px;
            margin-left:5px;
        }
        div.no2 ul>li:nth-child(2) button.btn-dis{
            background:#ccc;
            cursor:not-allowed;
        }
        div.no2 ul>li:nth-child(2) .date-radio{
            width:auto;
            height:auto;
        }
        .single-inp{
            width:415px;
            height:40px;
            position:relative;
            display:inline-block;
        }
        .single-inp input{
            width: 100%;
            height: 40px;
            border: 1px solid #ccc;
            font-size: 14px;
            padding-left: 10px;
            vertical-align: middle;
        }
        .single-inp .item-dis{
            background-color: #f5f7fa;
            border-color: #e4e7ed;
            color: #c0c4cc;
        }
        .inp-dis{
            position:absolute;
            width:100%;
            height: 100%;
            top: 0;
            left: 0;
            cursor: not-allowed;
        }
        .auth-title{
            margin: 0 auto;
            margin-top: 10px;
            width: 950px;
            height: 100px;
            background: #f5f5f5;
            position: relative;
            margin-bottom:30px;
        }
        .auth-title .auth-title-b{
            position: absolute;
            top: 25px;
            left: 220px;
            color: #ff4a03;
            font-size: 18px;
            font-weight: bold;
        }
        .auth-title .auth-title-s{
            position: absolute;
            top: 54px;
            left: 220px;
            color: #999;
            font-size: 12px;
        }
        .auth-title img{
            position: absolute;
            top: 16px;
            left: 130px;
            width: 68px;
        }
.lh40{
    line-height:40px;
}
.authen-title{
    padding:0 0 0 132px;
}
</style>