<template>
    <div class="no2">
        <xtable index :loading="loading" :search="search" :head="head" :tableData="tableData" btn="edit,download" @on-editClick="editClick" @on-downloadClick="downloadClick" @on-changePage="(v)=>getData(v)" @on-handleSelectionChange="(v)=>checkboxList=v" />
        <div class="bottom">
            <xbutton btnType="bg-fff" width="175" class="btn-back" @on-click="goAdd" value="申请平台咨询师审核服务"></xbutton>
            <xbutton class="btn-next" @on-click="goAdd" value="申请平台审核及认证服务"></xbutton>
        </div>
    </div>
</template>

<script>
import xtable from '@/components/UI/xtable.vue'
import xbutton from '@/components/UI/xbutton.vue'
import xstep from '@/components/UI/xsteps.vue'
export default {
    data(){
        return{
            loading:false,
            search:{},
            tableData:[],
            head:{
                resourceTypeName:"文件类型",
                fileList:"部门制度",
            },
        }
    },
    methods:{
        getData(){
            var autoServiceId = this.$route.query.autoServiceId;
            this.$com.majax.get(this.$com.testChIp+'/auto-service/getAutoServiceByCategoryId/' + autoServiceId,{},{
                success:(res)=>{
                    console.log(res)
                    if(res.code == 0){
                        this.loading=false;
                        this.tableData.records = res.data.fileData.fileList;
                    }
                }
            })
        },
        goAdd(){},
        editClick(){},
        downloadClick(){},
    },
    components: {
        xbutton,
        xstep,
        xtable
    },
    mounted:function(){
        this.getData();
        this.$parent.titleMeg = '自助认证';
        this.$parent.rightLink = true;
        this.$router.beforeEach((to, from ,next) => {
            this.$parent.rightLink = false;
            next();
        });
    },
}
</script>

<style scoped>
.no2{
    width:992px;
    background:#fff;
    padding: 25px 20px 15px 20px;
}
.bottom{
    clear:both;
    overflow:hidden;
    margin-top:20px;
}
.bottom button{
    font-size:14px;
    height:34px;
}
.btn-back{
    width:80px;
    float:left;
}
.btn-next{
    width:170px;
    float:right;
}
</style>

