<template>
    <div class="banner-wrap" :class="{'hide':hide}" v-if="list.length>0">
        <a class="banner-link" :style="{background: 'url(' + list[0].adImage + ') no-repeat center ' + (list[0].adBgColor||'')}" v-if="list[0].adUrl" target="_blank" :href="list[0].adUrl"></a>
        <a class="banner-link" v-else :style="{background: 'url(' + list[0].adImage + ') no-repeat center' + (list[0].adBgColor||'')}"></a>
    </div>
</template>


<script>
export default {
    props: {
        list:Array
    },
    data() {
        return {
            hide:false
        }
    },
    methods : {

    },
    mounted () {
        setTimeout(()=>{
            this.hide = true
        },5000)
    }
}
</script>

<style scoped>
.banner-wrap{
    height:120px;
    transition: all 2s;
    -moz-transition: all 2s;
    -webkit-transition: all 2s;
    -o-transition: all 2s;
    margin-top:0;
    position:relative;
    overflow:hidden;
}
.banner-link{
    position:absolute;
    top:0;
    left:0;
    transition: all 2s;
    -moz-transition: all 2s;
    -webkit-transition: all 2s;
    -o-transition: all 2s;
    height:100%;
    width:100%;
    display:block;
}
.hide{
    height:0;
}
</style>
