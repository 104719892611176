<template>
    <div>
        <div v-if="titleMeg" class="no1">
            <span>{{titleMeg}}</span>
            <a class="right-link" href="#" v-if="rightLink">下载质量体系认证标准文件</a>
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    data(){
        return {
            rightLink:false,
            titleMeg:'',
            selfStep:['自助认证开始','质量管理手册','程序文件','制度文件','日志文件','自助认证完成']
        }
    },
    components: {

    },
    methods:{

    },
    mounted:function(){

    },
}
</script>

<style scoped>
.no1{
    width:992px;height:39px;background:#fff;
    margin-bottom: 2px;
}
.no1>span{
    float:left;
    padding:0 20px;
    font-size: 16px;
    line-height: 37px;
    text-align: center;
    border-bottom: 2px solid #ff4a03;
}
.right-link{
    line-height:37px;
    float:right;
    margin-right:20px;
    font-weight:bold;
}
</style>

