<template>
    <div class="banner">
        <ul>
            <el-carousel height="450px">
                <el-carousel-item v-for="(item,index) in list" :key="index">
                    <a v-if="item.adUrl" target="_blank" :href="item.adUrl"><li class="banner-img" :style="{background: 'url(' + item.adImage + ') no-repeat center' + (item.adBgColor||'')}"></li></a>
                    <a v-else><li class="banner-img" :style="{background: 'url(' + item.adImage + ') no-repeat center' + (item.adBgColor||'')}"></li></a>
                </el-carousel-item>
            </el-carousel>
        </ul>
    </div>
</template>

<script>
/*import img1 from '@/assets/img/index/banner2.png'
import img2 from '@/assets/img/index/banner0.png'*/
export default {
    props: {
        list:Array
    },
    data(){
        return{

        }
    },
    mounted:function(){

    },
    components: {

    },
    methods: {

    }
}
</script>

<style scoped>
.banner{
    width:100%;
    position:relative;
}
</style>

