<template>
    <div class="nav-left">
        <ul v-if="index<6" v-for="(list,index) in sList" :key="index" @mouseenter="i(index)">
            <span>{{list[0]}}</span>
            <li>
                <router-link :class="{'active':list[2].length===2}" v-for="(li,index) in list[2]" :key="index" :to="{path:'/search_page',query:{title:li.name,id:li.id,parentId:li.parentId}}" :title="li.name"  target="_blank">{{li.catAbbr}}</router-link>
            </li>
        </ul>
        <div class="board">
            <!-- <div class="fl" v-for="(data,i) in datap" :key="i">
                <div><span :title="data[0]">{{data[0]}}</span><img src="../../../assets/img/index/jiantou.png"></div>
                <div>
                    <router-link v-for="(dat,i) in data[1]" :key="i" :to="{path:'/search_page',query:{title:dat.name,id:dat.id,parentId:dat.parentId}}" target="_blank">{{dat.name}}</router-link>
                </div>
            </div> -->
            <div class="fl" v-for="(item,index) in datap" :key="index">
                <div><span :title="item[0]">{{item[0]}}</span><img src="../../../assets/img/index/jiantou.png"></div>
                <div>
                    <router-link v-for="(dat,i) in item[1]" :key="i" :to="{path:'/search_page',query:{title:dat.name,id:dat.id,parentId:dat.parentId}}" target="_blank">{{dat.name}}</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            datap:[],
            sList:[],
            data:[],
        }
    },
    components: {

    },
    methods: {
        i(item){
            this.datap=[];
            this.datap=this.data[item][1];
            // for(var i=0;i<this.data[item][1].length;i++){
            //     var name=this.data[item][1][i][0];
            //     console.log(this.data[item][1])
            // }
        },
        more:function(){
            this.$axios.get(this.$com.testCsIp + '/baseCategory/getAll').then(res=>{
                var data=res.data.data;
                for(var x=0;x<data.list.length;x++){
                    var span=data.list[x].name;var sp=[];
                    var spanId=data.list[x].id;
                    for(var y=0;y<data.list[x].children.length;y++){
                        var spans=data.list[x].children[y];
                        sp.push(spans);
                    }
                    var s=[];
                    s.push(span,spanId,sp);
                    this.sList.push(s);
                }
                this.sList.reverse();
            })
        },
        getData(){
            this.$axios.get(this.$com.testCsIp + '/baseCategory/getAll').then(res=>{
                var data=res.data.data.list;
                for(var x=0;x<data.length;x++){
                    var span=data[x].name;var sp=[];
                    var spanId=data[x].id;var sS=[];var sSs=[];
                    for(var y=0;y<data[x].children.length;y++){
                        var spans=data[x].children[y];
                        sp.push(spans);
                        var spansName=spans.name
                        var spS=[];var ssss=[];
                        for(var z=0;z<spans.children.length;z++){
                            var spansS=spans.children[z];
                            spS.push(spansS);
                        }
                        ssss.push(spansName,spS);
                        sS.push(ssss);
                    }
                    sSs.unshift(span)
                    sSs.push(sS)
                    this.data.push(sSs);
                    var s=[];
                    s.push(span,spanId,sp);
                    this.sList.push(s);
                }
            })
        }
    },
    mounted(){
        //this.more();
        this.getData();
    }
}
</script>

<style scoped>
    .fl{
        overflow: hidden;
        margin-top: 6px;
    }
    .fl>div{
        float: left;
    }
    .fl>div:first-child{
        font-weight: bold;font-size: 14px;color: #333
    }
    .fl>div:first-child img{
        margin: 6px 10px 0 5px;
        vertical-align: top;
    }
    .fl>div:first-child span{
        width: 90px;text-align: right;
        display: inline-block;
        overflow: hidden;white-space: nowrap;text-overflow: ellipsis;
    }
    .fl>div:nth-child(2){
        width: 598px;min-width: 598px;
    }
    .board{
        padding: 20px 10px 20px 20px
    }
    .nav-left ul{
        height: 73px;
        overflow: hidden;
    }
    .nav-left>ul>li>a{
        overflow: hidden;
    }
    .active{
        width: 150px !important;
    }
</style>

