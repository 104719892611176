<template>
  <div v-loading="loading" style="width: 100%">
    <el-table
      :span-method="objectSpanMethod"
      :data="tableData.records"
      style="width: 100%"
      @row-click="rowClick"
      @selection-change="handleSelectionChange"
      >
      <el-table-column
      v-if="index"
      type="index"
      label="序号"
      width="50"/>
      <el-table-column
        v-if="checkbox"
        type="selection"
        width="43">
      </el-table-column>

      <el-table-column
        v-for="(item,index) in headerList"
        :prop="item.key"
        :label="item.name"
        :width="item.width"
        align="center"
        :key="index"
        header-align="center">
        <template slot-scope="scope">
          <div v-if="fixed">
            <div class="upload-wrap" v-if="item.key==='name'" >
              <span v-html="formatter(scope,item.key)"></span>
              <i v-if="scope.row.IMGurl||scope.row.name" class="el-icon-error" @click="()=>$emit('on-uploadDel', scope)"></i>
            </div>
            <span v-else>{{scope.row.type}}</span>
          </div>
          <div v-else>
            <span v-if="item.list" v-html="formatterList(scope,item)"></span>
            <template v-else>
              <span :class="{'item-large':type==='large','item-nowrap-two':item.key==='recommend'}" class="cursor-pinter" v-if="item.key==='orgCount'||item.key==='recommend'" @click="GoOrgList(scope,item.key)" v-html="formatter(scope,item.key)"></span>
              <span :title="item.key==='catName'||item.key==='title'||item.key==='area'||item.key==='certificateName'||item.key==='remark'?formatter(scope,item.key):''" :class="{'item-large':type==='large','item-nowrap':item.key==='catName'||item.key==='area'||item.key==='title'||item.key==='certificateName','item-nowrap-two':item.key==='remark'||item.key==='orgName'}" v-else v-html="formatter(scope,item.key)"></span>
            </template>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        v-if="btn"
        fixed="right"
        header-align="center"
        label="操作"
        align="center"
        width="100">
        <template slot-scope="scope">
          <el-button @click="()=>$emit('on-editClick', scope.row)" v-if="btn.indexOf('edit')>-1" type="text" size="small">编辑</el-button>
          <el-button @click="()=>$emit('on-lookClick', scope)" v-if="btn.indexOf('look')>-1" type="text" size="small">查看</el-button>
          <el-button @click="()=>$emit('on-delClick', scope.row)" v-if="btn.indexOf('del')>-1" type="text" size="small">删除</el-button>
          <el-button @click="()=>$emit('on-buycarClick', scope.row)" v-if="btn.indexOf('buycar')>-1&&scope.row.demandStatus!=2" type="text" size="small">加入购物车</el-button>
          <el-upload :show-file-list="false" v-if="btn.indexOf('upload')>-1" :action="$com.testImgin+'/upload/upload-file'" :headers="headers" :before-upload="uploadClick" :on-change="uploadFile">
            <el-button @click="uploadBefore(scope)" type="text" size="small">上传</el-button>
          </el-upload>
          <el-button @click="()=>$emit('on-downloadClick',scope.row)" v-if="btn.indexOf('download')>-1" type="text" size="small">下载</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page-wrap" v-if="tableData.pages>1">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="10"
          layout="prev, pager, next, jumper"
          :total="tableData.total">
        </el-pagination>
    </div>
  </div>

</template>

<script type="text/javascript">
  export default {
    name: 'xtable',
     // tableData:Object,
    props: {
        head:Object,
        tableData: Array | Object,
        btn:String,
        search:Object,
        type:String,
        checkbox:Boolean,
        loading:Boolean,
        index:Boolean,
        fixed:Boolean,
        spanMethod:Boolean
    },
    data () {
        return {
            headerList:[],
            _type:"",
            currentPage:1,
            slots:"",
            headers:{
              token:this.$store.state.tokenId
            }
          }
    },
    watch: {
      search(val){
        this.currentPage = val.current;
      }
    },
    computed:{

    },
    mounted () {
      console.log(typeof(this.tableData),this.tableData,'==================')
      for(var key in this.$slots){
        this.slots = key;
      }
      for(var key in this.head){
        var item = this.head[key];
        var name = item.name||this.head[key],
        list = item.list||"",
        color = item.color||"#333",
        width = item.width||"";
        this.headerList.push({name:name,key:key,width:width,list:list,color:color})
      }
    },
    methods: {
      objectSpanMethod:function({ row, column, rowIndex, columnIndex }){
        if (this.spanMethod) {
            if (columnIndex === 0) {
                if (rowIndex % 2 === 0) {
                    return {
                        rowspan: 2,
                        colspan: 1
                    };
                } else {
                    return {
                        rowspan: 0,
                        colspan: 0
                    };
                }
            }
        };
      },
      rowClick:function(row){
        this.$emit('on-rowClick', row)
      },
      uploadClick:function(file){
        if (this._type === "咨询资料") {
          if (file.name.indexOf('.rar')>-1||file.name.indexOf('.zip')>-1||file.name.indexOf('.7z')>-1) {
            return true;
          }else{
            this.$message.warning(this._type+'只能上传压缩文件');
            return false;
          };
        }else{
          if (file.type.indexOf('image')>-1) {
            return true;
          }else{
            this.$message.warning(this._type+'只能上传图片');
            return false;
          };
        };
      },
      uploadFile:function(file){
        this.$emit('on-uploadClick',file,this._type)
      },
      handleCurrentChange:function(){
        this.search.current = this.currentPage;
        this.$emit('on-changePage', this.search)
      },
      handleSelectionChange:function(val){
        this.$emit('on-handleSelectionChange', val)
      },
      uploadBefore:function(scope){
        this._type = scope.row.type;
      },
      GoOrgList:function(scope,key){
        if (key==="orgCount") {
          this.$router.push({ path:'/buyer/demand/demand_bidder',query: {id: scope.row.id} })
        }else if(key==="recommend"){
          this.$router.push({ path:'/shop_management/detail_page',query: {id: scope.row.servicesId} })
        };
      },
      formatterList:function(scope,item){
        for (var i = item.list.length - 1; i >= 0; i--) {
          var _item = item.list[i];
          if (_item.code==scope.row[item.key]) {
              return "<span style='color:" + _item.color + "'>" + _item.value + "</span>"
          };
        };
      },
      formatter:function(scope,key){
        //console.log(typeof(scope.row[key]))
        /*if (typeof(scope.row[key])==='object') {
          var html = '<div>'
          for (var i = 0; i < scope.row[key].length; i++) {
              var item = scope.row[key][i];
              html += '<a href="#" style="display:block;font-size:12px">'+item.fileName+'</a>';
          };
          html += '</div>';
          return html;
        };*/
        if (this.fixed) {
          if (key === 'name'&&scope.row.IMGurl) {
            return "<div style='width:200px;height:200px;margin:auto;line-height:200px;position:relative'><img style='width:100%' src='"+scope.row.IMGurl+"'/></div>"
          };
        };
        if (key==="demandStatus") {
          return this.demandStatus(scope.row[key])
        }else if(key==="status"){
          return this.status(scope.row[key])
        }else if(key==="bidStatus"){
          return this.bidStatus(scope.row[key])
        }else if(key==="enStatus"){
          return this.enStatus(scope.row[key])
        }else if(key==="shelfStatus"){
          return this.shelfStatus(scope.row[key])
        }else if(key==="createTime"||key==="deadline"||key==="changeTime"||key==="validTime"||key==="firstTime"||key==="executeTime"||key==="certifiedTime"){
          return this.sliceTime(scope.row[key])||'--'
        }else if(key==="orgCount"){
          return scope.row[key] + "个单位"
        }else if(key==="bidCount"){
          return scope.row[key] + "人参与"
        }else if(key==="cost"){
          if (scope.row[key] != null){
            return scope.row[key] + "元";
          }else{
            return scope.row[key]||'--'
          }
        }else if(key==="priceType"){
          return this.priceType(scope.row)
        }else if(key==="ccieSource"){
          return this.ccieSource(scope.row)
        }else if(scope.row[key]===0){
          return scope.row[key]
        }else if(key==="recommend"){
          return "<span>" + scope.row[key] + "</span>"
        }else{
          return scope.row[key]||'--'
        };
      },
      ccieSource:function(item){
        if (item.ccieSource===0) {
          return '自己上传'
        }else{
          return '平台认证'
        }
      },
      priceType:function(item){
        if (item.priceType===1&&item.price) {
          return item.price + '元';
        }else if(item.priceType===2){
          return item.priceMin + "元" + '-' + item.priceMax + "元";
        }else if(item.priceType===3){
          return '面议';
        };
      },
      sliceTime:function(item){
        if (item) {
          return "<span style='color:#999'>"+item.substring(0,10)+"</span>"
        };
      },
      status:function(item){
        if (item===0) {
          return "<span style='color:#00aa28;'>审核通过</span>"
        };
        if (item===1) {
          return "<span style='color:#999;'>待审核</span>"
        };
        if (item===2) {
          return "<span style='color:#ff4a03;'>驳回</span>"
        };
        if (item===9) {
          return "<span style='color:#ff4a03;'>删除</span>"
        };
      },
      demandStatus:function(item){
        if (item===1) {
          return "<span style='color:#ff4a03;'>进行中</span>"
        };
        if (item===2) {
          return "<span style='color:#00aa28;'>已完成</span>"
        };
      },
      bidStatus:function(item){
        if (item===1) {
          return "<span style='color:#ff4a03;'>竞标中</span>"
        };
        if (item===2) {
          return "<span style='color:#00aa28;'>已中标</span>"
        };
        if (item===3) {
          return "<span style='color:#999;'>未中标</span>"
        };
      },
      enStatus:function(item){
        if (item===1) {
          return "<span style='color:#999;'>待确认</span>"
        };
        if (item===2) {
          return "<span style='color:#00aa28;'>已确认</span>"
        };
        if (item===3) {
          return "<span style='color:#ff4a03;'>驳回</span>"
        };
      },
      shelfStatus:function(item){
        if (item===1) {
          return "<span style='color:#ff4a03;'>上架</span>"
        };
        if (item===2) {
          return "<span style='color:#999;'>下架</span>"
        };
        if (item===99) {
          return "<span style='color:#999;'>平台强制下架</span>"
        };
      },
      /*WidthFn:function(item){
        if (item.key=='title') {
          return '220'
        }
      }*/
    }
  }
</script>

<style scoped>
.page-wrap{
  text-align:center;
  margin-top:20px;
}
.cursor-pinter{
  cursor: pointer;
  color:#007bff;
}
.cursor-pinter:hover{
  text-decoration:underline;
}
.item-large{
  line-height:38px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.item-nowrap{
  white-space:nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display:block;
}
.item-nowrap-two{
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  height:46px;
  -webkit-box-pack:center;
}
.upload-wrap{
  position: relative;
  display:inline-block;
}
.upload-wrap i{
  position: absolute;
  color:red;
  top:2px;
  right:-13px;
  cursor:pointer;
}
</style>