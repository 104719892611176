<template>
    <div>
        <IndexHeader_home :textMeg="textMeg"/>
        <div class="online">
            <div class="set">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>关于我们</el-breadcrumb-item>
                </el-breadcrumb>
                <div class="sett">
                    <div>关于我们</div>
                    <div class="content-body">
                        <p>佰证通（www.bzt88.cn）是启翔鸿基（重庆）科技集团股份有限公司根据十五年认证行业经验总结，通过大数据分析并结合互联网平台的优势属性研发的，面向认证行业、检验检测机构的开放式B2B互联网认证交易共享服务平台。启翔鸿基（重庆）科技集团股份有限公司于2013年在OTC挂牌上市（股票代码850032），佰证通平台于2018年创建。</p>
                        <p>佰证通通过跟踪每一位 B客户端用户的核心需求，精准匹配认证资源，为国内5000万家企业用户、咨询公司、咨询师、认证机构、检验检测机构间搭建一个开放、便捷的互联网认证申请的交易平台，在降低企业认证、检验检测、咨询等环节所产生的资金成本的同时，也较大的降低了申请相关认证所产生的时间成本与人力成本。</p>
                        <p>做为服务提供方的企业、咨询机构、咨询师、认证机构、检验检测机构会员可在平台上发布相关认证服务。需求方可以在佰证通平台发布需求或直接购买所需要的服务。</p>
                    </div>
                </div>
            </div>
        </div>
        <IndexFooter_home />
    </div>
</template>

<script>
import IndexHeader_home from '@/components/IndexHeader_home.vue'
import IndexFooter_home from '@/components/IndexFooter_home.vue'
export default {
    data(){
        return{
            textMeg:'关于我们'
        }
    },
    components: {
        IndexHeader_home,IndexFooter_home
    },
}
</script>

<style scoped>
    p{
        margin: 14px 0;
    }
    .online{
        background: #f5f5f5;min-width:1200px;padding-bottom: 20px
    }
    .set{
        width: 1200px;margin: 0 auto;padding-top: 20px
    }
    .set .sett{
        margin-top: 10px;
        min-height: 500px;background: #fff;
        padding: 30px 40px 60px;

    }
    .set .sett>div:first-child{
        font-size: 28px;border-bottom: 1px solid #eee;
        padding-bottom: 20px;text-align: center;margin-bottom: 25px
    }
    .content-body p{
        text-indent: 28px;
        font-size: 14px;
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    }
    h3{
        display: block;
        font-size: 16px;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        color: #333
    }
</style>
