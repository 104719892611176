<template>
    <div class="no">
        <div class="no1">
            <p>所有资源筛选（<span> 共{{tableData.total}}个 </span>）</p>
            <p class="type-list">
                <span>服务类别：</span>
                <span class="type" :class="{'active':typeActive===''}" @click="ClickType('','')">不限</span>
                <span class="type" :class="{'active':typeActive===index}" v-for="(item,index) in TypeList" :key="index" @click="ClickType(index,item)">{{ item.name }}</span>
            </p>
        </div>
        <div class="no2">
            <xtable :loading="loading" type="large" :search="search" :head="head" :tableData="tableData" @on-rowClick="GoDetail" @on-changePage="(v)=>GetData(v)" />
        </div>
        <img class="no4" @click="post" src="../../assets/img/index1/fbxq03.jpg" alt="发布需求">
        <div class="no3">
            <div>
                <span>需求公示</span>
            </div>
            <div>
                <ul v-for="(list,i) in listss" :key="i">
                    <li :title="list.name">{{list.name}}</li>
                    <li>【已完成】</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import xtable from '@/components/UI/xtable.vue'
import Pagination from '@/components/UI/Pagination.vue'
export default {
    data(){
        return{
            list:[],
            listss:[
                {name:"IATF16949认证"},
                {name:"ISO14001认证"},
                {name:"建设施工行业质量管理体系认证"},
                {name:"材料类相关产品检测"},
                {name:"电力线路野外巡视智能眼镜CCC认证"},
                {name:"黄金梨子的绿色食品认证咨询服务"},
                {name:"茶味（红茶，绿茶）有机认证"},
                {name:"IS022000食品管理体系认证咨询"},
                {name:"五星商品售后服务认证"},
                {name:"ISO9001:2015认证咨询服务"},
                {name:"桶装水QS（SC）生产许可证咨询"},
                {name:"一种监控类电子产品CE认证咨询"},
                {name:"餐具（含果蔬）用洗涤剂的生产许可咨询认证"},
                {name:"消毒液产品的生产许可认证咨询服务"},
                {name:"脆红李、青脆李、红叶冬桃绿色食品认证"},
                {name:"南瓜、茄子的绿色食品认证的咨询服务"},
                {name:"IATF16949汽车管理体系认证咨询"},
                {name:"ISO27001管理体系的监督审核前的咨询辅导服务"},
                {name:"CQC自愿产品认证的咨询服务"},
                {name:"鸡、鸡蛋、饲料的有机转换认证"},
                {name:"ISO9001认证咨询服务"},
            ],
            TypeList:[],
            typeActive:"",
            search:{},
            loading:false,
            head:{
                title:{
                    name:"需求标题",
                    width:"250"
                },
                serviceTypeName:"服务类别",
                cost:"费用",
                createTime:"发布日期",
                bidCount:"竞标人数",
                deadline:"截止时间",
            },
            tableData:{},
            searchContent:{
                page:'',
                limit:''
            },
        }
    },
    methods:{
        post:function(){
            this.$router.push({ name:'buy_post_demand' })
        },
        pageChange(item) {
            this.searchContent.page = item.page;
            this.searchContent.limit = item.limit;
            //this.getList();
        },
        GoDetail:function(row){
            this.$router.push({ name:'demand_details',query:{id:row.id} })
        },
        ClickType:function(i,item){
            this.typeActive = i;
            var data = {};
            if (item === '') {
                data = {};
            }else{
                data.serviceType = item.id;
            };
            data.current = 1;
            data.demandStatus = "1";
            this.search = data;
            this.GetData(data)
        },
        GetData:function(data){
            this.loading = true;
            this.$axios.get(this.$com.testCsIp + '/demand/selectDemands', {params:data})
            .then((response) => {
                this.loading = false;
                var res = response.data;
                if (res.code === 0) {
                    this.tableData = res.data;
                }else{
                    this.$dialog.alert(res.msg,'error')
                };
            })
            .catch((error) => {
                this.loading = false;
                this.$dialog.alert('系统繁忙，请稍后再试','error')
            })
        }
    },
    components: {
        Pagination,
        xtable
    },
    mounted(){
        var type=this.$route.query.type;
        if(type == "3"){
            this.typeActive = 0;

        }
        if(type == "1"){
            this.typeActive = 2
        }
        var data = {
            serviceType:type,
            demandStatus:"1",
            current:1
        };
        this.search = {serviceType:type}
        this.GetData(data);
        this.$com.findCode('type_xqfw',(res)=>{
            this.TypeList = res;
        })
    }
}
</script>


<style scoped>
    ul,p{
        margin: 0;padding: 0;
        list-style: none;
    }
    .no{
        width: 1200px;min-height: 810px;;margin: 0 auto;
        position: relative;
    }
    .no1{
        position: absolute;
        top: 19px;left: 0;
        width: 1200px;height: 80px;
        background: #fff;padding: 20px
    }
    .no1>p{
        font-size: 12px;line-height: 25px
    }
    .no1>p:nth-child(1) span{
        color: red
    }
    .no1>p:nth-child(2) span:first-child{
        font-weight: bold
    }
    .no2{
        position: absolute;
        top: 107px;left: 0;
        width:893px;height: 670px;
        background: #fff;
        padding: 20px;text-align: center
    }
    .no2>ul>li,.no2>ul{
        float: left;
        cursor:pointer;
    }
    .no2>ul:first-child li{
        line-height: 35px;
        background: #f5f5f5;
        border-right: 1px solid #fff;
        text-align: center;
        font-size: 14px;font-weight: bold
    }
    .no2>ul:not(:first-child) li{
        line-height: 52px;text-align: center;
        border-bottom: 1px solid #E6E6E6;
        font-size: 14px
    }
    .no2>ul:not(:first-child) li:first-child{
        text-align: left
    }
    .no2>ul>li:first-child{
        width: 278px;padding-left: 20px;
        overflow: hidden;white-space: nowrap;
        text-overflow: ellipsis;
    }
    .no2>ul>li:nth-child(2){
        width: 118px
    }
    .no2>ul>li:nth-child(3){
        width: 100px
    }
    .no2>ul>li:nth-child(4){
        width: 120px
    }
    .no2>ul>li:nth-child(5){
        width: 116px
    }
    .no2>ul>li:last-child{
        width: 120px
    }
    .no2 span{
        color: #ff4a03
    }
    .no4{
        position: absolute;
        top:107px;right: 0;cursor: pointer;
    }
    .no3{
        position: absolute;
        top: 197px;right: 0;
        width: 300px;height: 580px;
        background: #fff;
    }
    .no3>div:nth-child(1){
        display: flex;justify-content: space-between;
        padding: 15px 20px 0 0 ;
        border-bottom: 1px solid #ccc
    }
    .no3>div:nth-child(1) span{
        font-size: 14px;font-weight: bold;
        color: #ff4a03;display: block;padding-bottom: 6px;
        width: 80px;text-align: center;
        border-bottom: 2px solid #ff4a03
    }
    .no3>div:nth-child(1) a{
        font-size: 12px;color: #666;
        text-decoration: none
    }
    .no3>div:nth-child(2){
        max-height: 520px;overflow: hidden;
    }
    .no3>div:nth-child(2)::-webkit-scrollbar {
        display: none;
    }
    .no3 ul{
        padding: 0 9px 0 13px;
    }
    .no3 ul>li{
        float: left;line-height: 36px;
        border-bottom: 1px dotted #ccc
    }
    .no3 ul li:first-child{
        width: 208px;
        overflow: hidden;white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 14px
    }
    .no3 ul li:last-child{
        width: 70px;font-size: 14px
    }
    .no3>button{
        width: 260px;height: 60px;border: 0;
        background:#3296FA;font-size: 20px;text-align: center;
        line-height: 60px;color: #fff;margin-left:20px;
        margin-top: 20px
    }
    .page{
        padding-left: 110px;
    }
    .type-list .type{
        font-size: 12px;color: #666;
        margin-right: 30px;
        cursor:pointer;
    }
    .type-list .active{
        color:red;
    }
</style>

