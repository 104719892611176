<template>
    <div>
        <el-dialog
            v-if="type=='img'"
            :visible.sync="thisShow"
            center=true
            top="2vh"
            :close-on-click-modal="false"
            class="dialog-img"
            width="50%">
            <span slot="title">
                <span class="dialog-title-text">查看大图</span>
            </span>
            <div class="img-wrap"><img :src="bodyMeg" /></div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="thisShow = false" v-if="cancelShow">取 消</el-button>
                <el-button type="primary" @click="changeShow">确 定</el-button>
            </span>
        </el-dialog>
        <div v-else>
            <el-dialog
                v-if="type=='alert'"
                :visible.sync="thisShow"
                :close-on-click-modal="false"
                center=true
                top="10vh"
                class="dialog-alert"
                width="60%">
                <span><Agreement/></span>
                <span slot="title">
                    <span class="dialog-title-text">服务协议</span>
                </span>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="changeShow" type="primary" >同 意</el-button>
                    <el-button @click="thisShow = false">取 消</el-button>
                </span>
            </el-dialog>
            <el-dialog
                v-else
                :visible.sync="thisShow"
                :close-on-click-modal="false"                
                top="15vh"
                width="30%">
                <span class="dialog-body"><i class="dialog-body-i" :class="{'dialog-body-i-error':type == 'error','dialog-body-i-success':type == 'success','dialog-body-i-confirm':type == 'confirm'}"></i><span class="dialog-body-text">{{bodyMeg}}</span></span>
                <span slot="title">
                    <span class="dialog-title-text">{{title}}</span>
                </span>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="thisShow = false" v-if="cancelShow">取 消</el-button>
                    <el-button type="primary" @click="changeShow">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script type="text/javascript">
import Agreement from '@/components/UI/Agreement.vue'
  export default {
    name: 'message',
    data () {
      return {
        bodyMeg:'',
        thisShow:true,
        title:'提示信息',
        cancelShow:false,
        type:'alert',
      }
    },
    components: {
        Agreement
    },
    mounted () {
            console.log(this.type,this.bodyMeg)
    },
    methods: {
        changeShow:function(){
            this.thisShow = false;
            this.submits()
        },
        submits:function(){
            console.log('55')
        }
    }
  }
</script>

<style scoped>
.meg-box{
    position: fixed;
    width:100%;
    height:100%;
    top:0;
    left:0;
    background:rgba(0, 0, 0, .5);
    z-index:99;
}
.meg-fixed{
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -200px;
    z-index:100;
    width:400px;
    min-height:200px;
    margin-top:-200px;
    background:#fff;
    border-radius:10px;
    overflow:hidden;
}
.meg-header{
    background:#f5f5f5;
}
.dialog-title-i{
    width: 20px;
    height: 20px;
    display: inline-block;
    background: url('../../assets/img/user/u808_5c76a3e.png');
    background-size: 20px auto;
    vertical-align: middle;
}
.dialog-title-text{
    font-size:16px;
    display:inline-block;
    vertical-align: middle;
    margin-left:8px;
}
.dialog-body{
    display:block;
    width:100%;
    padding:0px 25px 20px 25px;
    border-bottom:1px dashed #ccc;
}
.dialog-body-text{
    margin-left:20px;
    display:inline-block;
    vertical-align: middle;
    font-weight:bold;
    width:calc(100% - 60px);
}
.dialog-body-i{
    width:30px;
    height:30px;
    display:inline-block;
    vertical-align: middle;
    background-size:30px auto;
}
.dialog-body-i-success{
    background-image:url('../../assets/img/user/i-success.png');
}
.dialog-body-i-error{
    background-image:url('../../assets/img/user/i-error.png');
}
.dialog-body-i-confirm{
    background-image:url('../../assets/img/user/i-alert.png');
}
.dialog-body-i-load{
    background-image:url('../../assets/img/user/i-load.png');
}
.img-wrap img{
    width:100%;
}
</style>