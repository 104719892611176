<template>
    <div>
        <div class="header">
            <div class="main01">
                <router-link to="/"><img src="../assets/img/index/logo.png" alt=""></router-link>
                <div></div>
                <span>购物车</span>
            </div>
        </div>
        <div class="section-shop">
            <div class="section-shop-buycar">
                <buyCar radio :head="head" :tableData="tableData" btn="del" @on-delClick="delClick" />
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import buyCar from '@/components/UI/buyCar.vue'
import Footer from '@/components/Footer.vue'
export default {
    components: {
        Footer,
        buyCar
    },
    data () {
        return {
            tableData:{},
            head:{
                serviceName:"商品信息",
                priceType:"单价（元）",
                quantity:"数量",
                total:"总金额（元）"
            }
        }
    },
    computed:{

    },
    mounted () {
        //this.$com.isLogin();
        this.GetData();
    },
    methods: {
        GetData:function(){
            this.$com.majax.get(this.$com.testCsIp+'/ucCart/CartList',{},{
                success:(res)=>{
                    this.tableData = res.data;
                }
            },this)
        },
        delClick:function(item){
            this.$dialog.confirm('是否确认删除' + item.serviceName,'error',()=>{
                this.$com.majax.post(this.$com.testCsIp + '/ucCart/removeBuyerCartById/' + item.id,"",{
                    success:(res)=>{
                        this.$message({
                            message: '删除成功！',
                            type: 'success'
                        });
                        this.GetData();
                    }
                })
            })
        }
    }
}
</script>



<style scoped>
        body,ul{
            list-style: none;
            margin: 0;
            padding: 0;
        }
        .header{
            width: 100%;
            min-width: 1200px;
            height: 102px;
        }
        .header .main01{
            width: 1200px;
            margin: 0 auto;
            position: relative;
        }
        .main01 img:first-child{
            position: absolute;
            top: 22px;
            left:0;
        }
        .main01 div:nth-child(2){
            position: absolute;
            top:35px;
            left: 182px;
            height: 35px;
            border: 0.5px solid #CCDCF9;
        }
        .main01 span:nth-child(3){
            position: absolute;
            top:40px;
            left:199px;
            font-size: 20px;
            color: #666
        }
        .section-shop{
            background: #f5f5f5;
            padding-bottom: 20px ;min-width: 1200px;
        }
        .section-shop>div{
            width: 1200px;
            margin: 0 auto;
        }
        .section-shop div span{
            display: block;
        }
        div>span:first-child{
            padding-left: 30px;
            width: 67px;
            box-sizing: border-box;
        }
        input[type=checkbox],img{
            vertical-align: middle
        }
        .no1{
            height: 45px;font-weight: bold;
            font-size: 14px;color: #333;
            line-height: 45px
        }
        .no1 span{
            float: left;
        }
        .no1>span:nth-child(2){
            width: 30px
        }
        .no1>span:nth-child(3){
            width: 353px;text-align: center
        }
        .no1>span:nth-child(4),.no1>span:nth-child(5),.no1>span:nth-child(6){
            width: 200px;text-align: center
        }
        .no1>span:last-child{
            width: 138px;text-align: center
        }
        .no2{
            font-size: 14px;color: #333;
            background: #fff;margin-bottom: 20px
        }
        .no2>div span{
            float: left;
        }
        .no2>div:not(:last-child){
            height: 43px;border-bottom: 1px solid #E6E6E6 ;
            line-height: 40px;
        }
        .no2>div:first-child a{
            text-decoration: none;color: #333;font-weight: bold
        }
        .no2>div:not(:first-child){
            height: 120px;line-height: 120px
        }
        .details{
            width: 383px;
        }
        .details a{
            display: flex;align-items: center;
            text-decoration: none;color: #333
        }
        .details a div{
            width: 78px;height: 78px; border: 1px solid #ccc;
            display: flex;justify-content: center;align-items: center;margin-right: 10px
        }
        .no2>div span:nth-child(3),.no2>div span:nth-child(4),.no2>div span:nth-child(5){
            width: 200px;text-align: center;color: #e4393c;font-weight: bold
        }
        .no2>div:not(:first-child) span:last-child{
            width: 138px;text-align: center;line-height: 30px;
            margin-top: 30px;font-size: 12px;
        }
        .no2>div:not(:first-child) span:last-child a{
            display: block;text-decoration: none;color: #333
        }
        .no2 div span:last-child a:hover{
            color: #3296FA
        }
        .details a:hover{
            color: #3296FA
        }
        input[type=text]{
            padding: 0;
            width: 60px;height: 25px;
            text-align: center;box-sizing: border-box;
            border: 1px solid #ccc
        }
        .no2 button{
            width: 25px;height: 25px;box-sizing: border-box;
            border: 1px solid #ccc;background: #F9F9F9;
            cursor: pointer;
        }
        .no2 button:hover{
            color: #e4393c;border:1px solid #e4393c
        }
        ul{
            height: 50px;background: #fff;
            position: relative;font-size: 14px
        }
        ul li:first-child{
            position: absolute;
            bottom: 15px;left: 30px;
        }
        ul li:nth-child(2){
            position: absolute;
            left: 64px;bottom: 15px;
        }
        ul li:nth-child(2) a{
            text-decoration: none;color: #333
        }
        ul li:nth-child(2) a:hover{
            color: #3296FA
        }
        ul li:nth-child(3){
            position: absolute;
            left: 802px;top: 20px;display: flex;
        }
        ul li:nth-child(4){
            position: absolute;
            top: 10px;display: flex;
            right: 160px;font-size: 24px;
        }
        ul span{
            color: #e4393c
        }
        ul li:last-child{
            position: absolute;
            right: 0;
        }
        ul li:last-child a{
            display: block;width: 130px;height: 50px;
            background: #3296FA;color: #fff;font-size: 20px;
            text-align: center;line-height: 50px;text-decoration: none
        }
        ul li:last-child a:hover{
            background: linear-gradient(to right, #00aaff, #0085ff);
        }
        .section-shop-buycar{
            width:1200px;
        }
        .section-shop>.body-bottom{
            margin-top:20px;
        }
</style>
