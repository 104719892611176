<template>
    <div>
        <IndexHeader_home :textMeg="textMeg"/>
        <div class="online">
            <div class="set">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>商家入驻协议</el-breadcrumb-item>
                </el-breadcrumb>
                <div class="sett">
                    <div>商家入驻协议</div>
                    <div class="content-body">
                        <p>本协议由您和佰证通认证咨询服务平台共同缔结，本协议具有合同效力。</p>
                        <h3>一、协议内容及签署</h3>
                        <p>1、本协议内容包括协议正文及所有平台发布的或将来可能发布的各类法则。所有规则为本协议不可分割的组成部分，与协议正文具有同等法律效力。除另行明确声明外，任何平台及其关联公司提供的服务均受本协议约束。</p>
                        <p>2、您应当在使用平台服务之前认真阅读全部协议内容。如您对协议有任何疑问的，应当向平台咨询。</p>
                        <p>3、您承诺接受并遵守本协议的约定。如果您不同意本协议的约定，应立即停止使用平台服务。</p>
                        <p>4、平台有权根据需要不时地制定、修改本协议或各类规则，并以网站公式的方式进行公告，不再单独通知您。变更后的协议和规则一经在网站公布后，立即自动生效。如您不同意相关变更，应当立即停止使用平台服务。</p>
                        <h3>二、平台服务</h3>
                        <p>1、通过平台及其关联公司提供的平台服务，会员可在平台上发布信息、查询商品和服务信息、达成交易意向并进行交易、参加平台组织的活动以及使用其它信息服务及技术服务。</p>
                        <p>2、您在平台上交易过程中与其它会员发生交易纠纷时，一旦您与其它会员任一方或双方共同提交平台要求调处，则平台有权根据单方判断做出调处决定。</p>
                        <p>3、平台有权应政府部门的要求，向其提供您在平台交易记录信息。如您涉嫌侵犯他人知识产权，则平台有权向权利人提供您必要的身份信息。</p>
                        <h3>三、平台服务使用规范</h3>
                        <p>1、在使用平台服务过程中，您承诺遵守以下约定：</p>
                        <p>a）在使用平台服务过程中实施的所有行为均遵守国家法律、法规等规范性文件及平台的各项规则的规定和要求，不违背社会公共利益和公共道德，不损害他人的合法权益。否则您应以个人名义承担所有法律责任，并保证平台免于因此产生任何损失。</p>
                        <p>b）在交易过程中，遵守城市信用原则，不采取不正当竞争行为，不扰乱网上交易正常秩序。</p>
                        <p>c）不发布任何禁止或违法的商品或信息。</p>
                        <p>d）不对平台任何数据做商业性利用。</p>
                        <p>2、您了解并同意：</p>
                        <p>a）平台有权对您是否违反上述承诺做出单方认定，并根据单方认定结果适用规则予以处理或终止向您提供服务。</p>
                        <p>b）对于您在平台上发布的任何禁止或违法的信息，平台有权不经通知您即予以删除，并按照规定予以处罚。</p>
                        <p>c）对于您涉嫌违反承诺的行为，使平台遭受任何损失，或收到任何第三方的索赔，您应当赔偿平台因此受到的损失或发生的费用。</p>
                        <h3>四、责任范围和责任限制</h3>
                        <p>1、平台负责向您提供服务，但平台对服务不做任何明示或暗示的保证，包括但不限于平台服务的适用性、没有错误或疏漏、持续性、明确性、可靠性、适用于某一特定用途。</p>
                        <p>2、平台上的信息系用户自行发布，且可能存在风险和瑕疵、平台仅作为您获取物品和服务信息、协商并开展交易的场所。您应自行谨慎判断信息的真实性、合法性和有效性，并自行承担因此产生的责任和损失。</p>
                        <p>3、除非法律法规明确要求，否则平台没有义务对平台所有用户的注册数据、商品信息、交易行为事先审查。</p>
                        <p>4、不论任何情况下，平台均不对由于互联网正常的设备维护，互联网网络连接故障、电脑、通讯或其它设备的故障，电力故障等造成的不能服务或延迟服务承担责任。</p>
                        <h3>五、协议终止</h3>
                        <p>1、平台有权自行全权决定以任何理由不经事先通知的中止、终止向您提供部分或全部平台服务，暂时冻结或永久冻结（注销）您的账户，且无须为此向您或任何第三方承担任何责任。</p>
                        <p>2、出现以下情况时，平台有权直接以注销账户的方式终止本协议：</p>
                        <p>a）平台终止向您提供服务后，您涉嫌再一次直接或间接或者以他人名义注册为平台账户的；</p>
                        <p>b）您注册信息中的主要内容不真实或不准确或不及时或不完整。</p>
                        <p>c）其它平台认为应当终止协议的情况。</p>
                        <p>3、您有权向平台要求注销您的账户，经平台审核同意的，平台注销您的账户，届时，您与平台基于本协议的合同关系终止。</p>
                    </div>
                </div>
            </div> 
        </div>
        <IndexFooter_home />
    </div>
</template>

<script>
import IndexHeader_home from '@/components/IndexHeader_home.vue'
import IndexFooter_home from '@/components/IndexFooter_home.vue'
export default {
    data(){
        return{
            textMeg:'商家入驻协议'
        }
    },
    components: {
        IndexHeader_home,IndexFooter_home
    },
}
</script>

<style scoped>
    p{
        margin: 14px 0;
    }
    .online{
        background: #f5f5f5;min-width:1200px;padding-bottom: 20px 
    }
    .set{
        width: 1200px;margin: 0 auto;padding-top: 20px
    }
    .set .sett{
        margin-top: 10px;
        min-height: 500px;background: #fff;
        padding: 30px 40px 60px; 
        
    }
    .set .sett>div:first-child{
        font-size: 28px;border-bottom: 1px solid #eee;
        padding-bottom: 20px;text-align: center;margin-bottom: 25px
    }
    .content-body p{
        text-indent: 28px;
        font-size: 14px;
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    }
    h3{
        display: block;
        font-size: 16px;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        color: #333
    }
</style>
