import Vue from 'vue'
import Router from 'vue-router'
import Index from './views/Index.vue'
import Register from './views/Register.vue'
import Login from './views/Login.vue'
import Password from './views/Password.vue'
import Pwone from './components/password/Pwone.vue'
import Pwtwo from './components/password/Pwtwo.vue'
import Pwthree from './components/password/Pwthree.vue'
import Users from './views/Users.vue'
import Facilitating_agency from './views/Facilitating_agency.vue'

import Inv from './components/users/Inv.vue'

import Basic_home from './components/users/basic/Basic_home.vue'
import Basic_main from './components/users/basic/Basic_main.vue'
//用户中心实名认证模块
import Basic_Auth_home from './components/users/basic/Basic_Authentication_home.vue'
import Basic_auth_index from './components/users/basic/Basic_Authentication.vue'
import Basic_auth_per from './components/users/basic/Basic_Authentication_per.vue'
import Basic_auth_bus from './components/users/basic/Basic_Authentication_bus.vue'
//用户中心安全中心模块
import Account_home from './components/users/account/account_home.vue'
import Account_safeGrade from './components/users/account/account_safeGrade.vue'
//用户中心绑定手机模块
import Account_bdph_home from './components/users/account/account_bdph_home.vue'
import Account_bdph_add from './components/users/account/account_bdph_add.vue'
import Account_bdph_Cph1 from './components/users/account/account_bdph_Cph1.vue'
import Account_bdph_Cph2 from './components/users/account/account_bdph_Cph2.vue'
import Account_bdph_Cph3 from './components/users/account/account_bdph_Cph3.vue'
import Account_bdph_Cph4 from './components/users/account/account_bdph_Cph4.vue'
//用户中心绑定邮箱模块
import Account_bdemail_home from './components/users/account/account_bdemail_home.vue'
import Account_bdemail_add from './components/users/account/account_bdemail_add.vue'
import Account_bdemail_Cemail1 from './components/users/account/account_bdemail_Cemail1.vue'
import Account_bdemail_Cemail2 from './components/users/account/account_bdemail_Cemail2.vue'
import Account_bdemail_Cemail3 from './components/users/account/account_bdemail_Cemail3.vue'
import Account_bdemail_Cemail4 from './components/users/account/account_bdemail_Cemail4.vue'

import Account_Cpw from './components/users/account/account_Cpw.vue'
//用户中心地址模块
import Address_home from './components/users/address/Address_home.vue'
import Address_list from './components/users/address/Address_list.vue'
import Address_add from './components/users/address/Address_add.vue'
//用户中心开店模块
import Shop_home from './components/users/shop/Shop_home.vue'
import Shop_base from './components/users/shop/Shop_base.vue'
import Shop_detailed from './components/users/shop/Shop_detailed.vue'
import Binding_success from './components/users/Binding_success.vue'
//买家中心
import Buyer from './views/Buyer.vue'
import Buyer_index from './components/buyer/Buyer_index.vue'
import Buyer_home from './components/buyer/Buyer_home.vue'
import Demand_list from './components/buyer/demand/Demand_list.vue'
import Post_demand from './components/buyer/demand/Post_demand.vue'
import Demand_bidder from './components/buyer/demand/Demand_bidder.vue'
import Order_ticket from './components/buyer/entrustOrder/Order_ticket.vue'
import View_order from './components/buyer/entrustOrder/View_order.vue'
import Order_list from './components/buyer/order/Order_list.vue'
import Order_details from './components/buyer/order/Order_details.vue'
import Order_download from './components/buyer/order/Order_download.vue'
import Auth_manage from './components/buyer/auth/Auth_manage.vue'
import New_auth from './components/buyer/auth/New_auth.vue'
import My_favorite from './components/buyer/follow/My_favorite.vue'
import My_footprint from './components/buyer/follow/My_footprint.vue'
import Self_list from './components/buyer/selfhelp/self_list.vue'
import Self_step1 from './components/buyer/selfhelp/self_step1.vue'
import Self_step2 from './components/buyer/selfhelp/self_step2.vue'
import Self_step3 from './components/buyer/selfhelp/self_step3.vue'
import Self_step4 from './components/buyer/selfhelp/self_step4.vue'
import Self_step5 from './components/buyer/selfhelp/self_step5.vue'
import Self_step6 from './components/buyer/selfhelp/self_step6.vue'
import Pay_success from './components/buyer/selfhelp/pay_success.vue'
import Self_details from './components/buyer/selfhelp/self_details.vue'
import Self_details_audit from './components/buyer/selfhelp/self_details_audit.vue'
import Step3_details from './components/buyer/selfhelp/step3_details.vue'
import Step4_details from './components/buyer/selfhelp/step4_details.vue'
import Step5_details from './components/buyer/selfhelp/step5_details.vue'

//购物车
import Shopping_cart from './views/Shopping_cart.vue'
import Shopping_order from './views/Shopping_order.vue'
import Pay from './views/Pay.vue'
import Pay_status from'./views/Pay_status.vue'
//卖家中心
import Seller from './views/Seller.vue'
import seller_index from './components/seller/seller_index.vue'
import seller_home from './components/seller/seller_home'

import service_list from './components/seller/sever/service_list.vue'
import Publishing_service from './components/seller/sever/Publishing_service.vue'
import Order_list_seller from './components/seller/order/Order_list_seller.vue'
import Order_upload from './components/seller/order/Order_upload.vue'
import Order_ticket_seller from './components/seller/order/Order_ticket_seller.vue'
import Order_details_seller from './components/seller/order/Order_details_seller.vue'
import My_favorite_seller from './components/seller/follow/My_favorite_seller.vue'
import My_footprint_seller from './components/seller/follow/My_footprint_seller.vue'
import Shop_maintenance from './components/seller/shop/Shop_maintenance.vue'
import Qua_man from './components/seller/shop/Qua_man.vue'
import Case_man from './components/seller/shop/Case_man.vue'
import New_qua from './components/seller/shop/New_qua.vue'
import New_case from './components/seller/shop/New_case.vue'
import order_ticket_list from './components/seller/order_ticket/order_ticket_list.vue'
import my_bid from './components/seller/bid/my_bid.vue'
import counselor_list from './components/seller/counselor/counselor_list.vue'
import New_counselor from './components/seller/counselor/New_counselor.vue'
import About_store from './components/seller/shop/About_store.vue'

import P404 from './views/404.vue'
//需求大厅
import Demand_hall from './views/Demand_hall.vue'
import Demand_hall_index from './components/demand_hall/demand_hall_index.vue'
import Demand_details from './components/demand_hall/Demand_details.vue'
//搜索
import Search_page from './views/Search_page.vue'
//首页附属
import Contact_us from './components/index/Contact_us.vue'
import Prize_message from './components/index/Prize_message.vue'
//新闻资讯
import News_home from './components/index/news/News_home.vue'
import News_details from './components/index/news/News_details.vue'
import News_list from './components/index/news/News_list.vue'

//首页底部协议
import online_payment_protocol from './components/index/index_footer/online_payment_protocol.vue'
import Store_entry_agreement from './components/index/index_footer/Store_entry_agreement.vue'
import Invoice_policy from './components/index/index_footer/Invoice_policy.vue'
import Complaints_rights from './components/index/index_footer/Complaints_rights.vue'
import After_sales_policy from './components/index/index_footer/After_sales_policy.vue'
import buyer_Introduction from './components/index/index_footer/buyer_Introduction.vue'
import seller_Introduction from './components/index/index_footer/seller_Introduction.vue'

import about_us from './components/index/index_bottom/about_us.vue'

//店铺管理
import shop_management from './views/shop_management.vue'
import shop_man_index from './components/shop_management/shop_man_index.vue'
import Shop_introduction from './components/shop_management/Shop_introduction.vue'
import Shop_service_list from './components/shop_management/Shop_service_list.vue'
import Shop_Qua_Cer from './components/shop_management/Shop_Qua_Cer.vue'
import Shop_Service_Case from './components/shop_management/Shop_Service_Case.vue'
import detail_page from './components/shop_management/detail_page.vue'

import Shop_list from './views/Shop_list.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    //在开发页面
    {path:'/404',name:'P404',component:P404},
    { path: '*', component: P404 },
    //首页
    {path:'/',component:Index},
    //注册
    {path:'/register',component:Register,meta:{title:"用户注册"}},
    //登录
    {path:'/login',component:Login,meta:{title:"用户登录"}},
    //忘记密码
    {path:'/password',component:Password,children:[
      {path:"/",name:'Pwone',component:Pwone,meta:{title:"忘记密码"}},
      {path:'/pwtwo',name:'Pwtwo',component:Pwtwo,meta:{title:"忘记密码"}},
      {path:'/pwthree',name:'Pwthree',component:Pwthree,meta:{title:"忘记密码"}},
    ]},
    //用户中心
    {path: '/users',component: Users,redirect:'/users/base',children:[
        //个人资料模块
        {path:'base',component:Basic_home,redirect:'/users/base/base_main',children:[
          {path:'base_main',component:Basic_main,meta:{title:"用户中心"}},
          {path:'base_auth',component:Basic_Auth_home,redirect:'/users/base/base_auth/base_auth_index',children:[
          {
              path:'base_auth_index',
              name:'base_auth_index',
              meta:{title:"实名认证"},
              component:Basic_auth_index
            },
            {
              path:'base_auth_per',
              name:'base_auth_per',
              meta:{title:"个人实名认证"},
              component:Basic_auth_per
            },
            {
              path:'base_auth_bus',
              name:'base_auth_bus',
              meta:{title:"企业实名认证"},
              component:Basic_auth_bus
            },
            {
              path:'base_success',
              name:'base_success',
              meta:{title:"成功"},
              component:Binding_success
            }
          ]}
        ]},
        //发票管理
        {path:'inv',name:"inv",component:Inv,meta:{title:"发票管理"}},
        //账户安全
        {path:'account',component:Account_home,redirect:'/users/account/account_safeGrade',children:[
          {
            path:'account_safeGrade',
            name:'account_safeGrade',
            component:Account_safeGrade,
            meta:{title:"账户安全"}
          },
          //绑定手机
          {
            path:'account_bdph',
            component:Account_bdph_home,
            redirect:'/users/account/account_bdph/account_bdph_add',
            children:[{
              path:'account_bdph_add',
              name:'account_bdph_add',
              component:Account_bdph_add,
              meta:{title:"绑定手机"}
            },{
              path:'account_bdph_Cph1',
              name:'account_bdph_Cph1',
              component:Account_bdph_Cph1,
              meta:{title:"绑定手机"}
            },{
              path:'account_bdph_Cph2',
              name:'account_bdph_Cph2',
              component:Account_bdph_Cph2,
              meta:{title:"绑定手机"}
            },{
              path:'account_bdph_Cph3',
              name:'account_bdph_Cph3',
              component:Account_bdph_Cph3,
              meta:{title:"绑定手机"}
            },{
              path:'account_bdph_Cph4',
              name:'account_bdph_Cph4',
              component:Account_bdph_Cph4,
              meta:{title:"绑定手机"}
            }]
          },
          //绑定邮箱
          {
            path:'account_bdemail',
            component:Account_bdemail_home,redirect:'/users/account/account_bdemail/account_bdemail_add',
            children:[{
              path:'account_bdemail_add',
              name:'account_bdemail_add',
              component:Account_bdemail_add,
              meta:{title:"绑定邮箱"}
            },{
              path:'account_bdemail_Cemail1',
              name:'account_bdemail_Cemail1',
              component:Account_bdemail_Cemail1,
              meta:{title:"绑定邮箱"}
            },{
              path:'account_bdemail_Cemail2',
              name:'account_bdemail_Cemail2',
              component:Account_bdemail_Cemail2,
              meta:{title:"绑定邮箱"}
            },{
              path:'account_bdemail_Cemail3',
              name:'account_bdemail_Cemail3',
              component:Account_bdemail_Cemail3,
              meta:{title:"绑定邮箱"}
            },{
              path:'account_bdemail_Cemail4',
              name:'account_bdemail_Cemail4',
              component:Account_bdemail_Cemail4,
              meta:{title:"绑定邮箱"}
            }]
          },
          {
            path:'account_Cpw',
            name:'account_Cpw',
            component:Account_Cpw,
            meta:{title:"修改密码"}
          },
          {
            path:'account_success',
            name:'account_success',
            component:Binding_success,
            meta:{title:"成功"}
          }
        ]},
        //地址管理
        {path:'address',component:Address_home,redirect:'/users/address/address_list',children:[
          {
            path:'address_list',
            name:'address_list',
            component:Address_list,
            meta:{title:"地址管理"}
          },
          {
            path:'address_add',
            name:'address_add',
            component:Address_add,
            meta:{title:"地址管理"}
          }
        ]},


        {path:'success',name:'success',component:Binding_success},
        //申请开店模块
        {path:'shop',component:Shop_home,redirect:'/users/shop/shop_base',children:[
          {
            path:'shop_base',
            name:'shop_base',
            component:Shop_base,
            meta:{title:"申请开店"}
          },
          {
            path:'shop_detailed',
            name:'shop_detailed',
            component:Shop_detailed,
            meta:{title:"申请开店"}
          }
        ]},
      ]
    },
    //买家中心模块
    {path: '/buyer',component: Buyer,redirect:'/buyer/home',children:[
      //首页
      {path:'home',component:Buyer_index,meta:{title:"买家中心"}},
      //需求管理
      {path:'demand',component:Buyer_home,redirect:'/buyer/demand/demand_list',children:[
        {path:'demand_list',name:'buy_demand_list',component:Demand_list,meta:{title:"需求管理"}},
        {path:'post_demand',name:'buy_post_demand',component:Post_demand,meta:{title:"需求管理"}},
        {path:'demand_bidder',name:'buy_demand_bidder',component:Demand_bidder,meta:{title:"需求竞标单位"}},
      ]},
      //委托单
      {path:'entrustOrder',component:Buyer_home,redirect:'/buyer/entrustOrder/view_order',children:[
        {path:'order_ticket',name:'buy_order_ticket',component:Order_ticket,meta:{title:"委托单管理"}},
        {path:'view_order',name:'buy_view_order',component:View_order,meta:{title:"委托单管理"}},
      ]},
      //订单管理
      {path:'order',component:Buyer_home,redirect:'/buyer/order/order_list',children:[
        {path:'order_list',name:'buy_order_list',component:Order_list,meta:{title:"订单管理"}},
        {path:'order_details',name:'buy_order_details',component:Order_details,meta:{title:"订单详情"}},
        {path:'order_download',name:'order_download',component:Order_download,meta:{title:"下载凭证"}},
      ]},
      //认证管理
      {path:'auth',component:Buyer_home,redirect:'/buyer/auth/auth_manage',children:[
        {path:'auth_manage',name:'buy_auth_manage',component:Auth_manage,meta:{title:"认证管理"}},
        {path:'new_auth',name:'buy_new_auth',component:New_auth,meta:{title:"认证管理"}},
      ]},
      //我的关注
      {path:'follow',component:Buyer_home,redirect:'/buyer/follow/my_favorite',children:[
        {path:'my_favorite',name:'buy_my_favorite',component:My_favorite,meta:{title:"我的关注"}},
        {path:'my_footprint',name:'buy_my_footprint',component:My_footprint,meta:{title:"我的足迹"}},
      ]},
      //自助认证
      {path:'selfhelp',component:Buyer_home,redirect:'/buyer/selfhelp/self_list',children:[
        {path:'self_list',name:'buy_self_list',component:Self_list},
        {path:'self_step1',name:'buy_self_step1',component:Self_step1},
        {path:'self_step2',name:'buy_self_step2',component:Self_step2},
        {path:'self_step3',name:'buy_self_step3',component:Self_step3},
        {path:'self_step4',name:'buy_self_step4',component:Self_step4},
        {path:'self_step5',name:'buy_self_step5',component:Self_step5},
        {path:'self_step6',name:'buy_self_step6',component:Self_step6},
        {path:'pay_success',name:'buy_pay_success',component:Pay_success},
        {path:'self_details',name:'buy_self_details',component:Self_details},
        {path:'self_details_audit',name:'buy_self_details_audit',component:Self_details_audit},
        {path:'step3_details',name:'step3_details',component:Step3_details},
        {path:'step4_details',name:'step4_details',component:Step4_details},
        {path:'step5_details',name:'step5_details',component:Step5_details},
      ]},
    ]},
    //购物车
    {path:'/pay',name:'pay',component:Pay},
    {path:'/pay_status',name:'pay_status',component:Pay_status},
    {path:'/shopping_cart',component:Shopping_cart,meta:{title:"购物车"}},
    //确认订单
    {path:'/shopping_order',component:Shopping_order,meta:{title:"确认订单"}},
    //卖家中心
    {path:'/seller',component:Seller,redirect:'/seller/home',children:[
      //首页
      {path:'home',component:seller_index,meta:{title:"卖家中心"}},
      //服务管理
      {path:'serve',component:seller_home,redirect:'/seller/serve/service_list',children:[
        {path:'service_list',name:'service_list',component:service_list,meta:{title:"服务管理"}},
        {path:'publishing_service',name:'publishing_service',component:Publishing_service,meta:{title:"服务管理"}},
      ]},
      //咨询师
      {path:'counselor',component:seller_home,redirect:'/seller/counselor/counselor_list',children:[
        {path:'counselor_list',name:'counselor_list',component:counselor_list,meta:{title:"咨询师管理"}},
        {path:'new_counselor',name:'new_counselor',component:New_counselor,meta:{title:"咨询师管理"}}
      ]},
      //订单管理
      {path:'order',component:seller_home,redirect:'/seller/order/order_list_seller',children:[
        {path:'order_list_seller',name:'order_list_seller',component:Order_list_seller,meta:{title:"订单管理"}},
        {path:'order_ticket_seller',name:'order_ticket_seller',component:Order_ticket_seller,meta:{title:"订单管理"}},
        {path:'order_details_seller',name:'order_details_seller',component:Order_details_seller,meta:{title:"订单管理"}},
        {path:'order_upload',name:'order_upload',component:Order_upload,meta:{title:"上传凭证"}},
      ]},
      //委托单管理
      {path:'order_ticket',component:seller_home,redirect:'/seller/order_ticket/order_ticket_list',children:[
        {path:'order_ticket_list',component:order_ticket_list,meta:{title:"委托单管理"}}
      ]},
      //我的竞标
      {path:'bid',component:seller_home,redirect:'/seller/bid/my_bid',children:[
        {path:'my_bid',component:my_bid,meta:{title:"我的竞标"}}
      ]},
      //店铺管理
      {path:'shop',component:seller_home,redirect:'seller/shop/shop_maintenance',children:[
        {path:'shop_maintenance',name:'shop_maintenance',component:Shop_maintenance,meta:{title:"店铺信息维护"}},
        {path:'qua_man',name:'qua_man',component:Qua_man,meta:{title:"资质管理"}},
        {path:'case_man',name:'case_man',component:Case_man,meta:{title:"案例管理"}},
        {path:'new_qua',name:'new_qua',component:New_qua,meta:{title:"资质管理"}},
        {path:'new_case',name:'new_case',component:New_case,meta:{title:"案例管理"}},
        {path:'about_store',name:'about_store',component:About_store,meta:{title:"店铺简介"}}
      ]},
      //我的关注
      {path:'follow',component:seller_home,redirect:'seller/follow/my_favorite',children:[
        {path:'my_favorite',name:'my_favorite',component:My_favorite_seller,meta:{title:"我的关注"}},
        {path:'my_footprint',name:'my_footprint',component:My_footprint_seller,meta:{title:"我的足迹"}},
      ]},
    ]},
    //需求大厅
    {path:'/demand_hall',component:Demand_hall,redirect:'/demand_hall',children:[
      {path:"/demand_hall",name:"demand_hall",component:Demand_hall_index,meta:{title:"需求大厅"}},
      {path:'demand_details',name:"demand_details",component:Demand_details,meta:{title:"需求详情"}},
    ]},
    //搜索页面
    {path:'/search_page',name:'search_page',component:Search_page,meta:{title:"搜索"}},
    //首页附属
    {path:'/contact_us',component:Contact_us,meta:{title:"联系我们"}},
    {path:'/prize_message',name:'prize_message',component:Prize_message,meta:{title:"有奖留言"}},
    //新闻资讯
    {path:'/news_home',component:News_home,redirect:'/news_home',children:[
      {path:'news_details',component:News_details,meta:{title:"新闻详情"}},
      {path:'news_list',component:News_list,meta:{title:"新闻资讯"}}
    ]},
    //首页底部协议
    {path:'/online_payment_protocol',component:online_payment_protocol,meta:{title:"在线支付协议"}},
    {path:'/store_entry_agreement',component:Store_entry_agreement,meta:{title:"商家入驻协议"}},
    {path:'/invoice_policy',component:Invoice_policy,meta:{title:"发票政策"}},
    {path:'/complaints_rights',component:Complaints_rights,meta:{title:"投诉维权"}},
    {path:'/after_sales_policy',component:After_sales_policy,meta:{title:"售后政策"}},
    {path:'/buyer_Introduction',component:buyer_Introduction,meta:{title:"买家入门"}},
    {path:'/seller_Introduction',component:seller_Introduction,meta:{title:"卖家入门"}},
    //index-bottom
    {path:'/about_us',component:about_us,meta:{title:"关于我们"}},
    //店铺管理
    {path:'/shop_management',name:'shop_management',component:shop_management,redirect:'/shop_management/shop_man_index',children:[
      {path:'shop_man_index',name:'shop_man_index',component:shop_man_index,meta:{title:"店铺管理"}},
      {path:'shop_introduction',name:'Shop_introduction',component:Shop_introduction,meta:{title:"店铺介绍"}},
      {path:'shop_service_list',name:'shop_service_list',component:Shop_service_list,meta:{title:"店铺服务列表"}},
      {path:'shop_qua_cer',name:'shop_qua_cer',component:Shop_Qua_Cer,meta:{title:"店铺资质证书"}},
      {path:'shop_service_case',name:'shop_service_case',component:Shop_Service_Case,meta:{title:"店铺服务案例"}},
      {path:'detail_page',name:'detail_page',component:detail_page,meta:{title:"服务详情"}}
    ]},
    //店铺列表
    {path:'/shop_list',component:Shop_list,meta:{title:"店铺列表"}},
    //服务机构
    {path:'/facilitating_agency',component:Facilitating_agency,meta:{title:"服务机构"}}
  ]
})
