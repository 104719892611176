<template>
    <div class="auth-title" v-if="!status||status!=3">
        <img v-if="status===2" src="../../assets/img/user/i-error.png" />
        <img v-if="status===0" src="../../assets/img/user/i-success.png" />
        <img v-if="status===1" src="../../assets/img/user/i-load.png" />
        <div class="auth-title-span">
            <span class="auth-title-b">{{titleVal}}</span><!-- 非常抱歉，您的请求被驳回 -->
            <span class="auth-title-s" v-if="status===2">驳回原因：{{audit}}</span>
            <span class="auth-title-s" v-if="status===0">备注：{{audit}}</span>
            <span class="auth-title-s" v-if="status===1">请等待审核</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        status:Number | String,
        audit:String
    },
    data(){
        return {

        }
    },
    watch: {

    },
    computed: {
        titleVal:function(){
            var val = "";
            switch(this.status){
                case 1:
                    val = '等待审核';
                    break;
                case 2:
                    val = '非常抱歉，您的请求被驳回';
                    break;
                case 0:
                    val = '恭喜您，审核通过！'
                    break;
            }
            return val
        }
    },
    components: {

    },
    methods:{

    },
    mounted:function(){

    },
}
</script>

<style scoped>
.auth-title{
    height: 100px;
    background: #f5f5f5;
    position: relative;
    line-height:92px;
    padding: 0 42px 0 56px;
}
.auth-title .auth-title-b{
    color: #ff4a03;
    font-size: 18px;
    font-weight: bold;
    line-height:26px;
    display:block;
}
.auth-title .auth-title-s{
    color: #999;
    font-size: 12px;
    line-height: 18px;
    display:block;
}
.auth-title img{
    width: 68px;
}
.auth-title-span{
    display:inline-block;
    vertical-align:middle;
    margin-left:20px;
}
</style>
