<template>
    <div>
        <IndexHeader_home :textMeg="textMeg"/>
        <div class="online">
            <div class="set">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>发票政策</el-breadcrumb-item>
                </el-breadcrumb>
                <div class="sett">
                    <div>发票政策</div>
                    <div class="content-body">
                        <h3>一、 如何获得发票</h3>
                        <p>平台所售商品都是正品行货，均自带中文机打的“商品专用发票”（图书商品用户自由选择是否开发票），发票金额含配送费金额，另有说明的除外。</p>
                        <p>如何获得普通纸质发票：下单时选择“普通发票（纸质）”自助开取，此发票可用作单位报销凭证，一个包裹对应一张发票，不同的物流中心发出的包裹开具不同的发票，发票会随每次包裹一同发出。</p>
                        <h3>二、 开发票的注意事项</h3>
                        <p>1.发票金额不能高于订单实际支付金额。</p>
                        <p>2.为了享受厂商提供的质保服务，请您将商品发票开具为明细。</p>
                        <p>3.不同物流中心开具的发票无法合并。</p>
                        <p>4.使用优惠券的金额不开具发票。</p>
                        <p>5.一个订单对应一张发票。</p>
                        <h3>三、 换发票</h3>
                        <p>您在收到发票后如果发现因商家原因所致票据抬头、内容或金额错误，请在自开票日期起30日内可联系客服人员为您安排办理换发票事宜。</p>
                    </div>
                </div>
            </div>
        </div>
        <IndexFooter_home />
    </div>
</template>

<script>
import IndexHeader_home from '@/components/IndexHeader_home.vue'
import IndexFooter_home from '@/components/IndexFooter_home.vue'
export default {
    data(){
        return{
            textMeg:'发票政策'
        }
    },
    components: {
        IndexHeader_home,IndexFooter_home
    },
}
</script>

<style scoped>
    p{
        margin: 14px 0;
    }
    .online{
        background: #f5f5f5;min-width:1200px;padding-bottom: 20px
    }
    .set{
        width: 1200px;margin: 0 auto;padding-top: 20px
    }
    .set .sett{
        margin-top: 10px;
        min-height: 500px;background: #fff;
        padding: 30px 40px 60px;

    }
    .set .sett>div:first-child{
        font-size: 28px;border-bottom: 1px solid #eee;
        padding-bottom: 20px;text-align: center;margin-bottom: 25px
    }
    .content-body p{
        text-indent: 28px;
        font-size: 14px;
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    }
    h3{
        display: block;
        font-size: 16px;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        color: #333
    }
</style>
