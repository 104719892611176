import { render, staticRenderFns } from "./authenTitle.vue?vue&type=template&id=c9455568&scoped=true"
import script from "./authenTitle.vue?vue&type=script&lang=js"
export * from "./authenTitle.vue?vue&type=script&lang=js"
import style0 from "./authenTitle.vue?vue&type=style&index=0&id=c9455568&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c9455568",
  null
  
)

export default component.exports