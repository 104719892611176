<template>
    <div class="indx">
        <div class="shopI" v-loading="loading">
            <div class="no">
                <div>
                    <div></div>
                    <span>店铺介绍</span>
                </div>
                <div v-html="list.remark"></div>
            </div>
            <div class="no">
                <div>
                    <div></div>
                    <span>联系我们</span>
                </div>
                <div class="no1" id="no1">
                    <ul>
                        <li><span>机构名称<i></i></span>：{{list.shopName}}</li>
                        <li><span>座机号码<i></i></span>：{{list.shopTel}}</li>
                        <li><span>经营描述<i></i></span>：{{list.shopScope}}</li>
                        <li></li>
                    </ul>
                    <ul>
                        <li><span>店铺负责人<i></i></span>：{{list.shopManager}}</li>
                        <li><span>联系人电话<i></i></span>：{{list.managerPhone}}</li>
                        <li><span>业务范围<i></i></span>：<span v-for="(list,i) in list.shopCategory" :key="i" class="busi">{{list}}；</span></li>
                        <li></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    props:['id'],
    data(){
        return{
            list:{remark:`<p></p>`},
            loading:true
        }
    },
    methods:{
        goQuery(){
            this.$axios.get(this.$com.testCsIp + '/shop/getShopBrief/' + this.id)
            .then(res=>{
                if(res.data.code == 0){
                    this.loading=false;
                    this.list=res.data.data;
                }
            })
        }
    },
    mounted(){
        this.goQuery();
    }
}
</script>




<style scoped>
    .indx{
        min-width: 1200px;background: #f5f5f5;
        padding: 20px 0 10px 0
    }
    .shopI{
        width: 1200px;margin: 0 auto;
    }
    .shopI>div{
        margin-bottom: 10px;
        background: #fff;padding-left: 20px;
    }
    .no>div:first-child{
        display: flex;font-size: 16px;font-weight: bold;
        align-items: center;
        border-bottom: .5px solid #ccc;
        line-height: 50px
    }
    .no>div:nth-child(2){
        padding: 30px 30px 32px 20px;
    }
    .no p{
        font-size: 14px;color: #333;line-height: 34px;
        text-indent:28px;text-align: justify;
    }
    .no>div>div{
        width: 3px;height: 16px;
        background: #FF4A03;margin-right: 9px
    }
    #no1{
        margin: 0;padding: 24px 0 20px 20px;
        min-height: 150px;overflow:hidden
    }
    .no1>ul{
        float: left;width: 560px;
        min-height: 100px;border-right: .5px solid #ccc
    }
    .no1>ul:first-child{
        padding-right:20px;
    }
    .no1>ul:last-child{
        padding-left: 20px;border: none
    }
    ul>li{
        font-size:14px;line-height:34px;color:#333;
        text-align: justify
    }
    ul>li span{
        width: 70px;height:34px;line-height:34px;font-weight: bold;
        text-align:justify;display:inline-block;overflow:hidden;vertical-align:top;
    }
    ul>li:last-child{
        line-height: 24px
    }
    i{display:inline-block;width:100%;height:0;}
    .busi{
        display: inline;font-weight: normal
    }
</style>
