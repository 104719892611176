<template>
    <div>
        <IndexHeader_home :textMeg="textMeg"/>
        <div class="content">
            <div>
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/news_home/news_list'}">资讯列表</el-breadcrumb-item>
                </el-breadcrumb>
            </div>

            <div>
                <img src="../../../assets/img/index1/gg.png" alt="">
                <div>
                    <div class="no1">
                        <el-row class="tac">
                            <el-col >
                                <!-- default-active="0" -->
                                <el-menu
                                default-active="0"
                                class="el-menu-vertical-demo"
                                @open="handleOpen"
                                @close="handleClose">
                                <el-menu-item :index="tit.i+''" v-for="(tit,index) in title[0]" :key="index" @click="open(tit.id,1,tit.name)">
                                    <span slot="title">{{tit.name}}<i class="el-icon-arrow-right"></i></span>
                                </el-menu-item>
                                </el-menu>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="no2" v-loading="loading">
                        <p v-for="(lis,i) in list" :key="i" @click="goData(lis.id)"><span>{{lis.newsName}}</span><span>{{lis.createTime}}</span></p>
                        <pagination v-if="total>10" :total="total" :pageSize="10" @pageChange="pageChange"/>
                        <div class="imgs" v-if="total == 0">
                            <img class="empty-img" src="../../../assets/img/user/empty_page.jpg" alt="暂无数据">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IndexHeader_home from '@/components/IndexHeader_home.vue'
import pagination from '@/components/UI/Pagination.vue'
export default {
    data(){
        return{
            textMeg:'资讯列表',
            list:[],
            title:[],
            id:"",
            pageNum:1,
            total:"",
            loading:true,
            sid:"",
            titName:"",
        }
    },
    components: {
        IndexHeader_home,pagination
    },
    methods: {
              handleOpen(key, keyPath) {
        console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath);
      },
      pageChange(data){
          this.pageNum=data;
          this.open(this.id,this.pageNum)
      },
      goData(id){
            var routeData = this.$router.resolve({ path:'/news_home/news_details', query:  {sid:this.sid,name:this.titName, id:id } });
            window.open(routeData.href, '_blank');
            //this.$router.push({path:'/news_home/news_details',query:{ id }})
      },
      open(id,page,name){
        this.id=id;
        this.titName=name;
        var data = {
            pageSize:10,
            pageNum:page
        }
        if(id !=null||undefined ){
            data.id=this.id
        }
        this.$axios.post(this.$com.testUsIp + '/news/newsList',this.$qs.stringify(data)).then(res=>{
            if(res.data.code == 0){
                this.loading=false;
                this.list=res.data.data.records;
                this.total=res.data.data.total
            }
        })
      }
    },
    mounted(){
        this.$axios.post(this.$com.testUsIp + '/news/listType/1').then(res=>{
            var data=res.data.data
            if(data !=null){
                var a=[];
                this.titName=data[0].typeName;
                for(var i=0;i<data.length;i++){
                    this.sid=data[0].id
                    a.push({name:data[i].typeName,id:data[i].id,i:i})
                }
                this.title.push(a)
            }
            this.open(this.sid,1,this.titName);
        })
    }
}
</script>

<style scoped>
    .content{
        background: #f5f5f5;
        padding: 20px 0;min-width: 1200px
    }
    .content>div{
        width: 1200px;margin: 0 auto;
        padding-bottom: 10px
    }
    .content>div:nth-child(2){
        background: #fff;
        min-height: 400px;
    }
    .content>div:nth-child(2)>img{
        margin-bottom: 20px
    }
    .content>div:nth-child(2)>div{
        /*padding: 0px 32px;*/
        display: flex
    }
    .no1{
        text-align: center;width: 140px;
        /*padding:30px 0 20px 0;*/
    }
    .no2{
        width: 1060px;padding-left: 30px;
        font-size: 14px;color: #666;
        text-align: justify;
    }
    .no2 p{
        height: 50px;border-bottom: 1px dotted #DDD;
        line-height: 50px;padding:0 53px 0 28px;cursor: pointer;
    }
    .no2 p:hover span:first-child{
        color: #007bff;
    }
    .no2 p span:nth-child(2){
        float: right;
    }
    .el-menu{
        border: none
    }
    .pagi{
        float: right;
        margin-right: 50px
    }
    .imgs{
        width: 360px;
        margin: 0 auto
    }
</style>

