<template>
    <div class="pagination">
        <el-pagination class="text-center" background @current-change="handleCurrentChange"
         :current-page="current"  :page-size="pageSize"
         layout="total, prev, pager, next, jumper" :total="total">
        </el-pagination>
    </div>
</template>

<script>
export default {
    props: {
        total: {
            type: Number
        },// 总条数
        pageSize:{
            type: Number,
            default:10
        },
        current:Number
    },

    data() {
        return {
            page: {
                page: 1,
                current:this.current
            }
        };
    },
    watch:{
        /*current(val){
            console.log(val)
        }*/
    },
    methods: {
        // 当前页码变更
        handleCurrentChange(val) {
    console.log(val,'第幾頁')
            this.$emit('pageChange', {num:val});
        }
    }
}
</script>

<style>
    .pagination{
        margin: 20px 0;
    }
    .text-center{
        margin: 0 auto
    }
</style>