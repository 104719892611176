<template>
    <div>
        <IndexHeader_home :textMeg="textMeg"/>
        <div class="online">
            <div class="set">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>在线支付协议</el-breadcrumb-item>
                </el-breadcrumb>
                <div class="sett">
                    <div>在线支付服务协议</div>
                    <div class="content-body">
                        <p>在线支付服务是重庆启翔企业管理股份有限公司(以下简称“启翔股份”)与支付宝、微信合作共同向用户提供的一项在线支付服务。若您使用在线支付服务，您的使用行为将被视为对在线支付服务协议全部内容的知晓、认可与同意遵守。在确认本协议之前，请您仔细阅读本协议的全部内容；如果您不同意本协议的任意内容，或者无法准确理解本协议任何条款的含义，请不要进行确认及后续操作。</p>
                        <h3>一、定义</h3>
                        <p>1.用户（以下简称“您”）：是指佰证通（www.bzt88.cn）的用户中，使用本服务，以完成款项在线支付的用户。本服务中，您在提供服务为服务方，购买服务时为服务方。</p>
                        <p>2.交易双方：指在使用本服务，就交易服务达成一致的需求方和服务方。</p>
                        <p>3.订单：是指交易双方之间在系统中生成的，集成交易双方关键性信息的委托但。订单生成并经确认后具备法律效力。</p>
                        <h3>二、接受条款</h3>
                        <p>1.您知晓并同意，为控制可能存在的风险，启翔控股根据《中华人民共和国反洗钱法》，对您使用在线支付服务时的每天交易最高限额以及每笔交易最高限额进行了限制，并保留对限制种类和限制额度进行无需预告地调整的权利。</p>
                        <p>2.使用在线支付服务是基于您对交易对方的充分了解（包括但不限于对方的真实身份及确切的收款账号等），一旦您使用在线支付服务，您应当自行承担因您指示错误（失误）而导致的风险。该过错包括但不限于：不按照交易提示操作，未及时进行交易操作，遗忘或泄漏密码，密码被他人破解，您使用的计算机被他人侵入。</p>
                        <p>3.启翔股份依照您指示的并根据本协议的约定完成款项支付后，即完成了当次服务的所有义务。交易双方之间产生的关于当次交易的任何纠纷，经交易一方申请，启翔股份提供调解服务，但对任何纠纷不承担任何责任。关于争议的具体处理方式请参见本协议第七条《交易争议处理规则》。</p>
                        <p>4.您同意，为了您的账户内资金的安全，根据本协议的约定、法律法规及法律文书的规定、政府依行政职权的要求及启翔股份依据自行判断认为的可能对您的账户产生风险的情况，启翔股份有权对您名下的全部或部分资金进行冻结，即进行暂时关闭这些账户部分或全部资金使用权限的操作。冻结的逆过程为解冻，即启翔股份对被冻结的账户结束冻结。当冻结发生时，如您申请解冻，启翔股份有权依照自行判断根据本项规定前述的冻结原因来决定是否允许全部或部分解冻，您应充分理解您的解冻申请并不必然被允许，且申请解冻时您应当配合启翔股份核实您的身份的有关要求，提供包括但不限于身份信息、身份证、营业执照、其他有效的身份证明文件及启翔股份要求的其他信息或文件</p>
                        <p>5.您理解并同意，启翔股份有权不时对相关服务的约定做出修订，并在佰证通上进行公布，或向您在注册账号时提供的电子邮件地址向您发出通知。无论采用何种形式，前述修订内容自通知发出之日起即对您和服务提供主体产生约束力。若您在通知发出之后继续使用本服务的，表示您已充分阅读、理解并接受修改后的内容，也将遵循修改后的内容使用相关服务；若您不同意修改后的内容，您应停止使用相关服务。</p>
                        <h3>三、在线支付服务</h3>
                        <p>在线支付服务内容主要包含：</p>
                        <p>1.保管功能：您委托启翔股份代为保管您通过使用本服务的指定的方式向您的支付账户充值的资金。</p>
                        <p>2.代收功能：您可以要求启翔股份代为收取其他开通在线支付的用户向您支付的各类款项。</p>
                        <p>3.代付功能：您可以要求启翔股份将代管或代收的您的款项支付给您指定的其他开通在线支付的用户。您同意，启翔股份代付后，非经法律程序或者非依本协议之约定，该支付是不可逆转的。</p>
                        <p>4.提现功能：您可以要求启翔股份退返代管的您的资金或向您支付由启翔股份代收的款项。当您向启翔股份做出提现指令时，必须提供一个与您的名称一致的有效的中国大陆银行账户，启翔股份将于收到指令后的三个工作日内，将相应的款项汇入您提供的有效银行账户（根据您提供的银行不同，会产生汇入时间上的差异）。启翔股份拥有制定及调整提现限制额度以及提现频率之权利。您同意当启翔股份发现您发出大额提现指令时，要求您向启翔股份提供合法有效的身份证明。在您未能按要求提供前述材料之前，启翔股份有权不执行您的提现要求。除本条约定外，启翔股份不接受您提供的其他受领方式。</p>
                        <p>5.查询功能：启翔股份将对您在本系统中的所有操作进行记录，不论该操作之目的最终是否实现。您可以在本系统中实时查询您的在线支付账户名下的交易记录，您认为记录有误的，本公司将向您提供启翔股份已按照您的指令所执行的收付款的记录。您理解并同意您最终收到款项的服务是由您提供的银行账户对应的银行提供，您需向该银行请求查证。</p>
                        <p>6.款项专属：对您支付至在线支付账户的款项及您通过在线支付账户收到的货款，启翔股份将予以妥善保管，除本协议另有规定外，不作任何其他非您指令的用途。</p>
                        <h3>四、在线支付服务开通</h3>
                        <p>您须在佰证通完成账号的注册，并且按照启翔股份要求提供相关信息完成开通后方可使用本服务。您同意：</p>
                        <p>1.按照要求准确提供并在取得该账户后及时更新您正确、最新及完整的身份信息及相关资料。您有义务维护并立即更新您的注册信息，确保其为正确、最新及完整。若您提供任何错误、不实、过时或不完整的资料，或者启翔股份有合理的理由怀疑您的注册信息为错误、不实、过时或不完整，启翔股份有权暂停或终止您的在线支付服务，您并同意负担因此所产生的直接或间接的任何损失、支出、费用、罚金。若启翔股份依照国家法律法规、部门规章或监管机构的要求需要您补充提供任何相关资料时您不能及时配合提供，启翔股份有权暂停或终止向您提供部分或全部在线支付服务。</p>
                        <p>2.因您未及时更新资料（包括但不限于身份证、营业执照等证件或其他身份证明文件、联系方式、与在线支付服务绑定的邮箱、手机号码等），导致本服务无法提供或提供时发生任何错误，您不得将此作为取消交易、拒绝付款的理由，您将承担因此产生的一切后果，启翔股份不承担任何责任。</p>
                        <p>3.您应对您开通的在线支付服务负责，只有您本人可以使用您的在线支付服务。</p>
                        <p>4.在您决定不再使用该账户时，您应将该账户下所对应的可用款项全部提现或者向启翔股份发出其它支付指令，并向启翔股份申请注销该账户</p>
                        <p>5.若您丧失全部或部分民事权利能力或民事行为能力，启翔股份有权根据有效法律文书（包括但不限于生效的法院判决书等）处置与您账户中的相关款项。</p>
                        <h3>五、用户的权利和义务</h3>
                        <p>1、诚信服务</p>
                        <p>（1） 交易双方应按网站规则和双方约定全面履行合同义务。买卖合同有关的任何争议，应由您和交易对方自行解决。
（2） 供应方保证其提供的服务、商品符合国家或行业等质量规范，其宣传内容符合国家有关规定，不存在假冒伪劣、虚假宣传、侵犯他人知识产权等合法权益或违反国家法律法规的情形，并对服务、商品及其宣传中可能引发的问题承担全部责任。
（3） 供应方保证所发布信息的真实性和有效性。在服务时，必须向需求方提供合法有效的凭证（注：该凭证是指质保单、服务发票等）。对于供应方提供虚假信息或未提供合法有效凭证造成启翔股份或第三人损失的，启翔股份有权对其进行处罚并要求赔偿损失。
（4） 需求方应及时履行各阶段付款义务及收货义务。如确因商品数量、质量问题，双方应自行协商退换货事宜。</p>
                        <p>2、使用限制</p>
                        <p>您在使用在线支付服务时应遵守中华人民共和国相关法律法规，您不得利用本服务从事以下行为或用于以下不当用途</p>
                        <p>（1）侵害他人名誉权、商业秘密、隐私权、商标权、专利权等合法权益；
（2）冒用他人名义使用本服务；
（3）利用本服务从事不法交易行为，如洗钱、贩卖违法违禁物品或其他佰证通限制的服务、商品及行为；
（4）违反相关法律法规使用银行卡，或利用信用卡套取现金；
（5）提供虚假交易信息或不真实的交易；
（6）从事任何可能含有电脑病毒或是可能侵害本服务系统、资料的行为；
（7）其他启翔股份认为不适当的行为。</p>
                        <p>3、使用提示</p>
                        <p>（1）本服务仅作为您通过本网站服务达成交易时在线支付的工具，启翔股份并不实际参与买卖双方的交易，不能控制或保证服务信息的真实性、合法性、准确性，交易所涉及的物品的质量、安全或合法性，以及相关交易各方在贸易协议项下的各项义务的履行能力。因此您不得因其与其他用户之间发生的或与第三方发生的任何交易争议要求启翔股份承担任何责任。希望您在使用本服务时，小心谨慎并运用常识。
（2）本服务将对您在网站的所有操作进行记录。您可以在本服务中实时查询您的会员账号名下的交易记录，您认为记录有误的，启翔股份将向您提供已按照您的指令所执行的收付款的记录。您如对信息记录有异议，应在以书面形式向启翔股份提交，启翔股份将及时受理，并在合理时间内给予书面回复。</p>
                        <h3>六、启翔股份的权利和义务</h3>
                        <p>1.您同意在有正当、合法、合理之情形时，启翔股份将您的相关交易信息提供给第三方，如政府部门、司法机关、银行卡组织、其他合作机构等。</p>
                        <p>2.启翔股份有权为了您的利益留存您在使用本服务过程中形成的数据信息并提供给关联公司，以供后续向您提供持续地、更优质的服务。</p>
                        <p>3.对于因启翔股份合理控制范围以外的原因（包括但不限于自然灾害、罢工或骚乱、暴动、战争行为、政府行为、以及因互联网的特殊性而产生的包括黑客攻击、互联网连通中断或者系统故障、电信部门技术调整导致的影响、政府管制而造成的暂时性关闭在内的任何影响网络正常运营的因素等）致使服务中断或其他缺陷，启翔股份不承担任何责任，但将尽力减少因此而给您造成的损失和影响。</p>
                        <h3>七、交易争议处理规则</h3>
                        <p>交易双方在使用本服务时产生的交易争议，双方应自行协商解决，若双方无法协商或协商不能达成一致意见，一方或双方可申请提交启翔股份进行处理，启翔股份有权根据本规则决定是否受理相关争议投诉。对于决定受理的争议，启翔股份将根据本规则对相关事实进行认定，双方均有义务针对自己主张提供相关事实依据，启翔股份有权根据已搜集到的资料进行独立判断，并作出最终处理结果。同时，若认定某方存在违规行为，将按本规则进行处罚。但交易双方均知悉，启翔股份并非专业司法机关，如启翔股份介入调解或处理后，交易双方仍无法就相关争议达成一致意见的，交易双方应采用诉讼或仲裁等方式解决争议。</p>
                        <p>1、受理条件</p>
                        <p>（1）必须通过佰证通进行交易并完成在线支付；
（2）投诉方须提供真实、有效的身份证明、联系方式；
（3）有具体明确的被投诉方；
（4）有具体明确的投诉请求、事实和理由；
（5）提供必要、准确、详实的事实依据和证明材料；
（6）中华人民共和国大陆境内交易产生的争议；
（7）符合本规则第二条的受理范围；
（8） 其他启翔股份认为需要具备的条件。</p>
                        <p>2、受理范围提供已按照您的指令所执行的收付款的记录。您如对信息记录有异议，应在以书面形式向启翔股份提交，启翔股份将及时受理，并在合理时间内给予书面回复。</p>
                        <p>启翔股份仅受理在交易双方在交易履行过程中产生的交易争议，交易双方服务态度引起或非贸易争议类的投诉不属于启翔股份投诉受理范围。针对以下各种情形的交易争议，启翔股份有权根据处理结果，对责任方采取包括但不限于警告、公示、限权、终止服务等处罚措施。</p>
                        <p>3、争议处理流程</p>
                        <p>如交易双方对交易出现争议，且双方未能协商一致，可申请启翔股份介入，投诉方需在发起投诉的同时提交凭证资料，被投诉方在启翔股份确认介入之日起的2个工作日之内最后完成凭证资料的提交。
启翔股份将在收到申请后的5个工作日内确认是否介入，如确认介入，将在10个工作日内给出投诉处理结果，同时根据需求方或供应方注册平台（佰证通）的相关处罚规则对责任方做出相应处理（包括但不限于公示、限权、终止服务等）。</p>
                        <p><img src="../../../assets/img/xy1.png" height="278" width="558" alt=""></p>
                        <p>鉴于启翔股份非专业争议解决机构，对证据的鉴别能力及对纠纷的处理能力有限，不保证争议处理结果符合交易双方的期望，亦不对争议处理结果承担任何责任。如启翔股份介入处理后，交易双方仍无法就相关争议达成一致意见的，交易双方应采用诉讼或仲裁等方式解决争议。</p>
                        <h3>八、隐私保护</h3>
                        <p>为更安全有效地向您提供服务，根据法律法规的规定，或启翔股份需识别您的身份，或启翔股份认为您的账户存在风险时，启翔股份有权要求您提交身份信息（包括但不限于企业营业执照、身份证等证件或其他文件）。除以下情况外，启翔股份不对外公开或向第三方提供您的信息：</p>
                        <p>（1）事先获得您的明确授权；</p>
                        <p>（2）只有披露您的个人资料，才能提供您需要的产品和（或）服务；</p>
                        <p>（3）按照本协议的要求进行的披露；</p>
                        <p>（4）根据法律法规的规定；</p>
                        <p>（5）按照政府主管部门的要求；</p>
                        <p>（6）为维护启翔股份及其关联公司的合法权益；</p>
                        <p>（7）对您或您所代表公司的身份真实性进行验证。</p>
                        <h3>九、服务中止或终止</h3>
                        <p>启翔股份有权在下列情况下，中止或终止您使用全部或部分在线支付服务：</p>
                        <p>1、您违反了本协议或你注册网站（佰证通）用户协议的任何条款；</p>
                        <p>2、您出具书面申请，要求暂停或停止在线支付服务的部分或全部服务；</p>
                        <p>3、可能造成服务中止或终止的其他情形，包括但不限于：
（1）侵害他人名誉权、隐私权、商业秘密、商标权、著作权、专利权等合法权益。
（2）违反依法定或约定之保密义务。
（3）冒用他人名义使用支付宝、微信支付服务。</p>
                        <h3>十、其他</h3>
                        <p>1.本协议的订立、变更、执行和解释，以及与本协议有关的争议解决，均应适用中华人民共和国法律。如与本协议有关的某一特定事项缺乏明确法律规定，则应参照通用的国际商业惯例和行业惯例。</p>
                        <p>2.如因本协议内容或其执行发生争议，您应与启翔股份友好协商解决；协商不成时，任何一方均应向启翔股份所在地有管辖权的人民法院提起诉讼。</p>
                        <p>3.本协议解释权归启翔股份所有。</p>
                    </div>
                </div>
            </div>
        </div>
        <IndexFooter_home />
    </div>
</template>

<script>
import IndexHeader_home from '@/components/IndexHeader_home.vue'
import IndexFooter_home from '@/components/IndexFooter_home.vue'
export default {
    data(){
        return{
            textMeg:'在线支付协议'
        }
    },
    components: {
        IndexHeader_home,IndexFooter_home
    },
}
</script>

<style scoped>
    p{
        margin: 14px 0;
    }
    .online{
        background: #f5f5f5;min-width:1200px;padding-bottom: 20px
    }
    .set{
        width: 1200px;margin: 0 auto;padding-top: 20px
    }
    .set .sett{
        margin-top: 10px;
        min-height: 500px;background: #fff;
        padding: 30px 40px 60px;

    }
    .set .sett>div:first-child{
        font-size: 28px;border-bottom: 1px solid #eee;
        padding-bottom: 20px;text-align: center;margin-bottom: 25px
    }
    .content-body p{
        text-indent: 28px;
        font-size: 14px;
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    }
    h3{
        display: block;
        font-size: 16px;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        color: #333
    }
</style>
