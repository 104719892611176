<template>
    <div class="nav-right-bottom">
        <p><span>平台资讯</span><span @click="more">更多+</span></p>
        <ul>
            <li v-for="(item,index) in newsLists" :key="index">•<router-link :to="{path:'/news_home/news_details', query:{id:item.id}}" :title="item.newsName" target="_blank">{{item.newsName}}</router-link></li>
        </ul>
    </div>
</template>

<script>
export default {
    data(){
        return{
            newsLists:[]
        }
    },
    mounted:function(){
        this.GetData();
    },
    components: {

    },
    methods: {
        GetData:function(){
            this.$axios.post(this.$com.testUsIp + '/news/newsList',
            this.$qs.stringify({
                pageNum:1,
                pageSize:6
            }))
            .then(res=>{
                this.newsLists=res.data.data.records
            })
        },
        more:function(){
            var routeData = this.$router.resolve({ path:'/news_home/news_list' });
            window.open(routeData.href, '_blank');
            //this.$router.push({path:'/news_home/news_list'})
        }
    }
}
</script>

<style scoped>
.banner{
    width:100%;
    position:relative;
}
</style>

