<template>
    <div>
        <div id="index_demand_left" class="section-list-left" style="overflow: auto!important;">
            <div>
                <div>
                    <div></div>
                    <span>检测需求</span>
                    <span>(<span style="color:red">{{count}}</span>项)</span>
                </div>
                <router-link :to="{path:'/demand_hall',query:{ type:3 }}" target="_blank">更多+</router-link>
            </div>
            <router-link v-if="list" v-for="(list,i) in lists" :key="i" :to="{ name:'demand_details',query: { id:list.id }}" target="_blank">
                <ul>
                    <li class="ellipsis">{{list.title}}</li>
                    <li>￥{{list.cost}}</li>
                    <li>{{list.createTime.substring(0, 10)}}</li>
                </ul>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            lists:[],
            count:'',
            serviceType:'',
        }
    },
    mounted:function(){
        this.GetData();
    },
    components: {

    },
    methods: {
        GetData:function(){
            let _this = this;
            this.$axios.get(this.$com.testCsIp + '/recomCategory/listCategory').then(res=>{
                var data=res.data.data
                for(var i=0;i<data.length;i++){
                    if(data[i].id == "4028800a69463bb00169464b43a0000b"){
                        this.serviceType=data[i].categoryId
                    }
                }
                this.$axios.get(this.$com.testCsIp + '/recomCategory/listRecommendDemand',
                {params:{
                    id:'4028800a69463bb00169464b43a0000b',
                    num:10,
                    recommendType:3,
                    serviceType:this.serviceType
                }})
                .then(res=>{
                    this.lists=res.data.data.list;
                    this.count=res.data.data.count

                    _this.$nextTick(()=>{
                        const scrollableDiv = document.getElementById('index_demand_left');
                        let height = scrollableDiv.scrollHeight - 430;
                        scrollableDiv.scrollTop = 0;

                        setInterval(() => {
                            if (scrollableDiv.scrollTop >= height){
                                scrollableDiv.scrollTop = 0;
                            }
                            scrollableDiv.scrollTop += 1;
                        }, 20);
                    })
                })
            })
        },
        more:function(){

        }
    }
}
</script>

<style scoped>

</style>

