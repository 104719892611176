<template>
    <div class="no2">
        <formItem :disabled="disabled" maxlength="25" required title="需求机构" type="text" errorStyle="inline"  placeholder="请填写需求机构名称" :error="orgName.error" :value="orgName.value" v-model="orgName.value"/>
        
        <formItem :disabled="disabled" maxlength="25" required title="证书名称" type="text" errorStyle="inline" placeholder="请填写证书名称" :error="certificateName.error" :value="certificateName.value" v-model="certificateName.value"/>

        <formItem :disabled="disabled" maxlength="50" required title="证书编号" type="text" errorStyle="inline" placeholder="请填写证书编号" :error="certificateCode.error" :value="certificateCode.value" v-model="certificateCode.value"/>

        <formItem :disabled="disabled" title="办理日期" required type="date" errorStyle="inline" :error="executeTime.error" :value="executeTime.value" v-model="executeTime.value"/>

        <formItem :disabled="disabled" required errorStyle="inline" type="file" :error="certificateImg.error" :imgObg="imgObg" title="案例图片" v-model="certificateImg.value" />

        <formItem :disabled="disabled" maxlength="128" title="备注说明" type="textarea"  placeholder="请输入备注说明" v-model="remark"/>
        <ul>
            <li>
                <xbutton :disabled="disabled" @on-click="goAdd" value="保存并提交后台审核"></xbutton>
            </li>
        </ul>
    </div>
</template>

<script>
import formItem from '@/components/UI/formItem.vue'
import Xselect from '@/components/UI/select.vue'
import xbutton from '@/components/UI/xbutton.vue'
import imgBg from '../../../assets/img/seller/file.jpg'
export default {
    data(){
        return{
            typeList:[],
            typevalue:'请选择',
            certificateCode:{
                value:"",
                error:"",
                validate:['required'],
                name:"证书编号"
            },
            certificateImg:{
                value:"",
                error:"",
                validate:['required'],
                name:"案例图片"
            },
            certificateName:{
                value:"",
                error:"",
                validate:['required'],
                name:"证书名称"
            },
            executeTime:{
                value:"",
                error:"",
                validate:['required'],
                name:"办理日期"
            },
            orgName:{
                value:"",
                error:"",
                validate:['required'],
                name:"需求机构"
            },
            imgObg:{
                width:'140px',
                height:'140px',
                img:imgBg,
            },
            remark:"",
            disabled:false,
            CId:this.$route.query.CId,
        }
    },
    components: {
        formItem,Xselect,xbutton
    },
    methods:{
        goAdd(_this){
            var data={
                certificateCode:this.certificateCode.value,
                certificateImg:this.certificateImg.value.fileId,
                certificateName:this.certificateName.value,
                executeTime:this.executeTime.value,
                orgName:this.orgName.value,
                remark:this.remark,
                id:this.CId
            }
            var dataV={
                certificateCode:this.certificateCode,
                //ertificateImg:this.ertificateImg,
                certificateName:this.certificateName,
                executeTime:this.executeTime,
                orgName:this.orgName,
                certificateImg:this.certificateImg
            }
            var validate = this.$com.validate(dataV);
            if(validate){
                _this.loading = true;
                this.$com.majax.post(this.$com.testCwIp + '/sellerCases/saveOrUpdateCase',this.$qs.stringify(data),{
                    success:(res)=>{
                        _this.loading = false;
                        if(res.code==0){
                            this.$dialog.alert('提交成功，请等待审核！','success',()=>{
                                this.$router.push({ name:'case_man' })
                            })
                        }
                    }
                },_this)
            }
        },
    },
    created(){
        var id = this.CId 
        if(this.CId !== undefined){
            this.$parent.titleMeg = '修改案例';
            this.$com.majax.get(this.$com.testCsIp + '/sellerCases/findCaseById/'+id,{},{
                success:(res)=>{
                    this.disabled = (res.data.status===1);
                    this.certificateCode.value=res.data.certificateCode;
                    this.orgName.value=res.data.orgName;
                    this.certificateName.value=res.data.certificateName;
                    this.remark=res.data.remark;
                    this.executeTime.value=res.data.executeTime;
                    this.certificateImg.value={imgUrl:res.data.imagePath,fileId:res.data.certificateImg};
                },
            })
        }else{
            this.CId='';
            this.$parent.titleMeg = '新增案例';
        }
    },
    mounted:function(){
        
    },
}
</script>

<style scoped>
    div.no2{
        width:992px;padding-bottom: 20px;
        min-height:520px;
        background:#fff;
        position: relative;
        padding-top: 30px;
        padding-left: 80px
    }
    .no2>ul{
        display: flex;
        font-size: 14px;margin-bottom: 20px
    }
    .p{
        line-height: 40px
    }
    .p li:first-child{
        margin-right: 10px
    }
    .item-file-img{
        width:244px;
        height:154px;
        position:relative;
        background:url('../../../assets/img/user/license.jpg');
    }
    .no2 .item-file-img .file{
        position: absolute;
        width:100%;
        height:100%;
        cursor:pointer;
        opacity:0;
        text-indent:-100px;
    }
    .item-file-img img{
        height:100%;
        width:100%;
    }
    button{
        width: 200px;height: 40px;
        background: #3296fa;color: #fff;
        font-size: 18px;margin-left: 105px;
        border: 0
    }
    button:hover{
        cursor: pointer;
        background: linear-gradient(to right, #00aaff, #0085ff);
    }
    .required{
        color: red
    }
</style>
