<template>
    <div class="bdph">
        <router-view :phone="phone"></router-view>
    </div>
</template>

<script>
export default {
    props: {

    },
    data(){
        return {
            phone:""
        }
    },
    components: {

    },
    methods:{

    },
    mounted:function(){
        this.$parent.titleMeg = '手机绑定';
        this.$com.majax.get(this.$com.testUsIp + '/security/getOldPhone',{},{
            success:(res)=>{
                if(res.data!='未绑定安全手机'){
                    this.phone = res.data
                    this.$router.push({ name: 'account_bdph_Cph1' })
                }else{
                    this.$router.push({ name: 'account_bdph_add' })
                }
            },
            error:()=>{}
        })
    },
}
</script>

<style scoped>


</style>
