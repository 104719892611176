<template>
    <div class="no2">
        <!-- <a href="/buyer/home/new_auth">新增认证</a> -->
        <xbutton class="go-add" @on-click="GoAdd" value="新增认证" />
        <xtable :loading="loading" :head="head" :tableData="tableData" btn="del,edit" @on-delClick="delClick" @on-editClick="editClick" @on-changePage="(v)=>GetData(v)" @on-handleSelectionChange="(v)=>checkboxList=v" />
    </div>
</template>

<script>
import xtable from '@/components/UI/xtable.vue'
import xbutton from '@/components/UI/xbutton.vue'
export default {
    mounted(){
        this.$parent.titleMeg = '认证管理';
        this.GetData();
    },
    data(){
        return {
            list:[{
                title:"标题",
            }],
            tableData:[],
            currentPage:1,
            loading:false,
            head:{
                certificateName:{
                    name:"认证名称",
                    width:"200"
                },
                ccieSource:"证书来源",
                firstTime:"初步颁证日期",
                executeTime:"生效日期",
                validTime:"有效日期",
                changeTime:"换证日期",
                createTime:"上传日期",
                status:"审核状态",
            }
        }
    },
    components: {
        xtable,
        xbutton
    },
    methods:{
        editClick:function(item){
            this.$router.push({path:'/buyer/auth/new_auth',query: {id: item.id}});
        },
        delClick:function(item){
            this.$dialog.confirm('是否确认删除' + item.certificateName + '？','error',()=>{
                this.$com.majax.post(this.$com.testCsIp + '/existAuthenticate/removeBuyerAuthenticateById/' + item.id,{},{
                    success:(res)=>{
                        if(res.code==0){
                            this.$message({
                                message: '删除成功！',
                                type: 'success'
                            });
                            this.GetData();
                        }
                    }
                })
            })
        },
        GoAdd:function(){
            this.$router.push({name:'buy_new_auth'})
        },
        GetData:function(data){
            this.loading = true;
            this.$com.majax.get(this.$com.testCsIp+'/existAuthenticate/selectBuyerAuthenticate',data,{
                success:(res)=>{
                    this.tableData = res.data;
                }
            },this)
        }
    }
}
</script>


<style scoped>
    div.no2{
        width:992px;min-height: 580px;
        background:#fff;
        position: relative;
        padding: 15px 20px;
        box-sizing: border-box
    }
    .no2>a{
        display: block;width: 100px;height: 34px;
        float: right;background: #3296FA;color: #fff;
        text-align: center;line-height: 34px;font-size: 14px;
        margin-bottom: 10px;margin-right: 2px;text-decoration: none
    }
    table{
        width: 100%;text-align: center;
        border-collapse: collapse;table-layout:fixed;
    }
    table th{
        height: 35px;background: #f5f5f5;
        font-size: 14px;color: #333;font-weight: normal;
        border-right:2px solid #fff ;border-bottom: 1px solid #fff ;
    }
    table th:first-child,table tr td:first-child{
        width: 308px;text-align: left;
        padding-left: 20px;box-sizing: border-box;
        white-space:nowrap;overflow:hidden;text-overflow: ellipsis;
    }
    table th:last-child{
        width: 108px
    }
    table tr:not(:first-child){
        border-bottom:2px dotted #ccc ;
    }
    table td{
        height: 53px;
        font-size: 12px;
    }
    table tr:hover{
        background: #f5f5f5;
    }
    table tr td:not(:first-child):not(:last-child){
        color: #999
    }
    table tr td:last-child{
        color: #3296FA
    }
    table tr td a{
        text-decoration: none;
        color: #0561be
    }
    table tr td a:hover{
        color: #ff4a03
    }
    .go-add{
        width:90px;
        font-size:16px;
        height:34px;
        float:right;
        margin-bottom:15px;
    }
</style>
