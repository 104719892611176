<template>
    <div>
        <h2 class="h">咨询机构、咨询师、认证机构、检测机构</h2>
        <h2 class="h">注册协议</h2>
        <p>佰证通服务（以下简称“本服务”）是由 重庆启翔企业管理顾问股份有限公司（以下简称“本公司”）向佰证通用户提供认证咨询服务供应平台服务、认证咨询、检验检测供应平台服务及其相关认证SAAS平台。您可以在佰证通平台上发布您的认证、检验检测、认证咨询服务，可以获取需求信息。确保整个交易流程、服务流程、资金流，通过平台完成。
本协议由您和本公司签订。</p>
        <br />
        <h5>一、总则</h5>
        <p>1、您确认：在您注册成为佰证通用户以接受本服务之前，您已充分阅读、理解并接受本协议的全部内容，一旦您选择“同意”并完成注册进行购买或使用本服务，即表示您同意遵循本协议之所有约定。</p>
        <p>2、您同意，本公司有权随时对本协议内容进行单方面的变更，并以在本平台公告的方式予以公布，无需另行单独通知您；若您在本协议内容公告变更后继续使用本服务的，表示您已充分阅读、理解并接受修改后的协议内容，也将遵循修改后的协议内容使用本服务；若您不同意修改后的协议内容，您应停止使用本服务。</p>
        <p>3、您声明，在您同意接受本协议并注册成为佰证通平台用户时，您是具有法律规定的完全民事权利能力和民事行为能力，能够独立承担民事责任的自然人、法人或其他组织；本协议内容不受您所属国家或地区法律的排斥。不具备前述条件的，您应立即终止注册或停止使用本服务。</p>
        <br />
        <h5>二、本服务简介</h5>
        <p>向注册用户提供在线平台相关服务。您可以通过本平台发布您的认证供应信息，检验检测供应，可以通过本平台进行投标服务，获取需求方发布的供应信息。认证咨询、办理流程，检验检测流程必须通过本平台实现。完成业务服务流程后平台需要按照一定比例收取佣金。办理业务过程中产生的咨询费、认证费、检验检测费等费用由平台代收，在您和需求方完成整个交易过程后平台会在1到3个工作日内将款打到您的账户。</p>
        <p>本协议由您和本公司签订。</p>
        <p>佰证通平台所提供的相关服务说明详见网址： www.bzt88.cn相关产品及服务介绍。</p>
        <br />
        <h5>三、佰证通平台账户</h5>
        <p>1、注册相关</p>
        <p>在使用本服务前，您必须先行注册，取得本公司提供给您的佰证通平台账户（以下简称“该账户”），您同意并保证您所填写的用户信息是真实、准确、完整、及时的，并且没有任何引人误解或者虚假的陈述，且保证佰证通可以通过您所填写的联系方式与您取得联系。您应根据佰证通平台对于相关服务的要求及时提供相应的身份证明等资料完成实名认证，否则佰证通有权拒绝向您提供相关服务。您承诺将及时更新您用户信息以维持该等信息的有效性。如果您提供的资料或信息包含有不正确、不真实的信息，佰证通保留取消您会员资格并随时结束为您提供服务的权利</p>
        <p>2、账户安全</p>
        <p>您将对使用该账户及密码进行的一切操作及言论负完全的责任，您同意：本公司通过您的用户名和密码识别您的指示，请您妥善保管您的用户名和密码，对于因密码泄露所致的损失，由您自行承担。您保证不向其他任何人泄露该账户及密码，亦不使用其他任何人的佰证通平台账户及密码。您发现有他人冒用或盗用您的账户及密码或任何其他未经合法授权之情形时，应立即以有效方式通知本公司，要求本公司暂停相关服务。同时，您理解本公司对您的请求采取行动需要合理期限，在此之前，本公司对已执行的指令及(或)所导致的您的损失不承担任何责任。</p>
        <br />
        <h5>四、佰证通服务使用守则</h5>
        <p>为有效保障您使用本服务的合法权益，您理解并同意接受以下规则：</p>
        <p>1、您在使用本服务过程中，本协议内容、网页上出现的关于相关操作的提示或本公司发送到您手机的信息（短信或电话等）内容是您使用本服务的相关规则，您使用本服务即表示您同意接受本服务的相关规则。您了解并同意本公司单方修改服务的相关规则，而无须征得您的同意，服务规则应以您使用服务时的页面提示（或发送到该手机的短信或电话等）为准，您同意并遵照服务规则是您使用本服务的前提。</p>
        <p>2、本公司可能会以电子邮件（或发送到您手机的短信或电话等）方式通知您服务进展情况以及提示您进行下一步的操作，但本公司不保证您能够收到或者及时收到该邮件（或发送到该手机的短信或电话等），且不对此承担任何后果。因此，在服务过程中您应当及时登录到本平台查看和进行相关操作。因您没有及时查看和对服务状态进行修改或确认或未能提交相关申请而导致的任何纠纷或损失，本公司不负任何责任。</p>
        <p>3、服务费用，在您使用本服务平台时，本平台根据您完成的交易额收取佣金。</p>
        <br />
        <h5>五、您的权利和义务</h5>
        <p>1、您有权利拥有自己在佰证通的用户名和密码并有权利使用自己的用户名和密码随时登录佰证通。</p>
        <p>2、您有权利享受佰证通提供的相关服务，并有权利在接受佰证通提供的服务时获得佰证通的支持、咨询等服务，服务内容详见佰证通相关服务介绍。</p>
        <p>3、您保证不会利用技术或其他手段破坏及扰乱佰证通和服务以及佰证通平台其他客户的平台和服务。</p>
        <p>4、您应尊重佰证通平台及其他第三方的知识产权和其他合法权利，并保证在发生侵犯上述权益的违法事件时尽力保护佰证通及其股东、雇员、合作伙伴等免于因该等事件受到影响或损失；佰证通平台保留您侵犯佰证通平台合法权益时终止向您提供服务的权利。</p>
        <p>5、对由于您向佰证通平台提供的联络方式有误以及您用于接受佰证通邮件的电子邮箱安全性、稳定性不佳而导致的一切后果，您应自行承担责任，包括但不限于因您未能及时收到佰证通平台的相关通知而导致的后果和损失。</p>
        <p>6、您保证您使用佰证通平台服务时将遵从国家、地方法律法规、行业惯例和社会公共道德，不会利用佰证通平台提供的服务进行违反国家法律法规政策的信息。您承认佰证通平台有权在您违反上述约定时有权终止向您提供服务，因您上述行为给佰证通平台造成损失的，您应予赔偿。</p>
        <br />
        <h5>六、佰证通的权利和义务</h5>
        <p>1、佰证通应为您提供发布认证、检验检测、认证咨询需求信息的平台和在线认证平台。</p>
        <p>2、佰证通承诺对您资料采取对外保密措施，不向第三方披露您资料，不授权第三方使用您资料，除非：</p>
        <p>依据本协议条款或者您与佰证通之间其他服务协议、合同等规定可以提供；</p>
        <p>依据法律法规的规定应当提供；</p>
        <p>行政、司法等职权部门要求佰证通提供；</p>
        <p>您同意佰证通向第三方提供；</p>
        <p>佰证通解决举报事件、提起诉讼而提交的；</p>
        <p>佰证通为防止严重违法行为或涉嫌犯罪行为发生而采取必要合理行动所必须提交的；</p>
        <p>网为向您提供产品、服务、信息而向第三方提供的，包括佰证通通过第三方向您提供产品、服务、咨询。</p>
        <p>3、佰证通及佰证通平台关联公司所有平台有权使用您的资料和信息。</p>
        <p>4、佰证通保留在您违反国家、地方法律法规规定或违反本在线注册条款的情况下终止为您提供服务并终止您帐号的权利。</p>
        <br />
        <h5>七、隐私及其他个人信息的保护</h5>
        <p>一旦您同意本协议或使用本服务，您即同意本公司按照以下条款来使用和披露您的个人信息。</p>
        <p>1、注册信息</p>
        <p>您注册该账户时应向本公司提供您的真实姓名、地址、电话号码和电子邮件地址，您还可以选择来填写相关附加信息（包括但不限于您公司所在的省份和城市、和邮政编码、传真号码、网址和您的职务）。为有针对性地向您提供新的服务和机会，您了解并同意本公司及其关联公司或您登录的其他平台将通过您的电子邮件地址或该手机通知您这些信息。</p>
        <p>2、手机信息</p>
        <p>您应保护好您的手机，一旦您手机出借、被盗、遗失或被他人窥视等，均可能引起您佰证通平台账户相关个人信息之外泄，佰证通平台对此不承担任何责任。</p>
        <p>3、银行账户信息</p>
        <p>若本公司所提供的服务需要您提供您的银行账户信息，在您提供相应信息后，本公司将严格履行相关保密约定。</p>
        <p>4、登录记录</p>
        <p>为了保障您使用本服务的安全以及不断改进服务质量，本公司将记录并保存您登录和使用本服务的相关信息，但本公司承诺不将此类信息提供给任何第三方（除双方另有约定或法律法规另有规定及本公司关联公司外）。</p>
        <p>5、广告</p>
        <p>本公司会对佰证通用户的数据进行综合统计，并出于营销和回馈用户的需要使用或披露。</p>
        <p>6、外部链接</p>
        <p>本平台含有到其他平台的链接，但本公司对其他平台的隐私保护措施不负任何责任。本公司可能在任何需要的时候增加商业伙伴或共用品牌的平台。</p>
        <br />
        <h5>八、系统中断或故障</h5>
        <p>系统因下列状况无法正常运作，使您无法使用各项服务时，本公司不承担损害赔偿责任，该状况包括但不限于：</p>
        <p>1、本公司在本平台公告之系统停机维护期间。</p>
        <p>2、电信设备出现故障不能进行数据传输的。</p>
        <p>3、因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成本公司系统障碍不能执行业务的。</p>
        <p>您应保护好您的手机，一旦您手机出借、被盗、遗失或被他人窥视等，均可能引起您佰证通平台账户相关个人信息之外泄，佰证通平台对此不承担任何责任。</p>
        <p>4、由于黑客攻击、电信部门技术调整或故障、平台升级、银行方面的问题等原因而造成的服务中断或者延迟。</p>
        <br />
        <h5>九、责任范围及责任限制</h5>
        <p>1、本公司仅对本协议中列明的责任承担范围负责。</p>
        <p>2、佰证通用户信息是由您本人自行提供的，本公司无法保证该信息之准确、及时和完整，您应对您的判断承担全部责任。</p>
        <p>3、您自本公司及本公司工作人员或经由本服务取得之建议和资讯，无论其为书面或口头形式，均不构成本公司对本服务之保证。</p>
        <p>4、在法律允许的情况下，本公司对于与本协议有关或由本协议引起的任何间接的、惩罚性的、特殊的、派生的损失（包括业务损失、收益损失、利润损失、使用数据或其他经济利益的损失），不论是如何产生的，也不论是由对本协议的违约（包括违反保证）还是由侵权造成的，均不负有任何责任，即使事先已被告知此等损失的可能性。另外即使本协议规定的排他性救济没有达到其基本目的，也应排除本公司对上述损失的责任。</p>
        <p>5、除本协议另有规定外，在任何情况下，本公司对本协议所承担的违约赔偿责任总额不超过向您收取的当次服务费用的总额。</p>
        <br />
        <h5>十、完整协议</h5>
        <p>本协议由本协议条款与本平台公示的各项规则组成，相关名词可互相引用参照，如有不同理解，以本协议条款为准。</p>
        <p>您对本协议理解和认同，您即对本协议所有组成部分的内容理解并认同，一旦您使用本服务，您和本公司即受本协议所有组成部分的约束。</p>
        <p>本协议部分内容被有管辖权的法院认定为违法的，不因此影响其他内容的效力。</p>
        <br />
        <h5>十一、商标、知识产权的保护</h5>
        <p>1、本平台上所有内容，包括但不限于著作、图片、档案、资讯、资料、平台架构、平台画面的安排、网页设计，均由本公司或本公司关联企业依法拥有其知识产权，包括但不限于商标权、专利权、著作权、商业秘密等。</p>
        <p>2、非经本公司或本公司关联企业书面同意，任何人不得擅自使用、修改、复制、公开传播、改变、散布、发行或公开发表本平台程序或内容。</p>
        <p>3、尊重知识产权是您应尽的义务，如有违反，您应承担损害赔偿责任。</p>
        <br />
        <h5>十二、法律适用与管辖</h5>
        <p>本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律。因本协议产生之争议，均应依照中华人民共和国法律予以处理。</p>
    </div>
</template>

<script type="text/javascript">
  export default {
    name: 'Agreement',
    data () {
      return {

      }
    },
    mounted () {

    },
    methods: {

    }
  }
</script>

<style scoped>
h5{
    font-weight:bold;
}
p{
    text-indent:2em;
}
.h{
        text-align: center
}
</style>