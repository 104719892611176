var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"tree"},_vm._l((_vm.navData),function(item,index){return _c('li',{key:index,class:{'open-li':item.open}},[_c('span',{class:{'grzl-i':item.title=='个人资料',
        'zhaq-i':item.title=='账户安全',
        'detail-i':item.title=='需求管理',
        'order-i':item.title=='订单管理',
        'aut-i':item.title=='认证管理',
        'my-i':item.title=='我的关注',
        'shop-i':item.title=='店铺管理',
        'serve-i':item.title=='服务管理',
        'ticket-i':item.title=='确认委托单',
        'bid-i':item.title=='我的竞标',
        'son-i':item.title=='咨询师管理'
        }},[_vm._v("\n            "+_vm._s(item.title)+"\n        ")]),_c('ul',_vm._l((item.list),function(items,index){return _c('li',{key:index},[_c('router-link',{attrs:{"active-class":"active","to":items.url}},[_vm._v(_vm._s(items.title))])],1)}),0)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }