<template>
    <div>
        <IndexHeader_home :textMeg="textMeg"/>
        <div class="online">
            <div class="set">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>售后政策</el-breadcrumb-item>
                </el-breadcrumb>
                <div class="sett">
                    <div>售后政策</div>
                    <div class="content-body">
                        <p>自商品售出之日（以实际收货日期为准）起7日内可以退换货，15日内可以换货，您可在线提交返修/退换申请办理退换货事宜。具体退换货标准如下：</p>
                        <p>1.退货时请保持主商品完好，附件齐全，并将商品的赠品一并返回；</p>
                        <table class="m-table table-bordered table-hover">
                            <thead>
                            <tr>
                                <th>退换类别</th>
                                <th>具体描述</th>
                                <th>是否支持7天（含）内退换货</th>
                                <th>是否支持15天（含）内换货</th>
                                <th>是否收取返回运费</th>
                                <th>备注</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>国家法律所规定的功能性故障或商品质量问题</td>
                                <td>经由生产厂家指定或特约售后服务中心检测确认，并出具检测报告或经第三方卖家售后确认属于商品质量问题。</td>
                                <td>是</td>
                                <td>是</td>
                                <td>否</td>
                                <td>当地无检测条件的请联系第三方卖家客服处理。</td>
                            </tr>
                            <tr>
                                <td>到货物流损、缺件或商品描述与网站不符等平台原因</td>
                                <td>物流损指在运输过程中造成的损坏、漏液、破碎、性能故障，经售后人员核查情况属实。缺件指商品原装配件缺失。</td>
                                <td>是</td>
                                <td>是</td>
                                <td>否</td>
                                <td>平台审核期间可能需要快递人员证明或要求您提供实物照片等，以便第三方卖家售后快速做出判断并及时处理。</td>
                            </tr>
                            <tr>
                                <td>其他原因</td>
                                <td>除以上两种原因之外，如个人原因导致的退换货，在保证商品完好的前提下。</td>
                                <td>是</td>
                                <td>否</td>
                                <td>是</td>
                                <td>由客户承担商品返回第三方卖家的运费。</td>
                            </tr>
                            </tbody>
                        </table>
                        <p>注：</p>
                        <p>1.判断实际收货日期规则：</p>
                        <p>平台配送的订单，以客户实际签收日期为准；</p>
                        <p>2. 在商品无任何问题情况下，第三方卖家承诺：自您实际收到商品之日起7日内可享受无理由退换商品，退换商品返回运费由您承担的情况下，可以全额退款。</p>
                        <p>特别说明，以下情况不予办理退换货：</p>
                        <p>1. 任何非平台第三方卖家出售的商品（序列号不符）；</p>
                        <p>2. 过保商品（超过三包保修期的商品）；</p>
                        <p>3. 未经授权的维修、误用、碰撞、疏忽、滥用、进液、事故、改动、不正确的安装所造成的商品质量问题，或撕毁、涂改标贴、机器序号、防伪标记；</p>
                        <p>4. 无法提供商品的发票、保修卡等三包凭证或者三包凭证信息与商品不符及被涂改的；</p>
                        <p>5. 其他依法不应办理退换货的。</p>
                        <p>温馨提示：在商品退货时，需扣除购买该商品时通过评价或晒单所获得的相应优惠。</p>
                    </div>
                </div>
            </div> 
        </div>
        <IndexFooter_home />
    </div>
</template>

<script>
import IndexHeader_home from '@/components/IndexHeader_home.vue'
import IndexFooter_home from '@/components/IndexFooter_home.vue'
export default {
    data(){
        return{
            textMeg:'售后政策'
        }
    },
    components: {
        IndexHeader_home,IndexFooter_home
    },
}
</script>

<style scoped>
    p{
        margin: 14px 0;
    }
    .online{
        background: #f5f5f5;min-width:1200px;padding-bottom: 20px 
    }
    .set{
        width: 1200px;margin: 0 auto;padding-top: 20px
    }
    .set .sett{
        margin-top: 10px;
        min-height: 500px;background: #fff;
        padding: 30px 40px 60px; 
        
    }
    .set .sett>div:first-child{
        font-size: 28px;border-bottom: 1px solid #eee;
        padding-bottom: 20px;text-align: center;margin-bottom: 25px
    }
    .content-body p{
        text-indent: 28px;
        font-size: 14px;
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    }
    h3{
        display: block;
        font-size: 16px;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        color: #333
    }
    .content-body p span{
        font-size: 20px;font-weight: bold;color: #333
    }
    table{
        text-align: center;font-size: 14px
    }
</style>
