<template>
    <div  class="register">
        <div class="header">
            <div class="main01">
                <router-link to="/"><img src="../assets/img/index/logo.png" alt=""></router-link>
                <div></div>
                <span>用户注册</span>
                <div>已有账户，<router-link to="/login">请登录</router-link></div>
            </div>
        </div>
        <div class="section">
            <div class="section-content">
                <div>
                    <ul>
                        <li>
                            <span>*</span> &nbsp;用户类型：
                        </li>
                        <li>
                            <input v-for="(item,index) in userTypeList" :key="index" type="button" :value="item.name" :class="{'active':userType == item.id}" @click="changeUserType(item.id)">
                        </li>
                    </ul>

                    <formItem ps="支持数字、字母、文字、“_”、“@”及其组合格式，6-20位字符" @on-change="(val)=>{repeatAccount(val)}" errorStyle="inline" title="用户名" required :error="account.error" v-model="account.value" type="text" placeholder="请输入用户名" />
                    <formItem errorStyle="inline" title="密码" required :error="passWord.error" type="password" v-model="passWord.value" placeholder="请设置密码（6-16位，由数字和字母组成）" />
                    <formItem errorStyle="inline" title="确认密码" required :error="passWordA.error" type="password" v-model="passWordA.value" placeholder="请确认密码" />

                    <ul class="pt10">
                        <li><span>*</span> &nbsp;安全绑定：</li>
                        <li>
                            <div @click="bdChange(0)">
                                <input type="radio" value="0" v-model="bdtype">
                                <label></label>
                                <span>安全手机</span>
                            </div>
                            <div @click="bdChange(1)">
                                <input type="radio" value="1" v-model="bdtype">
                                <label></label>
                                <span>安全邮箱</span>
                            </div>
                        </li>
                    </ul>

                    <div v-show="bdtype == 0">
                        <formItem required title="安全手机" type="text" errorStyle="inline" :error="safePhone.error" v-model="safePhone.value" placeholder="请输入常用手机号码" />
                        <ul>
                            <li><span>*</span> &nbsp;手机验证码：</li>
                            <li>
                                <input class="code-inp" type="text" placeholder="请输入手机验证码" v-model="code.value">
                                <codeBtn @on-click="codePh" :disabled="codePhDisable" @on-type="(val)=>{codePhType=val}" />
                                <span class="error">{{code.error}}</span>
                            </li>
                        </ul>
                    </div>
                    <div v-show="bdtype == 1">
                        <formItem required title="安全邮箱" type="text" errorStyle="inline" :error="safeEmail.error" v-model="safeEmail.value" placeholder="请输入常用邮箱地址" />
                        <ul>
                            <li><span>*</span> &nbsp;邮箱验证码：</li>
                            <li>
                                <input class="code-inp" type="text" placeholder="请输入邮箱验证码" v-model="code.value">
                                <codeBtn @on-click="codeEm" :disabled="codeEmDisable" @on-type="(val)=>{codeEmType=val}" />
                                <span class="error">{{code.error}}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="xy-wrap">
                        <input v-model="xyType" type="checkbox">我已阅读并同意<span @click="xyClick">《企业认证SAAS平台服务协议》</span>
                    </div>
                    <ul>
                        <li><xbutton @on-click="validate" value="同意协议并注册" /></li>
                    </ul>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import formItem from '@/components/UI/formItem.vue'
import codeBtn from '@/components/UI/codeBtn.vue'
import xbutton from '@/components/UI/xbutton.vue'
import Footer from '@/components/Footer.vue'

export default {
    components: {
        formItem,
        codeBtn,
        xbutton,
        Footer
    },
    data(){
        return {
            userType:"",
            account:{
                value:"",
                error:"",
                validate:['required'],
                min:6,
                max:20,
                name:"用户名"
            },
            passWord:{
                value:"",
                error:"",
                validate:['required','password'],
                min:6,
                max:16,
                name:"密码"
            },
            passWordA:{
                value:"",
                error:"",
                validate:['required'],
                min:6,
                max:16,
                name:"密码"
            },
            code:{
                value:"",
                error:"",
                validate:['required'],
                name:"验证码"
            },
            safeEmail:{
                value:"",
                error:"",
                validate:['required','email'],
                name:"安全邮箱"
            },
            safePhone:{
                value:"",
                error:"",
                validate:['required','phone'],
                name:"安全手机"
            },
            userTypeList:[],
            bdtype:0,
            xyType:false,
            codePhDisable:false,
            codeEmDisable:false,
            codeEmType:false,
            codePhType:false
        }
    },

    methods:{
        changeUserType:function(key){
            this.userType = key;
        },
        bdChange:function(key){
            this.safeEmail.value = "";
            this.safePhone.value = "";
            this.code.value = "";
            this.bdtype = key;
        },
        validate:function(_this){
            var dataV = {
                account:this.account,
                passWord:this.passWord,
                passWordA:this.passWordA,
                code:this.code
            }
            if (this.bdtype===0) {
                dataV.safePhone=this.safePhone
            }else{
                dataV.safeEmail=this.safeEmail
            };
            var validate = this.$com.validate(dataV)

            if (validate) {
                if (this.passWordA.value!=this.passWord.value) {
                    this.passWordA.error = "密码不一致";
                    return false;
                };
                if (!this.xyType) {
                    this.$dialog.alert("请勾选服务协议",'error')
                }else{
                    this.submit(_this)
                }
            };
        },
        xyClick:function(){
            this.$dialog.alert('','alert',()=>{
                this.xyType = true;
            })
        },
        submit:function(_this){
            var data = {
                userType:this.userType,
                account:this.account.value,
                passWord:this.passWord.value,
                code:this.code.value,
                safeEmail:this.safeEmail.value,
                safePhone:this.safePhone.value
            };
            _this.loading = true;
            this.$axios.post(this.$com.testUsIp+'/user/register', this.$qs.stringify(data),{
                headers: {token:'token'}
            })
            .then((response) => {
                _this.loading = false;
                var data = response.data;
                if (data.code === 0) {
                    this.$dialog.alert("恭喜注册成功！",'success',() => {
                        this.$router.push({ path: '/' })
                        //========
                        //注册后自动登陆
                        // this.$axios.post(this.$com.testUwIp + '/login', this.$qs.stringify(data))
                        //     .then((response) => {
                        //         _this.loading = false;
                        //         var res = response.data;
                        //         if (res.code === 0) {
                        //             this.$store.commit('SET_token',res.data.tokenId)
                        //             this.$store.commit('SET_user',res.data)
                        //             sessionStorage.setItem('account',res.data.nickName)
                        //             this.$router.push({ path: '/users' })
                        //         }else{
                        //             this.$dialog.alert(res.msg,'error')
                        //         };
                        //     })
                        //     .catch((error) => {
                        //         _this.loading = false;
                        //         this.$dialog.alert('系统繁忙，请稍后再试','error')
                        //     })
                        //=============

                    },(res)=>{
                        res.thisShow = false;
                        this.$router.push({ path: '/' })
                    })
                }else{
                    this.$dialog.alert(data.msg,'error')
                };
            })
            .catch((error) => {
                _this.loading = false;
                this.$dialog.alert('系统错误','error')
            })
        },
        codePh:function(v){
            var dataV={
                safePhone:this.safePhone
            }
            var validate = this.$com.validate(dataV)
            if (validate) {
                var data = {
                    phone:this.safePhone.value,
                    sendType:1
                }
                this.$axios.post(this.$com.testUsIp+'/sms/send-code', this.$qs.stringify(data))
                .then((response) => {
                    var res = response.data
                    if (res.code === 0) {
                        this.codePhDisable = !this.codePhType
                    }else{
                        this.$dialog.alert(res.msg,'error')
                    };
                })
                .catch((error) => {
                    this.$dialog.alert('系统错误','error')
                })
            };
        },
        codeEm:function(v){
            var dataV={
                safeEmail:this.safeEmail
            }
            var validate = this.$com.validate(dataV)
            if(validate){
                var data = {
                    email:this.safeEmail.value,
                    sendType:1
                }
                this.$axios.post(this.$com.testUsIp+'/email/send-reg-code', this.$qs.stringify(data))
                .then((response) => {
                    var res = response.data
                    if (res.code === 0) {
                        this.codeEmDisable = !this.codeEmType
                    }else{
                        this.$dialog.alert(res.msg,'error')
                    };
                })
                .catch((error) => {
                    this.$dialog.alert('系统错误','error')
                })
            }
        },
        //检查重名
        repeatAccount:function(val){
            if (val) {
                this.$axios.get(this.$com.testUsIp+'/user/repeatAccount/' + val)
                .then((response) => {
                    var res = response.data
                    if (res.code!=0) {
                        this.account.error = res.msg
                    }else{
                        this.account.error = ""
                    };
                })
                .catch((error) => {
                    this.$dialog.alert('系统错误','error')
                })
            };
        },
    },
    mounted:function(){
        this.$com.findCode('type_yh',(res)=>{
            this.userTypeList = res
            this.userType = res[0].id
        })
        //this.repeatAccount('allen0')
    }
}
</script>

<style scoped>
body,ul{
    list-style: none;
    margin: 0;
    padding: 0;
}
.header{
    width: 100%;
    min-width: 1200px;
    height: 102px;
    border-bottom: 2px solid #e6e6e6;
}
.header .main01{
    width: 1200px;
    margin: 0 auto;
    position: relative;
}
.main01 img:first-child{
    position: absolute;
    top: 22px;
    left:0;
}
.main01 div:nth-child(2){
    position: absolute;
    top:35px;
    left: 190px;
    height: 35px;
    border: 0.5px solid #CCDCF9;
}
.main01 span:nth-child(3){
    position: absolute;
    top:35px;
    left:218px;
    font-size: 20px;
    color: #666
}
.main01 div:nth-child(4){
    position: absolute;
    right: 0;
    top: 47px;
    font-size: 16px;
    color: #666
}
.main01 div:nth-child(4) a{
    text-decoration: none;
    color: #5DB1FF
}
.section{
    background-color: #F5F5F5;
    width: 100%;
    min-width: 1200px;
    height: 620px;
    padding-top: 16px
}
.section-content{
    width: 1200px;
    padding-bottom: 40px;
    margin: 0 auto;
    background-color: #fff
}
.section-content > div{
    margin-left: 200px;
    position: relative;
}
.section-content > div ul{
    display: flex;
    padding-top: 20px
}
.section-content > div>ul:first-child{
    padding-top: 40px
}
.section-content > div ul li:nth-child(1){
    width: 100px;
    text-align: right;
    line-height: 40px;
    font-size: 14px;
    color: #333;
    margin-right: 7px
}
.section-content > div ul li>span{
    color: red;
}
.section-content > div ul:nth-child(7) li>span{
    color: #0085ff
}
.section-content > div ul li:nth-child(2)>input{
    height:40px;
    border: 1px solid #ccc;
    font-size: 14px;
    /* padding-left: 10px; */
}
.section-content > div ul li>input::-webkit-input-placeholder{
    color: #999
}
.section-content > div ul:nth-child(5) li:nth-child(2)>div{
    position: relative;
    line-height: 40px;
    float: left;
}
.section-content > div ul:nth-child(5) li:nth-child(2)>div:nth-child(2){
    margin-left: 56px
}
input[type="radio"] {
    width: 13px;
    height: 13px;
    opacity: 0;
}
input[type="checkbox"]{
    vertical-align: middle;
}
label {
    position: absolute;
    left: 0;
    top: 14px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid #999;
}
input:checked+label{
    background-color: #3296FA;
    border: 1px solid #006eb2;
}
input:checked+label::after {
    position: absolute;
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #fff;
    top: 2px;
    left: 2px;
    border: 2px solid #fff;
}
.section-content > div ul li:nth-child(2){
    font-size: 14px;
    color: #666;
}
.section-content > div>ul:nth-child(1) li:nth-child(2)>input{
    width: 80px;
    height: 40px;
    margin-right: 4px;
    border: 0;
    color: #666;
    background-color: #f5f5f5;
    font-size: 14px;
    cursor: pointer;
}
.section-content > div>ul:nth-child(1) li:nth-child(2)>input.active,
.section-content > div>ul:nth-child(1) li:nth-child(2)>input:hover{
    color: #fff;
    background: linear-gradient(to right, #00aaff, #0085ff);
}
.section-content > div div>ul:nth-child(1){
    padding-top: 10px;
}
.section-content > div div>ul:nth-child(2) li:nth-child(2) input:nth-child(1){
    width: 280px;
}
.section-content > div ul:nth-child(4) li>input{
    width: 13px;
    height: 13px;
}
.section-content > div ul:nth-child(9) li button{
    width:  280px;
    height: 40px;
    border: 0;
    background-color: #3296FA;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    margin-left:106px;
}
.section-content > div ul:nth-child(9) li button:hover{
    background: linear-gradient(to right, #00aaff, #0085ff);
}
.footer-bottom{
    border-top:1px solid #ccc;
    width: 100%;
    height: 75px;
    min-width: 1200px;
    position: relative;
}
.footer-bottom>div{
    width: 1200px;
    margin: 0 auto;
    position: relative;
    text-align: center
}
.footer-bottom ul:nth-child(1){
    height: 45px;
    width: 875px;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 164px;
    line-height: 45px;
}
.footer-bottom ul li a,b{
    text-decoration: none;
    font-size: 12px;
    color: #333;
}
.footer-bottom ul li a:hover{
    text-decoration: none;
    color: #2e89e4;
}
.footer-bottom div:nth-child(2){
    width: 100%;
    line-height: 25px;
    font-size: 12px;
    position: absolute;
    top: 45px;
    color: #666;
}
.xy-wrap{
    line-height: 40px;
    font-size: 14px;
    color: #333;
    margin-right: 7px;
    width: 100%;
    text-align: left;
    padding-left:103px;
}
.xy-wrap span{
    color: red;
    cursor: pointer;
}
.error{
    color:red;
    font-size:14px;
    display:inline-block;
    margin-left:5px;
}
.code-inp{
    padding-left: 10px;
}
.section-content > div ul.pt10{
    padding-top: 10px;
}
</style>
