<template>
    <div>
        <div class="no2">
            <ul>
                <li>
                    <span>需求标题：</span>
                    <input type="text" placeholder="需求标题" v-model="title">
                </li>
                <!-- <li>
                    <span>中标状态：</span>
                    <input type="text" placeholder="委托方" v-model="userDemandName">
                </li> -->
                <li>
                    <span>中标状态：</span>
                    <Xselect class="x" @on-focus="(fn)=>fn(typeList)" :list="typeList" v-model="bidStatus" />
                </li>
                <li><input type="button" value="查 询" @click="goSearch"></li>
            </ul>
            <xtable :loading="loading" :search="search" :head="head" :tableData="tableData"  @on-changePage="(v)=>getData(v)" />
        </div>
    </div>
</template>

<script>
import Xselect from '@/components/UI/select.vue'
import xtable from '@/components/UI/xtable.vue'
export default {
    data(){
        return {
            head:{
                title:"需求标题",
                serviceType:"需求类型",
                industry:"所属行业",
                scaleName:"企业规模",
                cost:"预算费用",
                createTime:"竞标日期",
                updateTime:"中标日期",
                bidStatus:"中标状态"
            },
            cardType:'请选择',
            typeList:[{id:'',name:'全部'},{id:1,name:'竞标中'},{id:2,name:'已中标'},{id:3,name:'未中标'}],
            title:'',
            tableData:{},
            loading:false,
            search:{},
            bidStatus:'',
        }
    },
    components: {
        Xselect,xtable
    },
    methods:{
        goSearch(){
            this.search = {
                title:this.title,
                bidStatus:this.bidStatus
            };
            this.getData(this.search);
        },
        getData:function(data){
            this.loading = true;
            this.$com.majax.get(this.$com.testCsIp + '/buyerBid/getBuyerBidList',data,{
                success:(res)=>{
                    this.tableData = res.data;
                }
            },this)
        },
        typeChange:function(val){
            this.bidStatus = val.id
        },
    },
    mounted:function(){
        this.$parent.titleMeg = '我的竞标';
        this.getData()
    },
}
</script>

<style scoped>
    .x{
        width: 175px
    }
    div.no2{
        width:992px;padding-bottom: 20px;
        min-height:600px;
        background:#fff;
        position: relative;
        padding: 20px;
    }
    div.no2 ul{
        display: flex;
        background: #fff;
        line-height: 60px;
        margin-bottom: 10px
    }
    div.no2 ul span{
        font-size: 14px;
        color: #333
    }
    div.no2 ul li{
        display: flex;
        line-height: 40px;
        margin-top: 15px;
        margin-bottom: 10px
    }
    div.no2 ul>li:not(:first-child){
        margin-left: 185px;
    }
    div.no2 ul input[type=button]{
        width: 80px;
        height: 40px;border: 0;background: #3296FA;
        color: #fff;font-size: 16px;
        margin-left: 8px;
        cursor: pointer;border-radius: 4px
    }
    div.no2 ul input[type=button]:hover{
        background: linear-gradient(to right, #00aaff, #0085ff);
    }
    input[type=text]{
        width: 175px;
        border: 1px solid #ccc;
        padding-left: 10px;box-sizing: border-box
    }
    div.no2 ul input.btn1{
        width: 100px;
        text-align: center;
        margin-left: 200px;
        border-radius:0 
    }
    table{
        width: 950px;
        margin: 4px auto;
        text-align: center;
        border-left: none;
        border-top: none
    }
    td{
        border-bottom: none;
        border-right: none;
        height: 52px;
        font-size: 12px;
        color: #333;
        white-space:wrap;
        border-bottom: 1px dotted #ccc
    }
    table tr:not(:first-child):hover{
        background: #f5f5f5
    }
    table tr>th{
        height: 40px;font-size: 14px;color: #333;
        background: #f5f5f5;
        color: #333;border-right: 1px solid #fff;
        font-weight: normal
    }
    table td:first-child{
        width:275px;padding-left: 20px;
        text-align: left;overflow: hidden;
        white-space: nowrap;text-overflow: ellipsis;
    }
    table td:nth-child(2),td:nth-child(6){
        color: #999
    }
    table td:last-child{
        width: 140px
    }
    table td span{
        color: #333;
    }
    table td span:hover{
        cursor: pointer;
        color: #3296FA
    }
    .empty-img{
        display: block;
        margin:auto;
    }
</style>
