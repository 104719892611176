<template>
    <div>
        <IndexHeader_home :textMeg="textMeg"/>
        <div class="content">
            <router-view></router-view>
        </div>
        <IndexFooter_home />
    </div>
</template>

<script>
import IndexHeader_home from '@/components/IndexHeader_home.vue'
import IndexFooter_home from '@/components/IndexFooter_home.vue'

export default {
    data(){
        return{
            textMeg:'需求大厅'
        }
    },
    components: {
        IndexHeader_home,IndexFooter_home
    },
}
</script>

<style scoped>
    .content{
        background: #f5f5f5;min-width: 1200px;
    }
</style>