<template>
    <div>
        <IndexHeader_home :textMeg="textMeg"/>
        <div class="content">
            <div>
                <img src="../../assets/img/index1/header03.jpg" alt="">
                <div>
                    启翔控股集团公司是西部极具实力的管理咨询、认证咨询、管理培训、资本运作、市场研究、营销策划的智囊机构。公司成立于2004年，经过十五年不断的努力创新与管理，现已成为同行业中西南地区领先品牌；2012年荣获重庆服务企业100强单位殊誉；2013年中国创新企业，2014年重庆优秀诚信企业；并于2013年国内首家认证咨询行业企业在OTC挂牌上市（启翔股份---股份代码850032）。公司下设北京分支机构、上海分支机构、湖北分公司、贵州控股公司、深圳控股公司、湖南控股公司、陕西控股公司、宁夏控股公司、珠海控股公司、江西控股公司、青岛控股公司、江苏控股公司、安徽控股公司，并分别设立培训开发部事业部、质量认证事业部、产品检测事业部、品牌战略策划事业部、商标注册部、管理咨询事业部、综合部事业部等。
                </div>
                <div>
                    公司主要提供各类企业的管理咨询(战略咨询、组织咨询、薪酬咨询、绩效咨询、文化咨询、品牌咨询)及ISO9001、ISO14001、OHSAS18001、QC080000、ISO27001、IATF16949、GJB9001B 、ISO22000、ISO20000、ISO13485、ISO17025、ISO14065、ISO19580、TL9000、IRIS、AS9100、HACCP、SA8000等体系认证咨询；QS(SC) 、CCC、FSC、CMA、工业生产许可证、有机产品、绿色产品、无公害食品、良好农业规范（GAP）、中国环保产品、CQC中国质量环保产品、ISO14025(Ⅲ型环境标志)、中国环境标志（十环）等国内产品认证咨询；以及CE、E-mark、RoHS、BRC、EICC、CSA、DOT、VDE、UL、EPA、SAA、CB、GS、API等国际产品认证咨询。公司直接与国内外多家著名审核机构进行项目合作，主要有：中国质量认证中心、方圆标志集团产品认证中心、北京中经科环认证有限公司、深圳环通认证有限公司、北京东方纵横认证中心、九鼎认证中心、CE（欧盟）、EPA（美国）、AQA（美国）、NQA（英国）、BV（法国）、SAA（澳洲）、SGS(瑞士)、BSI(英国)、DNV(挪威)、TUV（德国）等。
                </div>    
                <div>
                    公司拥有一批国际互认的国家注册审核员、主任审核员、验证审核员，以及经验丰富的质量管理、环境管理、安全管理技术专家。并具有对各类组织（企业、事业、社团等）进行体系及产品认证咨询服务和管理培训的能力。公司共有39大门类的专业认证咨询资格，涉及机械、电子、化工、冶金、纺织、轻工、建筑房地产、建材、玩具、食品、烟草、家具、餐饮娱乐、宾馆、运输、环保、物业等行业的质量管理体系和产品认证咨询服务。公司迄今已成功为5000多家企业进行了认证咨询服务，成为西南地区最大的管理咨询及质量认证咨询机构。
                </div>
                <ul>
                    <li>重庆启翔企业管理顾问股份有限公司</li>
                    <li>联系地址：重庆市渝北区金渝大道89号线城市花园6幢10楼</li>
                    <li>联系电话：023-6773 3179</li>
                    <li>公司网址：www.cqckrz.com</li>
                </ul>
            </div>
        </div>
        <IndexFooter_home />
    </div>
</template>

<script>
import IndexHeader_home from '@/components/IndexHeader_home.vue'
import IndexFooter_home from '@/components/IndexFooter_home.vue'

export default {
    data(){
        return{
            textMeg:'联系我们'
        }
    },
    components: {
        IndexHeader_home,IndexFooter_home
    },
}
</script>

<style scoped>
    .content{
        background: #f5f5f5;
        padding: 20px 0;min-width: 1200px
    }
    .content>div{
        width: 1200px;margin: 0 auto;
        background: #fff;
        min-height: 400px;
    }
    .content>div div{
        padding: 30px 50px 0;
        text-indent:24px;font-size: 12px;color: #333;
        line-height: 24px;text-align: justify;
    }
    .content>div div:last-child{
        padding-bottom: 30px
    }
    .content>div ul{
        padding: 30px 50px 74px;
    }
    .content>div li:first-child{
        font-size: 18px;line-height: 34px;
        color: #666;font-weight: bold
    }
    .content>div li:not(:first-child){
        font-size: 14px;color: #666
    }
</style>

