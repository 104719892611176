import { render, staticRenderFns } from "./step3_details.vue?vue&type=template&id=4bde3940&scoped=true"
import script from "./step3_details.vue?vue&type=script&lang=js"
export * from "./step3_details.vue?vue&type=script&lang=js"
import style0 from "./step3_details.vue?vue&type=style&index=0&id=4bde3940&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bde3940",
  null
  
)

export default component.exports