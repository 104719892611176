<template>
    <div>
        <div class="no2">
            <ul>
                <li>
                    <span>服务名称：</span>
                    <input type="text" placeholder="服务名称" v-model="serviceName">
                </li>
                <li>
                    <span>委托方：</span>
                    <input type="text" placeholder="委托方" v-model="userDemandName">
                </li>
                <li>
                    <span>审核状态：</span>
                    <Xselect class="x" :list="typeList" v-model="enStatus" @on-focus="(fn)=>fn(typeList)" />
                </li>
                <!-- <formItem width="160" errorStyle="inline" title="企业规模" required :error="user.scale.error" type="select" v-model="user.scale.value" placeholder="请选择企业规模" :selectList="this.user.scale" @on-focus="(fn)=>$com.findCode('scale',(res)=>{fn(res)})" /> -->
                <li><input type="button" value="查 询" @click="goSearch"></li>
            </ul>
            <xtable :loading="loading" :search="search" :head="head" :tableData="tableData" btn="look" @on-lookClick="lookClick" @on-changePage="(v)=>getData(v)" />
        </div>
        <diyDialog :status="status" title="委托单" type="seller" disabled :formData="formData" :snycShow.sync="dialogShow" @on-ok="pass(2)" @on-no="pass(3)"/>
    </div>
</template>

<script>
import Xselect from '@/components/UI/select.vue'
import xtable from '@/components/UI/xtable.vue'
import diyDialog from '@/components/UI/diyDialog.vue'
export default {
    data(){
        return {
            head:{
                serviceName:"服务名称",
                userDemandName:"委托方",
                signature:"联系人",
                userDemandTel:"联系电话",
                enStatus:"委托单状态",
            },
            formData:{
                reason:"",
                consultPrice:{
                    value:"",
                    error:"",
                    validate:['required'],
                    name:"协议价格"
                },
                consultContent:{
                    value:"",
                    error:"",
                    validate:['required'],
                    name:"协议内容"
                },
                signature:{
                    value:"",
                    error:"",
                    validate:['required'],
                    name:"委托人姓名"
                },
            },
            dialogShow:false,
            lists:[],
            cardType:'请选择',
            typeList:[{id:'',name:'全部'},{id:1,name:'待确认'},{id:2,name:'已确认'},{id:3,name:'驳回'}],
            serviceName:'',
            userDemandName:'',
            tableData:{},
            loading:false,
            search:{},
            enStatus:'',
            id:'',
            status:''
        }
    },
    components: {
        Xselect,xtable,
        diyDialog
    },
    methods:{
        lookClick(item){
            this.dialogShow = true;
            this.id = item.row.id
            this.$com.majax.get(this.$com.testCsIp + '/uc-entrust-order/getEntrustOrderDetailsById/' + item.row.id,{},{
                success:(res)=>{
                    this.status = res.data.enStatus.toString()
                    this.formData.userServicesName=res.data.userServicesName;
                    this.formData.userServicesContacts=res.data.userServicesContacts
                    this.formData.userServicesTel=res.data.userServicesTel;
                    this.formData.userServicesAddress=res.data.userServicesAddress;
                    this.formData.userDemandName=res.data.userDemandName;
                    this.formData.userDemandAddress=res.data.userDemandAddress;
                    this.formData.userDemandTel=res.data.userDemandTel;
                    this.formData.consultPrice.value=res.data.consultPrice.toString();
                    this.formData.consultContent.value=res.data.consultContent;
                    this.formData.signature.value=res.data.signature;
                    this.formData.reason=res.data.reason
                }
            },this)
        },
        pass(status){
            if(status == 2){
                var val="审核通过！"
            }else{
                var val="已驳回！"
            }
            var data = {
                reason:this.formData.reason,
                status:status,
                id:this.id
            }
            this.$com.majax.post(this.$com.testCwIp + '/entrustOrder/auditEntrustOrder',this.$qs.stringify(data),{
                success:(res)=>{
                    if(res.code == 0){
                        this.$dialog.alert(val,'success',()=>{
                            this.dialogShow = false;
                            this.getData()
                        })
                    }
                }
            })
        },
        goSearch(){
            this.search = {
                serviceName:this.serviceName,
                userDemandName:this.userDemandName,
                enStatus:this.enStatus
            };
            this.getData(this.search);
        },
        getData:function(data){
            this.loading = true;
            this.$com.majax.get(this.$com.testCsIp + '/uc-entrust-order/getEntrustOrderList',data,{
                success:(res)=>{
                    this.tableData = res.data;
                }
            },this)
        },
        typeChange:function(val){
            this.enStatus = val.id
        },
    },
    mounted:function(){
        this.$parent.titleMeg = '审核委托单';
        this.getData()
    },
}
</script>

<style scoped>
    .x{
        width: 175px
    }
    div.no2{
        width:992px;padding-bottom: 20px;
        min-height:650px;
        background:#fff;
        position: relative;
        padding: 20px;
    }
    div.no2 ul{
        display: flex;
        background: #fff;
        line-height: 60px;
        margin-bottom: 10px
    }
    div.no2 ul span{
        font-size: 14px;
        color: #333
    }
    div.no2 ul li{
        display: flex;
        line-height: 40px;
        margin-top: 15px;
        margin-bottom: 10px
    }
    div.no2 ul>li:not(:first-child){
        margin-left: 47px;
    }
    div.no2 ul input[type=button]{
        width: 80px;
        height: 40px;border: 0;background: #3296FA;
        color: #fff;font-size: 16px;
        margin-left: 8px;
        cursor: pointer;border-radius: 4px
    }
    div.no2 ul input[type=button]:hover{
        background: linear-gradient(to right, #00aaff, #0085ff);
    }
    input[type=text]{
        width: 175px;
        border: 1px solid #ccc;
        padding-left: 10px;box-sizing: border-box
    }
    div.no2 ul input.btn1{
        width: 100px;
        text-align: center;
        margin-left: 200px;
        border-radius:0
    }
    table{
        width: 950px;
        margin: 4px auto;
        text-align: center;
        border-left: none;
        border-top: none
    }
    td{
        border-bottom: none;
        border-right: none;
        height: 52px;
        font-size: 12px;
        color: #333;
        white-space:wrap;
        border-bottom: 1px dotted #ccc
    }
    table tr:not(:first-child):hover{
        background: #f5f5f5
    }
    table tr>th{
        height: 40px;font-size: 14px;color: #333;
        background: #f5f5f5;
        color: #333;border-right: 1px solid #fff;
        font-weight: normal
    }
    table td:first-child{
        width:275px;padding-left: 20px;
        text-align: left;overflow: hidden;
        white-space: nowrap;text-overflow: ellipsis;
    }
    table td:nth-child(2),td:nth-child(6){
        color: #999
    }
    table td:last-child{
        width: 140px
    }
    table td span{
        color: #333;
    }
    table td span:hover{
        cursor: pointer;
        color: #3296FA
    }
    .empty-img{
        display: block;
        margin:auto;
    }
</style>
