<template>
    <div class="indx">
        <div class="no1" v-loading="loading">
            <div class="noi2" v-show="lists.length > 0" v-for="(list,i) in lists" :key="i">
                <img :src="list.imgIdPath||$com.errorImg" :onerror="$com.errorImg" alt="资质证书" >
            </div>  
            <pagination v-if="total>12" :total="total" :current="current" :pageSize="12" @pageChange="goQuery"/>
            <div v-show="lists.length == 0">
                <img class="empty-img" src="../../assets/img/user/empty_page.jpg" alt="暂无数据">
            </div>
        </div>
    </div>
</template>

<script>
import pagination from '@/components/UI/Pagination.vue'
export default {
    props:['id'],
    data(){
        return{
            lists:[],
            total:'',
            loading:true,
            current:1
        }
    },
    components: {
        pagination,
    },
    methods:{
        goQuery(page){
            this.$axios.get(this.$com.testCsIp + '/shop/shopManageQualificationList/' + this.id + '/'+ page +'/12')
            .then(res=>{
                if(res.data.code == 0){
                    this.loading=false;
                    this.lists=res.data.data.records;
                    this.total=res.data.data.total;
                }
            })
        }
    },
    mounted(){
        this.goQuery(1);
    }
}
</script>


<style scoped>
    .noi2{
        width: 276px;
        height: 184px;
        margin-bottom: 18px;
        margin-right: 19px;
        border: 1px solid #ddd;
        float: left;
        position: relative;
    }
    .noi2>img{
        max-height:100%;
        max-width:100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    .indx{
        min-width: 1200px;background: #f5f5f5;
        padding: 20px 0 0.1px 0
    }
    .no1{
        width: 1200px;background: #fff;
        margin: 0 auto;
        overflow:hidden;
        padding: 20px 0 0 20px;min-height: 220px;
        overflow: hidden;margin-bottom: 20px
    }
    .no1>img{
        width: 276px;height: 184px;border: 1px solid #ccc;
        margin-bottom:18px;margin-right: 19px 
    }
    .empty-img{
        display: block;
        margin: auto
    }
</style>
