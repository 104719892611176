<template>
    <div class="index">
        <div class="footer">
            <div>
                <div>
                    <img src="../assets/img/index/xsicon.png" alt="">
                </div>
                <ul>
                    <li>新手入门</li>
                    <li>
                        <a href="/buyer_Introduction">买家入门</a>
                        <a href="/seller_Introduction">卖家入门</a>
                        <a href="/404" target="_blank">联系客服</a>
                    </li>
                </ul>
            </div>
            <div id="div_border"></div>
            <div>
                    <div>
                        <img src="../assets/img/index/zficon.png" alt="">
                    </div>
                    <ul>
                        <li>支付方式</li>
                    <li>
                        <!-- target="_blank" -->
                            <a href="/online_payment_protocol" >在线支付协议</a>  
                        </li>
                    </ul>
            </div>
            <div id="div_border"></div>
            <div>
                    <div>
                        <img src="../assets/img/index/dpicon.png" alt="">
                    </div>
                    <ul>
                        <li>店铺服务</li>
                        <li>
                            <a href="/store_entry_agreement" >商家入驻协议</a>
                        </li>
                    </ul>
            </div>
            <div id="div_border"></div>
            <div>
                <div>
                    <img src="../assets/img/index/shicon.png" alt="">
                </div>
                <ul>
                    <li>售后保障</li>
                    <li>
                        <a href="/after_sales_policy">售后政策</a>
                        <a href="/invoice_policy" >发票政策</a>
                        <a href="/complaints_rights">投诉维权</a>
                    </li>
                </ul>
            </div>
        </div>
        <div id="div_bor"></div>
        <div class="footer-list d-flex">
            <ul>
                <li>友情链接：</li>
            </ul>
            <ul class="lis d-flex flex-wrap">
                <li><a href="http://www.cnca.gov.cn/" target="_blank" rel="nofollow" title="国家认监委" alt="国家认监委">国家认监委</a></li>
                <li><a href="http://www.cqc.com.cn/www/chinese/index.shtml" target="_blank" rel="nofollow" title="中国质量认证中心" alt="中国质量认证中心">中国质量认证中心</a></li>
                <li><a href="http://www.aqsiq.gov.cn/" target="_blank" rel="nofollow" title="国家质量监督检验检疫总局" alt="国家质量监督检验检疫总局">国家质量监督检验检疫总局</a></li>
                <li><a href="http://www.sac.gov.cn/" target="_blank" rel="nofollow" title="国家标准化管理委员会" alt="国家标准化管理委员会">国家标准化管理委员会</a></li>
                <li><a href="http://www.most.gov.cn/" target="_blank" rel="nofollow" title="科学技术部" alt="科学技术部">科学技术部</a></li>
                <li><a href="http://www.ndrc.gov.cn/" target="_blank" rel="nofollow" title="国家发展与改革委员会" alt="国家发展与改革委员会">国家发展与改革委员会</a></li>
                <li><a href="http://www.customs.gov.cn/" target="_blank" rel="nofollow" title="海关总署" alt="海关总署">海关总署</a></li>
                <li><a href="http://www.mee.gov.cn/" target="_blank" rel="nofollow" title="国家生态环境部" alt="国家生态环境部">国家生态环境部</a></li>
                <li><a href="http://www.sda.gov.cn/WS01/CL0001/" target="_blank" rel="nofollow" title="国家食品药品监督管理局" alt="国家食品药品监督管理局">国家食品药品监督管理局</a></li>
                <li><a href="http://www.mofcom.gov.cn/" target="_blank" rel="nofollow" title="商务部" alt="商务部">商务部</a></li>
                <li><a href="http://www.moh.gov.cn/" target="_blank" rel="nofollow" title="卫生部" alt="卫生部">卫生部</a></li>
                <li><a href="http://www.agri.cn/" target="_blank" rel="nofollow" title="农业部" alt="农业部">农业部</a></li>
                <li><a href="https://www.wto.org/" target="_blank" rel="nofollow" title="世界贸易组织" alt="世界贸易组织">世界贸易组织</a></li>
                <li><a href="https://www.iso.org/home.html" target="_blank" rel="nofollow" title="国际标准化组织" alt="国际标准化组织">国际标准化组织</a></li>
                <li><a href="http://www.iec.ch/" target="_blank" rel="nofollow" title="国际电工委员会" alt="国际电工委员会">国际电工委员会</a></li>
                <li><a href="http://ilac.org/" target="_blank" rel="nofollow" title="国际实验室认可合作组织" alt="国际实验室认可合作组织">国际实验室认可合作组织</a></li>
                <li><a href="https://www.aplac.org/" target="_blank" rel="nofollow" title="亚太实验室认可合作组织" alt="亚太实验室认可合作组织">亚太实验室认可合作组织</a></li>
                <li><a href="http://www.apec-pac.org/" target="_blank" rel="nofollow" title="太平洋认可合作组织" alt="太平洋认可合作组织">太平洋认可合作组织</a></li>
                <li><a href="http://www.european-accreditation.org/" target="_blank" rel="nofollow" title="欧洲认可合作组织" alt="欧洲认可合作组织">欧洲认可合作组织</a></li>
                <li><a href="http://www.iaac.org.mx/" target="_blank" rel="nofollow" title="泛美认可合作组织" alt="泛美认可合作组织">泛美认可合作组织</a></li>
                <li><a href="" target="_blank" rel="nofollow" title="中国合格评定国家认可委员会" alt="中国合格评定国家认可委员会">中国合格评定国家认可委员会</a></li>
                <li><a href="http://www.isccc.gov.cn/" target="_blank" rel="nofollow" title="中国信息安全认证中心" alt="中国信息安全认证中心">中国信息安全认证中心</a></li>
                <li><a href="http://www.ccaa.org.cn/" target="_blank" rel="nofollow" title="中国认证认可协会" alt="中国认证认可协会">中国认证认可协会</a></li>
                <li><a href="http://www.cnis.gov.cn/" target="_blank" rel="nofollow" title="中国标准化研究院" alt="中国标准化研究院">中国标准化研究院</a></li>
                <li><a href="http://www.cast.org.cn/" target="_blank" rel="nofollow" title="中国科学技术协会" alt="中国科学技术协会">中国科学技术协会</a></li>
                <li><a href="http://www.cait.cn/" target="_blank" rel="nofollow" title="中国认证认可信息网" alt="中国认证认可信息网">中国认证认可信息网</a></li>
                <li><a href="http://www.agri.cn/" target="_blank" rel="nofollow" title="中国农业信息网" alt="中国农业信息网">中国农业信息网</a></li>
                <li><a href="http://www.aqsc.org/" target="_blank" rel="nofollow" title="中国农产品质量安全网" alt="中国农产品质量安全网">中国农产品质量安全网</a></li>
                <li><a href="http://www.cnelc.com/" target="_blank" rel="nofollow" title="中国工业电器网" alt="中国工业电器网">中国工业电器网</a></li>
                <li><a href="http://www.jsjzjz.com/" target="_blank" rel="nofollow" title="问问我建筑咨询网" alt="问问我建筑咨询网">问问我建筑咨询网</a></li>
                <li><a href="http://www.chn-cstc.com/" target="_blank" rel="nofollow" title="重庆股份转让中心（股份交易所）" alt="重庆股份转让中心（股份交易所）">重庆股份转让中心（股份交易所）</a></li>
            </ul>
        </div>
        <div class="footer-bottom">
            <div>
                <ul>
                    <li><a href="/about_us" target="_blank">关于我们</a></li>
                    <b>|</b>
                    <li><a  target="_blank">联系客服</a></li>
                    <b>|</b>
                    <li><a  target="_blank">合作招商</a></li>
                    <b>|</b>
                    <li><a  target="_blank">营销中心</a></li>
                    <b>|</b>
                    <li><a  target="_blank">手机平台</a></li>
                    <b>|</b>
                    <li><a  target="_blank">销售联盟</a></li>
                    <b>|</b>
                    <li><a  target="_blank">法律申明</a></li>
                    <b>|</b>
                    <li><a  target="_blank">使用条款</a></li>
                    <b>|</b>
                    <li><a  target="_blank">隐私政策</a></li>
                </ul>
                <ul>
                    <li>Copyright © 2004 - 2019 重庆启翔控股集团有限公司 版权所有</li>
                    <li><a href="http://www.miitbeian.gov.cn/publish/query/indexFirst.action" target="_blank">渝ICP备11005295号</a> &nbsp;&nbsp; 技术支持：重庆启翔控股集团有限公司</li>
                    <li>地址：重庆市渝北区金渝大道89号线外城市花园6栋10楼&nbsp;&nbsp;&nbsp; 邮编：401147 </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    methods:{
        pageChange:function(){
            this.$router.push({path:'online_payment_protocol'})
        }
    }
}
</script>

<style scoped>
    @import '../assets/css/bootstrap.css';
    @import '../assets/css/footer.css';
    .lis{
        width: 1130px;
    }
</style>
