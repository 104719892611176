<template>
    <div class="no">
        <div class="no1">
            <div class="no11">
                <ul>
                    <li>商品名称：</li>
                    <li><input type="text" placeholder="全部" v-model="serviceName"></li>
                </ul>
                <ul>
                    <li>商品状态：</li>
                    <li><Xselect class="x"  @on-focus="(fn)=>fn(typeList)" :list="typeList" v-model="status" placeholder="全部"/></li>
                </ul>
                <ul>
                    <li>上架状态：</li>
                    <li><Xselect class="x" @on-focus="(fn)=>fn(typeList1)" v-model="shelfStatus" :list="typeList1"  placeholder="全部"/></li>
                </ul>
                <button @click="goSearch">搜索</button>
            </div>
            <div class="no12">
                <table></table>
                <button @click="sxj(1)">上架</button><button @click="sxj(2)">下架</button>
                <button @click="goAdd">发布服务</button>
            </div>
            <xtable :loading="loading" :search="search" :head="head" :tableData="tableData" btn="edit,del" @on-delClick="delClick" @on-editClick="editClick" @on-changePage="(v)=>getData(v)" checkbox @on-handleSelectionChange="(v)=>checkboxList=v"/>
        </div>
    </div>
</template>

<script>
import Xselect from '@/components/UI/select.vue'
import xtable from '@/components/UI/xtable.vue'
export default {
    data(){
        return{
            head:{
                catName:"服务项目",
                serviceName:"服务名称",
                priceType:"价格（元）",
                cycle:"周期（月）",
                status:"商品状态",
                shelfStatus:"上架状态",
                createTime:"发布时间",
            },
            tableData:[],
            cardType:'全部',
            typeList:[{id:'',name:'全部'},{id:0,name:'审核通过'},{id:1,name:'待审核'},{id:2,name:'驳回'}],
            typeList1:[{id:'',name:'全部'},{id:1,name:'上架'},{id:2,name:'下架'},{id:99,name:'平台强制下架'}],
            show:true,
            lists:[],
            shelfStatus:'',
            status:'',
            serviceName:'',
            idList:[],
            loading:false,
            checkboxList:[],
            search:{},
        }
    },
    components: {
        Xselect,xtable
    },
    methods: {
        typeChange:function(val){
            this.status=val.id
        },
        typeChangeA:function(val){
            this.shelfStatus=val.id
        },
        goAdd(){
            this.$router.push({name:'publishing_service'})
        },
        editClick(item){
            this.$router.push({ name:'publishing_service',query:  { SId:item.id } })
        },
        delClick(item){
            this.$dialog.confirm('是否确认删除'+ item.serviceName,'error',()=>{
                 this.$com.majax.post(this.$com.testCsIp + '/sellerServices/deleteServiceById/' + item.id,{},{
                     success:(res)=>{
                         if(res.code==0){
                            this.getData()
                         }
                     }
                 })
            })
        },
        sxj:function(v){
            var val = v === 1 ? '上架' : '下架';
            if (this.checkboxList.length===0) {
                this.$dialog.alert('请至少选择一项' + val)
            }else{
                var idList = [];
                if (v===1) {
                    for (var i = this.checkboxList.length - 1; i >= 0; i--) {
                        idList.push(this.checkboxList[i].id);
                        if(this.checkboxList[i].status!=0){
                            this.$dialog.alert('只能上架审核通过的服务','confirm');
                            return false;
                        }
                        if (this.checkboxList[i].shelfStatus===99) {
                            this.$dialog.alert('强制下架的服务不能上架，请联系管理员','confirm');
                            return false;
                        };
                        if(this.checkboxList[i].shelfStatus===1){
                            this.$dialog.alert('上架服务不能再上架','confirm');
                            return false;
                        }
                    };
                }else{
                    for (var i = this.checkboxList.length - 1; i >= 0; i--) {
                        idList.push(this.checkboxList[i].id);
                        if(this.checkboxList[i].shelfStatus===2||this.checkboxList[i].shelfStatus===99){
                            this.$dialog.alert('下架服务不能再下架','confirm');
                            return false;
                        }
                    };
                };

                var ids = idList.join(',');
                var data = {
                    idList:ids,
                    shelfStatus:v
                }
                this.$com.majax.post(this.$com.testCsIp + '/sellerServices/updateShelfStatus',this.$qs.stringify(data),{
                    success:(res)=>{
                        if(res.code==0){
                            this.$message({
                                message: val + '成功！',
                                type: 'success'
                            });
                            this.goSearch()
                        }
                    }
                })
            };
        },
        goSearch(){
            this.search = {
                shelfStatus:this.shelfStatus,
                status:this.status,
                serviceName:this.serviceName,
            }
            this.getData(this.search)
        },
        getData(data){
            this.loading = true;
            this.$com.majax.post(this.$com.testCsIp + '/sellerServices/findListService',this.$qs.stringify(data),{
                success:(res)=>{
                    // var tableData = [];
                    // for(var i=0;i<res.data.records.length;i++){
                    //     if(res.data.records[i].status !==9){
                    //         tableData.push(res.data.records[i])
                    //     }
                    // }
                    // res.data.records=tableData
                    this.tableData = res.data;
                }
            },this)
        }
    },
    mounted(){
        this.$parent.titleMeg = '服务列表';
        this.getData()
    }
}
</script>

<style scoped>
    .x{
        width: 175px
    }
    .no{
        padding: 30px 20px;background: #fff ;
        min-height: 520px
    }
    .no11 ul,.no11 ul li{
        float: left;line-height: 40px;
        font-size: 14px;
    }
    .no11 ul input{
        width: 180px;height: 40px;
        border:1px solid #ccc;
        padding-left: 6px
    }
    .no11 ul:nth-child(2){
        margin: 0 60px
    }
    .no11 button{
         width: 80px;height: 40px;line-height: 40px;
         border: 0;background: #3296FA;
         border-radius: 5px;font-size: 14px;color: #fff;
         cursor: pointer;margin-left: 10px;margin-top: 2px;
     }
    .no12{
        margin-top: 20px;
        font-size: 14px;
        line-height: 34px;
        margin-bottom: 20px
    }
    .no12 input{
        vertical-align: middle
    }
    .no12 button{
        width: 80px;height: 34px;border: 0;
        background: #fff;border: 1px solid #3296FA;
        color: #3296FA;cursor: pointer;
    }
    .no12 button:nth-child(3){
        background: #3296FA;color: #fff
    }
    .no12 button:nth-child(4){
        float: right;width: 90px
    }
    .no12 button:nth-child(2){
        margin-right: 10px;
    }
    table{
        width: 100%;margin-top: 10px;
        text-align: center;font-size: 12px
    }
     table th{
         font-size: 14px;font-weight: normal;
         height: 36px;background: #f5f5f5;
         color: #333;border-right: 1px solid #fff
     }
     table th:first-child{
         width: 165px
     }
     table tr:not(:first-child){
         height: 52px;line-height: 52px;
         border-bottom: 1px dotted #ccc
     }
     table td:first-child input{
         vertical-align: sub;margin-right: 9px
     }
     table td:first-child{
         text-align: left;padding-left: 20px;
         overflow: hidden;display: block;
         width: 200px;
         text-overflow:ellipsis;
         white-space: nowrap;
     }
     table td:nth-child(3){
         color: #e4393c;font-weight: bold
     }
     table td:nth-child(4),table td:nth-child(7){
         color: #999
     }
     table td:last-child{
         color: #066cd4
     }
</style>

