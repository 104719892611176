<template>
    <div class="no2">
        <xstep class="xstep" :list="$parent.selfStep" :active="6" />
        <div class="sp">
            <div>
                <img src="../../../assets/img/user/i-success.png" />
            </div>
            <div>
                <p class="pF">祝贺你，自助认证完成</p>
                <p class="pL">你可以点击认证完成进行预览，也可以向平台申请咨询师审核。</p>
                <xbutton btnType="bg-fff" class="btn-back" @on-click="goAccomplish" value="自助认证完成"></xbutton>
                <xbutton class="btn-next" @on-click="goAudit" value="申请平台咨询师审核"></xbutton>
            </div>
        </div>
        <div class="xsp">
            <p>特别提醒：</p>
            <p>1、用户申请平台咨询师审核资料，咨询师可以修改资料，用户需向平台支付：5000元的咨询费；</p>
            <p>2、如认证资料没有通过认证机构的审核，平台咨询师负责根据认证机构的要求修改认证资料，再次修改不产生费用；</p>
            <p>3、认证成功后，平台可以向企业提供正规发票，无需额外支付发票费。</p>
        </div>
    </div>
</template>

<script>
import xtable from '@/components/UI/xtable.vue'
import xbutton from '@/components/UI/xbutton.vue'
import xstep from '@/components/UI/xsteps.vue'
export default {
    data(){
        return{
            autoServiceId:this.$route.query.autoServiceId,
        }
    },
    methods:{
        goAccomplish(){
            this.$router.push({name:'buy_self_details',query:{autoServiceId:this.autoServiceId}})
        },
        goAudit(){
            this.$router.push({name:'buy_self_step1',query:{autoServiceId:this.autoServiceId}})
        },
    },
    components: {
        xbutton,
        xstep,
    },
    mounted:function(){
        this.$parent.titleMeg = '自助认证-完成';
        this.$parent.rightLink = true;
        this.$router.beforeEach((to, from ,next) => {
            this.$parent.rightLink = false;
            next();
        });
    },
}
</script>

<style scoped>
.no2{
    width:992px;
    background:#fff;
    padding: 25px 20px 15px 20px;
    min-height: 580px;
}
.xstep{
    background:#f5f5f5;
}
.sp{
    margin-top: 112px;
    margin-left: 283px;
    overflow: hidden;
}
.sp img{
    width: 40px;height: 40px;
    margin-right: 16px;
    margin-top: 6px;
}
.sp>div{
    float: left;
}
.pF{
    font-size: 20px;color: #333;
    font-weight: bold;
}
.pL{
    font-size: 12px;color: #666;
    margin-bottom: 33px
}
.xsp{
    border-top:1px solid #ddd ;
    padding: 20px 0 0 16px;
}
.xsp p{
    font-size: 12px;color: #666;
}
.xsp p:first-child{
    font-size: 14px;
    color: red;
}
.btn-back{
    width: 120px;
    font-size: 14px;
    margin-right: 10px;
    margin-bottom: 96px;
}
.btn-next{
    width: 160px;
    font-size: 14px;
}
</style>

