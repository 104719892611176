<template>
    <div>
        <div v-if="titleMeg" class="no1"><span>{{titleMeg}}</span></div>
        <div class="content">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            titleMeg:'店铺信息',
        }
    },
    components: {

    },
    methods:{

    },
    mounted:function(){

    },
}
</script>

<style scoped>
        div.no1{
            width:992px;height:39px;background:#fff;
            margin-bottom: 2px;
        }
        div.no1>span{
            display: block;
            font-size: 16px;
            line-height: 37px;
            text-align: center;
            width: 120px;
            border-bottom: 2px solid #FF4A03;
        }
        .content{
            background:#fff;
            min-height:581px;
        }
</style>
