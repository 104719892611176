<template>
    <div>
        <div class="no2">
            <formItem rwidth="375" maxlength="25" errorStyle="inline" title="邮箱" required :error="safeEmail.error"  v-model="safeEmail.value" type="text" placeholder="请输入邮箱" />
            <ul>
                <li><span style="color:red">*</span> &nbsp;验证码：</li>
                <li>
                    <input maxlength="6" type="text" placeholder="请输入邮箱验证码" v-model="code.value">
                </li>
                <li>
                    <codeBtn @on-click="codeEm" :disabled="codeEmDisable" @on-type="(val)=>{codeType=val}" />
                    <span class="error">{{code.error}}</span>
                </li>
            </ul>
            <ul>
                <li></li>
                <li><xbutton @on-click="goSubmit" value="确&nbsp; 定" /></li>
            </ul>
        </div>
    </div>
</template>

<script>
import codeBtn from '@/components/UI/codeBtn.vue'
import formItem from '@/components/UI/formItem.vue'
import xbutton from '@/components/UI/xbutton.vue'
export default {
    props: {
        navData:Array
    },
    data(){
        return {
            formShow:true,
            megBox:{
                show:false,
                bodyMeg:'恭喜您，绑定成功！'
                /*type:'success'*/
            },
            safeEmail:{
                value:"",
                error:"",
                validate:['required'],
                name:"邮箱"
            },
            code:{
                value:"",
                error:"",
                validate:['required'],
                name:"验证码"
            },
            codeEmDisable:false,
            codeType:false,
        }
    },
    components: {
        codeBtn,
        formItem,
        xbutton
    },
    methods:{
        goSubmit:function(_this){
            var data ={
                newEmail:this.safeEmail.value,
                vcode:this.code.value
            }
            var dataV = {
                safeEmail:this.safeEmail,
                code:this.code
            };
            var validate = this.$com.validate(dataV);
            if(validate){
                _this.loading = true;
                this.$com.majax.post(this.$com.testUsIp + '/security/bindEmail',this.$qs.stringify(data),{
                    success:(res)=>{
                        this.$dialog.alert('恭喜您，绑定成功！','success',()=>{
                             this.$router.push({ name:'account_bdemail_Cemail1',query:{reload:true} });
                        })
                    }
                },_this)
            }
        },
        codeEm:function(v){
            if (this.safeEmail.value == "") {
                this.$dialog.alert('请输入邮箱','error')
            }else{
                var data = {
                    email:this.safeEmail.value,
                    sendType:2
                }
                this.$axios.post(this.$com.testUsIp+'/email/send-reg-code', this.$qs.stringify(data))
                .then((response) => {
                    var res = response.data
                    if (res.code === 0) {
                        this.codeEmDisable = !this.codeType
                    }else{
                        this.$dialog.alert(res.msg,'error')
                    };
                })
                .catch((error) => {
                    this.$dialog.alert('系统错误','error')
                })
            };
        }
    },
    mounted:function(){
        this.$parent.titleMeg = '邮箱绑定';
    },
}
</script>

<style scoped>
    body{
        margin: 0;
        padding: 0;
    }
    ul,p{
        list-style: none;
        margin: 0;
        padding: 0;
    }
    div.no2{
        width:992px;height:580px;
        background:#fff;
        position: relative;
        padding-top: 38px;
    }
    div.no2 ul{
        display: flex;
        margin-left: 84px;
        background: #fff;
        line-height: 40px;
        margin-bottom:20px;
    }
    div.no2 ul>li:nth-child(1){
        width: 100px;
        text-align: right;
        font-size: 14px;
        color: #333;
        margin-right:7px;
    }
    div.no2 ul>li:nth-child(2) input{
        width: 375px;
        height: 40px;
        padding-left: 10px;
        box-sizing: border-box;
        border: 1px solid #ccc
    }
    div.no2 ul:nth-child(2)>li:nth-child(2)>input{
        width: 240px;
    }
    div.no2 ul:nth-child(2)>li:nth-child(3)>input:hover{
        cursor: pointer;
        background: linear-gradient(to right, #00aaff, #0085ff);
        color: #fff
    }
    div.no2 ul>li:nth-child(2) button{
        width: 240px;
        height: 40px;
        color: #fff;
        background: #3296FA;
        border: 0;
        font-size: 18px;
        margin-top: 20px;
    }
    div.no2 ul>li:nth-child(2) button:hover{
        background: linear-gradient(to right, #00aaff, #0085ff);
        cursor: pointer;
    }
div.no2 p{
    line-height: 0px;font-size: 12px;
    color: #FF4A03
}
.error{
    font-size: 14px;
    color:red;
    margin-left:5px;
}
</style>
