<template>
    <div class="rank">
        <div class="no2">
            <div class="d1">
                <span>当前安全级别 ：</span>
                <ul class="safe-grade" :class="safeGradeClass" >
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
                <span>{{safeGrade}}</span>
            </div>
            <div class="d2">
                    <img src="../../../assets/img/user/laba_04c6e6e.png" style="vertical-align: middle;margin-right:8px">
                    <span>为了更好的保护您的交易安全，建议您通过以下方式提高安全级别 </span>
            </div>
            <div class="d3">
                <div class="item-default" :class="{'item-ok':user.pwdStrength}">
                    <img v-if="user.pwdStrength" src="../../../assets/img/user/u810_c140083 拷贝.png" />
                    <img v-else src="../../../assets/img/user/u808_5c76a3e.png" />
                    <p>账户密码</p>
                    <p>当前密码强度 ：<span style="color: #FF4A03">{{pwdStrength}}</span> &nbsp;&nbsp;<span>|&nbsp;&nbsp;建议您定期更改密码以保护账户安全</span></p>
                    <router-link to="/users/account/account_Cpw">修改</router-link>
                </div>
                <div class="item-default" :class="{'item-ok':user.safePhone!='未绑定安全手机'}">
                    <img v-if="user.safePhone!='未绑定安全手机'" src="../../../assets/img/user/u810_c140083 拷贝.png" />
                    <img v-else src="../../../assets/img/user/u808_5c76a3e.png" />
                    <p>绑定手机</p>
                    <p v-if="user.safePhone!='未绑定安全手机'">已绑定手机 ：<span>{{user.safePhone}}</span></p>
                    <p class="col999" v-else>您还未绑定手机，快去绑定吧</p>
                    <router-link v-if="user.safePhone!='未绑定安全手机'" to="/users/account/account_bdph/account_bdph_Cph1">修改</router-link>
                    <router-link v-else to="/users/account/account_bdph">绑定</router-link>
                </div>
                <div class="item-default" :class="{'item-ok':user.safeEmail!='未绑定安全邮箱'}">
                    <img v-if="user.safeEmail!='未绑定安全邮箱'" src="../../../assets/img/user/u810_c140083 拷贝.png" />
                    <img v-else src="../../../assets/img/user/u808_5c76a3e.png" />
                    <p>绑定邮箱</p>
                    <p v-if="user.safeEmail!='未绑定安全邮箱'">已绑定邮箱 ：<span>{{user.safeEmail}}</span></p>
                    <p class="col999" v-else>您还未绑定邮箱，快去绑定吧</p>
                    <router-link v-if="user.safeEmail!='未绑定安全邮箱'" to="/users/account/account_bdemail/account_bdemail_Cemail1">修改</router-link>
                    <router-link v-else to="/users/account/account_bdemail">绑定</router-link>
                </div>
                <div class="item-default" :class="{'item-ok':authentication===0}">
                    <img v-if="authentication===0" src="../../../assets/img/user/u810_c140083 拷贝.png" />
                    <img v-else src="../../../assets/img/user/u808_5c76a3e.png" />
                    <p>身份验证</p>
                    <p><span>{{authenticationVal}}</span>   &nbsp;&nbsp;<span>|&nbsp;&nbsp;认证后方可进行交易，开通店铺等操作</span></p>
                    <router-link v-if="authentication===3" to="/users/base/base_auth/base_auth_index">开启保护</router-link>
                    <router-link v-if="authentication===2" :to="authenUrl">重新认证</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        rzData:Object
    },
    data(){
        return {
            gradeNumber:0,
            safeGradeClass:"",
            safeGrade:"",
            user:{},
            authentication:3
        }
    },
    components: {

    },
    computed:{
        userType:function(){
            return this.$store.state.userType
        },
        authenUrl:function(){
            if (this.userType.orgStatus||this.userType.orgStatus===0) {
                return '/users/base/base_auth/base_auth_bus'
            }else{
                return '/users/base/base_auth/base_auth_per'
            };
        },
        pwdStrength:function(){
            var pwd = "";
            switch(this.user.pwdStrength){
                case 1:
                    pwd = '低';
                    break;
                case 2:
                    pwd = '中';
                    break;
                case 3:
                    pwd = '高'
                    break;
            }
            return pwd
        },
        authenticationVal:function(){
            var val = "";
            switch(this.authentication){
                case 0:
                    val = '已认证';
                    break;
                case 1:
                    val = '认证中';
                    break;
                case 2:
                    val = '被驳回'
                    break;
                case 3:
                    val = '未认证'
                    break;
            }
            return val
        }
    },
    methods:{
        getListData:function(){
            this.$com.majax.post(this.$com.testUsIp+'/security/securityLevel',{},{
                success:(res)=>{
                    var data = res.data;
                    this.user = data;

                    var userData = this.$store.state.user;
                    userData.safePhone = data.safePhone;
                    userData.safeEmail = data.safeEmail;
                    this.$store.commit('SET_user',userData);

                    if (data.personlStatus===0||data.orgStatus===0) {
                        this.authentication = 0
                    };
                    if (data.personlStatus===1||data.orgStatus===1) {
                        this.authentication = 1
                    };
                    if (data.personlStatus===2||data.orgStatus===2) {
                        this.authentication = 2
                    };
                    var gradeList = [this.user.safePhone,this.user.safeEmail,this.user.pwdStrength];
                    for (var i = gradeList.length - 1; i >= 0; i--) {
                        if (gradeList[i]) {
                            if (gradeList[i]!='未绑定安全邮箱'&&gradeList[i]!='未绑定安全手机') {
                                this.gradeNumber++
                            };
                        };
                    };
                    if (this.authentication === 0) {
                        this.gradeNumber++
                    };
                    this.safeGradeClass = 'safeGradeClass' + this.gradeNumber
                    switch(this.gradeNumber){
                        case 0:
                            this.safeGrade = '危险';
                            break;
                        case 1:
                            this.safeGrade = '低';
                            break;
                        case 2:
                            this.safeGrade = '中'
                            break;
                        case 3:
                            this.safeGrade = '高'
                            break;
                        case 4:
                            this.safeGrade = '安全'
                            break;
                    }
                }
            })
        }
    },
    mounted:function(){
        this.$parent.titleMeg = '当前安全级别';
        this.getListData()
    },
}
</script>


<style scoped>

.rank>div.no1{
    width:992px;height:39px;background:#fff;
    margin-bottom: 2px;
}
.rank>div.no1>span{
    display: block;
    font-size: 16px;
    line-height: 37px;
    text-align: center;
    width: 120px;
    border-bottom: 2px solid #FF4A03;
}
.rank>div.no2{
    width:992px;height:580px;
    background:#fff;
    position: relative;
    padding-top: 15px;
    padding-left: 30px;
    box-sizing: border-box
}
.rank>div.no2>.d1{
    display: flex;
    margin-top: 15px;
}
.rank>div.no2>.d1>span{
    display: block;
    font-size:16px;
    color: #333;
    line-height: 16px;
    margin-right: 9px
}
.rank>div.no2>.d1>span:last-child{
    color: #FF4A03
}
/* .rank>div.no2>.d1>div{
    width: 70px;
    height: 16px;
    background-color: #f5f5f5;
    margin-right: 3px;
} */
.rank>div.no2>.d2{
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 12px;
    color: #FF4A03
}
.rank>div.no2>.d3{
    position: relative;
}
.rank>div.no2>.d3>div{
    width: 900px;
    height: 80px;
    margin-top: 20px;
    position: relative;
}
.rank>div.no2>.d3>div img{
    position: absolute;
    top: 22px;
    left: 21px;
}
.rank>div.no2>.d3>div>p:nth-child(2){
    position: absolute;
    top: 20px;
    left: 77px;
    font-size: 16px;
    font-weight: bold
}
.rank>div.no2>.d3>div>p:nth-child(3){
    position: absolute;
    top: 46px;
    left: 76px;
    font-size: 14px;
}
.rank>div.no2>.d3>div>p:nth-child(3)>span:nth-child(2){
    color: #999;
}
.rank>div.no2>.d3>div>a:nth-child(4){
    position: absolute;
    right: 20px;
    top: 34px;
    font-size: 14px;
    color: #3296fa;
    text-decoration: none
}
.rank>div.no2>.d3>div>a:nth-child(4):hover{
    color: #FF4A03
}
.rank>div.no2>.d3>div:nth-child(4) >a:nth-child(4){
    display: block;
    width: 90px;height: 30px;
    background-color: #3296fa;
    color: #fff;
    text-align: center;
    line-height: 30px;
    position: absolute;
    top: 25px;
}
.rank>div.no2>.d3>div:nth-child(4) >a:nth-child(4):hover{
    background: linear-gradient(to right, #00aaff, #0085ff);
}
.col999{
    color:#999;
}
.safe-grade li{
    width: 70px;
    height: 16px;
    background-color: #f5f5f5;
    margin-right: 3px;
    display:inline-block;
}
.safeGradeClass1 li:first-child{
    background:#ff0000;
}
.safeGradeClass2 li:nth-child(1),.safeGradeClass2 li:nth-child(2){
    background:#ff6c00;
}
.safeGradeClass3 li:nth-child(1),.safeGradeClass3 li:nth-child(2),.safeGradeClass3 li:nth-child(3){
    background:#ffcc00;
}
.safeGradeClass4 li{
    background:#00c249;
}
.item-default{
    background-color: #f5f5f5;
    border: 1px solid #E6E6E6;
}
.item-ok{
    background-color: #eff7ff;
    border: 1px solid #DDEEFF;
}
</style>
