<template>
    <div class="item-wrap" :class="{'row4':row==='4'}">
        <div v-if="!type" class="item">
            <div class="img-wrap">
                <img :src="goodsData.imagePath||$com.errorImg" @click="()=>$emit('on-clickDetail', goodsData)" :onerror="$com.errorImg"/>
                <span v-if="delValue" class="del-span" @click="()=>$emit('on-clickDel', goodsData)">{{delValue}}</span>
            </div>
            <span class="goods-name" @click="()=>$emit('on-clickDetail', goodsData)">{{goodsData.name}}</span>
        </div>
        <div class="item goods-wrap" v-if="type==='money'">
            <div class="img-wrap">
                <img :src="goodsData.imagePath||$com.errorImg" @click="()=>$emit('on-clickDetail', goodsData)" :onerror="$com.errorImg"/>
                <span v-if="delValue" class="del-span" @click="()=>$emit('on-clickDel', goodsData)">{{delValue}}</span>
            </div>
            <div class="money-wrap">
                <span class="goods-name font-bold" @click="()=>$emit('on-clickDetail', goodsData)" v-html="goodsData.name">{{goodsData.name}}</span>
                <div class="money-item">
                    <span v-if="goodsData.priceType=='1'"><i>￥</i>{{goodsData.price}}</span>
                    <span v-if="goodsData.priceType=='2'"><i>￥</i>{{goodsData.priceMin}}-<i>￥</i>{{goodsData.priceMax}}</span>
                    <span v-if="goodsData.priceType=='3'">面议</span>
                </div>
                <div><span>服务周期：</span><span>{{goodsData.cycleName}}</span></div>
            </div>
        </div>
        <div class="item goods-wrap" v-if="type==='time'">
            <div class="img-wrap">
                <img :src="goodsData.imagePath||$com.errorImg" @click="()=>$emit('on-clickDetail', goodsData)" :onerror="$com.errorImg"/>
                <span v-if="delValue" class="del-span" @click="()=>$emit('on-clickDel', goodsData)">{{delValue}}</span>
            </div>
            <div class="money-wrap">
                <span class="goods-name font-bold" @click="()=>$emit('on-clickDetail', goodsData)">{{goodsData.name}}</span>
                <div><span>日期：</span><span>{{goodsData.cycle.substring(0,10)}}</span></div>
            </div>
        </div></div>
</template>

<script>

export default {
    props: {
        goodsData:Object,
        delValue:String,
        type:String,
        row:String,
    },
    data(){
        return {
            himg:false
        }
    },
    components: {

    },
    mounted:function(){
        this.$com.ImgHW(this.goodsData.imagePath,(v)=>{
            this.himg = v;
        });
    },
    methods:{

    },
}
</script>

<style scoped>
.img-wrap{
    width:100%;
    height:240px;
    cursor:pointer;
    border:1px solid #ccc;
    position:relative;
    overflow: hidden;
}
.img-wrap img{
    max-height:100%;
    max-width:100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.img-wrap:hover .del-span{
    display:block;
}
.item-wrap{
    display:inline-block;
    margin-bottom:15px;
    width:220px;
    margin-right:20px;
}
.item-wrap:hover{
    box-shadow:0px 0px 12px 0.5px #ccc;
}
.item-wrap span{
    font-size:14px;
}
.item-wrap .goods-name{
    cursor:pointer;
    width: 200px
}
.item-wrap .goods-name:hover{
    color:red;
}
.item-wrap .del-span{
    cursor:pointer;
    font-size:12px;
    position:absolute;
    top:0;
    right:0;
    display:block;
    color:#fff;
    padding:6px 12px;
    background:rgba(0,0,0,.3);
    display:none;
    line-height: 20px
}
.goods-name{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    display: inline-block;
}
.font-bold{
    font-weight:bold;
}
.goods-wrap{
    border:1px solid #ccc;
}
.goods-wrap .img-wrap{
    border:none;background: #fff;
    border-bottom:1px solid #ccc;
}
.money-wrap{
    padding:10px;
}
.money-item{
    color:#e4393c;
}
.money-item span{
    font-size:18px;
    font-weight:bold;
}
.money-item i{
    font-size:14px;
    font-weight:normal;
    font-style:normal;
}
.row4:nth-child(4n),.row5:nth-child(5n){
    margin-right:0;
}
</style>
