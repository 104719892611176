<template>
    <div class="no2">
        <div>
            <div>
                <div>
                </div>
                <div>
                    <span>1</span>
                </div>
                <span>验证原邮箱</span>
            </div>
            <div>
                <div>
                </div>
                <div>
                    <span>2</span>
                </div>
                <span>绑定新邮箱</span>
            </div>
            <div>
                <div>
                </div>
                <div>
                    <span>3</span>
                </div>
                <span>绑定成功</span>
            </div>
        </div>
        <ul>
            <li>原邮箱：</li>
            <li>{{safeEmail}}</li>
        </ul>
        <ul>
            <li>验证码：</li>
            <li>
                <input type="text" placeholder="请输入邮箱验证码" v-model="code.value">
                <p class="p">{{code.error}}</p>
            </li>
            <li>
                <codeBtn @on-click="codeEm" :disabled="codeEmDisable" @on-type="(val)=>{codeType=val}" />
            </li>
        </ul>
        <ul>
            <li>
                <xbutton @on-click="goNext" value="下一步" />
            </li>
        </ul>
        <div>
            <p>如果您没有收到邮件：</p>
            <p>邮件到达需要2-3分钟，若您长时间未收到邮件，建议您检查邮件中的垃圾邮件啊或者重发验证信息</p>
            <p>若原邮箱已无法使用，您可以通过<span>申请人工审核</span>来取消原邮箱的认证信息，然后重新进行认证</p>
        </div>
    </div>
</template>

<script>
import codeBtn from '@/components/UI/codeBtn.vue'
import xbutton from '@/components/UI/xbutton.vue'
export default {
    props: {

    },
    data(){
        return {
            safeEmail:'',
            code:{
                value:"",
                error:"",
                validate:['required'],
                name:"验证码"
            },
            codeEmDisable:false,
            tokenId:'',
            codeType:false,
        }
    },
    components: {
        codeBtn,
        xbutton
    },
    methods:{
        goNext:function(_this){
            var data ={
                vcode:this.code.value
            }
            var dataV = {
                code:this.code
            };
            var validate = this.$com.validate(dataV);
            if(validate){
                _this.loading = true;
                this.$com.majax.post(this.$com.testUsIp + '/security/verifyOldEmail',this.$qs.stringify(data),{
                    success:(res)=>{
                         if(res.code===0){
                             this.$router.push({ name: 'account_bdemail_Cemail3' })
                         }else{
                            this.$dialog.alert(res.msg,'error')
                         }
                    }
                },_this)
            }
        },
    codeEm:function(v){
            //this.codeEmDisable = v;
            var data = {
                sendType:4,
            }
            this.$com.majax.post(this.$com.testUsIp+'/email/send-update-code', this.$qs.stringify(data),{
                success:(res)=>{
                    if(res.code === 0){
                        this.codeEmDisable = !this.codeType
                    }
                    else{
                        this.$dialog.alert(res.msg,'error')
                    }
                }
            })
        }
    },
    mounted:function(){
        this.$parent.$parent.titleMeg = '修改绑定邮箱';
        this.$com.majax.get(this.$com.testUsIp + '/security/getOldEmail',{},{
             success:(res)=>{
                 this.safeEmail=res.data
             }
        })
    }
}
</script>

<style scoped>

body{
    margin: 0;
    padding: 0;
}
div.no2{
    width:992px;height:580px;
    background:#fff;
    position: relative;
    box-sizing: border-box;
    position: relative;
}
div.no2>div>div>div:nth-child(1){
    width: 267px;
    border: 1px solid #ff4a03;
    position: absolute;
    top: 96px;
    left: 95px
}
div.no2>div>div>div:nth-child(2){
    width: 30px;
    height: 30px;
    background-color: #ff4a03;
    transform:rotate(45deg);
    position: absolute;
    top: 82px;
    left: 208px;
    text-align: center;
    line-height: 30px;
}
div.no2>div>div>div:nth-child(2) span{
    display: block;
    color: #fff;
    transform: rotate(-45deg)
}
div.no2>div>div:nth-child(1)>span{
    color: #ff4a03;
    font-size: 16px;
    position: absolute;
    top: 141px;
    left: 180px
}
div.no2>div>div:nth-child(2)>span,.section-password>div>div:nth-child(3)>span{
    position: absolute;
    top: 141px;
    left: 454px;
    color: #333;
    font-size: 16px;
}
div.no2>div>div:nth-child(3)>span{
    position: absolute;
    left: 725px;
    top: 141px
}
div.no2>div>div:nth-child(2)>div:nth-child(1){
    position: absolute;
    left: 361px;
    border: 1px solid #ccc;
}
div.no2>div>div:nth-child(2)>div:nth-child(2){
    background-color: #ccc;
    position: absolute;
    left: 474px;
}
div.no2>div>div:nth-child(3)>div:nth-child(1){
    position: absolute;
    left:628px ;
    border: 1px solid #ccc;
}
div.no2>div>div:nth-child(3)>div:nth-child(2){
    background-color: #ccc;
    position: absolute;
    left: 742px;
}
div.no2>ul{
    display: flex;
}
div.no2>ul:nth-child(2){
    position: absolute;
    top: 240px;
    left: 197px;
    line-height: 30px;
}
div.no2>ul:nth-child(3){
    position: absolute;
    top: 272px;
    left: 197px;
    line-height: 60px
}
div.no2>ul:nth-child(3) input{
    width: 240px;
    height: 40px;
    border: 1px solid #ccc;
    padding-left: 10px;
    box-sizing: border-box;
}
div.no2>ul:nth-child(3) li:nth-child(3) input{
    width: 130px;padding: 0;
}
div.no2>ul:nth-child(4){
    position: absolute;
    top: 342px;
    left: 253px;
}
div.no2>ul:nth-child(4) button{
    width: 240px;
    height: 40px;
    background-color: #3296FA;
    border: 0;
    color: #fff;
    font-size: 18px;
    margin-top: 10px;
}
div.no2>ul:nth-child(4) button:hover{
    background: linear-gradient(to right, #00aaff, #0085ff);
    cursor: pointer;
}
div.no2>ul li:nth-child(1){
    text-align: right;
    font-size: 14px;
    color: #333
}
div.no2>div:nth-child(5){
    position: absolute;
    left: 20px;
    bottom: 30px;
}
div.no2>div:nth-child(5)>p{
    font-size: 12px;
    color: #999;
    line-height: 18px
}
div.no2>div:nth-child(5)>p:nth-child(1){
    font-size: 14px;
    color: #333
}
.no2 .p{
    font-size: 14px;color: #ff4a03;
    line-height: 0
}
</style>

