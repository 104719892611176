<template>
    <div class="no2">
        <formItem type="file" title="头像" v-model="avatar" />
        <formItem title="用户名" v-model="user.account" />
        <formItem maxlength="20" errorStyle="inline" title="昵称" required :error="nickName.error" type="text" v-model="user.nickName" placeholder="请输入昵称" />
        <formItem title="性别" type="radio" v-model="user.sex" :radioList="sexList" @on-change="radioChange" />
        <formItem title="生日" type="date" v-model="user.birthday" />
        <ul class="addr">
            <li class="addr-label"><span class="required">* &nbsp;</span>联系地址：</li>
            <li>
                <threeSelect @on-change="getArea" v-model="user.areaId" />
                <input maxlength="25" type="text"  placeholder="请输入详细地址" v-model="user.detailAddress">
                <span class="error">{{areaId.error}}</span>
            </li>
        </ul>
        <formItem maxlength="8" title="邮编" type="text" errorStyle="inline" :error="zipCode.error" v-model="user.zipCode" placeholder="请输入邮编" />
        <formItem maxlength="11" required title="联系手机" type="text" errorStyle="inline" :error="phone.error" v-model="user.phone" placeholder="请输入联系手机" />
        <formItem maxlength="30" required title="邮箱" type="text" errorStyle="inline" :error="email.error" v-model="user.email" placeholder="请输入邮箱" />
        <formItem maxlength="15" title="QQ" type="text" errorStyle="inline" :error="qq.error" v-model="user.qq" placeholder="请输入QQ" />
        <formItem maxlength="15" title="微信" type="text" v-model="user.wechat" placeholder="请输入微信" />
        <formItem maxlength="125" title="简介" type="textarea" v-model="user.introduction" placeholder="请输入简介" />
        <ul class="btn-wrap">
            <li></li>
            <li><xbutton @on-click="goSubmit" value="保 存" /><button @click="goAuth">实名认证</button></li>
        </ul>
    </div>
</template>

<script>
import formItem from '@/components/UI/formItem.vue'
import threeSelect from '@/components/UI/threeSelect.vue'
import xbutton from '@/components/UI/xbutton.vue'
export default {
    props: {
        rzData:Object
    },
    data(){
        return {
            user:{},
            formShow:true,
            avatar:{},
            date:"",
            sexList:[{name:'男',id:0},{name:'女',id:1}],
            nickName:{
                value:"",
                error:"",
                validate:['required'],
                name:"昵称"
            },
            areaId:{
                value:"",
                error:"",
                validate:['required'],
                name:"地址"
            },
            zipCode:{
                value:"",
                error:"",
                validate:['number'],
                name:"邮编"
            },
            phone:{
                value:"",
                error:"",
                validate:['required','phone'],/*'telphone'*/
                name:"联系手机"
            },
            email:{
                value:"",
                error:"",
                validate:['required','email'],
                name:"邮箱"
            },
            qq:{
                value:"",
                error:"",
                validate:['number'],
                name:"QQ"
            },
            goAuthUrl:"base_auth_index",
            userType:{}
        }
    },
    components: {
        formItem,
        threeSelect,
        xbutton,
    },
    watch:{
        rzData(val){
            if (val.personlStatus||val.personlStatus===0) {
                this.goAuthUrl = 'base_auth_per'
            }else if(val.orgStatus||val.orgStatus===0){
                this.goAuthUrl = 'base_auth_bus'
            }else{
                this.goAuthUrl = 'base_auth_index'
            };
        }
    },
    computed: {
        titleVal:function(){
            var val = "";
            switch(this.status){
                case 1:
                    val = '等待审核';
                    break;
                case 2:
                    val = '非常抱歉，您的请求被驳回';
                    break;
                case 0:
                    val = '恭喜您，审核通过！'
                    break;
            }
            return val
        }
    },
    methods:{
        //提交
        goSubmit:function(_this){
            this.nickName.value = this.user.nickName
            this.zipCode.value = this.user.zipCode
            this.phone.value = this.user.phone
            this.qq.value = this.user.qq
            this.email.value = this.user.email
            this.areaId.value = this.user.areaId
            var dataV = {
                nickName:this.nickName,
                phone:this.phone,
                email:this.email,
                zipCode:this.zipCode,
                qq:this.qq,
                areaId:this.areaId
            }
            if (detailAddress) {
                var detailAddress = this.user.detailAddress.replace(/(^\s*)|(\s*$)/g, "");
                if (detailAddress=="") {
                    this.areaId.error="地址不能为空"
                    return false;
                }
            };
            delete this.user.personlStatus;
            delete this.user.orgStatus;
            var validate = this.$com.validate(dataV)
            if (validate) {
                _this.loading = true;
                var data = this.user;
                delete data.avatarPath;
                data.avatar = this.avatar.fileId;
                this.$com.majax.post(this.$com.testUsIp + '/user/saveUser',this.$qs.stringify(data),{
                    success:(res)=>{
                        this.$dialog.alert("保存成功",'success',"",(_res)=>{
                            _res.thisShow = false;
                            this.getUserData();
                        })
                    }
                },_this)
            };
        },
        //跳转实名认证
        goAuth:function(){
            this.$router.push({ name:this.goAuthUrl })
        },

        //获取用户信息
        getUserData:function(){
            this.$com.majax.get(this.$com.testUsIp+'/user/selectUserInfo',{},{
                success:(res)=>{
                    var data = res.data
                    this.user = data;
                    this.user.sex+=''
                    delete this.user.org;
                    delete this.user.personl;
                    this.nickName.value = data.nickName;
                    this.avatar = {imgUrl:data.avatarPath,fileId:data.avatar};
                    var _data = this.$store.state.user;
                    _data.nickName = data.nickName;
                    _data.avatarPath = data.avatarPath;
                    this.$store.commit('SET_user',_data);
                }
            })
        },
        //获取店铺信息
        getShopData:function(userType){
            this.$com.majax.get(this.$com.testUwIp+'/shop/getShopByUserId',{},{
                success:(res)=>{
                    if(res.data.status===0){
                        sessionStorage.setItem('shopType',true)
                        userType.shopStatus = res.data.status||res.data.status===0 ? res.data.status : ""
                    }else{
                        sessionStorage.setItem('shopType',false)
                    }
                    this.$store.commit('SET_userType',userType);
                }
            })
        },
        //判断权限
        Jurisdiction:function(){
            this.$com.majax.post(this.$com.testUsIp+'/security/securityLevel',{},{
                success:(res)=>{
                    var userType = {}
                    var data = res.data;
                    //是否绑定安全邮箱
                    if (data.safeEmail != "未绑定安全邮箱") {
                        userType.safeEmail = true;
                    };
                    //是否绑定安全手机
                    if (data.safePhone != "未绑定安全手机") {
                        userType.safePhone = true;
                    };
                    //是否个人实名认证
                    if (data.personlStatus||data.personlStatus===0) {
                        userType.personlStatus = data.personlStatus;
                        this.goAuthUrl = 'base_auth_per'
                    };
                    //是否机构实名认证
                    if (data.orgStatus||data.orgStatus===0) {
                        userType.orgStatus = data.orgStatus;
                        this.goAuthUrl = 'base_auth_bus'
                    };
                    this.getShopData(userType);
                }
            })
        },
        radioChange:function(val){
            this.user.sex = val
        },
        getDate:function(val){
            this.user.birthday = val
        },
        /*getImg:function(val){
            this.user.avatar = val
        },*/
        getArea:function(item){
            this.user.areaId = item?item.id:""
        },
    },
    mounted:function(){
        /*if (!this.$store.state.tokenId) {
            this.$message({
                message: '请先登录',
                type: 'error',
                duration: 1000
            });
            setTimeout(()=>{
                this.$router.push({ path:'/login' })
            },'1000');
        };*/
        //this.$com.isLogin()
        this.getUserData();
        this.Jurisdiction();
        this.$parent.titleMeg = '基本信息';
    },
}
</script>

<style scoped>
    ul,p{
        list-style: none;
        margin: 0;
        padding: 0;
    }
    div.no1{
        width:992px;height:39px;background:#fff;
        margin-bottom: 2px;
    }
    div.no1>span{
        display: block;
        font-size: 16px;
        line-height: 37px;
        text-align: center;
        width: 120px;
        border-bottom: 2px solid #FF4A03;
    }
    div.no2{
        width:992px;
        background:#fff;
        position: relative;
        padding-top: 15px;
        padding-bottom: 20px;
    }
    div.no2 ul{
        display: flex;
        margin-left: 112px;
        background: #fff;
        font-size: 14px;
        margin-bottom:20px;
    }
    div.no2 ul:nth-child(1){
        margin-top: 19px;
        line-height: 30px;
    }
    div.no2 ul>li:nth-child(1){
        width: 100px;
        text-align: right;
        margin-right: 7px;
        color: #333;
    }
    div.no2 ul input{
        width: 415px;height: 40px;
        border: 1px solid #ccc;
        padding-left: 10px;
        box-sizing: border-box
    }
    div.no2 ul.sex,div.no2 ul.uname{
        line-height: 40px;
    }
    div.no2 ul.sex input{
        width: 13px;height: 13px;
        vertical-align: sub;
        margin-right: 3px
    }
    div.no2 ul.sex input:nth-child(2){
        margin-left: 56px;
    }
    div.no2 ul.addr>li:last-child input{
        width:415px;
    }
    div.no2 ul.addr select{
        color: #999;
        padding-left: 6px;
        width:180px;height: 40px; margin-right: 5px;
        border: 1px solid #ccc;
    }
    div.no2 ul textarea{
        width:415px;height:114px;margin-top: 10px;
        border: 1px solid #ccc;
        padding:10px;
    }
    div.no2 ul.btn-wrap button{
        margin-top: 10px;
        width: 178px;height: 40px;
        background-color: #3296FA;
        border: 0;color: #fff;font-size: 18px;
        margin-right: 20px;
    }
    div.no2 ul.btn-wrap button:last-child{
        background-color: #fff;
        border: 1px solid #3296FA;
        color: #3296FA
    }
    div.no2 ul.btn-wrap button:hover{
        background: linear-gradient(to right, #00aaff, #0085ff);
        cursor: pointer;
    }
    div.no2 ul.btn-wrap button:last-child:hover{
        color: #fff
    }
    div.no2 ul:first-child>li:last-child{
        position: relative;
        width: 82px;height: 82px;border: 1px solid #ccc;
        margin-bottom: 5px
    }
    div.no2 ul:first-child>li:last-child a{
        position: absolute;
        bottom: 0;
        display: block;
        background: rgba(0, 0, 0, .5);
        font-size: 12px;
        width: 80px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        color: #fff;
        text-decoration: none;
    }
    div.no2 ul input.file-input{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: 9;
    }
    textarea{
        resize:none;
    }
    .addr-label{
        line-height:36px;
    }
    .int-label{
        line-height:50px;
    }
    .required{
        color:red;
    }
    .error{
        color: red;
        margin-left: 5px;
    }
    .auth-title{
            margin-top: 10px;;
            height: 100px;
            background: #f5f5f5;
            position: relative;
            line-height:86px;
        }
        .auth-title .auth-title-b{
            color: #ff4a03;
            font-size: 18px;
            font-weight: bold;
            line-height:26px;
            display:block;
        }
        .auth-title .auth-title-s{
            color: #999;
            font-size: 12px;
            line-height: 18px;
            display:block;
        }
        .auth-title img{
            width: 68px;
        }
        div.no2>div div.auth-title-span{
            display:inline-block;
            vertical-align:middle;
            margin-left:10px;
        }
</style>
