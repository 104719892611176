<template>
    <div class="no2">
        <div>
            <formItem itemStyle="inline" class="width30" maxlength="20" title="订单编号" type="text" v-model="orderNo" placeholder="请输入订单编号" />
            <formItem itemStyle="inline" class="width30" maxlength="20" title="服务名称" type="text" v-model="serviceName" placeholder="请输入服务名称" />
            <formItem itemStyle="inline" class="width30" maxlength="20" title="店铺名称" type="text" v-model="vendName" placeholder="请输入店铺名称" />
        </div>
        <ul class="form-item-wrap">
            <formItem class="form-item" itemStyle="inline" zIndex="15" title="订单状态" type="select" v-model="orderStatus" placeholder="请选择订单状态" :selectList="typeList" @on-focus="(fn)=>fn(typeList)" />
            <li class="date"><span>订单日期：</span>
                <formItem class="date-wrap" v-model="startTime" type="date" />
             至
                <formItem class="date-wrap" v-model="endTime" type="date" /></li>
            <li class="search-wrap"><xbutton class="search-btn" @on-click="goSearch" value="搜索" /></li>
        </ul>
        <buyCar type="order" :head="head" :tableData="tableData" />
                    <pagination
              v-if="total>10"
              :current="current"
              :total="total"
              :pageSize="10"
              @pageChange="search_change"
            />
    </div>
</template>

<script>
import xbutton from '@/components/UI/xbutton.vue'
import Xselect from '@/components/UI/select.vue'
import formItem from '@/components/UI/formItem.vue'
import buyCar from '@/components/UI/buyCar.vue'
import pagination from "@/components/UI/Pagination.vue";
export default {
    data(){
        return{
            show:true,
            head:{
                serviceName:"商品信息",
                price:"单价",
                quantity:"数量",
                subTotal:"费用（小计）",
                cfmTime:"下单时间",
                cfmTime:"服务凭证",
                orderStatus:"订单状态",
            },
            tableData:{},
            typeList:[{id:'',name:'全部'},{id:3,name:'未付款'},{id:4,name:'已付款'},{id:5,name:'待确认完成服务'},{id:6,name:'交易完成'},{id:1,name:'订单取消'},{id:2,name:'订单关闭'}],
            orderNo:'',
            serviceName:'',
            vendName:'',
            startTime:'',
            endTime:'',
            orderStatus:'',
            search:{},
            total:0,
            current: 1,
        }
    },
    methods:{
        //頁數切換
         search_change(page) {
             this.goData({current:page.num})
         },
        goSearch(){
            this.search = {
                orderNo:this.orderNo,
                serviceName:this.serviceName,
                vendName:this.vendName,
                startTime:this.startTime,
                endTime:this.endTime,
                orderStatus:this.orderStatus
            }
            this.goData(this.search)
        },
        goData(data){
            this.$com.majax.get(this.$com.testCsIp+'/order/sellerSelectOrders',data,{
                success:(res)=>{
                    this.tableData = res.data;
                    this.total=res.data.total
                }
            })
        }
    },
    components: {
        Xselect,formItem,
        buyCar,
        xbutton,
        pagination
    },
    mounted(){
        this.$parent.titleMeg = '订单列表';
        this.goData()
    }
}
</script>


<style scoped>
        div.no2{
            width:992px;min-height: 790px;
            background:#fff;
            padding: 30px 20px 1px 20px;box-sizing: border-box
        }
        div.no2>ul{
            font-size: 14px;color: #333;margin-bottom: 12px
        }
        div.no2 ul input[type=text]{
            width: 180px;height: 40px;border: 1px solid #ccc;
            padding-left: 10px;box-sizing: border-box;color: #666
        }
        div.no2 ul input[type=button]{
            width: 80px;height: 40px;background: #3296FA;color: #fff;
            border: 0;border-radius: 5px
        }
        div.no2 ul input[type=button]:hover{
            cursor: pointer;
            background: linear-gradient(to right, #00aaff, #0085ff);
        }
        div.no2 .date{
           line-height: 40px
        }
        div.no2>div .cat{
            display: flex;
            background: #f5f5f5;line-height: 40px;
            padding-left: 20px;box-sizing: border-box;
            justify-content: start;
        }
        div.no2>div .cat li:not(:last-child){
            border-right: 1px solid #fff
        }
        div.no2>div .cat li:not(:first-child){
            width: 100px;text-align: center
        }
        div.no2>div:nth-child(3){
            margin-bottom: 10px;margin-top: 20px;
            font-size: 14px
        }
        div.no2>div .cat li:first-child{
            width: 240px
        }
        div.no2>div .cat li:nth-child(4){
            width: 160px
        }
        div.no2>div .cat li:last-child{
            width: 135px
        }
        div.no2 .cart>li:first-child{
            background: #f5f5f5;
            line-height: 35px;color: #333;
            padding-left: 20px;box-sizing: border-box
        }
        div.no2 .cart>li:first-child span{
            color: #999
        }
        div.no2 .cart .cat{
            background: #fff;height: 120px;
            line-height: 120px
        }
        div.no2 .cart{
            margin-bottom: 15px;
            border: 1px solid #E6E6E6;font-size: 12px
        }
        div.no2 .cart .cat li:not(:last-child){
            border-right: 1px solid #E6E6E6
        }
        div.no2 .cart .cat li a{
            text-decoration: none;
            color: #3296fa;
        }
        div.no2 .cart .cat li:first-child{
            display: flex;align-items: center
        }
        div.no2 .cart .cat li:first-child a{
            display: flex;
        }
        div.no2 .cart .cat li span{
            line-height: 20px;width: 135px
        }
        div.no2 .cart .cat li:nth-child(4),div.no2 .cart .cat li:nth-child(6){
            color: #e61728;font-weight: bold
        }
        div.no2 .cart .cat li:last-child{
            line-height: 120px;
        }
        div.no2 .cart .cat li:last-child a{
            color: #333;
            display: block
        }
        div.no2 .cart .cat li:last-child a:hover{
            color: #3296fa
        }
        div.no2 .cart div{
            border: 1px solid #E6E6E6;
            width: 80px;height: 80px;
            margin-right: 16px;display: flex;
            justify-content: center;align-items: center
        }
        .x{
            width: 180px;
        }
        .search-btn{
            font-size:14px;
            width:80px;
            height:40px;
            border-radius:5px;
        }
        .width30{
            width:300px;
            display:inline-block;
        }
        .date{
            float:left;
            margin-left:11px;
        }
        .date span,
        .date .item-wrap{
            display:inline-block;
        }
        .date span{
            width:100px;
            text-align:right;
            margin-right:7px;
        }
        .form-item{
            width:292px;
        }
        .search-wrap{
            float:right;
        }
        .form-item-wrap{
            height:40px;
        }
</style>
