<template>
  <div>
    <IndexHeader_home :textMeg="textMeg" :title="title" />
    <div class="sera">
      <div class="search">
        <table></table>
        <div class="no1">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item v-if="!pCatName">
              <span @click="sload" class="sload">所有资源</span>
            </el-breadcrumb-item>
            <el-breadcrumb-item v-if="pCatName">{{pCatName}}</el-breadcrumb-item>
            <el-breadcrumb-item v-if="catName">{{catName}}</el-breadcrumb-item>
            <el-breadcrumb-item v-if="name">{{name}}</el-breadcrumb-item>
          </el-breadcrumb>
          <!-- <el-breadcrumb v-if="parentId" separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                        <el-breadcrumb-item>{{pCatName}}</el-breadcrumb-item>
                        <el-breadcrumb-item>{{catName}}</el-breadcrumb-item>
                        <el-breadcrumb-item>{{title}}</el-breadcrumb-item>
          </el-breadcrumb>-->
        </div>
        <div class="no2">
          <ul>
            <li>服务分类：</li>
            <li>
              <span
                :class="{'active':categoryType===item.id}"
                v-for="(item,index) in categoryList"
                :key="index"
                @click="GetCategoryData(item.id)"
              >{{item.catName}}</span>
            </li>
          </ul>
          <!-- <ul>
                        <li>服务价格：</li>
                        <li>
                            <span :class="{'active':priceType===item.id}" v-for="(item,index) in priceList" :key="index" @click="GetPriceData(item.id)">{{item.name}}</span>
                        </li>
          </ul>-->
          <ul>
            <li>服务周期：</li>
            <li>
              <span
                :class="{'active':cycleType===item.typecode}"
                v-for="(item,index) in cycleList"
                :key="index"
                @click="GetCycleTData(item.typecode)"
              >{{item.typename}}</span>
            </li>
          </ul>
          <ul class="prov">
            <li>省份/直辖市：</li>
            <li :class="{ 'child' : isA, 'childe': !isA}">
              <span
                :class="{'active':areaType===item.id}"
                v-for="(item,index) in areaList"
                :key="index"
                @click="GetAreaTData(item.id)"
              >{{item.name}}</span>
            </li>
            <li @click="add" v-html="html1"></li>
          </ul>
        </div>
        <div class="no3" v-loading="loading">
          <div v-if="Plists!=null && Plists.length>0 ">
            <div class="no31">
              <goodsItem
                type="money"
                v-for="(item,index) in Plists"
                :key="index"
                :goodsData="item"
                @on-clickDetail="goToS(item.id)"
              />
            </div>
            <div class="ds"></div>
            <pagination
              v-if="total>15"
              :current="current"
              :total="total"
              :pageSize="15"
              @pageChange="search"
            />
          </div>
          <img v-else class="empty-img" src="../assets/img/user/empty_page.jpg" alt="暂无数据" />
        </div>
      </div>
    </div>
    <IndexFooter_home />
  </div>
</template>

<script>
import Xselect from "@/components/UI/select.vue";
import IndexHeader_home from "@/components/IndexHeader_home.vue";
import IndexFooter_home from "@/components/IndexFooter_home.vue";
import goodsItem from "@/components/UI/goodsItem.vue";
import pagination from "@/components/UI/Pagination.vue";
export default {
  metaInfo() {
    return {
      title:
        (this.$route.query.title ? this.$route.query.title + "-" : "") +
        "佰证通认证平台",
      meta: [
        {
          name: "keywords",
          content: "佰证通,认证,平台"
        },
        {
          name: "description",
          content:
            (this.$route.query.title ? this.$route.query.title + "-" : "") +
            "佰证通认证平台"
        }
      ]
    };
  },
  inject: ["reload"],
  data() {
    return {
      loading: false,
      isA: true,
      html1: `更多<i class="el-icon-caret-bottom"></i>`,
      Plists: [],
      textMeg: "资源服务",
      total: 1,
      current: 1,
      title: this.$route.query.title,
      cat_path: "",
      parentId: this.$route.query.parentId,
      categoryType: "",
      categoryList: [],
      cycleType: "",
      cycleList: [],
      areaType: "",
      areaList: [],
      name: this.$route.query.title,
      catName: "",
      pCatName: "",
      data: { cat_path: "", cycle: "", merger_id: "", title: "" }
    };
  },
  components: {
    Xselect,
    IndexHeader_home,
    IndexFooter_home,
    goodsItem,
    pagination
  },
  methods: {
    sload() {
      this.$router.push({ path: "/search_page", title: "" });
      this.reload();
    },
    GetCategoryData: function(val) {
      this.parentId = "";
      this.cat_path = val;
      this.getItem();
      this.categoryType = val;
      this.data.cat_path = val;
      if (!val) {
        this.pCatName = "所有资源";
        this.catName = "";
      //  this.name = "";
      }
      this.search("");
    },
    GetCycleTData: function(val) {
      this.cycleType = val;
      this.data.cycle = val;
      this.search("");
    },
    GetAreaTData: function(val) {
      this.areaType = val;
      this.data.merger_id = val;
      this.search("");
    },
    add() {
      if (this.isA) {
        this.isA = false;
        this.html1 = `收起<i class="el-icon-caret-top"></i>`;
      } else {
        this.isA = true;
        this.html1 = `更多<i class="el-icon-caret-bottom"></i>`;
      }
    },
    search(page) {
      this.loading = true;
      this.data.title = this.title;
      this.data.pageSize = 15;
      this.data.currentPage = page.num;
      this.Plists = [];
      if (this.cat_path) {
        this.data.cat_path = this.cat_path;
      } else {
        this.data.cat_path = this.categoryType;
        this.cat_path = this.categoryType;
      }
      console.log(this.$qs.stringify(this.data),'============请求数据=================')
      this.$axios
        .post(
          this.$com.testCsIp + "/solr/service/query",
          this.$qs.stringify(this.data)
        )
        .then(res => {
          this.loading = false;
          // if (res.data.code != 200) {
          //       return false
          // }
          if (res.data.data.records != null) {
            for (var i = 0; i < res.data.data.records.length; i++) {
              var item = res.data.data.records[i];
              if (item.cycle == "1") {
                item.cycle = "1个月以内";
              } else if (item.cycle == "2") {
                item.cycle = "1-3个月";
              } else if (item.cycle == "3") {
                item.cycle = "3-6个月";
              } else if (item.cycle == "4") {
                item.cycle = "6-12个月";
              } else {
                item.cycle = "一年以上";
              }
              item.name = item.serviceName;
              item.imagePath = item.coveImage;
              item.cycleName = item.cycle;
              // if(item.name.indexOf(this.title) !== -1 && this.title !== ''){
              //     item.name=item.name.replace(this.title, '<font color="#007bff" >' + this.title + '</font>')
              // }
            }
          }
          this.Plists = res.data.data.records;
          // this.Plists=res.data.data.data.records;
        
          this.total = res.data.data.total;
          this.current = page.num;
        });
    },
    goToS(id) {
      var routeData = this.$router.resolve({
        name: "detail_page",
        query: { id: id }
      });
      window.open(routeData.href, "_blank");
    },
    GetData() {
      this.$axios.get(this.$com.testUsIp + "/area/linkage/100000").then(res => {
        this.areaList = res.data.data;
        this.areaList.unshift({ name: "不限", id: "" });
      });
      this.$axios
        .get(
          this.$com.testCsIp +
            "/baseCategory/getCategoryByPid/5a3bf491063548deb25bfac8877df6c4"
        )
        .then(res => {
          this.categoryList = res.data.data;
          this.categoryList.reverse();
          this.categoryList.unshift({ catName: "不限", id: "" });
        });
      this.$axios
        .get(this.$com.testUsIp + "/dic/list/period_service")
        .then(res => {
          this.cycleList = res.data.data;
          this.cycleList.reverse();
          this.cycleList.unshift({ typename: "不限", typecode: "" });
        });
    },
    getItem() {
      if (this.parentId) {
        this.$axios
          .get(
            this.$com.testCsIp +
              "/baseCategory/getCategoryByid/" +
              this.parentId
          )
          .then(res => {
            var id = res.data.data[0].parentId;
            if (id == "5a3bf491063548deb25bfac8877df6c4") {
              this.pCatName = res.data.data[0].catName;
              this.categoryType = this.parentId;
            } else {
              this.categoryType = id;
              this.catName = res.data.data[0].catName;
              this.$axios
                .get(this.$com.testCsIp + "/baseCategory/getCategoryByid/" + id)
                .then(res => {
                  this.pCatName = res.data.data[0].catName;
                });
            }
          });
      }
      if (this.cat_path && !this.parentId) {
        this.$axios
          .get(
            this.$com.testCsIp +
              "/baseCategory/getCategoryByid/" +
              this.cat_path
          )
          .then(res => {
            this.categoryType = res.data.data[0].id;
            this.pCatName = res.data.data[0].catName;
            this.catName = "";
            if (location.search.indexOf("id") != -1) {
              this.name = "";
            }
          });
      }
    }
  },
  mounted: function() {
    this.cat_path = this.$route.query.id;
    if (this.cat_path) {
      this.title = "";
    }
    this.GetData();
    this.getItem();
    this.search("");
  }
};
</script>


<style scoped>
.sload {
  cursor: pointer;
  color: #333;
}
.sload:hover {
  color: #96fa;
}
.ds {
  clear: both;
}
.nav1 {
  border: 1px solid #ddd;
  background: #fff;
  padding: 6px;
  position: absolute;
  top: -7px;
}
.nav2 {
  position: absolute;
  top: 19px;
  border: 1px solid #000;
  z-index: 8;
  background: #fff;
  width: 300px;
  height: 140px;
  display: none;
}
.nav2 > ul > li {
  float: left;
  margin-left: 20px;
  font-size: 14px;
  line-height: 30px;
  cursor: pointer;
}
.nav2 > ul > li:hover {
  color: #ff500b;
}
.nav0:hover .nav2 {
  display: block;
}
.nav0:hover .nav1 {
  border: 1px solid #000;
  cursor: pointer;
  border-bottom: none;
  z-index: 10;
}
.el-breadcrumb__separator {
  position: absolute;
  left: 140px;
}
.nav3 {
  position: absolute;
  left: 165px;
}
.sera {
  background: #f5f5f5;
  min-width: 1200px;
}
.search {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 20px;
}
.no1 {
  margin-top: 20px;
  margin-bottom: 8px;
  font-size: 12px;
  color: #333;
  position: relative;
}
.no2 {
  width: 100%;
  min-height: 140px;
  background: #fff;
}
.no2 > ul {
  display: flex;
  font-size: 12px;
  line-height: 45px;
}
.no2 > ul > li:first-child {
  width: 100px;
  text-align: right;
  color: #333;
  font-weight: bold;
}
.no2 li:nth-child(2) span {
  margin: 0 17px;
  display: block;
  float: left;
  cursor: pointer;
}
.no2 li:nth-child(2) span:hover {
  color: #ff500b;
}
.no2 li:nth-child(2) span.active,
.active {
  color: #ff500b;
}
.no2 ul.prov .child {
  width: 1000px;
  height: 45px;
  overflow: hidden;
}
.no2 ul.prov .childe {
  width: 1000px;
  min-height: 45px;
}
.no2 ul li:nth-child(3) {
  background: #666;
  color: #fff;
  width: 50px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  margin-top: 10px;
  padding-left: 5px;
}
.no3 {
  min-height: 370px;
  margin-top: 10px;
  background: #fff;
  padding: 20px 0 0px 20px;
  overflow: hidden;
}
.no31 > div {
  width: 217px;
  float: left;
  margin-right: 19px;
  margin-bottom: 19px;
}
.no32 {
  float: right;
  margin-right: 100px;
}
.no3 > div p:first-child {
  width: 217px;
  height: 162px;
}
.no3 > div p:nth-child(2) {
  font-size: 16px;
  font-weight: bold;
  margin: 18px 12px 0 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.no3 > div p:nth-child(3) {
  font-size: 18px;
  color: #e4393c;
  font-weight: bold;
  margin-left: 12px;
}
.no3 > div p:nth-child(4) {
  font-size: 12px;
  color: #666;
  margin-left: 12px;
}
.empty-img {
  display: block;
  margin: auto;
}
</style>
