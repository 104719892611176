<template>
    <div ref="editorElem"></div>
</template>

<script>
export default {
    props: {
        value:String
    },
    data(){
        return {
            editor:{}
        }
    },
    computed: {

    },
    components: {

    },
    methods:{

    },
    watch:{
        value(val){
            this.editor.txt.html(val)
        }
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    mounted:function(){
        var wangEditor = require('wangeditor')
        this.editor = new wangEditor(this.$refs.editorElem)
        //上传路径
        this.editor.customConfig.uploadImgServer = this.$com.testImgin + '/upload/upload-file'
        //herder
        this.editor.customConfig.uploadFileName = 'file'
        this.editor.customConfig.uploadImgHeaders = {
            token: this.$store.state.tokenId
        }
        //图片大小
        this.editor.customConfig.uploadImgMaxSize = 1 * 1024 * 1024;
        //隐藏网络图片
        this.editor.customConfig.showLinkImg = false;
        this.editor.customConfig.customAlert = (info) => {
            this.$message.error('上传失败，请重试');
        };
        this.editor.customConfig.uploadImgHooks = {
            customInsert:(insertImg, result, editor) => {
                var url = result.data.imgUrl
                insertImg(url)
            }
        };
        this.editor.customConfig.onchange = (val)=>{
            this.$emit('change', val)
        }
        this.editor.create();
    },
}
</script>

<style scoped>

</style>
