import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        tokenId:localStorage.getItem("tokenId"),
        userType:JSON.parse(localStorage.getItem("userType")),
        user:JSON.parse(localStorage.getItem("user")),
    },
    mutations: {
        SET_token:(state, tokenId) => {
            sessionStorage.setItem("tokenId", tokenId);
            localStorage.setItem("tokenId", tokenId);
            state.tokenId = tokenId;
        },
        SET_user:(state, user) => {
            sessionStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("user", JSON.stringify(user));
            state.user = user;
        },
        SET_userType:(state, userType) => {
            sessionStorage.setItem("userType", JSON.stringify(userType));
            localStorage.setItem("userType", JSON.stringify(userType));
            state.userType = userType;
        },
        REMOVE_token:(state) => {
            sessionStorage.clear();
            localStorage.clear();
            state.tokenId = ""
        }
    },
    getters: {

    },
    actions: {

    }
})
