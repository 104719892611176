import Vue from 'vue'
import Message from '../UI/dialog.vue'


Message.alert = function(options,obj,fn,canfn) {
  if (options === undefined || options === null) {
    options = {
      bodyMeg: ''
    }
  } else if (typeof options === 'string' || typeof options === 'number') {
    options = {
      bodyMeg: options,
      type:obj
    }
  }
  fn=fn?fn:()=>{}
  var message = Vue.extend(Message)

  var component = new message({
    data: options,
    methods:{
      submit:fn
    }
  }).$mount()
  if (canfn) {
    setTimeout(()=>{
      canfn(component)
    },1000)
  };

  document.querySelector('body').appendChild(component.$el)
}

Message.confirm = function(options,obj,fn) {
  if (options === undefined || options === null) {
    options = {
      bodyMeg: ''
    }
  } else if (typeof options === 'string' || typeof options === 'number') {
    options = {
      bodyMeg: options,
      cancelShow: true,
      type:'confirm'
    }
  }
  fn=fn?fn:()=>{}
  var message = Vue.extend(Message)

  var component = new message({
    data: options,
    methods:{
      submit:fn
    }
  }).$mount()

  document.querySelector('body').appendChild(component.$el)
}

export default Message