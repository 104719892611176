<template>
    <div class="no2"><!-- v-if="domShow" -->
        <authenTitle v-if="shop.status===2" :status="shop.status" :audit="shop.audit" />
        <div class="form-wrap">
            <formItem errorStyle="inline" maxlength="20" width="150" title="店铺LOGO" required :error="shop.logoId.error" type="file" v-model="shop.logoId.value" />
            <formItem errorStyle="inline" maxlength="20" width="150" title="店铺名称" required :error="shop.shopName.error" type="text" v-model="shop.shopName.value" placeholder="请输入店铺名称" />
            <formItem errorStyle="inline" maxlength="10" width="150" title="店铺负责人" required :error="shop.shopManager.error" type="text" v-model="shop.shopManager.value" placeholder="请输入店铺负责人" />
            <formItem errorStyle="inline" width="150" maxlength="15" title="负责人手机号" required :error="shop.managerPhone.error" type="text" v-model="shop.managerPhone.value" placeholder="请输入负责人手机号" />

            <formItem errorStyle="inline" width="150" maxlength="15" title="店铺地址" required :error="shop.areaId.error" type="threeSelect" v-model="shop.areaId.value" @on-changeTwo="(item)=>location=item.name" />

            <formItem errorStyle="inline" maxlength="20" width="150" title="详细地址" required :error="shop.detailAddress.error" type="text" v-model="shop.detailAddress.value" placeholder="请输入详细地址" />

            <div v-show="shop.detailAddress.value&&location">
                <baidu-map>
                    <bm-view class="map"></bm-view>
                    <bm-local-search @infohtmlset="infohtmlset" :keyword="shop.detailAddress.value" :panel="false" :auto-viewport="true" :location="location"></bm-local-search>
                </baidu-map>
            </div>

            <formItem class="ml20" errorStyle="inline" maxlength="20" width="220" title="店铺地址（经纬度）" type="readonly" v-model="shop.lngAndLat.value" />

            <ul class="tel">
                <li>座机号码：</li>
                <li><input maxlength="5" v-model="shop.zone.value" placeholder="区号" type="text"><input maxlength="15" v-model="shop.number.value" placeholder="请输入座机号码" type="text"></li>
            </ul>
            <formItem errorStyle="inline" maxlength="120" width="150" title="经营描述" required :error="shop.shopScope.error" type="textarea" v-model="shop.shopScope.value" placeholder="请输入经营描述" />
        </div>

        <div class="no1"><span>银行信息</span></div>
        <div class="form-wrap">
            <formItem width="150" errorStyle="inline" title="开户银行" required :error="shop.bankId.error" type="select" v-model="shop.bankId.value" placeholder="请选择开户银行" :selectList="shop.bankId" @on-focus="(fn)=>$com.findCode('bank',(res)=>{fn(res)})" />

            <formItem errorStyle="inline" width="150" maxlength="20" title="支行名称/分处理" required :error="shop.branchName.error" type="text" v-model="shop.branchName.value" placeholder="请输入开户支行名称" />
            <formItem errorStyle="inline" width="150" maxlength="20"  title="单位名称" required :error="shop.orgName.error" type="text" v-model="shop.orgName.value" placeholder="请输入单位名称" />
            <formItem errorStyle="inline" width="150" maxlength="25" title="银行账号" required :error="shop.bankAccount.error" type="text" v-model="shop.bankAccount.value" placeholder="请输入银行账号" />
        </div>

        <div class="no1"><span>业务范围</span></div>
        <div class="form-wrap">
            <ul class="yw-wrap" v-for="(item,index) in list" :key="index">
                <li class="label">{{item.name}}：</li>
                <li class="checked-box">
                    <el-checkbox-group
                        v-model="item.checkedList"
                        >
                        <div class="checked" v-for="(items,i) in item.list" :key="i">
                            <el-checkbox :label="items.id" :key="items.id">{{items.catName}}</el-checkbox>
                        </div>
                    </el-checkbox-group>
                </li>
            </ul>
        </div>

        <ul>
            <li></li>
            <li><xbutton @on-click="goSubmit" value="申请开店" /></li>
        </ul>
    </div>
</template>

<script>
import authenTitle from '@/components/UI/authenTitle.vue'
import formItem from '@/components/UI/formItem.vue'
import xbutton from '@/components/UI/xbutton.vue'
export default {
    props: {

    },
    data(){
        return {
            location: '',
            id:"",
            domShow:false,
            list:[],
            load:true,
            shop:{
                zone:{
                    value:""
                },
                number:{
                    value:""
                },
                lngAndLat:{
                    value:""
                },
                areaId:{
                    value:"",
                    error:"",
                    validate:['required'],
                    name:"店铺地址"
                },
                detailAddress:{
                    value:"",
                    error:"",
                    validate:['required'],
                    name:"详细地址"
                },
                logoId:{
                    value:"",
                    error:"",
                    validate:['required'],
                    name:"店铺logo"
                },
                shopName:{
                    value:"",
                    error:"",
                    validate:['required'],
                    name:"店铺名称"
                },
                shopManager:{
                    value:"",
                    error:"",
                    validate:['required'],
                    name:"店铺负责人"
                },
                managerPhone:{
                    value:"",
                    error:"",
                    validate:['phone','required'],
                    name:"负责人手机号"
                },
                shopScope:{
                    value:"",
                    error:"",
                    validate:['required'],
                    name:"经营描述"
                },


                bankAccount:{
                    value:"",
                    error:"",
                    validate:['required'],
                    name:"银行账号"
                },
                branchName:{
                    value:"",
                    error:"",
                    validate:['required'],
                    name:"支行名称"
                },
                orgName:{
                    value:"",
                    error:"",
                    validate:['required'],
                    name:"单位名称"
                },
                bankId:{
                    value:"",
                    label:"",
                    error:"",
                    validate:['required'],
                    name:"开户银行"
                },

            },
        }
    },
    components: {
        formItem,
        xbutton,
        authenTitle,
    },
    computed: {
        titleVal:function(){
            var val = "";
            switch(this.shop.status){
                case 1:
                    val = '等待审核';
                    break;
                case 2:
                    val = '非常抱歉，您的请求被驳回';
                    break;
                case 0:
                    val = '恭喜您，审核通过！'
                    break;
            }
            return val
        }
    },
    methods:{
        handler () {
            this.center.lng = 116.404
            this.center.lat = 39.915
            this.zoom = 15
        },
        goSubmit:function(_this){
            var dataV={
                logoId:this.shop.logoId,
                areaId:this.shop.areaId,
                shopName:this.shop.shopName,
                shopManager:this.shop.shopManager,
                managerPhone:this.shop.managerPhone,
                shopScope:this.shop.shopScope,
                bankAccount:this.shop.bankAccount,
                branchName:this.shop.branchName,
                orgName:this.shop.orgName,
                bankId:this.shop.bankId,
                detailAddress:this.shop.detailAddress
            }
            var list = [];
            for(var key in this.list){
                var checkedList = this.list[key].checkedList;
                if (checkedList.length!=0) {
                    for (var i = checkedList.length - 1; i >= 0; i--) {
                        list.push(checkedList[i])
                    };
                };
            }
            var validate = this.$com.validate(dataV)
            if (list.length===0) {
                this.$dialog.alert("请至少勾选一项业务范围",'error')
                return false
            };
            if (!this.shop.lngAndLat.value) {
                this.$dialog.alert("请在地图上选择地区",'error')
                return false
            };
            if (validate) {
                var data = {}
                for(var key in this.shop){
                    data[key] = this.shop[key].value
                }
                if (this.id) {
                    data.id = this.id
                };
                data.categoryList = list
                _this.loading = true;
                data.logoId = this.shop.logoId.value.fileId;
                this.$com.majax.post(this.$com.testUwIp+'/shop/saveOrUpdateShop',this.$qs.stringify(data),{
                    success:(res)=>{
                        this.$router.push({ name: 'shop_detailed'})
                    }
                },_this)
            };
        },
        getList:function(){
            this.$com.majax.get(this.$com.testUsIp+'/baseCategory/findCategoryList',{},{
                success:(res)=>{
                    var data = res.data,
                    list = [];
                    for(var key in data){
                        list.push({name:key,list:data[key],checkedList:[]})
                    }
                    this.list = list;
                    this.getShop();
                }
            })
        },
        getShop:function(){
            this.$com.majax.get(this.$com.testUwIp+'/shop/getShopByUserId',{},{
                success:(res)=>{
                    var data = res.data;
                    if(data.id){
                        if(data.status===2){
                            this.id = data.id;
                            for(var key in this.shop){
                                this.shop[key].value = data[key]
                            }
                            this.shop.bankId.label = data.bankName;
                            this.shop.status = data.status;
                            this.shop.audit = data.audit;
                            this.shop.logoId.value = {imgUrl:data.logoPath,fileId:data.logoId};
                            for (var i = this.list.length - 1; i >= 0; i--) {
                                var item = this.list[i];
                                for (var j = item.list.length - 1; j >= 0; j--) {
                                    var ind = data.categoryList.indexOf(item.list[j].id)
                                    if (ind>-1) {
                                        item.checkedList.push(data.categoryList[ind])
                                    };
                                };
                            };
                            this.location = data.area.split('/')[1];
                            //this.domShow = true;
                        }else{
                            this.$router.push({ name: 'shop_detailed'})
                        }
                    }else{
                        this.shop.status = 3
                        this.domShow = true;
                    }
                }
            })
        },
        infohtmlset:function(val){
            this.shop.detailAddress.value = val.address;
            this.shop.lngAndLat.value = val.point.lng + "," + val.point.lat;
        }
    },
    mounted:function(){
        this.$parent.titleMeg = '店铺信息';
        this.getList();
    },
}
</script>

<style scoped>
        body{
            margin: 0;
            padding: 0;
        }
        ul,p{
            list-style: none;
            margin: 0;
            padding: 0;
        }
        div.no1{
            width:992px;height:40px;background:#fff;
            margin-bottom: 2px;
            border-bottom:1px solid #f5f5f5;
        }
        div.no1>span{
            display: block;
            font-size: 16px;
            line-height: 37px;
            text-align: center;
            width: 120px;
            border-bottom: 2px solid #FF4A03;
        }
        div.no2{
            width:992px;
            background:#fff;
            position: relative;
            padding-bottom:10px;
        }
        div.no2 ul{
            display: flex;
            margin-left: 84px;
            background: #fff;
            margin-bottom:20px;
        }
        div.no2 ul.yw-wrap{
            margin-bottom:10px;
        }
        div.no2 ul>li:nth-child(1){
            width: 150px;
            text-align: right;
            font-size: 14px;
            color: #333;
            margin-right: 7px;
            line-height: 40px;
        }
        /* div.no2 span{
            color: red
        } */
        div.no2 ul>li:nth-child(2) input{
            width: 415px;
            height: 40px;
            padding-left: 10px;
            box-sizing: border-box;
            border: 1px solid #ccc;
        }
        div.no2 ul.tel>li:nth-child(2) input:first-child{
            width: 75px;
        }
        div.no2 ul.tel>li:nth-child(2) input:last-child{
            width:336px;
            margin-left: 4px;
        }
        div.no2 ul>li:nth-child(2) button{
            width: 180px;
            height: 40px;
            color: #fff;
            background: #3296FA;
            border: 0;
            font-size: 18px;
            margin-top: 20px;
        }
        div.no2 ul>li:nth-child(2) button:hover{
            background: linear-gradient(to right, #00aaff, #0085ff);
            cursor: pointer;
        }
        textarea{
            margin-top: 10px;
            width: 415px;height: 100px;border: 1px solid #ccc;
            box-sizing: border-box
        }
        div.no2 ul:nth-child(5){
            position: relative;
        }
        div.no2 ul:nth-child(5)>li:nth-child(2) span{
            color: #999;
            font-size: 14px;
            position: absolute;
            top: 65px;
            left: 500px;
        }
        .form-wrap{
            padding-top:15px;
        }
        div.no2 ul>li.label{
        width:200px;
        text-align:right;
        font-size:14px;
        font-weight:bold;
        margin-right:6px;
        vertical-align:top;
        line-height:22px;
    }
    .checked-box{
        width:740px;
        line-height:22px;
    }
    .checked{
        display: inline-block;
        width: 240px;
        padding-right: 20px;
    }
    .btn-wrap{
        text-align:center;
        padding-bottom:20px;
    }
    .btn-default{
        width: 180px;
        height: 40px;
        color: #fff;
        background: #3296FA;
        border: 0;
        font-size: 16px;
        margin:30px auto 0 auto;
        cursor: pointer;
    }
    .btn-default:hover{
        background: -webkit-gradient(linear, left top, right top, from(#00aaff), to(#0085ff));
        background: linear-gradient(to right, #00aaff, #0085ff);
    }
    .audit{
        font-size:14px;
        padding:10px 10px 0 30px;
    }
    .audit span{
        color:red;
    }

    .map{
        width:400px;
        height:400px;
        margin-left:240px;
    }
    div.no2 ul.ml20{
        margin-left:20px;
    }
</style>
