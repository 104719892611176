<template>
        <div class="no2">
            <table></table>
            <authenTitle class="authen-title" :status="status" :audit="audit" />
            <formItem maxlength="10" errorStyle="inline" :disabled="disabled" width="130" title="真实姓名" required :error="name.error" ps="为保障您的资金安全，实名认证姓名和身份证号必须和您提现的银行卡的户名保持一致" v-model="name.value" type="text" placeholder="请输入真实姓名" />

            <formItem width="130" :error="issued.error" :disabled="disabled" errorStyle="inline" title="证件类型" required type="select" v-model="issued.value" placeholder="请选择证件类型" :selectList="issued" @on-focus="(fn)=>fn(cardList)" />

            <formItem maxlength="20" errorStyle="inline" :disabled="disabled" width="130" title="证件号码" required :error="idCard.error" ps="证件号码前后不能有空格，年龄不足18岁不能认证" v-model="idCard.value" type="text" placeholder="请输入证件号码" />

            <formItem maxlength="20" errorStyle="inline" :disabled="disabled" width="130" title="发证单位" required :error="authority.error" v-model="authority.value" type="text" placeholder="请输入发证单位" />

            <ul class="date">
                <li><span>*</span> 证件到期时间：</li>
                <li class="item-inp">
                    <input v-model="dateType" value="1" class="date-radio" type="radio" >
                    <el-date-picker
                        @change="dateChange"
                        v-model="expiration.value"
                        type="date"
                        :disabled="disabled"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                    </el-date-picker>
                </li>
                <li class="item-inp-cq">&nbsp; <input v-model="dateType" value='0' type="radio" ><span>长期</span> </li>
                <span class="error lh40">{{ expiration.error }}</span>
            </ul>
            <ul>
                <li><span>*</span> 证件照片：</li>
                <li class="item-inp">
                    <div class="item-file-img item-file-Front" :class="{'item-file-passport1':issued.value=='1'}" v-loading="frontLoading">
                        <input :disabled="disabled" title="点击上传图片" class="file" type="file" @change="changeFileFront">
                        <img :src="idPhotoFront.value.imgUrl" v-if="idPhotoFront.value.imgUrl"/>
                    </div>
                    <span class="error">{{ idPhotoFront.error }}</span>
                </li>
                <li class="item-inp">
                    <div class="item-file-img item-file-Back" :class="{'item-file-passport2':issued.value=='1'}" v-loading="backLoading">
                        <input :disabled="disabled" title="点击上传图片" class="file" type="file" @change="changeFileBack">
                        <img :src="idPhotoBack.value.imgUrl" v-if="idPhotoBack.value.imgUrl"/>
                    </div>
                    <span class="error">{{ idPhotoBack.error }}</span>
                </li>
            </ul>
            <!-- <formItem width="130" type="file" :imgObg="imgObg" title="上传图片" v-model="idPhotoFront.value" /> -->
            <ul class="p" style="margin-top:-30px">
                <li></li>
                <li>
                    <p>• &nbsp; 照片为免冠未化妆的数码照片原始图片，<span>请勿用任何软件修改</span></p>
                    <p>• &nbsp; 必须看清证件信息，且证件信息不能被遮挡</p>
                    <p>• &nbsp; 仅支持JPG、PNG、JPEG格式，<span>文件小于1M</span> </p>
                </li>
            </ul>
            <ul>
                <li></li>
                <li><xbutton :class="{'btn-dis':disabled}" :disabled="disabled" @on-click="goSubmit" value="提交审核" /></li>
            </ul>
        </div>
</template>

<script>
import formItem from '@/components/UI/formItem.vue'
import Xselect from '@/components/UI/select.vue'
import xbutton from '@/components/UI/xbutton.vue'
import authenTitle from '@/components/UI/authenTitle.vue'
/*import imgBg from '../../../assets/img/user/PhotoBack.jpg'*/
export default {
    props: {
        navData:Array
    },
    data(){
        return {
            disabled:false,
            id:"",
            cardType:"证件类型",
            status:'',
            /*imgObg:{
                img:imgBg,
                width:'80px',
                height:'80px'
            },*/
            name:{
                value:"",
                error:"",
                validate:['required'],
                name:"真实姓名"
            },
            idCard:{
                value:"",
                error:"",
                validate:['required'],
                min:6,
                name:"证件号码"
            },
            authority:{
                value:"",
                error:"",
                validate:['required'],
                name:"发证单位"
            },
            expiration:{
                value:"",
                error:"",
                validate:['required'],
                name:"有效期限"
            },
            issued:{
                value:"",
                label:"",
                error:"",
                validate:['required'],
                name:"证件类型"
            },
            idPhotoBack:{
                value:"",
                error:"",
                validate:['required'],
                name:"证件照"
            },
            idPhotoFront:{
                value:"",
                error:"",
                validate:['required'],
                name:"证件照"
            },
            cardList:[{id:0,name:'身份证'},{id:1,name:'护照'}],
            formShow:true,
            thisValue:"",
            error:"",
            dateType:'1',
            frontLoading:false,
            backLoading:false,
            audit:""
        }
    },
    computed:{
        titleVal:function(){
            var val = "";
            switch(this.status){
                case 1:
                    val = '等待审核';
                    break;
                case 2:
                    val = '非常抱歉，您的请求被驳回';
                    break;
                case 0:
                    val = '恭喜您，审核通过！'
                    break;
            }
            return val
        },
        userType:function(){
            return this.$store.state.userType
        }
    },
    components: {
        formItem,
        Xselect,
        xbutton,
        authenTitle
    },
    methods:{
        goSubmit:function(_this){
            var dataV = {
                authority:this.authority,
                expiration:this.expiration,
                idCard:this.idCard,
                idPhotoBack:this.idPhotoBack,
                idPhotoFront:this.idPhotoFront,
                issued:this.issued,
                name:this.name
            }
            var data = {};
            for(var key in dataV){
                data[key] = dataV[key].value
            }
            if (this.id) {
                data.id = this.id
            };
            if (this.dateType==='0') {
                delete dataV.expiration
                data.expiration = '0'
                this.expiration.error = ""
            };
            if (this.issued.value === '0') {
                dataV.idCard.validate[1] = 'IDcard'
            };
            /*if (this.issued.value === 1) {
                dataV.idCard.validate[1] = 'passport'
            };*/
            var validate = this.$com.validate(dataV)
            if (validate) {
                data.idPhotoBack = this.idPhotoBack.value.fileId;
                data.idPhotoFront = this.idPhotoFront.value.fileId;
                _this.loading = true;
                this.$com.majax.post(this.$com.testUsIp + '/personal/personalCertificate',this.$qs.stringify(data),{
                    success:(res)=>{
                        this.$dialog.alert("恭喜您，提交成功！请等待后台审核！",'success',() => {
                            this.getPersonalData()
                        },(res)=>{
                            res.thisShow = false;
                            this.getPersonalData()
                        })
                        //this.$router.push({ name: 'base_success',params: {meg: '恭喜您，提交成功！请等待后台审核',type:'success'}})
                    }
                },_this)
            };
        },
        changeFileFront:function(e){
            var files = e.target.files[0];
            if (files.type.indexOf('image')==-1) {
                this.$dialog.alert("只能上传图片",'confirm')
            }else if(files.size/1024>1024){
                this.$dialog.alert("图片大小不能超过1M",'confirm')
            }else{
                var image = new FormData();
                image.append('file', files);
                this.frontLoading = true
                this.$com.majax.post(this.$com.testImgin+'/upload/upload-file', image,{
                    success:(res) => {
                        this.frontLoading = false
                        this.idPhotoFront.value = res.data;
                    },
                    error:() => {
                        this.frontLoading = false
                        this.$dialog.alert('上传失败，请重试','error')
                    }
                })
            };
        },
        changeFileBack:function(e){
            var files = e.target.files[0];
            if (files.type.indexOf('image')==-1) {
                this.$dialog.alert("只能上传图片",'confirm')
            }else if(files.size/1024>1024){
                this.$dialog.alert("图片大小不能超过1M",'confirm')
            }else{
                var image = new FormData();
                image.append('file', files);
                this.backLoading = true
                this.$com.majax.post(this.$com.testImgin+'/upload/upload-file', image,{
                    success:(res) => {
                        this.backLoading = false
                        this.idPhotoBack.value = res.data;
                    },
                    error:() => {
                        this.backLoading = false
                        this.$dialog.alert('上传失败，请重试','error')
                    }
                })
            };
        },
        cardChange:function(data){
            this.issued.value = data.id
        },
        //获取个人认证信息
        getPersonalData:function(){
            this.$com.majax.get(this.$com.testUsIp+'/personal/selectPersonalByUser', {},{
                success:(res) => {
                    var data = res.data;
                    if (data) {
                        this.name.value = data.name;
                        this.authority.value = data.authority;
                        this.expiration.value = data.expiration;
                        this.idPhotoFront.value = {fileId:data.idPhotoFront,imgUrl:data.idPhotoFrontPath};
                        this.idPhotoBack.value = {fileId:data.idPhotoBack,imgUrl:data.idPhotoBackPath};
                        this.idCard.value = data.idCard;
                        this.issued.value = data.issued;
                        this.issued.label = this.$com.findName(res.data.issued,this.cardList);
                        this.audit = data.audit;
                        this.status = data.status;
                        this.id = data.id;

                        var userType = this.userType;
                        userType.personlStatus = data.status;
                        this.$store.commit('SET_userType',userType);
                        //日期为长期判断
                        if (data.expiration=='0'||data.expiration=='长期') {
                            this.expiration.value = "";
                            this.dateType = '0'
                        }
                        if (data.status===1||data.status===0) {
                            this.disabled = true
                        };
                        for (var i = 0; i < this.cardList.length; i++) {
                            var _data = this.cardList[i]
                            if (_data.id == data.issued) {
                                this.cardType = _data.name
                            };
                        };
                    }else{
                        this.status = 3
                    }
                }
            })
        },
        dateChange:function(){
            this.dateType = "1"
        }
    },
    mounted:function(){
        //this.$com.isLogin(this);
        this.$parent.formShow = false;
        this.$parent.$parent.titleMeg = '个人实名认证';

        this.getPersonalData()
    },
}
</script>

<style scoped>
    body{
        margin: 0;
        padding: 0;
    }
    ul,p{
        list-style: none;
        margin: 0;
        padding: 0;
    }
    div.no1{
        width:992px;height:39px;background:#fff;
        margin-bottom: 2px;
    }
    div.no1>span{
        display: block;
        font-size: 16px;
        line-height: 37px;
        text-align: center;
        width: 120px;
        border-bottom: 2px solid #FF4A03;
    }
    div.no2{
        width:992px;
        background:#fff;
        position: relative;
    }
    div.no2>div{
        margin:0 auto;
        margin-top:10px;width:950px;
        background:#f5f5f5;
        position: relative
    }
    div.no2>div>img{
        position: absolute;
        top: 12px;left: 60px;
    }
    div.no2>div>span:nth-child(2){
        position: absolute;
        top: 25px;
        left: 130px;
        color: #ff4a03;font-size: 18px;font-weight: bold
    }
    div.no2>div>span:nth-child(3){
        position: absolute;
        top: 54px;left: 130px;color: #999;font-size: 12px
    }
    div.no2 ul{
        display: flex;
        margin: 15px 0 20px 84px;
        background: #fff;
    }
    div.no2 ul:nth-child(3) li:nth-child(3),div.no2 ul:nth-child(5) li:nth-child(3){
        color:#999;font-size:12px;width:340px;
        line-height:15px;margin-top: 18px;margin-left: 10px
    }
    div.no2 ul>li:nth-child(1){
        width: 130px;
        text-align: right;
        font-size: 14px;
        color: #333;
        line-height:40px;
        margin-right:7px;
    }
    div.no2 span{
        color: red
    }
    div.no2 ul>li:nth-child(2) select{
        width: 180px;
        height: 40px;
        color: #666;
        padding-left: 10px;
        box-sizing: border-box;
        border: 1px solid #ccc;
    }
    div.no2 ul.date>li:nth-child(2){
        position: relative;
    }
    div.no2 ul.date>li:nth-child(2)>img{
        position: absolute;
        right: 10px;
        top: 25px
    }
    div.no2 ul.date>li:nth-child(2) input{
        width: 180px;
        height: 40px;
    }
    div.no2 ul.date>li:nth-child(3) span{
        color: #666;
        font-size: 14px
    }
    div.no2 ul.p>li:nth-child(2){
        margin-top: 12px;
    }
    div.no2 ul.p>li:nth-child(2)>p{
        padding: 0;
        line-height: 20px;
        font-size: 12px;
        color: #666;
        background: #fff
    }
    div.no2 ul>li:nth-child(2) button{
        width: 180px;
        height: 40px;
        color: #fff;
        background: #3296FA;
        border: 0;
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 40px
    }
    div.no2 ul>li:nth-child(2) button:hover{
        background: linear-gradient(to right, #00aaff, #0085ff);
        cursor: pointer;
    }
    .item-inp{
        margin-right:15px;
    }
    .item-inp-cq{
        line-height:40px;
    }
    .item-file-img{
        width:244px;
        height:154px;
        position:relative;
    }
    .item-file-Back{
        background:url('../../../assets/img/user/PhotoBack.jpg');
    }
    .item-file-Front{
        background:url('../../../assets/img/user/PhotoFront.jpg');
    }
    .item-file-passport1{
        background:url('../../../assets/img/user/passport1.jpg');
    }
    .item-file-passport2{
        background:url('../../../assets/img/user/passport2.jpg');
    }
    .item-file-img .file{
        position: absolute;
        width:100%;
        height:100%;
        cursor:pointer;
        opacity:0;
        text-indent:-100px;
    }
    .item-file-img img{
        height:100%;
        width:100%;
    }
    div.no2 ul>li:nth-child(2) button.btn-dis{
        background:#ccc;
        cursor:not-allowed;
    }
    div.no2 ul.date>li:nth-child(2) input{
        width:auto;
        height:auto;
    }
    .auth-title img{
        position: absolute;
        top: 16px;
        left: 130px;
        width: 68px;
    }
    .error{
        font-size:14px;
        display:inline-block;
        margin-left:5px;
    }
    .lh40{
        line-height:40px;
    }
    .authen-title{
        padding:0 0 0 132px;
    }
</style>
