<template>
    <div>
        <div class="header">
            <div class="main01">
                <router-link to="/"><img src="../assets/img/index/logo.png" alt=""></router-link>
                <div></div>
                <span>支付结算</span>
            </div>
        </div>
        <div class="section">
            <div class="top">
                <p>订单提交成功，请及时付款！订单号为：<span>{{orderData.orderNo}}</span> </p>
                <p>请您在<span>24小时</span>内完成支付，否则订单会自动取消。</p>
                <p>应付总金额：<span>￥{{orderData.consultPrice}}</span></p>
            </div>
            <div></div>
            <div class="bottom">
                <table></table>
                <div class="xq">
                    <el-radio-group v-model="radio2" :disabled="disabled">
                        <!-- <el-radio :label="1"><span class="sp" :class="{active : radio2==1}"><img src="../assets/img/index/WeChat Pay.png" alt="">微信支付</span></el-radio> -->
                        <el-radio :label="2"><span class="sp" :class="{active : radio2==2}"><img src="../assets/img/index/Alipay pay.png" alt="">支付宝</span></el-radio>
                        <!-- <el-radio :label="3"><span class="sp" :class="{active : radio2==3}"><img src="../assets/img/index/CUP.png" alt="">中国银联</span></el-radio> -->
                    </el-radio-group>
                </div>
                <div class="xb">
                    <xbutton :disabled="disabled" @on-click="goPay" value="确 定"></xbutton>
                </div>
            </div>
            <div v-html="formData">
                {{formData}}
            </div>
            <el-dialog title="支付提示" :visible.sync="dialogFormVisible" :close-on-click-modal="false" :close-on-press-escape="false">
                <div>
                    <!-- <p class="time_out">请于30分钟内，在新开的页面完成支付</p> -->
                    <div class="pay-item">
                        <span class="el-icon-success success-i icon"></span>
                        <span>如已经完成支付，请点击</span>
                        <button type="button" class="i-f56c6c" @click="reCheck">已完成付款</button>
                        <p v-if="payStatus===3">未支付成功，若已付款，请重新点击按钮</p>
                    </div>

                    <div class="pay-item">
                        <span class="el-icon-warning warn-i icon"></span>
                        <span>如付款遇到问题，你可以</span>
                        <button type="button" class="i-3296fa" @click="goPay">重新支付</button>
                    </div>
                </div>
            </el-dialog>
        </div>
        <Footer />
    </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import xbutton from '@/components/UI/xbutton.vue'
export default {
    components: {
        Footer,
        xbutton
    },
    data(){
        return {
            dialogFormVisible:false,
            radio2:1,
            disabled:false,
            orderData:{},
            formData:"",
            payStatus:"",
            id:this.$route.query.id||""
        }
    },
    methods:{
        goPay(_this){
            if (this.radio2==2) {
                this.GoAlipay(_this);
            }else{
                this.$message.warning('请选择支付方式');
            };
        },
        reCheck:function(){
            this.$com.majax.get(this.$com.testCsIp + '/order/getStatusByOrderNo/' + this.orderData.orderNo,{},{
                success:(res)=>{
                    this.payStatus = res.data;
                    if (res.data===4) {
                        this.$router.push({ name:'buy_order_details', query:  { id:this.id }})
                    };
                },
            })
        },
        GetOrderDet:function(){
            this.$axios.get(this.$com.testCsIp + '/order/getOrderDetailsById/' + this.id)
            .then(res=>{
                var data = res.data;
                if (data.code===0) {
                    this.orderData = data.data;
                }else{
                    this.$dialog.alert(data.msg,'error')
                };
            })
        },
        GoAlipay:function(_this){
            _this.loading = true;
            var data = {orderNo:this.orderData.orderNo};
            this.$com.majax.post(this.$com.testInin + '/alipay/gotopay',this.$qs.stringify(data),{
                success:(res)=>{
                    var _data = {enStr:res.data,orderNo:this.orderData.orderNo,title:this.orderData.serviceName}
                    this.$com.majax.post(this.$com.testInin + '/alipay/pay',this.$qs.stringify(_data),{
                        success:(_res)=>{
                            this.formData = _res.data;
                            var form = document.getElementsByName('punchout_form');
                            setTimeout(()=>{
                                form[0].setAttribute("target","_blank");
                                form[0].submit();
                                this.dialogFormVisible = true;
                                _this.loading = false;
                            },200)
                        },
                        error:(res)=>{
                            this.$dialog.alert(res.msg,'error')
                            _this.loading = false;
                        }
                    })
                },
                error:(res)=>{
                    this.$dialog.alert(res.msg,'error')
                    _this.loading = false;
                }
            })
        }
    },
    mounted:function(){
        this.GetOrderDet()
    }
}
</script>


<style scoped>
body,ul{
    list-style: none;
    margin: 0;
    padding: 0;
}
.header{
    width: 100%;
    min-width: 1200px;
    height: 102px;
}
.header .main01{
    width: 1200px;
    margin: 0 auto;
    position: relative;
}
.main01 img:first-child{
    position: absolute;
    top: 22px;
    left:0;
}
.main01 div:nth-child(2){
    position: absolute;
    top:35px;
    left: 182px;
    height: 35px;
    border: 0.5px solid #CCDCF9;
}
.main01 span:nth-child(3){
    position: absolute;
    top:35px;
    left:202px;
    font-size: 20px;
    color: #666
}
.section{
    background-color: #F5F5F5;
    width: 100%;
    min-width: 1200px;
    height: 345px;
    position: relative;
}
.section>div{
    margin: 0 auto;
    width: 1200px;
    min-width: 1200px;
}
.section>div:nth-child(2){
    clear: both;
}
.top p:first-child{
    margin-top: 30px;
    font-size: 14px;
    color: #333;
}
.top p:nth-child(2){
    color: #999;
    font-size: 12px
}
.top p:last-child{
    float: right;
    color: #666;
    font-size: 12px;
    margin-top: -34px;
}
.top p:last-child span{
    font-weight: bold;
    font-size: 28px;
}
.top span{
    color: #ff4a03
}
.bottom{
    height: 250px;
    background: #fff;
    margin-top: 13px;
}
.xb{
    margin: 60px auto;
    width: 150px;
}
.sp{
    display: inline-block;
    width: 160px;
    height: 47px;
    border: 1px solid #ddd;
    line-height: 47px;
    text-align: center;
}
.sp img{
    height: 26px;
    margin-right: 11px;
}
.xq{
    margin: 50px 0 0  30px;
}
.active{
    border: 1px solid #409EFF
}
.el-radio+.el-radio{
    margin-left: 60px !important;
}
.icon{
    font-size:28px;
}
.success-i{
    color:#67c23a;
}
.warn-i{
    color:#fac332;
}
.pay-item,.time_out{
    margin-bottom:20px;
}
.pay-item span,.pay-item button{
    display:inline-block;
    vertical-align:middle;
    margin-right:6px;
}
.pay-item p{
    color:red;
}
.pay-item button{
    border:none;
    font-size:16px;
    color:#fff;
    padding:3px 10px;
    border-radius:4px;
    cursor:pointer;
}
.i-f56c6c{
    background:#f56c6c;
}
.i-3296fa{
    background:#3296fa;
}
</style>
